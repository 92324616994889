import gql from 'graphql-tag'

const getInvestorStats = gql`
  query GetInvestorStats {
    investorStats{
      investorId
      totalInvested
      countInvestedCompanies
      investedGroupedByYear{
        year
        total
        companies{
          id
          name
          slug
        }
      }
    }
  }
`

export default getInvestorStats
