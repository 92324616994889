import TypeForm from '../TypeForm'
import Question from '../Question'
import Skeleton from 'react-loading-skeleton'
import React, { useState } from 'react'

const PaddedWrapper = ({ children, ...rest }) => <section {...rest}>{children}</section>

/*
  It renders all the questions except hidden fields
*/
const renderQuestions = ({ questions, state, dispatch, questionClass, next }) => questions.sort((a, b) => a.order - b.order).filter(q => q.type !== 'hidden_field').map((question, index) => {
  return <PaddedWrapper key={question.id} className={questionClass}>
    {index + 1}.
    <Question
      className={questionClass}
      question={question}
      onChange={event => handleDispatch(dispatch, question, event)}
      onSubmit={next}
      value={() => state.questions[question.id]}
    />
  </PaddedWrapper>
})

const handleDispatch = (dispatch, question, event) => {
  if (question.type === 'boolean') {
    return dispatch({ type: 'setQuestionValue', questionId: question.id, value: event })
  }
  if (question.type === 'multi_select') {
    return dispatch({ type: 'setQuestionValue', questionId: question.id, value: event.toArray() })
  }
  if (question.type === 'single_select') {
    return dispatch({ type: 'setQuestionValue', questionId: question.id, value: event })
  }
  if (question.type === 'file') {
    return dispatch({ type: 'setQuestionValue', questionId: question.id, value: event })
  }
  if (question.type === 'investment_range') {
    return dispatch({ type: 'setQuestionValue', questionId: question.id, value: event })
  }
  if (question.type === 'tag') {
    return dispatch({ type: 'setQuestionValue', questionId: question.id, value: event })
  }
  if (question.type === 'experience') {
    const value = JSON.stringify(event)
    return dispatch({ type: 'setQuestionValue', questionId: question.id, value })
  }
  if (question.type === 'date') {
    return dispatch({ type: 'setQuestionValue', questionId: question.id, value: event })
  }
  return dispatch({ type: 'setQuestionValue', questionId: question.id, value: event.target.value })
}

/*
  It creates hidden fields in the HTML
*/
const renderHiddenFields = ({ questions, submitQuestion, formKey, hiddenFieldsKeyValues, state, dispatch }) => questions.filter(q => q.type === 'hidden_field').map(question => {
  let value = hiddenFieldsKeyValues[question.key]
  if (state.questions[question.id] !== value) {
    dispatch({ type: 'setQuestionValue', questionId: question.id, value: value })

    let questionSubmissions = getQuestionSubmissionsArgs(state.questions)
    let questionSubmission = questionSubmissions.find(qs => qs.id === question.id)

    questionSubmissions.find(q => q.id === question.id).value = value

    questionSubmission = questionSubmissions.find(a => a.id === question.id)

    if (questionSubmission) {
      submitQuestion({
        variables: {
          formKey,
          questionSubmission
        }
      })
    }
  }
  return <input type="hidden" key={question.key} id={question.key} name={question.key} value={value} />
})

const LaunchForm = ({
  questions,
  state,
  formKey,
  submitQuestion,
  nextBtnOnClick,
  dispatch,
  backBtnClass,
  nextBtnClass,
  submitBtnClass,
  hiddenFieldsKeyValues,
  questionClass,
  questionIndex,
  setQuestionIndex,
  onSubmit,
  continueFormSubmissionLater,
  updateShowReviewView
}) => {
  console.log('launch form')
  return <>
    <TypeForm
      continueFormSubmissionLater={continueFormSubmissionLater}
      questionIndex={questionIndex}
      setQuestionIndex={setQuestionIndex}
      backBtnClass={backBtnClass}
      nextBtnClass={nextBtnClass}
      submitBtnClass={submitBtnClass}
      nextBtnOnClick={nextBtnOnClick}
      questionClass={questionClass}
      state={state}
      formKey={formKey}
      updateShowReviewView={updateShowReviewView}
      showReviewView={false}
      questions={questions}
      submitBtnText={formKey === 'wait_list' ? 'Join Wait List' : 'Save'}
      dispatch={dispatch}
      onSubmit={onSubmit}
    >
      {
        ({ next, back }) => questions
          ? renderQuestions({ questions, state, dispatch, questionClass, next, back })
          : <Skeleton count={5} />
      }
    </TypeForm>
    { renderHiddenFields({ formKey, questions, submitQuestion, hiddenFieldsKeyValues, state, dispatch }) }
  </>
}

export default LaunchForm
