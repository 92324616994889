import { TextField } from '@material-ui/core'
import React from 'react'

const LongTextArea = ({ label, fieldName, value, onChange, classes }) => {
  return <TextField
    fullWidth
    multiline
    className={classes.textInput}
    label={label}
    value={value}
    onChange={onChange}
  />
}

export default LongTextArea
