import { List, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React, { useEffect, useRef } from 'react'
import Message from './Message'
import NewMessageFormContainer from './NewMessageFormContainer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComment } from '@fortawesome/pro-solid-svg-icons'

const styles = theme => ({
  modal: {
    width: '80%',
    height: '80%',
    margin: 'auto'
  },
  newMessageWrapper: {
    width: '100%'
  },
  list: {
    maxHeight: '30rem',
    width: '100%',
    overflow: 'auto'
  }
})

const FullDiscussionBase = props => {
  let { messages, id, onCreateMessage, classes, executeReading } = props
  let count = messages ? messages.length - 1 : 0
  const messagesEndRef = useRef(null)

  const scrollToBottom = () => {
    messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight
  }

  useEffect(scrollToBottom, [messages]);
  return (
    <>
      <List ref={messagesEndRef} className={classes.list}>
        {messages &&
          messages.map((message, index) => {
            return (
              <Message
                variant={index === 0 ? 'principal' : 'default'}
                key={message.id}
                message={message}
                executeReading={executeReading}
              />
            )
          })}
        {!messages && (
          <>
            <Message />
          </>
        )}
      </List>
      <div className={classes.newMessageWrapper}>
        <NewMessageFormContainer
          discussionId={id}
          onCreateMessage={onCreateMessage}
        />
      </div>
    </>
  )
}

export const FullDiscussion = withStyles(styles)(FullDiscussionBase)

class Discussion extends React.Component {
  render () {
    let { messages, classes, id, overviewBodyMap, handleOpen, executeReading } = this.props
    let firstMessage = { ...(messages || [])[0] }
    const replyCount = ((messages || []).length) - 1

    overviewBodyMap = overviewBodyMap || (body => body)

    firstMessage = firstMessage && {
      ...firstMessage,
      body: overviewBodyMap(firstMessage.body)
    }

    return (
      <div>
        <Message message={firstMessage}
          executeReading={executeReading}
          metadata={
            <Typography style={{ display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              width: '100%' }}>
              <FontAwesomeIcon icon={faComment} />
              {replyCount}
            </Typography>}
          onClick={() => this.handleOpen(id)} />
      </div>
    )
  }
}

export default withStyles(styles)(Discussion)
