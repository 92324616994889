import { Typography } from '@material-ui/core'
import ahoy from 'ahoy.js'
import React, { useState, useEffect } from 'react'
import { Mutation, Query } from 'react-apollo'
import {getUnreadConversationsAndUpdates} from '../../graphql/queries/getNotifications'
import Skeleton from 'react-loading-skeleton'
import { Switch, Redirect } from 'react-router'
import { Route } from 'react-router-dom'
import CreateDealDiscussion from '../../graphql/mutations/CreateDealDiscussion'
import PassOnDeal from '../../graphql/mutations/PassOnDeal'
import RequestAllocation from '../../graphql/mutations/RequestAllocation'
import SubmitSupplementalInvestmentInformation from '../../graphql/mutations/SubmitSupplementalInvestmentInformation'
import getDeal from '../../graphql/queries/getDeal'
import getDescriptionLegalNameOfInvestment from '../../graphql/queries/getDescriptionLegalNameOfInvestment'
import DealShow from '../deal/DealShow'
import AppLayout from '../layout/AppLayout'
import CenterColumn from '../layout/CenterColumn'
import Title from '../layout/Title'
import DealConversationModalRoute from '../routes/DealConversationModalRoute'
import PassAllocationModalRoute from './PassAllocationModalRoute'
import RequestAllocationModalRoute from './RequestAllocationModalRoute'
import SupplementalInvestmentInformationModalRoute from './SupplementalInvestmentInformationModalRoute'
import { withSnackbar } from 'notistack'

function DealRoute ({ match, history, enqueueSnackbar }) {
  const { params: { slug, id } } = match
  const [requestAllocationModalIsOpen, setRequestAllocationModalIsOpen] = useState(true)
  const [passAllocationModalIsOpen, setPassAllocationModalIsOpen] = useState(true)
  const [supplementalModalIsOpen, setSupplementalModalIsOpen] = useState(true)
  const [investorResponseId, setInvestorResponseId] = useState('')
  const onGetDealQuery = data => {
    if (data && data.company && data.company.deal) {
      ahoy.track('Viewed Deal', { deal_id: data.company.deal.id })
    }
  }
  useEffect(() => {
    document.title = "The Syndicate - Deal"
  }, []);

  return <Query query={getDescriptionLegalNameOfInvestment} fetchPolicy="network-only">
    {({ data, loading, error }) => {
      let descriptionOfLegalName
      if (!loading) {
        descriptionOfLegalName = data.descriptionLegalNameOfInvesmentText.descriptionText
      }

      return <Query
        query={getDeal}
        variables={{ companySlug: slug, id }}
        fetchPolicy="network-only"
        onCompleted={onGetDealQuery}
      >
        {({ loading, error, data, refetch, networkStatus }) => {
          if (error) {
            if (error.message && error.message.includes('unauthorized')) {
              enqueueSnackbar("You don't have access to see this.", { variant: 'error' })
              return <Redirect
                to={`/login`}
              />
            } else {
              return <Typography>Error...</Typography>
            }
          }

          let company, deal
          let unreadConversations = []

          if (!loading) {
            company = data.company
            deal = company.deal
            deal.dealDiscussions = (deal.dealDiscussions || []).sort((a,b) => {
              return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
            }).reverse()
            unreadConversations = deal.unreadConversations.sort((a,b) => {
              return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
            }).reverse()
          } else {
            return null
          }

          const title = <Title>
            {(company && deal && `${company.name} - Deal Name Goes Here`) || <Skeleton />}
          </Title>

          return (
            <Mutation
              mutation={RequestAllocation}
              onCompleted={(data) => {
                if (data.requestAllocation) {
                  setInvestorResponseId(data.requestAllocation.id)
                }
                refetch()
              }}
            >
              {requestAllocation => {
                return <Mutation
                  mutation={SubmitSupplementalInvestmentInformation}
                  onCompleted={refetch}
                >
                  {submitSupplementalInvestmentInformation => {
                    return (
                      <Mutation
                        mutation={PassOnDeal}
                        onCompleted={refetch}
                      >
                        {passOnDeal => {
                          return (
                            <Mutation
                              mutation={CreateDealDiscussion}
                              onCompleted={refetch}
                            >
                              {createDealDiscussion => {
                                return (

                                  <AppLayout title={title}>
                                    <CenterColumn>
                                      <DealShow
                                        createDealDiscussion={(body) => createDealDiscussion({ variables: { dealId: id, messageInput: { body } } })}
                                        refetch={refetch}
                                        onCreateMessage={refetch}
                                        company={company}
                                        history={history}
                                        unreadUpdates={unreadConversations}
                                        {...deal}
                                      />
                                      <Switch>
                                        <Route path={`${match.url}/deal_discussions/:id`} render={(props) => <DealConversationModalRoute companySlug={slug} {...props} onClose={() => history.push('../')} />} />
                                        <Route
                                          path={`${match.url}/request_allocation/:value?`}
                                          render={(props) => <RequestAllocationModalRoute {...props}
                                            requestAllocationModalIsOpen={requestAllocationModalIsOpen}
                                            requestAllocation={(amount, hasExperience) => requestAllocation({ variables: { dealId: id, amount, hasExperience } })}
                                            companySlug={slug}
                                            company={company}
                                            history={history}
                                            deal={deal}
                                            onInterested={() => {
                                              setRequestAllocationModalIsOpen(false)
                                            }}
                                            onClose={() => {
                                              history.replace('./')
                                              setRequestAllocationModalIsOpen(false)
                                            }} />}
                                        />
                                        <Route
                                          path={`${match.url}/pass_allocation`}
                                          render={(props) => <PassAllocationModalRoute {...props}
                                            passAllocationModalIsOpen={passAllocationModalIsOpen}
                                            passOnDeal={(passReason) => passOnDeal({ variables: { dealId: id, passReason } })}
                                            companySlug={slug}
                                            onClose={() => {
                                              history.replace('./')
                                              setPassAllocationModalIsOpen(false)
                                            }} />}
                                        />
                                      </Switch>
                                    </CenterColumn>
                                  </AppLayout>)
                              }}</Mutation>

                          )
                        }}</Mutation>)
                  }}</Mutation>
              }}</Mutation>)
        }}
      </Query>
    }}
  </Query>
}

export default withSnackbar(DealRoute)
