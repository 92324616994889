import { Map, Record } from 'immutable'

export default class ChoiceType
  extends Record({
    id: '',
    questionId: '',
    metadata: new Map(),
    order: 1,
    maximumChosen: 0,
    label: '',
    disabled: false,
    persisted: false
  }) { }
