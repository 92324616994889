import React from 'react'
import { MuiThemeProvider } from '@material-ui/core/styles'
import AppRouter from './AppRouter'
import AppWithGraphQL from '../graphql/AppWithGraphQL'
import { SnackbarProvider } from 'notistack'
import ahoy from 'ahoy.js'
import { withStyles } from '@material-ui/core/styles'
import {
  blueRibbon
} from './layout/colors'
import theme from './layout/theme'
import 'video-react/dist/video-react.css'
import WithSentry from './WithSentry'

export const AppContext = React.createContext('token')

const styles = {
  success: {},
  error: {},
  warning: {},
  info: {
    backgroundColor: blueRibbon
  }
}

const App = ({
  token,
  classes
}) => {
  ahoy.trackAll()
  return <WithSentry>
    <AppWithGraphQL token={token}>
      <AppContext.Provider value={{ token }}>
        <MuiThemeProvider theme={theme}>
          <SnackbarProvider
            classes={{
              variantSuccess: classes.success,
              variantError: classes.error,
              variantWarning: classes.warning,
              variantInfo: classes.info
            }}
            maxSnack={3}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
          >
            <AppRouter />
          </SnackbarProvider>
        </MuiThemeProvider>
      </AppContext.Provider>
    </AppWithGraphQL>
  </WithSentry>
}

export default withStyles(styles)(App)
