import { AppBar, Dialog, DialogContent, IconButton, Slide, Toolbar, Typography, withMobileDialog } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import React, {useEffect} from 'react'
import { Query } from 'react-apollo'
import GetDealDiscussion from '../../graphql/queries/getDealDiscussion'
import { FullDiscussion } from '../core/Discussion'

const styles = theme => ({
  modal: {
    '& .MuiDialog-paperFullWidth': {
      borderRadius: '.5rem',
      maxWidth: '45rem',
      width: '95%',
      height: 'unset',
      maxHeight: '80%'
    },
    '& .MuiDialogContent-root': {
      padding: '0 1.5rem 2rem',
      overFlow: 'hidden'
    }
  },
  appBar: {
    backgroundColor: theme.palette.info.light,
    position: 'relative',
    paddingRight: '0 !important',
    padding: '.5rem 1.5rem'
  },
  appBarTitle: {
    flex: 1
  },
  appBarIcon: {
    marginRight: '-1rem'
  },
  toolbar: {
    justifyContent: 'flex-end'
  }
})

function Transition (props) {
  return <Slide direction="up" {...props} />
}

function DealConversationModalRoute ({
  match: {
    params: { id }
  },
  fullScreen,
  companySlug,
  onClose,
  classes
}) {
  useEffect(() => {
    document.title = "The Syndicate - Deal COnversation Modal"
  }, []);
  return (
    <Dialog
      fullScreen
      TransitionComponent={Transition}
      open
      onClose={onClose}
      scroll="paper"
      className={classes.modal}
    >
      <AppBar
        color="default"
        elevation={0}
        className={classes.appBar}
      >
        <Toolbar
          className={classes.toolbar}
        >
          <Typography variant="h6" color="inherit" className={classes.appBarTitle}>
            Discussion
          </Typography>
          <IconButton
            color="inherit"
            onClick={onClose}
            aria-label="Close"
            className={classes.appBarIcon}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Query
          query={GetDealDiscussion}
          variables={{ id }}
          fetchPolicy="cache-and-network"
        >
          {({ loading, error, data, refetch, networkStatus }) => {
            if (error) {
              return <Typography>Error...</Typography>
            }

            let dealDiscussion
            let discussion
            if (!loading) {
              dealDiscussion = data.dealDiscussion
              discussion = dealDiscussion.discussion
            }

            return (
              <FullDiscussion executeReading {...discussion} onCreateMessage={refetch} />
            )
          }}
        </Query>
      </DialogContent>
    </Dialog>
  )
}

export default withStyles(styles)(withMobileDialog()(DealConversationModalRoute))
