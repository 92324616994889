import { Typography, withMobileDialog } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import EditableImageGallery from '../core/EditableImageGallery'
import ImageGallery from '../core/ImageGallery.jsx'
import TitledCard from '../core/TitledCard'
import { blackText } from '../layout/styleComponents'

const styles = theme => ({
  whiteBackgroundContainer: {
    background: 'white'
  },
  text: {
    ...blackText,
    fontSize: '1rem',
    textAlign: 'left'
  }
})

function ProductHero (props) {
  const {
    classes,
    imageGalleryItemRef,
    refetch,
    slug,
    productImages,
    productDescription,
    can
  } = props
  let imageGallery = <Skeleton height={250} />

  if (can) {
    if (can.manage) {
      imageGallery = (
        <EditableImageGallery
          imageGalleryItemRef={imageGalleryItemRef}
          items={productImages}
          showPlayButton={false}
          onUpdate={refetch}
          companySlug={slug}
        />
      )
    } else {
      if (productImages.length > 0) {
        imageGallery = (
          <ImageGallery items={productImages} showPlayButton={false} />
        )
      } else {
        imageGallery = null
      }
    }
  }

  return (
    <>
      <TitledCard
        className={classes.whiteBackgroundContainer}
        white
        media={imageGallery}
        content={
          <Typography className={classes.text}>{productDescription || 'No description yet'}</Typography>
        }
      />
    </>
  )
}

export default withStyles(styles)(withMobileDialog()(ProductHero))
