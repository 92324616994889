import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import { Link } from 'react-router-dom'
import TileHeading from '../core/TileHeading'
import { bigStone, gullGray } from '../layout/colors'
import { smallTitle } from '../layout/styleComponents'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome} from '@fortawesome/pro-solid-svg-icons'

const styles = theme => ({
  person: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '& .icon': {
      border: `.05rem solid ${gullGray}`,
      borderRadius: '50%',
      padding: '1rem .75rem',
      color: gullGray,
      fontSize: '3rem',
      height: '3.3rem',
      opacity: 0,
      transition: 'all .25s'
    },
    '&:hover .icon': {
      opacity: 1
    }
  },
  innerPerson: {
    alignItems: 'flex-start',
    display: 'flex',
    maxWidth: '26.5rem',
    '& div': {
      maxWidth: '20rem'
    }
  },
  avatar: {
    borderRadius: '50%',
    boxShadow: `0 0 1.5rem -1.4rem ${bigStone}`,
    height: '6.5rem',
    margin: 0,
    marginRight: '1rem',
    width: '6.5rem',
  },
  link: {
    ...smallTitle,
    fontSize: '1.15rem',
    fontWeight: 500,
    textDecoration: 'none',
    textAlign: 'left'
  }
})

const Person = props => {
  const { classes, name, avatarUrl, bio, url } = props
  return <Link to={url}  className={classes.person}>
    <div className={classes.innerPerson}>
      <figure style={{background: `url(${avatarUrl}) center center no-repeat`, backgroundSize: 'cover'}} className={classes.avatar}/>
      <TileHeading
        primary={<Link to={url} className={classes.link}>{name}</Link>}
        secondary={bio}
      />
    </div>
    <FontAwesomeIcon icon={faHome} className="icon"/>
  </Link>
}

export default withStyles(styles)(Person)
