import { Avatar as MuiAvatar, Badge } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'

const styles = theme => ({
  avatar: {
    height: 'auto',
    width: 'auto',
    border: '1px solid transparent',
    transition: 'all .25s',
    '&:hover': {
      border: `1px solid ${theme.palette.info.main}`
    },
    '& img': {
      height: '45px',
      width: '45px'
    }
  },
  badge: {
    margin: theme.spacing.unit * 2
  }
})

const Avatar = props => {
  const { profile, classes } = props
  const { unreadNotificationsCount, unreadConversationsCount } = profile
  const totalUnreadMessagesCount =
    unreadConversationsCount + unreadNotificationsCount
  const hideBadge = Boolean(props.hideBadge)

  if (hideBadge) {
    return <MuiAvatar src={profile.avatarUrl || '/user.png'} className={classes.avatar} />
  } else {
    return (
      <Badge
        className={classes.margin}
        badgeContent={totalUnreadMessagesCount}
        color="primary"
      >
        <MuiAvatar src={profile.avatarUrl} className={classes.avatar} />
      </Badge>
    )
  }
}

export default withStyles(styles)(Avatar)
