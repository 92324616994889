import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { withSnackbar } from 'notistack'
import React, { useState, useEffect } from 'react'
import { Mutation, Query } from 'react-apollo'
import Skeleton from 'react-loading-skeleton'
import { Redirect, Switch } from 'react-router'
import { Route } from 'react-router-dom'
import DeleteUserExperience from '../../graphql/mutations/DeleteUserExperience'
import FollowUser from '../../graphql/mutations/FollowUser'
import RequestMeeting from '../../graphql/mutations/RequestMeeting'
import SetDefaultCompany from '../../graphql/mutations/SetDefaultCompany'
import UnfollowUser from '../../graphql/mutations/UnfollowUser'
import getUserBySlug from '../../graphql/queries/getUserBySlug'
import { AppContext } from '../App'
import AppLayout from '../layout/AppLayout'
import CenterColumn from '../layout/CenterColumn'
import Title from '../layout/Title'
import UserProfile from '../user/UserProfile'
import UserUpdateModalRoute from './UserUpdateModalRoute'
import getPendingApplicationPageContent from '../../graphql/queries/getPendingApplicationPageContent'
import hasPendingApplication from '../../graphql/queries/hasPendingApplication'

const styles = theme => ({})

function UserProfileRoute ({
  match,
  history,
  enqueueSnackbar
}) {
  const {
    params: { slug }
  } = match
  const [isOpenSnackbar, setSnackbarOpen] = useState(false)
  const [unauthorized, setUnauthorized] = useState(false)
  useEffect(() => {
    document.title = "The Syndicate - Profile"
  }, []);
  return (
    <AppContext.Consumer>
      {context => {
        const token = context.token

        return <Query
          query={hasPendingApplication}
        >
          {({ data }) => {
            let hasPendingApplication

            if (data.hasPendingApplication && data.hasPendingApplication) {
              hasPendingApplication = data.hasPendingApplication
            }
            return <Query
              query={getPendingApplicationPageContent}
            >
              {({ data }) => {
                let pendingApplicationContent

                if (data.pendingApplicationContent && data.pendingApplicationContent.pendingContent) {
                  pendingApplicationContent = data.pendingApplicationContent.pendingContent
                }
                return <Query
                  query={getUserBySlug}
                  variables={{ id: slug }
                  }
                  fetchPolicy="cache-and-network"
                >
                  {({ loading, error, data, refetch }) => {
                    if (error) {
                      if (error.message && error.message.includes('unauthorized')) {
                        enqueueSnackbar("You don't have access to view that user.", { variant: 'error' })
                        return <Redirect
                          to={`/login/?redirect_to=users,${slug}`}
                        />
                      } else {
                        return <Typography>Error...</Typography>
                      }
                    }

                    let user = {}
                    if (!loading && data && data.user) {
                      user = data.user
                    }
                    const followUnfollowMutation = (user && user.following) ? UnfollowUser : FollowUser
                    const title = <Title>{user.name || <Skeleton />}</Title>

                    return (
                      <Mutation
                        onCompleted={data => {
                          if (data) {
                            enqueueSnackbar('User experience deleted', { variant: 'success' })
                            refetch()
                          }
                        }}
                        mutation={DeleteUserExperience}
                      >
                        {deleteUserExperience => {
                          return <Mutation
                            onCompleted={data => {
                              if (data) {
                                setSnackbarOpen(true)
                              }
                            }}
                            mutation={RequestMeeting}
                          >
                            {requestMeeting => {
                              return (
                                <AppLayout title={title}>
                                  <CenterColumn>
                                    <Mutation
                                      onCompleted={data => {
                                        if (data) {
                                          refetch()
                                        }
                                      }}
                                      mutation={SetDefaultCompany}
                                    >
                                      {setDefaultCompany => {
                                        return <Mutation
                                          onCompleted={data => {
                                            if (data) {
                                              refetch()
                                            }
                                          }}
                                          mutation={followUnfollowMutation}
                                        >
                                          {followUnfollow => {
                                            const onFollowUnfollow = () => followUnfollow({ variables: { id: slug } })
                                            return <UserProfile
                                              hasPendingApplication={hasPendingApplication}
                                              pendingApplicationContent={pendingApplicationContent}
                                              deleteUserExperience={(id) => deleteUserExperience({ variables: { id } })}
                                              unauthorized={unauthorized}
                                              token={token}
                                              setDefaultCompany={(id) => setDefaultCompany({ variables: { id } })}
                                              history={history}
                                              setSnackbarOpen={setSnackbarOpen}
                                              isOpenSnackbar={isOpenSnackbar}
                                              refetch={refetch}
                                              requestMeeting={message =>
                                                requestMeeting({
                                                  variables: {
                                                    to: { user: slug },
                                                    message: message
                                                  }
                                                })
                                              }
                                              loading={loading}
                                              onFollowUnfollow={onFollowUnfollow}
                                              {...user}
                                            />
                                          }}
                                        </Mutation>
                                      }}
                                    </Mutation>
                                    <Switch>
                                      <Route
                                        path={`${match.url}/updates/:id`}
                                        render={props => (
                                          <UserUpdateModalRoute
                                            {...props}
                                            userSlug={slug}
                                            onClose={() => history.push('../')}
                                          />
                                        )}
                                      />
                                    </Switch>
                                  </CenterColumn>
                                </AppLayout>
                              )
                            }}
                          </Mutation>
                        }}</Mutation>)
                  }}
                </Query>
              }}
            </Query>
          }}
        </Query>
      }
      }
    </AppContext.Consumer >)
}

export default withStyles(styles, { withTheme: true })(withSnackbar(UserProfileRoute))
