import React from 'react'
import Boolean from './questions/Boolean'
import Experience from './questions/Experience'
import File from './questions/File'
import InvestmentRange from './questions/InvestmentRange'
import MultiLine from './questions/MultiLine'
import MultiSelect from './questions/MultiSelect'
import OneLine from './questions/OneLine'
import SelectBox from './questions/SelectBox'
import SingleSelect from './questions/SingleSelect'
import TagContainer from './questions/TagContainer'
import Date from './questions/Date'

const Question = ({ question, onChange, value: valThunk, onSubmit }) => {
  let value = valThunk()
  const defaultProps = {
    label: question.label,
    placeholderKey: question.key,
    content: question.content,
    placeholder: question.placeholder,
    required: question.required,
    onSubmit,
    onChange,
    value
  }
  switch (question.type) {
    case 'string':
      return <OneLine {...defaultProps} />
    case 'date':
      return <Date {...defaultProps} />
    case 'text':
      return <MultiLine {...defaultProps} />
    case 'select_box':
      return <SelectBox {...defaultProps} choices={question.choices} />
    case 'single_select':
      return <SingleSelect {...defaultProps} choices={question.choices} />
    case 'multi_select':
      return <MultiSelect {...defaultProps} choices={question.choices} />
    case 'boolean':
      return <Boolean {...defaultProps} />
    case 'hidden_field':
      return <Boolean {...defaultProps} />
    case 'file':
      return <File {...defaultProps} />
    case 'investment_range':
      return <InvestmentRange {...defaultProps} />
    case 'tag':
      return <TagContainer {...defaultProps} />
    case 'experience':
      return <Experience {...defaultProps} />
    default:
      return <div />
  }
}

export default Question
