import { Grid, Typography, withMobileDialog } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import TitledPaper from '../core/TitledPaper'
import { blackText } from '../layout/styleComponents'

const styles = theme => ({
  companyInformationClass: {
    background: 'white',
    boxShadow: 'none',
    margin: 0,
    padding: '2rem 1.5rem'
  },
  title: {
    ...blackText,
    margin: '0 0 .25rem',
    fontSize: '1rem',
    fontWeight: 500
  },
  titleInline: {
    ...blackText,
    fontSize: '.95rem',
    display: 'inline-block',
    margin: '.25rem .15rem 0 0',
    fontWeight: 500,
    '&::before': {
      content: '""'
    }
  },
  item: {
    marginBottom: '1rem'
  },
  detailContentInline: {
    alignItems: 'center',
    justifyContent: 'center',
    '& h4': {
      width: 'auto'
    }
  },
  text: {
    ...blackText,
    fontSize: '.95rem',
    margin: 0,
    textAlign: 'left'
  },
  textInline: {
    ...blackText,
    fontSize: '.95rem',
    display: 'inline-block',
    margin: '0 0 0 .5rem',
    textAlign: 'left'
  },
  inlineItems: {
    marginBottom: '2rem'
  },
  detailContent: {
    marginBottom: '2rem'
  }
})

function CompanyInformationHero (props) {
  const {
    classes,
    foundedOn,
    employeeCount,
    howDoYouMakeMoney,
    whatProblemAreYouSolving,
    unfairAdvantage,
    classifications
  } = props

  const detail = ({ title, content, inline }) => (<Grid item xs={12} className={!inline && classes.detailContent}>
    <Typography variant="h4" className={inline ? classes.titleInline : classes.title}>{inline ? `${title}:` : title}</Typography>
    <Typography variant="body1" className={inline ? classes.textInline : classes.text}>{content}</Typography>
  </Grid>)

  return (
    <>
      <TitledPaper
        className={classes.companyInformationClass}
        noLogo
      >
        <Grid container>
          <Grid item xs={12} className={classes.inlineItems}>
            {detail({
              inline: true,
              title: 'Founded on',
              content: foundedOn
            })}
            {detail({
              inline: true,
              title: 'Number of employees',
              content: employeeCount || 0
            })}
            {detail({
              inline: true,
              title: 'What type of business is it?',
              content: (classifications || []).join(', ')
            })}
          </Grid>
          {detail({
            title: 'How do you make money?',
            content: howDoYouMakeMoney
          })}
          {detail({
            title: 'What problem are you solving?',
            content: whatProblemAreYouSolving
          })}
          {detail({
            title: "What's your unfair advantage?",
            content: unfairAdvantage
          })}
        </Grid>
      </TitledPaper>
    </>
  )
}

export default withStyles(styles)(withMobileDialog()(CompanyInformationHero))
