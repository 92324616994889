import { Typography } from '@material-ui/core'
import { withSnackbar } from 'notistack'
import React, { useState, useEffect } from 'react'
import { Mutation, Query } from 'react-apollo'
import Skeleton from 'react-loading-skeleton'
import UpdateFundingHistory from '../../graphql/mutations/UpdateFundingHistory'
import getFundingHistoryById from '../../graphql/queries/getFundingHistoryById'
import EditFundingHistory from '../company/EditFundingHistory'
import TitledPaper from '../core/TitledPaper'
import AppLayout from '../layout/AppLayout'
import CenterColumn from '../layout/CenterColumn'
import Title from '../layout/Title'

function EditFundingHistoryRoute (props) {
  const { match: { params: { slug, id } }, history, enqueueSnackbar } = props

  const [roundName, setRoundName] = useState('')
  const [roundNumber, setRoundNumber] = useState('')
  const [amount, setAmount] = useState('')
  const [cap, setCap] = useState('')
  const [date, setDate] = useState('')
  const [price, setPrice] = useState('')
  const [initalized, setInitialized] = useState(false)
  useEffect(() => {
    document.title = "The Syndicate - Edit Funding History"
  }, []);
  return <Query query={getFundingHistoryById} variables={{ companySlug: slug, fundingHistoryId: id }} fetchPolicy="network-only">
    {({ loading, error, data }) => {
      if (error) {
        return <Typography variant="display2">Error...</Typography>
      }

      let fundingHistory
      if (!loading && data.company && !initalized) {
        fundingHistory = data.company.fundingHistory
        setAmount(fundingHistory.amount)
        setRoundName(fundingHistory.roundName)
        setRoundNumber(fundingHistory.roundNumber)
        setCap(fundingHistory.cap)
        setPrice(fundingHistory.price)
        setDate(fundingHistory.date)
        setInitialized(true)
      }

      const title = <Title>
        Edit your funding history
      </Title>

      return <AppLayout title={title}>
        <CenterColumn>
          <Mutation
            onCompleted={data => {
              if (data) {
                history.push(`/companies/${slug}`)
              }
            }}
            mutation={UpdateFundingHistory}
          >
            {updateFundingHistory => {
              const onSave = fundingHistoryInput => updateFundingHistory({ variables: { id, fundingHistoryInput } })
              if (loading) {
                return <TitledPaper title="Edit your funding history">
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                </TitledPaper>
              } else {
                return <EditFundingHistory
                  amount={amount}
                  enqueueSnackbar={enqueueSnackbar}
                  cap={cap}
                  price={price}
                  date={date}
                  setDate={setDate}
                  roundName={roundName}
                  roundNumber={roundNumber}
                  setAmount={setAmount}
                  setCap={setCap}
                  setPrice={setPrice}
                  setRoundName={setRoundName}
                  setRoundNumber={setRoundNumber} onSave={onSave}
                />
              }
            }}
          </Mutation>
        </CenterColumn>
      </AppLayout>
    }}
  </Query>
}

export default withSnackbar(EditFundingHistoryRoute)
