import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import profileImageUrl from '../../../../../assets/images/Profile.png'
import { bigStone } from '../layout/colors'

const styles = theme => ({
  headerContainer: {
    backgroundColor: 'grey',
    backgroundSize: 'cover !important',
    width: '100%',
    minHeight: '22.5rem',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      opacity: '.65',
      background: bigStone,
      height: '100%',
    }
  }
})

const headerHero = props => {
  const { classes, children, className, title, backgroundUrl, profile, centered } = props
  return (
    <header className={`${className || ''} ${classes.headerContainer}`} style={{background: backgroundUrl ? `url(${backgroundUrl}) no-repeat top center` : bigStone}}>
      {children}
    </header>
  )
}

export default withStyles(styles)(headerHero)
