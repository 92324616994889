import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import { FullDiscussion } from '../core/Discussion'
import { Grid } from '@material-ui/core'
import TitledPaper from '../core/TitledPaper'
import { blueButton, centeredFlex, transparentBackgroundContainer } from '../layout/styleComponents'
import HeaderHero from '../core/HeaderHero'
import MainHeading from '../core/MainHeading'
import TabsContainer from '../layout/TabsContainer'
import { faComment } from '@fortawesome/pro-solid-svg-icons'

const styles = theme => ({
  transparentBackgroundContainer: transparentBackgroundContainer,
  centered: {
    ...centeredFlex,
    position: 'relative',
    '&:first-child': {
      marginRight: '1.5rem'
    }
  },
  heroCentered: {
    minHeight: '10rem',
  },
  updatesButton: blueButton,
  relative: {
    position: 'relative'
  }
})

const Conversation = ({ title, discussion, classes, refetch }) => <>
  <HeaderHero className={`${classes.centered} ${classes.heroCentered}`}>
    <Grid container alignItems="flex-start" className={classes.relative}>
      <Grid item xs={12} className={classes.centered}>
        <MainHeading
          primary="Conversations"
        />
      </Grid>
    </Grid>
  </HeaderHero>
  <Grid container className={classes.transparentBackgroundContainer}>
    <TabsContainer icon={faComment} title={title}>
      <TitledPaper
        noLogo
        updates
      >
        <Grid container className={classes.updatesContainer}>
          <FullDiscussion {...discussion} onCreateMessage={refetch} executeReading/>
        </Grid>
      </TitledPaper>
    </TabsContainer>
  </Grid>
</>

export default withStyles(styles)(Conversation)
