import { Button, Grid, Typography, withMobileDialog } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import React from 'react'
import NumberFormat from 'react-number-format'
import { Link as BrowserLink } from 'react-router-dom'
import TitledPaper from '../core/TitledPaper'
import { label, blackText, smallTitle, blueButton } from '../layout/styleComponents'
import { background } from '../layout/colors'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowRight, faEdit, faTrash } from '@fortawesome/pro-solid-svg-icons'

const styles = theme => ({
  fundingHistory: {
    background: 'white',
    boxShadow: 'none',
    borderBottom: `.025rem solid ${background}`,
    padding: '1rem 1.5rem',
    margin: '0 auto'
  },
  button: blueButton,
  icon: {
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    fontSize: '100%'
  },
  iconNegative: {
    color: theme.palette.error.main
  },
  smallButton: {
    minWidth: 'auto !important',
    border: `1px solid ${theme.palette.info.light}`,
    borderRadius: '50%',
    padding: '.5rem',
    margin: '0 .25rem 0 0 !important'
  },
  title: {
    ...blackText,
    fontWeight: 500,
    margin: 0,
  },
  date: {
    ...label
  },
  alignRightOnMobile: {
    ...smallTitle,
    fontSize: '1.1rem',
    fontWeight: 'bold',
    margin: 0,
    '@media (max-width: 960px)': {
      textAlign: 'right'
    }
  },
  roundedButton: {
    borderRadius: '50%',
    margin: 0,
    padding: '.25rem',
    minWidth: 'unset',
    '& span svg': {
      width: '1rem'
    }
  },
  delete: {
    color: `${theme.palette.error.main} !important`,
    backgroundColor: 'none !important',
    '&:hover': {
      color: `${theme.palette.error.dark} !important`
    }
  },
  edit: {
    color: `${theme.palette.secondary.main} !important`,
    backgroundColor: 'none !important',
    '&:hover': {
      color: `${theme.palette.secondary.dark} !important`
    }
  },
})

function FundingHistoriesHero (props) {
  const {
    classes,
    fundingHistories,
    deleteFundingHistory,
    slug,
    can
  } = props

  const fundingHistoryDetail = ({ slug, id, title, date, content, amount }) => (
    <Grid key={id} container className={classes.fundingHistory}>
      <Grid item md={4} xs={8}>
        <Typography className={classes.title} component="span">{title}</Typography>
      </Grid>

      <Grid item md={3} xs={4}>
        <Typography className={classes.alignRightOnMobile}><NumberFormat value={amount} displayType={'text'} thousandSeparator={true} prefix={'$'} /></Typography>
      </Grid>

      <Grid item md={3} xs={8}>
        <Typography className={classes.date} component="span">{moment(date).format('DD MMM, YYYY')}</Typography>
      </Grid>

      <Grid item md={2} xs={4} className={classes.alignRightOnMobile}>
        {can && can['manage'] && (
          <Button
            className={classes.smallButton}
            component={BrowserLink}
            size="small"
            color="primary"
            to={`/companies/${slug}/funding_histories/${id}/edit`}
          >
            <FontAwesomeIcon icon={faEdit} className={`${classes.icon} ${classes.edit}`} />
          </Button>
        )}
        {can && can['manage'] && (
          <Button
            className={classes.smallButton}
            onClick={() => deleteFundingHistory(id)}
            size="small"
            color="primary"
          >
            <DeleteIcon className={`${classes.icon} ${classes.iconNegative}`} />
          </Button>
        )}
      </Grid>
    </Grid>
  )

  return (
    <>
      <TitledPaper
        noLogo
        actions={
          slug && can && can['manage'] && (
            <Button
              className={classes.button}
              component={BrowserLink}
              size="small"
              color="primary"
              to={`/companies/${slug}/funding_histories/new`}
            >
              Add
              <FontAwesomeIcon icon={faLongArrowRight}/>
            </Button>
          )
        }
      >
        {(fundingHistories || []).map((fundingHistory, i) => (
          <div key={fundingHistory.id}>
            {fundingHistoryDetail({ slug, id: fundingHistory.id, date: fundingHistory.date, title: fundingHistory.roundName, content: fundingHistory.roundNumber, amount: fundingHistory.amount })}
          </div>
        ))}
        {(fundingHistories || []).length === 0 ? (
          <div>
            <Typography>No funding histories yet.</Typography>
          </div>
        ) : null}
      </TitledPaper>
    </>
  )
}

export default withStyles(styles)(withMobileDialog()(FundingHistoriesHero))
