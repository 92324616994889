import gql from 'graphql-tag'

const followUser = gql`
  mutation followUser($id: ID!) {
    followUser(id: $id) {
      id
    }
  }
`

export default followUser
