import { Badge, Button, Avatar, List, ListItem, ListItemText, Typography, Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import TitledPaper from '../core/TitledPaper'
import TabsContainer from '../layout/TabsContainer'
import HeaderHero from '../core/HeaderHero'
import MainHeading from '../core/MainHeading'
import { faBan, faCaretDown, faCaretUp, faComment } from '@fortawesome/pro-solid-svg-icons'
import { blueRibbon, background, bigStone, white } from '../layout/colors'
import { faEye } from '@fortawesome/pro-light-svg-icons'
import { blueButton, smallTitle, subTitle, transparentBackgroundContainer, blackText, centeredFlex } from '../layout/styleComponents'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link as BrowserLink, useHistory } from 'react-router-dom'

const styles = theme => ({
  paper: {
    background: 'white',
  },
  list: {
    padding: 0,
  },
  badge: {
    width: '100%',
    '& > span': {
      background: theme.palette.error.main,
      top: '1.25rem',
      right: '1.25rem'
    }
  },
  blackLabel: {
    ...blackText,
    fontWeight: 500,
    fontSize: '.85rem',
    margin: 0
  },
  transparentBackgroundContainer: {
    ...transparentBackgroundContainer,
    background: 'white'
  },
  icon: {
    marginLeft: '.5rem',
  },
  link: {
    ...smallTitle,
    fontSize: '.85rem',
    textDecoration: 'none',
    minWidth: 'unset',
    '& span': {
      fontWeight: 400
    }
  },
  item: {
    background: 'white',
    boxShadow: `0 0 .5rem -.35rem ${theme.palette.success.dark}`,
    maxWidth: '15rem',
    margin: '0 .75rem 1.5rem'
  },
  flex: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    width: '100%'
  },
  title: {
    marginLeft: '1rem',
    flexGrow: 3
  },
  dataInfo: {
    color: theme.palette.primary.main,
    fontFamily: 'Lato !important',
    fontSize: '.95rem',
    '& span, & p': {
      color: `${theme.palette.primary.main} !important`,
      fontFamily: 'Lato !important',
      fontWeight: 'bold'
    }
  },
  startDate: {
    color: 'rgba(0,0,0, .5)',
    marginLeft: '.5rem',
    '& span, & p': {
      color: 'rgba(0,0,0, .5)'
    }
  },
  data: {
    width: '100%'
  },
  titleWrapper: {
    margin: '1rem 0'
  },
  heroCentered: {
    minHeight: '12rem'
  },
  centered: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    '&:first-child': {
      marginRight: '1.5rem'
    }
  },
  relative: {
    position: 'relative'
  },
  text: {
    ...blackText,
    fontSize: '1rem',
    margin: 0,
    fontWeight: 400,
    width: '100%',
    textAlign: 'left',
    marginLeft: '.75rem',
    '& p, & span': {
      fontSize: '1rem'
    }
  },
  listItemIcon: {
    height: '4rem',
    marginRight: 0,
    width: '4rem'
  },
  dropdownOptions: {
    background: 'white',
    borderRadius: '.5rem',
    boxShadow: `0 0 .6rem -.3rem ${bigStone}`,
    padding: '.5rem',
    position: 'absolute',
    top: '100%',
    zIndex: 9,
    listStyle: 'none',
    margin: 0,
    padding: 0,
    width: '100%'
  },
  updatesButton: blueButton,
  option: {
    color: blueRibbon,
    fontSize: '.85rem',
    padding: '.5rem 1rem',
    width: '100% !important',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.info.light,
    }
  },
  actionsFilterBar: {
    borderBottom: `.015rem solid ${background}`,
    padding: '.5rem 1.25rem',
    backgroundColor: background,
    listStyle: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 0,
    width: '100%',
    '& li:first-child': {
      '@media (min-width: 50rem)': {
        width: '60%'
      }
    },
    '& li:nth-child(2)': {
      '@media (max-width: 50rem)': {
        display: 'none'
      }
    }
  },
  noUpdates: {
    ...centeredFlex,
    paddingBottom: '2rem',
    '& p': {
      textAlign: 'center'
    }
  },
  iconBig: {
    color: theme.palette.info.light,
    fontSize: '6rem',
    fontWeight: 300,
    margin: '3rem auto 1.5rem'
  },
})

function CompanyUpdateDiscussion ({ classes, onClick, companyName, logoUrl, title, body }) {
  return <ListItem button onClick={onClick} className={classes.marginBottom}>
    {companyName && logoUrl && <Avatar className={classes.listItemIcon} alt={companyName} src={logoUrl} />}
    {!(companyName && logoUrl) && <Avatar className={classes.listItemIcon} />}
    <ListItemText className={classes.text} primary={title || <Skeleton />} secondary={
      <>
        <Typography component="span" className={classes.inline} color="textPrimary">
          {companyName || <Skeleton />}
        </Typography>
        {' - '} {body || <Skeleton />}
      </>
    } />
  </ListItem>
}

function UserUpdateDiscussion ({ classes, onClick, userName, avatarUrl, title, body }) {
  return <ListItem button onClick={onClick} className={classes.marginBottom}>
    {userName && avatarUrl && <Avatar className={classes.listItemIcon} alt={userName} src={avatarUrl} />}
    {!(userName && avatarUrl) && <Avatar className={classes.listItemIcon} />}
    <ListItemText className={classes.text} primary={title || <Skeleton />} secondary={
      <>
        <Typography component="span" className={classes.inline} color="textPrimary">
          {userName || <Skeleton />}
        </Typography>
        {' - '} {body || <Skeleton />}
      </>
    } />
  </ListItem>
}

function MeetingRequest ({ classes, onClick, userName, avatarUrl, title, body }) {
  return <ListItem button onClick={onClick} className={classes.marginBottom}>
    {userName && avatarUrl && <Avatar className={classes.listItemIcon} alt={userName} src={avatarUrl} />}
    {!(userName && avatarUrl) && <Avatar className={classes.listItemIcon} />}
    <ListItemText className={classes.text} primary={title || <Skeleton />} secondary={
      <>
        <Typography component="span" className={classes.inline} color="textPrimary">
          {userName || <Skeleton />}
        </Typography>
        {' - '} {body || <Skeleton />}
      </>
    } />
  </ListItem>
}

function Notification (props) {
  const { title, body, classes, onClick, history, markNotificationAsRead, notificationId } = props
  if (props.notifiable && props.notifiable.__typename === 'MeetingRequest') {
    const { notifiable: { user: { name, avatarUrl } } } = props
    return <MeetingRequest
      key={props.id}
      classes={props.classes}
      onClick={props.onClick}
      userName={name}
      avatarUrl={avatarUrl}
      title={props.title}
      body={props.body}
    />
  }
  if (props.notifiable && props.notifiable.__typename === 'CompanyUpdateDiscussion') {
    const { notifiable: { discussion: { id }, company: { slug, name, logoUrl } } } = props
    return <CompanyUpdateDiscussion
      classes={classes}
      onClick={() => {
        markNotificationAsRead({ variables: { notificationId } })
        history.push(`/companies/${slug}/updates/${id}`)
      }}
      companyName={name}
      logoUrl={logoUrl}
      title={title}
      body={body}
    />
  }
  if (props.notifiable && props.notifiable.__typename === 'UserUpdateDiscussion') {
    const { notifiable: { discussion: { id }, user: { name, slug, avatarUrl } } } = props
    return <UserUpdateDiscussion
      classes={classes}
      onClick={() => {
        markNotificationAsRead({ variables: { notificationId } })
        history.push(`/users/${slug}/updates/${id}`)
      }}
      userName={name}
      avatarUrl={avatarUrl}
      title={title}
      body={body}
    />
  }
}

class Notifications extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      showOptions: false,
      showFilters: false,
      currentOption: 0,
      currentFilter: 0,
      uneadNotificationsState: [],
      readNotificationsState: [],
      messageCounterState: {},
      NotificationsState: props.notifications,
      noDataText: 'This company has no notifications yet.',
    }
  }
  componentDidMount () {
    const {
      notifications,
      unreadNotifications,
    } = this.props
    this.setState({NotificationsState: notifications})
    if (unreadNotifications && unreadNotifications.unreadNotifications.length > 0 && this.state.uneadNotificationsState.length === 0) {
      let unreadMessagesArray = []
      let unreadMessagesCountObject = {}
      let readNotifications = []
      unreadNotifications.unreadNotifications.map(unreadNotification => {
        const discussionAdded = this.discussionExist(unreadNotification.id, unreadMessagesArray)
        if (discussionAdded) unreadMessagesCountObject[unreadNotification.id] = unreadMessagesCountObject[unreadNotification.id] + 1
        else {
          const discussion = notifications.filter(noti => noti.id === unreadNotification.id)
          unreadMessagesArray.push(discussion[0])
          unreadMessagesCountObject[unreadNotification.id] = 1
        }
      })
      notifications.map(notification => {
        const haveUnreadMessages = unreadMessagesArray.filter(notificationUnread => notificationUnread.id === notification.id)
        const discussionAdded = this.discussionExist(notification.id, readNotifications)
        if (haveUnreadMessages.length === 0 && !discussionAdded) readNotifications.push(notification)
      })
      this.setState({readNotificationsState: readNotifications})
      this.setState({uneadNotificationsState: unreadMessagesArray})
      this.setState({messageCounterState: unreadMessagesCountObject})
    } else if (unreadNotifications && unreadNotifications.unreadNotifications.length === 0 && this.state.readNotificationsState.length === 0) {
      this.setState({readNotificationsState: notifications})
    }
  }

  discussionExist (id, list) {
    const exist = list && list.length > 0 && list.filter(item => item.id === id)
    if (exist && exist.length > 0) return true
    else return false
  }

  changeFilter (filter, index) {
    if (this.state.currentFilter !== index) {
      this.setState({NotificationsState: this.state.NotificationsState.reverse()})
    }
    this.setState({currentFilter: index})
  }

  markAllAsRead () {
    (this.props.unreadNotifications && this.props.unreadNotifications.unreadNotifications || []).map((notification, index) => {
      this.props.markNotificationAsRead({ variables: { notificationId: notification.id } })
      if (index + 1 === this.props.unreadNotifications.unreadNotifications.length) {
        setTimeout(() => {
          this.props.refetch()
        }, 2000)
      }
    })
  }

  selectViewType (index, option) {
    this.setState({currentOption: index})
    switch (option.value) {
      case 'read':
        this.setState({
          noDataText: 'User has no notifications without unread messages.',
          NotificationsState: this.state.currentFilter === 0 ? this.state.readNotificationsState : this.state.readNotificationsState.reverse()
        })
        break
      case 'unread':
        this.setState({
          NotificationsState: this.state.currentFilter === 0 ? this.state.uneadNotificationsState : this.state.uneadNotificationsState.reverse(),
          noDataText: 'User has no unread messages.'
        })
        break
      case 'all':
        this.setState({
          noDataText: 'User has no notifications yet.',
          NotificationsState: this.state.currentFilter === 0 ? this.props.notifications : this.props.notifications.reverse()
        })
        break
    }
  }

  render () {
    const {
      classes,
      markNotificationAsRead,
      history,
    } = this.props

    const options = [{name: 'All', value: 'all'}, {name: 'Read', value: 'read'}, {name: 'Unread', value: 'unread'}]
    const filters = [{name: 'Last Activity', value: 'desc'}, {name: 'Date of creation', value: 'asc'}]
    const dropdownOptions = []
    const dropdownFilters = []

    options.map((option, index) => {
      dropdownOptions.push(<li key={option.value} className={classes.option} onClick={() => this.selectViewType(index, option)}>{option.name}</li>)
    })

    filters.map((filter, index) => {
      dropdownFilters.push(<li key={filter.value} className={classes.option} onClick={() => this.changeFilter(filter.value, index)}>{filter.name}</li>)
    })

    return (<>
      <HeaderHero className={`${classes.centered} ${classes.heroCentered}`}>
        <Grid container alignItems="flex-start" className={classes.relative}>
          <Grid item xs={12} className={classes.centered}>
            <MainHeading
              primary='Notifications'
            />
          </Grid>
        </Grid>
      </HeaderHero>
      <Grid container className={classes.transparentBackgroundContainer}>
        <TabsContainer tabs={[]} icon={faEye} title="Notifications">
          <TitledPaper noLogo className={classes.paper}>
            <ul className={classes.actionsFilterBar}>
              <li>
                <Button
                  className={classes.link}
                  component={BrowserLink}
                  size="small"
                  color="primary"
                  onClick={() => this.markAllAsRead()}
                >
                  Mark all as read
                </Button>
              </li>
              <li style={{display: 'flex'}}>
                <div className={classes.centered}>
                  <label className={classes.blackLabel}>Showing:</label>
                  <Button
                    className={classes.link}
                    component={BrowserLink}
                    size="small"
                    color="primary"
                    onClick={() => this.setState({showOptions: !this.state.showOptions})}
                  >
                    {options[this.state.currentOption].name}
                    <FontAwesomeIcon className={classes.icon} icon={this.state.showOptions ? faCaretUp : faCaretDown} />
                  </Button>
                  {this.state.showOptions && <ul className={classes.dropdownOptions}>
                    {dropdownOptions}
                  </ul>}
                </div>
                <div className={classes.centered}>
                  <label className={classes.blackLabel}>Sort by:</label>
                  <Button
                    className={classes.link}
                    component={BrowserLink}
                    size="small"
                    color="primary"
                    onClick={() => this.setState({showFilters: !this.state.showFilters})}
                  >
                    {filters[this.state.currentFilter].name}
                    <FontAwesomeIcon className={classes.icon} icon={this.state.showFilters ? faCaretUp : faCaretDown} />
                  </Button>
                  {this.state.showFilters && <ul className={classes.dropdownOptions}>
                    {dropdownFilters}
                  </ul>}
                </div>
              </li>
            </ul>
            <List className={classes.list}>
              {this.state.NotificationsState && this.state.NotificationsState.map(notification => {
                const itHaveUnreadMessages = this.state.messageCounterState[notification.id] ? this.state.messageCounterState[notification.id] : null
                if (notification) {
                  if (itHaveUnreadMessages) return (<Badge className={classes.badge} badgeContent={itHaveUnreadMessages} color="primary">
                    <Notification
                      key={notification.id}
                      notificationId={notification.id}
                      classes={classes}
                      history={history}
                      markNotificationAsRead={markNotificationAsRead}
                      {...notification} onClick={() => history.push(`/notifications/${notification.id}`)}
                    />
                  </Badge>)
                  else return (
                    <Notification
                      key={notification.id}
                      notificationId={notification.id}
                      classes={classes}
                      history={history}
                      markNotificationAsRead={markNotificationAsRead}
                      {...notification} onClick={() => history.push(`/notifications/${notification.id}`)}
                    />
                  )
                } else return null
              })}
              {!this.state.NotificationsState || this.state.NotificationsState.length === 0 && (
                <section className={classes.noUpdates}>
                  <FontAwesomeIcon icon={faBan} className={classes.iconBig} />
                  <Typography className={classes.text}>
                    User has no notifications.
                  </Typography>
                </section>
              )}
              {!this.state.NotificationsState && <>
                <MeetingRequest classes={classes} />
                <MeetingRequest classes={classes} />
                <MeetingRequest classes={classes} />
              </>}
            </List>
          </TitledPaper>
      </TabsContainer>
    </Grid>
    </>)
  }
}

export default withStyles(styles)(Notifications)
