import Input from '@material-ui/core/Input'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React, { useState } from 'react'

const styles = theme => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
})

const onEnter = onSubmit => (evt) => evt.key === 'Enter' ? onSubmit() : null

function InvestmentRange (props) {
  const {
    value,
    content,
    classes,
    onChange,
    required,
    label,
    onSubmit
  } = props
  const [lowInvestmentRange, setLowInvestmentRange] = useState(0.0)
  const [highInvestmentRange, setHighInvestmentRange] = useState(0.0)
  const onChangeForInvestmentRange = (lowInvestmentRange, highInvestmentRange) => {
    onChange(`${lowInvestmentRange}-${highInvestmentRange}`)
  }
  return <>
    <Typography variant="h2">
      {label} <small><i>{required && '(required)'}</i></small>
    </Typography>
    <Typography variant="h4">
      {content}
    </Typography>
    <div className={classes.wrapper}>
      <Typography>Low:</Typography> <Input onChange={(e) => { setLowInvestmentRange(e.target.value); onChangeForInvestmentRange(e.target.value, highInvestmentRange) }} defaultValue={value} onKeyPress={onEnter(onSubmit)} />
      <Typography>High:</Typography> <Input onChange={(e) => { setHighInvestmentRange(e.target.value); onChangeForInvestmentRange(lowInvestmentRange, e.target.value) }} defaultValue={value} onKeyPress={onEnter(onSubmit)} />
    </div>
  </>
}

export default withStyles(styles, { withTheme: true })(InvestmentRange)
