import { withSnackbar } from 'notistack'
import React, { useState, useEffect } from 'react'
import { Mutation } from 'react-apollo'
import EditUserExperience from '../../components/company/EditUserExperience'
import CreateUserExperience from '../../graphql/mutations/CreateUserExperience'
import AppLayout from '../layout/AppLayout'
import CenterColumn from '../layout/CenterColumn'

function EditUserExperienceRoute (props) {
  const { match: { params: { slug } }, history, enqueueSnackbar } = props

  const [startYear, setStartYear] = useState('')
  const [endYear, setEndYear] = useState('')
  const [company, setCompany] = useState('')
  const [position, setPosition] = useState('')
  useEffect(() => {
    document.title = "The Syndicate - Create User Experience"
  }, []);
  return <AppLayout>
    <CenterColumn>
      <Mutation
        onCompleted={data => {
          if (data) {
            history.push(`/users/${slug}`)
          }
        }}
        mutation={CreateUserExperience}
      >
        {createUserExperience => {
          const onSave = userExperienceInput => createUserExperience({ variables: { userSlug: slug, userExperienceInput } })
          return <EditUserExperience
            title="Create your user experience"
            enqueueSnackbar={enqueueSnackbar}
            buttonText="Save"
            startYear={startYear}
            setStartYear={setStartYear}
            endYear={endYear}
            setEndYear={setEndYear}
            company={company}
            setCompany={setCompany}
            position={position}
            setPosition={setPosition}
            onSave={onSave}
          />
        }}
      </Mutation>
    </CenterColumn>
  </AppLayout>
}

export default withSnackbar(EditUserExperienceRoute)
