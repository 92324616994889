export const initialState = { type: 'user', message: '', email: '' }

export function reducer (state, action) {
  console.log('state', state, 'action', action)
  switch (action.type) {
    case 'setMessage':
      state = { ...state, message: action.value }
      return state
    case 'setEmail':
      state = { ...state, email: action.value }
      return state
    case 'clear':
      return initialState
    default:
      throw new Error('No type for InviteReducer')
  }
}
