import gql from 'graphql-tag'

const createProductImage = gql`
  mutation createProductImage($companySlug: String!, $input: ProductImageInput!) {
    createProductImage(companySlug: $companySlug, input: $input) {
      id
    }
  }
`

export default createProductImage
