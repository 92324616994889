import { Typography, withMobileDialog, Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import { bigStone } from '../layout/colors'

const styles = theme => ({
  paper: {
    padding: '2rem',
    boxShadow: 'none'
  },
  textContent: {
    fontWeight: '600'
  }
})

function SimpleHero (props) {
  const {
    text,
    classes,
    className,
    children,
    ...rest

  } = props

  return (
    <Paper
      titleVariant="h1"
      className={`${classes.paper} ${className}`}
      {...rest}
    >
      <div className={classes.textContent}>
        {children}
      </div>
    </Paper>
  )
}

export default withStyles(styles)(withMobileDialog()(SimpleHero))
