import gql from 'graphql-tag'

const getUserBySlug = gql`
  query UserProfile($id: String!) {
    user(id: $id){
      id
      slug
      name
      email
      firstName
      lastName
      avatarUrl
      memberSince
      title
      url
      bio
      phone
      location
      badges
      linkedInUrl
      twitterUrl
      githubUrl
      instagramUrl
      angelListUrl
      facebookUrl
      isInvestor
      receiveNotifications
      defaultCompany {
        id
        name
      }
      investor {
        id
        focus
        assistantEmail
        lowInvestmentRange
        highInvestmentRange
        startupCategories {
          id
          title
        }
      }
      userExperiences {
        id
        startYear
        endYear
        company
        position
      }
      userUpdates(limit: 3) {
        id
        messages {
          id
          body
          createdAt
          user {
            id
            name
            title
            avatarUrl
            defaultCompany {
              id
              name
            }
          }
        }
      }
      companies {
        id
        slug
        name
        logoUrl
        tagline
        twitterUrl
        githubUrl
        facebookUrl
        linkedInUrl
        url
      }
      investments {
        id
        company {
          id
          slug
          name
          logoUrl
          tagline
          twitterUrl
          githubUrl
          facebookUrl
          linkedInUrl
          url
        }
      }
      can {
        manage
      }
      followedCompanies {
        id
        slug
        name
        logoUrl
        tagline
        twitterUrl
        githubUrl
        facebookUrl
        linkedInUrl
        url
      }
      following
    }
  }
`

export default getUserBySlug
