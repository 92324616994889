import gql from 'graphql-tag'

const createUserExperience = gql`
  mutation CreateUserExperience($userSlug: String!, $userExperienceInput: UserExperienceInput!){
    createUserExperience(userSlug: $userSlug, userExperienceInput: $userExperienceInput){
      id
      position
    }
  }
`

export default createUserExperience
