import { withStyles } from '@material-ui/core/styles'
import React from 'react'

const styles = theme => ({
  layout: {
    position: 'relative',
    width: '100%',
    maxWidth: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'relative'
  }
})

const CenterColumn = ({ children, classes }) => {
  return <main className={classes.layout}>
    {children}
  </main>
}

export default withStyles(styles)(CenterColumn)
