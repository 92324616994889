import { AppBar, Button, Dialog, DialogContent, IconButton, Slide, TextField, Toolbar, Typography, withMobileDialog, FormControlLabel, RadioGroup, Radio } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import { withSnackbar } from 'notistack'
import React, { useState, useEffect } from 'react'
import get from 'lodash.get'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons'
import { blueButton, blackText, textInput }  from '../layout/styleComponents'

const styles = theme => ({
  modal: {
    '& .MuiDialog-paperFullWidth': {
      borderRadius: '.5rem',
      maxWidth: '45rem',
      height: 'unset',
      width: '95%',
      maxHeight: '80%'
    },
    '& .MuiDialogContent-root': {
      padding: '0 1.5rem 2rem',
    }
  },
  blueButton: {
    ...blueButton,
    margin: '.15rem auto 0'
  },
  option: {
    ...blackText,
    fontSize: '.75rem',
    fontWeight: 300,
    margin: 0,
    textAlign: 'left'
  },
  blackText: {
    ...blackText,
    fontWeight: 300,
    margin: 0,
  },
  textInput: {
    ...textInput,
    marginBottom: '1.5rem'
  },
  appBarTitle: {
    ...blackText,
    margin: 0,
    fontSize: '1.25rem',
    flex: 1,
    textAlign: 'left'
  },
  appBarIcon: {
    marginRight: '-1rem'
  },
  buttonMarginTop: {
    marginTop: '1rem'
  },
  appBar: {
    backgroundColor: theme.palette.info.light,
    margin: 0,
    width: '100%',
    marginBottom: '1rem',
    position: 'relative',
    padding: '.5rem 1.5rem',
    paddingRight: '1.5rem !important'
  },
  button: {
    backgroundColor: '#2D49B6',
    boxShadow: 'inset 0 0 0 0 #4f4f4f, 1px 1px 0 0 #4f4f4f',
    color: 'white',
    display: 'block',
    fontFamily: 'Poppins',
    fontSize: '70%',
    fontWeight: 'bold',
    margin: ' 2rem auto 0',
    textAlign: 'center',
    padding: '1rem',
    '&:hover': {
      color: 'black'
    }
  },
  toolbar: {
    background: 'none',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: 0
  },
  content: {
    padding: 0
  },
  textAlignRight: {
    cursor: 'pointer',
    width: '100%',
    textAlign: 'right'
  },
  appBarIcon: {
    marginRight: '-1rem'
  }
})

function Transition (props) {
  return <Slide direction="up" {...props} />
}

function CompanyUpdateModalRoute ({
  companySlug,
  fullScreen,
  match: {
    params: { id }
  },
  onClose,
  enqueueSnackbar,
  requestAllocationModalIsOpen,
  requestAllocation,
  classes,
  deal,
  history,
  company,
  onInterested,
  match
}) {
  const submitInterest = () => {
    requestAllocation(parseInt(amount), hasExperience === 'yes')
    enqueueSnackbar('Your interest has been noted.', { variant: 'success' })
  }

  const [amount, setAmount] = useState(match.params.value || '')
  const [hasExperience, setHasExperience] = useState('false')
  useEffect(() => {
    document.title = "The Syndicate - Request Allocation Modal"
  }, []);
  return (
    <Dialog
      TransitionComponent={Transition}
      open={requestAllocation}
      fullWidth
      fullScreen={fullScreen}
      maxWidth="md"
      onClose={onClose}
      className={classes.modal}
    >
      <AppBar
        color="default"
        elevation={0}
        className={classes.appBar}
      >
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" color="inherit" className={classes.appBarTitle}>
            Invest
          </Typography>
          <IconButton
            className={classes.appBarIcon}
            color="inherit"
            onClick={() => history.push('../')}
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent className={classes.content}>
        <Typography variant="subtitle1" className={classes.blackText}>
          How much would you like to invest in Deal #{get(deal, 'number')}: {get(company, 'name')}?
        </Typography>
        <TextField
          fullWidth
          required
          label="Amount"
          value={amount}
          onChange={evt => setAmount(evt.target.value)}
          className={classes.textInput}
        />
        <Typography variant="subtitle1" className={classes.blackText}>
          Do you have expertise in the space? <small><i>(optional)</i></small>
        </Typography>
        <RadioGroup
          onChange={(event) => {
            setHasExperience(event.target.value)
          }}
          value={hasExperience}
          name={'yes'}
        >
          <FormControlLabel
            className={classes.option}
            key={'yes'}
            label={'Yes'}
            value={'yes'}
            control={<Radio size="small" className={classes.radio} />}
          />
          <FormControlLabel
            className={classes.option}
            key={'no'}
            label={'No'}
            value={'no'}
            control={<Radio size="small" className={classes.radio} />}
          />
        </RadioGroup>
        <Button
          onClick={() => { submitInterest(); onClose(); onInterested() }}
          className={`${classes.blueButton} ${classes.buttonMarginTop}`}
          variant="contained"
        >
          Invest
          <FontAwesomeIcon icon={faLongArrowRight} />
        </Button>
      </DialogContent>
    </Dialog>
  )
}

export default withSnackbar(withStyles(styles)(withMobileDialog()(CompanyUpdateModalRoute)))
