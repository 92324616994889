import { Typography } from '@material-ui/core'
import { withSnackbar } from 'notistack'
import React, { useRef, useState, useEffect } from 'react'
import { Mutation, Query } from 'react-apollo'
import Skeleton from 'react-loading-skeleton'
import { Redirect, Switch } from 'react-router'
import { Route } from 'react-router-dom'
import DeleteFundingHistory from '../../graphql/mutations/DeleteFundingHistory'
import FollowCompany from '../../graphql/mutations/FollowCompany'
import RequestMeeting from '../../graphql/mutations/RequestMeeting'
import UnfollowCompany from '../../graphql/mutations/UnfollowCompany'
import getCompanyBySlug from '../../graphql/queries/getCompanyBySlug'
import { AppContext } from '../App'
import CompanyProfile from '../company/CompanyProfile'
import AppLayout from '../layout/AppLayout'
import CenterColumn from '../layout/CenterColumn'
import Title from '../layout/Title'
import { REFETCH } from '../../graphql/networkStatuses'
import CompanyUpdateModalRoute from './CompanyUpdateModalRoute'

function CompanyProfileRoute ({
  match,
  history,
  enqueueSnackbar
}) {
  const { params: { slug } } = match

  const [message, setMessage] = useState('')
  const [createCompanyUpdateOpen, setCreateCompanyUpdateOpen] = useState(false)
  const [isOpenRequestMeetingModal, setIsOpenRequestMeetingModal] = useState(false)
  const imageGalleryItemRef = useRef(null)
  useEffect(() => {
    document.title = "The Syndicate - Company Profile"
  }, []);
  return <AppContext.Consumer>
    {context => {
      const token = context.token
      return <Query query={getCompanyBySlug} variables={{ id: slug }} fetchPolicy="network-only">
        {({ loading, error, data, refetch, networkStatus }) => {
          if (error) {
            if (error.message && error.message.includes('unauthorized')) {
              enqueueSnackbar("You don't have access to view that company.", { variant: 'error' })
              return <Redirect
                to={`/login/?redirect_to=companies,${slug}`}
              />
            } else {
              return <Typography>Error...</Typography>
            }
          }

          let company

          if (data) {
            company = data.company
          }

          const followUnfollowMutation = (company && company.following) ? UnfollowCompany : FollowCompany
          const title = <Title>
            {(company && company.name) || <Skeleton />}
          </Title>

          if (loading && networkStatus !== REFETCH) return null

          return <AppLayout title={title}>
            <CenterColumn>
              <Mutation
                onCompleted={data => {
                  if (data) {
                    enqueueSnackbar('Funding History deleted', { variant: 'success' })
                    refetch()
                  }
                }}
                mutation={DeleteFundingHistory}
              >
                {deleteFundingHistory => {
                  return <Mutation
                    onCompleted={data => {
                      if (data) {
                        // setSnackbarOpen(true)
                      }
                    }}
                    mutation={RequestMeeting}
                  >
                    {requestMeeting => {
                      return <Mutation
                        onCompleted={data => {
                          if (data) {
                            refetch()
                          }
                        }}
                        mutation={followUnfollowMutation}
                      >
                        {followUnfollow => {
                          const onFollowUnfollow = () => followUnfollow({ variables: { id: slug } })
                          return <CompanyProfile
                            token={token}
                            deleteFundingHistory={(fundingHistoryId) => deleteFundingHistory({ variables: { id: fundingHistoryId } })}
                            requestMeeting={(message) => requestMeeting({ variables: { to: { company: slug }, message: message } })}
                            history={history}
                            match={match}
                            refetch={refetch}
                            onFollowUnfollow={onFollowUnfollow}
                            loading={loading}
                            message={message}
                            imageGalleryItemRef={imageGalleryItemRef}
                            setMessage={setMessage}
                            createCompanyUpdateOpen={createCompanyUpdateOpen}
                            setCreateCompanyUpdateOpen={setCreateCompanyUpdateOpen}
                            isOpenRequestMeetingModal={isOpenRequestMeetingModal}
                            setIsOpenRequestMeetingModal={setIsOpenRequestMeetingModal}
                            {...company}
                          />
                        }}
                      </Mutation>
                    }}
                  </Mutation>
                }}
              </Mutation>

              <Switch>
                <Route path={`${match.url}/updates/:id`} render={(props) => <CompanyUpdateModalRoute {...props} companySlug={slug} onClose={() => history.push('../')} />} />
              </Switch>
            </CenterColumn>
          </AppLayout>
        }}
      </Query>
    }}</AppContext.Consumer>
}

export default withSnackbar(CompanyProfileRoute)
