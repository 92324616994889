import React, {useEffect} from 'react'
import { Query } from 'react-apollo'
import { Link as BrowserLink } from 'react-router-dom'
import getConversation from '../../graphql/queries/getConversation'
import ConversationShow from '../conversations/ConversationShow'
import AppLayout from '../layout/AppLayout'
import CenterColumn from '../layout/CenterColumn'
import Title from '../layout/Title'

function ConversationRoute ({ match: { params: { id } } }) {
  useEffect(() => {
    document.title = "The Syndicate - Conversation"
  }, []);
  return <Query query={getConversation} fetchPolicy="cache-and-network" variables={{ id }}>
    {({ loading, error, data, refetch }) => {
      if (error) {
        return <h2>Error...</h2>
      }
      let conversation
      if (data) {
        conversation = data.conversation
      }

      const title = <Title><BrowserLink to="/conversations">&lt;- Conversations</BrowserLink></Title>
      return <AppLayout title={title}>
        <CenterColumn>
          <ConversationShow {...conversation} refetch={refetch} loading={loading} />
        </CenterColumn>
      </AppLayout>
    }}
  </Query>
}

export default ConversationRoute
