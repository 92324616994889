import gql from 'graphql-tag'

const updateFundingHistory = gql`
  mutation UpdateFundingHistory($id: ID!, $fundingHistoryInput: FundingHistoryInput!){
    updateFundingHistory(id: $id, fundingHistoryInput: $fundingHistoryInput){
      id
      roundName
      roundNumber
    }
  }
`

export default updateFundingHistory
