import { withSnackbar } from 'notistack'
import React from 'react'
import { Mutation } from 'react-apollo'
import CreateUserUpdate from '../../graphql/mutations/CreateUserUpdate'
import NewMessageForm from './NewMessageForm'

const CreateUserUpdateFormContainer = ({
  userSlug,
  enqueueSnackbar,
  onSave
}) => {
  const initialState = {
    body: ''
  }

  return <Mutation
    errorPolicy="all"
    onCompleted={data => {
      if (data) {
        onSave(data)
      }
    }}
    onError={error => {
      error.graphQLErrors.map(graphQLError =>
        enqueueSnackbar(graphQLError.message, { variant: 'error' })
      )
    }}
    mutation={CreateUserUpdate}
  >
    {createUserUpdate => {
      const onSubmit = messageInput => createUserUpdate({
        variables: {
          userSlug,
          messageInput
        }
      })
      return <NewMessageForm create onSubmit={onSubmit} initialState={initialState} />
    }}
  </Mutation>
}

export default withSnackbar(CreateUserUpdateFormContainer)
