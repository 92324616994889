import gql from 'graphql-tag';

const getPostInvestorText = gql`
  query getPostInvestorText {
    postInvestorText {
      postInvestorText
    }
  }
`

export default getPostInvestorText;
