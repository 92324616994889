// Colors
export const white = '#ffffff'
export const redOrange = '#fd3438'
export const background = '#ECF1F4'
export const blueRibbon = '#275efe'
export const tealBlue = '#bccbdb'
export const gullGray = '#99a6b3'
export const bigStone = '#172432'
export const formTextColor = gullGray
export const textBlack = '#4f4f4f'
export const formFieldBackgroundBlue = 'rgba(9, 106, 208, .04)'
