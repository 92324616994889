import { Button, TextField, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import TitledPaper from '../core/TitledPaper'

const styles = theme => ({
  paper: {
    marginTop: theme.spacing.unit * 3
  },
  button: {
    backgroundColor: '#2D49B6',
    boxShadow: 'inset 0 0 0 0 #4f4f4f, 1px 1px 0 0 #4f4f4f',
    color: 'white',
    display: 'block',
    fontFamily: 'Poppins',
    fontSize: '70%',
    fontWeight: 'bold',
    margin: ' 2rem auto 0',
    textAlign: 'center',
    padding: '1rem',
    '&:hover': {
      color: 'black'
    }
  }
})

const OnboardingForm = props => {
  const { email, setEmail, classes, joinUs, formKey, notice } = props

  return (
    <TitledPaper white form title="Sign up to get started" className={classes.paper}>
      <Typography variant="h5">{notice}</Typography>
      <TextField
        fullWidth
        value={email}
        onChange={event => setEmail(event.target.value)}
        onKeyPress={(evt) => evt.key === 'Enter' ? joinUs({ variables: { email, formKey } }) : null}
        label="E-mail"
        name="email"
        autoComplete="email"
      />
      <Button
        fullWidth
        onClick={() => joinUs({ variables: { email, formKey } })}
        type="submit"
        variant="contained"
        color="primary"
        className={classes.button}
      >
        Submit
      </Button>
    </TitledPaper>
  )
}

export default withStyles(styles, { withTheme: true })(OnboardingForm)
