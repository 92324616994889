import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import React, { useState, useEffect } from 'react'
import { uppercaseSmallDarkText, blueButton, label, formButton } from '../layout/styleComponents'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons'
import { faCaretUp, faCaretDown} from '@fortawesome/pro-solid-svg-icons'

const styles = theme => ({
  button: blueButton,
  finishLater: {
    ...label,
    width: '100%',
    textAlign: 'center',
    border: 'none',
    marginTop: '1.5rem',
    padding: 0
  },
  darkText: uppercaseSmallDarkText,
  label: {
    ...label,
    margin: '0 .35rem'
  },
  disabledButton: {
    ...formButton,
    color: theme.palette.info.main
  },
  container: {
    margin: 'auto',
    maxWidth: '50rem',
    width: '100%',
    minHeight: 'calc(100vh - 11.5rem)',
    padding: '0 1.5rem'
  },
  actions: {
    backgroundColor: 'white',
    boxShadow: `0 -0rem 1.5rem -.4rem ${theme.palette.info.main}`,
    alignItems: 'center',
    bottom: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    left: 0,
    padding: '2rem .5rem',
    position: 'absolute',
    right: 0,
    '@media (max-height: 38rem)': {
      padding: '1rem .5rem',
    }
  },
  footerActions: {
    alignItems: 'center',
    display: 'flex'
  },
  footerActionsEnd: {
    justifyContent: 'flex-end'
  },
  footerActionsStart: {
    justifyContent: 'flex-start'
  },
  formButton: formButton,
  formContainer: {
    paddingBottom: '10rem'
  },
  innerFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    maxWidth: '60rem',
    width: '100%'
  }
})

const ListForm = ({ classes, children, title, onSubmit, submitBtnText }) => {
  const [currentQuestion, SetCurrentQuestion] = useState(0)
  const [smallHeight, setSmallHeight] = useState(null)
  const [reviewAnswers, setReviewAnswers] = useState(false)

  const checkSize = () => {
    return setSmallHeight(window.innerWidth > 535)
  }

  const isLastComponent = () => {
    return currentQuestion === children.length - 1
  }

  const isFirstComponent = () => {
    return currentQuestion === 0
  }

  const handleEnter = (e) =>  {
    if (e.shiftKey) return
    const code = e.keyCode || e.which
    if(code === 13 && !e.shiftKey) {
      if (reviewAnswers) onSubmit()
      if (isLastComponent()) setReviewAnswers(true)
      else nextQuestion()
    }
  }

  if (smallHeight === null) checkSize()

  useEffect(() => {
    window.addEventListener('resize', checkSize)
    document.addEventListener('keyup', handleEnter)
    return function cleanup() {
      window.removeEventListener('resize', checkSize)
    document.removeEventListener('keyup', handleEnter)
    }
  });

  const prevQuestion = () => {
    if (reviewAnswers) setReviewAnswers(false)
    else {
      SetCurrentQuestion(currentQuestion - 1)
    }
  }

  const nextQuestion = () => {
    if (isLastComponent()) setReviewAnswers(true)
    else {
      setReviewAnswers(false)
      SetCurrentQuestion(currentQuestion + 1)
    }
  }

  return <section className={classes.container}>
    {title}
    {reviewAnswers ? children : children[currentQuestion]}
    <footer className={classes.actions}>
      <section className={classes.innerFooter}>
        <article className={`${classes.footerActions} ${classes.footerActionsStart}`}>
          {
            reviewAnswers
              ? <Button
                type="submit"
                variant="contained"
                size="medium"
                onClick={onSubmit}
                className={classes.button}
              >
                {submitBtnText}
                <FontAwesomeIcon icon={faLongArrowRight} />
              </Button>
              : <Button
                variant="contained"
                size="medium"
                onClick={nextQuestion}
                className={classes.button}
              >
                {isLastComponent() ? 'Review Answers' : 'Next Question'}
                <FontAwesomeIcon icon={faLongArrowRight} />
              </Button>
          }
          {smallHeight && <span className={classes.label}>Or</span>}
          {smallHeight && <span className={classes.darkText}>Press enter</span>}
        </article>
        <article className={`${classes.footerActions} ${classes.footerActionsEnd}`}>
          <span className={classes.label}>Question {currentQuestion + 1}/{children.length}</span>
          {smallHeight && <div>
            {
              !isFirstComponent()
                ? <Button
                onClick={prevQuestion}
                variant="contained"
                size="medium"
                className={classes.formButton}
              >
                <FontAwesomeIcon icon={faCaretUp} />
              </Button> : <Button
                  variant="contained"
                  size="medium"
                  disabled
                  classes={{ disabled: classes.disabledButton }}
                  className={classes.formButton}
                >
                  <FontAwesomeIcon icon={faCaretUp} />
                </Button>
            }
            {
              !reviewAnswers
                ? <Button
                variant="contained"
                size="medium"
                onClick={nextQuestion}
                className={classes.formButton}
              >
                <FontAwesomeIcon icon={faCaretDown} />
              </Button> : <Button
                variant="contained"
                size="medium"
                disabled
                className={classes.formButton}
                classes={{ disabled: classes.disabledButton }}
              >
                <FontAwesomeIcon icon={faCaretDown} />
              </Button>
          }
          </div>}
        </article>
      </section>
    </footer>
  </section>
}

export default withStyles(styles)(ListForm)
