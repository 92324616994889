/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable react/jsx-indent-props */
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import { withSnackbar } from 'notistack'
import React, { useState, useEffect } from 'react'
import { Mutation, Query } from 'react-apollo'
import GetFormSubmission from '../../../../graphql/queries/getFormSubmission'
import Decoders from '../../Decoders'
import ContinueFormSubmissionLater from '../../graphql/mutations/ContinueFormSubmissionLater'
import SubmitForm from '../../graphql/mutations/SubmitForm'
import SubmitQuestion from '../../graphql/mutations/SubmitQuestion'
import GetForm from '../../graphql/queries/get_form'
import Form from '../form/Form'
import FormCompleted from '../form/FormCompleted'
import AppLayout from '../layout/AppLayout'
import CenterColumn from '../layout/CenterColumn'
import Title from '../layout/Title'

const styles = theme => ({
  paper: {
    marginTop: theme.spacing.unit * 3
  }
})

const getPostConfirmationFromPath = (searchString) => {
  if (searchString.includes('post_confirmation')) {
    let searchStringSplitted = searchString.split('=')
    return searchStringSplitted[1]
  }
}

const AfterConfirmationRoute = props => {
  let form

  const { classes } = props
  const formKey = props.match.params.id
  let postConfirmation = getPostConfirmationFromPath(props.location.search)

  const title = <Title>
    TheSyndicate.com
  </Title>

  const [finished, setFinished] = useState(false)
  useEffect(() => {
    document.title = "The Syndicate - Alter Confirmation"
  }, []);
  return (
    <AppLayout title={title}>
      <CenterColumn>
        <Query query={GetForm} variables={{ key: 'user' }}>
          {({ data, error, loading }) => {
            if (!loading) {
              form = Decoders.form(data.form)
            }
            return (
              !loading && <Query query={GetFormSubmission} variables={{ formKey: 'user' }}>
                {({ data, error, loading }) => {
                  let questionSubmissions
                  if (!loading) {
                    if (data.formSubmission != null && !data.formSubmission.questionSubmissions) {
                      questionSubmissions = data.formSubmission.questionSubmissions
                    }
                  }
                  return (
                    <Mutation
                      onCompleted={data => {
                        if (data) {
                          enqueueSnackbar('An email has been sent to you.', { variant: 'success' })
                        }
                      }}
                      mutation={ContinueFormSubmissionLater}
                    >
                      {continueFormSubmissionLater => {
                        return <Mutation
                          onError={error => {
                            enqueueSnackbar('There was an error', { variant: 'error' })
                          }}
                          onCompleted={data => {
                            if (data.submitForm) {
                              setFinished(true)
                            }
                          }}
                          mutation={SubmitForm}
                        >
                          {submitForm => {
                            return <Mutation
                              onError={error => {
                                alert(error)
                              }}
                              onCompleted={data => {
                                console.log('submitQuestion response oncompleted', data)
                              }}
                              mutation={SubmitQuestion}
                            >
                              {submitQuestion => {
                                if (!finished) {
                                  return loading ? (
                                    <h2>Loading...</h2>
                                  ) : <>
                                      <Form
                                        title={form.title}
                                        questions={form.questions}
                                        questionSubmissions={questionSubmissions}
                                        formKey='user'
                                        submitForm={submitForm}
                                        submitQuestion={submitQuestion}
                                        {...props}
                                      />
                                      <Button
                                        onClick={() => continueFormSubmissionLater({ variables: { formKey } })}
                                        className={classes.button}
                                      >
                                        Get a link to continue filling out this form later
                                      </Button>
                                    </>
                                } else {
                                  return <FormCompleted formKey={'user'} postConfirmation={postConfirmation} />
                                }
                              }}
                            </Mutation>
                          }}
                        </Mutation>
                      }}
                    </Mutation>
                  )
                }}
              </Query>)
          }}</Query>
      </CenterColumn>
    </AppLayout>
  )
}

export default withStyles(styles)(withSnackbar(AfterConfirmationRoute))
