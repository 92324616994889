import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import React from 'react'
import { Mutation } from 'react-apollo'
import ReactImageGallery from 'react-image-gallery'
import CreateProductImage from '../../graphql/mutations/CreateProductImage'
import DeleteProductImage from '../../graphql/mutations/DeleteProductImage'
import UpdateProductImage from '../../graphql/mutations/UpdateProductImage'
import './ImageGallery.css'
import { maybeRenderImage, maybeRenderImageSet, maybeRenderVideo, onSlide } from './ImageGallery.jsx'
import ImageUploadField from './ImageUploadField'

const styles = theme => ({
  customControls: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 1000
  },
  addAnImageSlide: {
    height: '300px',
    width: '100%',
    backgroundColor: '#eee'
  },
  addAnImageButton: {
    width: '100%',
    height: '100%'
  }
})

const renderItem = ({ props, classes, onUpdate }) => (item) => {
  const onImageError = props.onImageError || (() => { })
  const { onImageLoad, ref } = props

  return <>
    <div className='image-gallery-image'>
      <div className={classes.customControls}>
        <Mutation
          mutation={DeleteProductImage}
          onCompleted={onUpdate}
        >
          {deleteProductImage => {
            return <Button
              color="primary"
              aria-label="Delete Image"
              onClick={() => {
                deleteProductImage({ variables: { id: item.id } })
              }}
            >
              <DeleteIcon />
            </Button>
          }}
        </Mutation>
        <Mutation
          mutation={UpdateProductImage}
          onCompleted={onUpdate}
        >
          {updateProductImage => {
            return <ImageUploadField
              color="primary"
              aria-label="Edit Image"
              label={<EditIcon />}
              onChange={({ target: { files: [file] } }) => {
                updateProductImage({ variables: { id: item.id, input: { image: file } } })
              }}
            />
          }}
        </Mutation>
      </div>

      {maybeRenderImageSet(item, { onImageLoad, onImageError })}
      {maybeRenderImage(item, { onImageLoad, onImageError })}
      {maybeRenderVideo(item, { ref })}

      {
        item.description &&
        <span className='image-gallery-description'>
          {item.description}
        </span>
      }
    </div>
  </>
}

const renderAddProductImageItem = ({ props, classes, onUpdate, companySlug }) => (item) => {
  const onImageError = props.onImageError || (() => { })

  return <>
    <div className='image-gallery-image'>
      <div className={classes.addAnImageSlide}>
        <Mutation
          mutation={CreateProductImage}
          onCompleted={onUpdate}
        >
          {createProductImage => {
            return <ImageUploadField
              color="primary"
              aria-label="Add Image"
              label={<AddIcon />}
              className={classes.addAnImageButton}
              onChange={({ target: { files: [file] } }) => {
                createProductImage({ variables: { companySlug, input: { image: file } } })
              }}
            />
          }}
        </Mutation>
      </div>
    </div>
  </>
}

const renderAddProductImageThumbInner = () => <AddIcon />

const EditableImageGallery = props => {
  let { classes, items, onUpdate, companySlug, imageGalleryItemRef, ...rest } = props
  items = items.map(item => {
    const ref = imageGalleryItemRef
    return {
      ...item,
      ref,
      renderItem: renderItem({ props: { ...props, ref }, classes, onUpdate })
    }
  })

  const addProductImageItem = {
    renderThumbInner: renderAddProductImageThumbInner,
    renderItem: renderAddProductImageItem({ props, classes, onUpdate, companySlug })
  }

  return <ReactImageGallery
    items={items.concat(addProductImageItem)}
    lazyLoad={false}
    useBrowserFullscreen={false}
    onSlide={onSlide(items)}
    {...rest}
  />
}

export default withStyles(styles)(EditableImageGallery)
