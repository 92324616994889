import React from 'react'
import { Mutation } from 'react-apollo'
import CreateMessage from '../../graphql/mutations/CreateMessage'
import NewMessageForm from './NewMessageForm'

const NewMessageFormContainer = ({
  discussionId,
  onCreateMessage
}) => {
  const initialState = {
    body: ''
  }

  return <Mutation
    onCompleted={data => {
      if (data) {
        onCreateMessage && onCreateMessage(data)
      }
    }}
    mutation={CreateMessage}
  >
    {createMessage => {
      const onSubmit = messageInput => createMessage({ variables: { discussionId, messageInput } })
      return <NewMessageForm onSubmit={onSubmit} initialState={initialState} />
    }}
  </Mutation>
}

export default NewMessageFormContainer
