import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ReactMarkdown from 'markdown-to-jsx'
import React from 'react'
import {
  title,
  subTitle,
  smallTitle,
  blackText,
  label,
  generalTitle
} from '../layout/styleComponents'
const styles = theme => ({
  em: {
    color: theme.palette.success.main,
    display: 'block',
    fontSize: '12px',
    marginTop: '1.5rem',
    textAlign: 'left'
  },
  link: {
    color: theme.palette.secondary.main,
    textDecoration: 'none'
  },
  list: {
    paddingLeft: '1rem',
    margin: 0
  },
  listItem: {
    fontSize: '.7rem',
    '& span': {
      ...subTitle,
      fontSize: '.95rem !important',
      fontWeight: 400,
      wordBreak: 'break-word'
    },
    '& strong': {
      fontWeight: '600 !important'
    }
  },
  text: {
    ...blackText,
    fontWeight: 400,
    fontSize: '.9rem',
    textAlign: 'left',
    margin: 0,
    '& span, & p': {
      fontSize: '.9rem'
    }
  },
  principalP: {
    fontSize: '115%'
  },
  principalSpan: {
    fontSize: '100%'
  },
  principalh2: {
    fontSize: '200%'
  },
  generalTitle: generalTitle,
  title: title,
  subTitle: subTitle,
  smallTitle: smallTitle,
  label: {
    ...label,
    textTransform: 'uppercase'
  },
  blackTitle: {
    ...subTitle,
    fontSize: '.9rem !important',
    fontWeight: 400
  },
  blackTitleBig: {
    ...subTitle,
    fontSize: '1.175rem !important',
    fontWeight: 400,
    marginTop: '1.5rem'
  }
})

const Markdown = ({ classes, variant, blackTitles, ...rest }) => {
  const defaultOverrides = {
    h1: {
      component: props => <Typography {...props} className={`${classes.generalTitle} ${blackTitles ? classes.blackTitle : classes.title}`} component="h1" gutterBottom variant={"h1"} />
    },
    h2: {
      component: props => <Typography {...props} className={`${classes.generalTitle} ${blackTitles ? classes.blackTitleBig : classes.subTitle}`} component="h2" gutterBottom variant={"h2"} />
    },
    h3: {
      component: props => (
        <Typography {...props} className={`${classes.generalTitle} ${blackTitles ? classes.blackTitleBig : classes.smallTitle}`} gutterBottom variant="subtitle1" />
      )
    },
    h4: {
      component: props => (
        <Typography {...props} className={`${classes.generalTitle} ${classes.label}`} gutterBottom variant="caption" paragraph />
      )
    },
    h6: {
      component: props => (
        <Typography {...props} className={classes.blackTitleBig} gutterBottom variant="subtitle1" paragraph />
      )
    },
    em: {
      component: 'em',
      props: {
        component: 'em',
        className: classes.em
      }
    },
    a: {
      component: 'a',
      props: {
        component: 'a',
        className: classes.link
      }
    },
    p: {
      component: Typography,
      props: {
        component: 'p',
        paragraph: true,
        className: classes.text
      }
    },
    span: {
      component: Typography,
      props: {
        component: 'span',
        className: classes.text
      }
    },
    li: {
      component: withStyles(styles)(({ classes, ...props }) => <li className={classes.listItem}>
        <Typography component="span" {...props} />
      </li>)
    },
    ul: {
      component: 'ul',
      props: {
        component: 'ul',
        className: classes.list
      }
    }
  }

  const principalOverridesAdditions = {
    p: {
      component: Typography,
      props: {
        component: 'p',
        paragraph: true,
        className: classes.principalP
      }
    },
    h2: {
      component: Typography,
      props: {
        component: 'h2',
        gutterBottom: true,
        variant: 'h6',
        className: classes.principalh2
      }
    },
    span: {
      component: Typography,
      props: {
        component: 'span',
        className: classes.principalSpan
      }
    },
    test: 'foo'
  }

  const principalOverrides = { ...defaultOverrides, ...principalOverridesAdditions }
  const principalOptions = {
    overrides: principalOverrides
  }
  const defaultOptions = {
    overrides: defaultOverrides
  }

  const options = variant === 'principal' ? principalOptions : defaultOptions
  return <ReactMarkdown options={options} {...rest} />
}

export default withStyles(styles)(Markdown)
