import gql from 'graphql-tag';

const submitQuestion = gql`
  mutation submitQuestion($formKey: String!, $questionSubmission: QuestionSubmissionInput!) {
    submitQuestion(formKey: $formKey, questionSubmission: $questionSubmission) {
      id
    }
  }
`;

export default submitQuestion;