import gql from 'graphql-tag'

const getCompanyUpdate = gql`
  query CompanyUpdate($companySlug: String!, $id: ID!) {
    company(id: $companySlug){
      id
      slug
      can {
        manage
      }
      companyUpdate(id: $id) {
        id
        messages {
          id
          body
          createdAt
          user {
            id
            name
            title
            avatarUrl
            defaultCompany {
              id
              name
            }
          }
        }
      }
    }
  }
`

export default getCompanyUpdate
