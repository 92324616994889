import gql from 'graphql-tag'

const getNotification = gql`
  query Notification($id: ID!) {
    notification(id: $id) {
      id
      title
      body
      notifiable {
        ... on MeetingRequest {
          id
          status
          conversation {
            title
            discussion {
              id
              messages {
                id
                body
                createdAt
                user {
                  id
                  name
                  avatarUrl
                }
              }
            }
          }
          user {
            id
            avatarUrl
            name
          }
        }
        ... on CompanyUpdateDiscussion {
          id
          company {
            id
            name
            logoUrl
          }
          discussion {
            id
            messages {
              id
              body
              createdAt
              user {
                id
                name
                avatarUrl
              }
            }
          }
        }
        ... on UserUpdateDiscussion {
          id
          user {
            id
            name
            avatarUrl
          }
          discussion {
            id
            messages {
              id
              body
              createdAt
              user {
                id
                name
                avatarUrl
              }
            }
          }
        }
        __typename
      }
    }
  }
`

export default getNotification
