import brand from '../brand'
import {background} from '../colors'

export const tagIcon = {
  fontSize: '14px',
  color: background,
  marginRight: '.5rem'
}
export const tagIconTwo = {
  fontSize: '14px',
  color: background,
  marginRight: '.75rem'
}
export const badges = {
}
export const socialLinks = (theme) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  marginBottom: '0.3rem',
  marginRight: theme.spacing.unit
})

export const socialLink = (theme) => ({
  fontSize: '1.5rem',
  color: background,
  marginRight: '.75rem',
  transition: 'all 0.25s',
  '&:hover': {
    color: theme.palette.primary.main
  }
})
