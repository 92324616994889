import { Grid, withMobileDialog } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import Person from '../core/Person'
import TitledPaper from '../core/TitledPaper'

const styles = theme => ({
  paddedPaperClassName: {
    backgroundColor: 'white',
    mixBlendMode: 'multiply',
    padding: '2rem 1.5rem'
  },
  container: {
    justifyContent: 'space-between'
  },
  investor: {
    margin: '0 0 1.5rem',
    '&:last-child': {
      margin: 0
    }
  }
})

function InvestorsHero (props) {
  const {
    classes,
    investors
  } = props

  return (
    <>
      {
        (investors || []).length > 0 && (
          <TitledPaper noLogo className={classes.paddedPaperClassName}>
            <Grid container className={classes.container}>
              {investors.map((investor, i) => (
                <Grid item xs={12} key={i} className={classes.investor}>
                  <Person
                    white
                    avatarUrl={investor.user.avatarUrl ||'/user.png'}
                    name={investor.user.name}
                    bio={investor.user.bio}
                    url={`/users/${investor.user.slug}`}
                  />
                </Grid>
              ))}
            </Grid>
          </TitledPaper>
        )
      }
    </>
  )
}

export default withStyles(styles)(withMobileDialog()(InvestorsHero))
