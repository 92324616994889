import { Button, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import LongTextArea from '../core/LongTextArea';
import { textInput, blueButton, label } from '../layout/styleComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons'
import { white } from '../layout/colors'

const styles = theme => ({
  textInput: textInput,
  actions: {
    margin: ' 1rem auto',
  },
  container: {
    padding: '1rem 1.25rem',
    background: white,
  },
  text: {
    ...label,
    textTransform: 'none',
    '& a': {
      color: theme.palette.secondary.main,
      textDecoration: 'none',
      fontWeight: 400
    }
  },
  button: blueButton
})

class NewMessageFormBase extends React.Component {
  setField = fieldName => {
    return evt => {
      this.setState({
        [fieldName]: evt.target.value,
      })
    }
  }

  constructor(props) {
    super(props)
    this.state = props.initialState
  }

  sendMessage () {
    this.props.onSubmit(this.state)
    this.setField('body')({ target: { value: '' } })
  }

  render () {
    const { classes, create } = this.props

    return (
      <form className={classes.container}>
        <LongTextArea
          label="Message"
          value={this.state.body}
          onChange={this.setField('body')}
          classes={classes}
        />

        <div className={classes.actions}>
          <Button
            variant="contained"
            onClick={this.sendMessage.bind(this)}
            className={classes.button}
          >
            {create ? 'Create Update' : 'Reply'}
            <FontAwesomeIcon  icon={faLongArrowRight}/>
          </Button>
        </div>
        <Typography variant="caption" className={classes.text}>
          Your text supports markdown, if you have any questions,{' '}
          <a target="_blank" href="https://www.markdownguide.org/cheat-sheet/">
            check out this guide
          </a>
          .
        </Typography>
      </form>
    )
  }
}

const NewMessageForm = withStyles(styles, { withTheme: true })(
  NewMessageFormBase
)

export default NewMessageForm
