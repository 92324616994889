import React, { useState, useEffect } from 'react'
import { Query } from 'react-apollo'
import { withSnackbar } from 'notistack'
import getConversations from '../../graphql/queries/getConversations'
import {getUnreadConversationsAndUpdates} from '../../graphql/queries/getNotifications'
import Conversations from '../conversations/Conversations'
import AppLayout from '../layout/AppLayout'
import CenterColumn from '../layout/CenterColumn'
import { Redirect } from 'react-router'
import Title from '../layout/Title'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
})


function ConversationsRoute ({ history, enqueueSnackbar }) {
  useEffect(() => {
    document.title = "The Syndicate - Conversations"
  }, []);
  const [conversations, setConversations] = useState(null)

  return <Query query={getConversations} fetchPolicy="cache-and-network">
    {({ loading, error, data }) => {
      if (loading) return null
      if (error) {
        if (error.message && error.message.includes('unauthorized')) {
          enqueueSnackbar("You don't have access to see this.", { variant: 'error' })
          return <Redirect
            to={`/login`}
          />
        } else {
          return <Typography>Error...</Typography>
        }
      }

      if (data && data.conversations && data.conversations.length > 0 && (!conversations || conversations.length === 0)) {
        const withoutDupli = Array.from(new Set(data.conversations.map(a => a.id)))
        .map(id => {
          return data.conversations.find(a => a.id === id)
        })
        setConversations(withoutDupli)
      }

      const title = <Title>Conversations</Title>
      return <Query query={getUnreadConversationsAndUpdates} fetchPolicy="cache-and-network">
        {({ loading, error, data, refetch }) => {
          if (loading) {
            return null
          }
          return <AppLayout title={title}>
            <CenterColumn>
              <Conversations conversations={conversations || []} history={history} loading={loading} unreadConversations={data.profile} refetch={refetch} />
            </CenterColumn>
          </AppLayout>
        }}
      </Query>
    }}
  </Query>
}

export default withStyles(styles)(withSnackbar(ConversationsRoute))
