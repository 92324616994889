import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import React, {useState} from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { Link as BrowserLink } from 'react-router-dom'
import thesyndicateLogoBlack from '../../../../assets/images/thesyndicate_logo_black.png'
import Video from '../../../../assets/video/background.mp4'
import Markdown from './core/Markdown'
import CenterColumn from './layout/CenterColumn'
import SimpleHero from './core/SimpleHero'
import { withSnackbar } from 'notistack'
import { background } from './layout/colors'
import { homeButton, blueButton, redButton, label } from './layout/styleComponents'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons'
import { faExternalLinkSquare } from '@fortawesome/pro-solid-svg-icons'
import { Query } from 'react-apollo'
import getProfile from '../graphql/queries/getProfile'
import {dateFormatMMMDDYYYY} from './helpers/dateFormat'

const styles = theme => ({
  tealButton: homeButton,
  container: {
    background: 'transparent',
    maxWidth: '100%',
    margin: 'auto'
  },
  videoBackground: {
    top: 0,
    left: 0,
    position: 'absolute',
    zIndex: 0,
    opacity: '.1',
    width: '109%',
    height: '100%',
    objectFit: 'cover',
  },
  hero: {
    background: 'none',
    padding: 0,
    fontSize: '1.5rem'
  },
  logo: {
    display: 'block',
    margin: 'auto',
    maxWidth: '220px',
    padding: '7rem 0 3rem',
    textAlign: 'center',
    '@media (min-width: 45rem)': {
      padding: '5rem 0 7rem'
    }
  },
  buttonBlueContainer: {
    textAlign: 'center',
    '@media (max-width: 45rem)': {
      marginBottom: '.5rem',
      width: '100%',
    },
    '@media (min-width: 45rem)': {
      marginRight: '.5rem',
    },
    '&::before': {
      ...label,
      content: '"Join as"',
      marginRight: '.5rem',
      '@media (max-width: 45rem)': {
        display: 'block',
        width: '100%',
        marginBottom: '.5rem'
      }
    }
  },
  buttonRedContainer: {
    textAlign: 'center',
    '@media (max-width: 45rem)': {
      marginBottom: '.5rem',
      width: '100%',
    },
    '@media (min-width: 45rem)': {
      '&::before': {
        ...label,
        content: '"Or"',
        marginRight: '1rem',
      }
    }
  },
  logos: {
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    listStyle: 'none',
    margin: '2rem 0 4rem',
    alignItems: 'stretch',
    width: '2000%',
    marginLeft: '-950%',
    '@media (max-width: 45rem)': {
      padding: '2rem 0'
    }
  },
  logosColumn: {
    maxWidth: '8.75rem',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    position: 'relative'
  },
  actionUrl: {},
  brandImage: {
    backgroundColor: 'white !important',
    backgroundSize: 'contain !important',
    borderRadius: '1rem',
    width: '100px',
    height: '100px',
    objectFit: 'contain',
    transition: '0.3s',
    position: 'relative',
    margin: '0 0 1.25rem'
  },
  logoText: {
    color: theme.palette.primary.main,
    fontSize: '4rem'
  },
  logoTextInner: {
    fontSize: '50px',
    fontVariant: 'small-caps'
  },
  heroContent: {
    fontSize: '60%',
    maxWidth: '42.5rem',
    margin: 'auto',
    '& h2': {
      maxWidth: '16rem',
      margin: 'auto',
      '@media (min-width: 30rem)': {
        maxWidth: '40rem',
      }
    }
  },
  buttonLink: {
    alignItems: 'center',
    textDecoration: 'none',
    '@media (max-width: 45rem)': {
      display: 'flex',
      justifyContent: 'center',
      width: '100%'
    }
  },
  smallText: {
    fontFamily: 'Lato',
    fontSize: '80%',
    margin: '.5rem 0',
    textAlign: 'center',
    width: '100%'
  },
  centered: {
    textAlign: 'center',
    padding: '0 2rem'
  },
  row: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    listStyle: 'none',
    margin: 0,
    padding: '2rem 0 3rem',
    position: 'relative',
    zIndex: 1
  },
  heroContainerTwo: {
    backgroundColor: background,
    display: 'block',
    margin: 'auto',
    width: '100%',
    position: 'relative',
    '& main': {
      maxWidth: '45rem',
      '@media (min-width: 51rem)': {
        maxWidth: '50rem'
      },
      '@media (min-width: 64rem)': {
        maxWidth: '940px'
      }
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      pointerEvents: 'none',
      backgroundImage: 'linear-gradient(to bottom, rgba(236,241,244,0), rgba(236,241,244, 1) 90%)',
      width: '100%',
      height: '7rem',
    }
  },
  marginTop: {
    paddingTop: '7rem',
    '@media (max-height: 45rem)': {
      paddingTop: '4rem'
    }
  },
  relative: {
    position: 'relative',
    '&:hover .collage-popover': {
      display: 'block'
    }
  },
  founderButton: {
    ...blueButton,
    '@media (max-width: 45rem)': {
      marginBottomn: '.5rem',
      maxWidth: '15rem',
      width: '80%'
    },
    '@media (min-width: 45rem)': {
      marginRight: '.5rem'
    }
  },
  marginTop: {
    paddingTop: '7rem'
  },
  investorButton: {
    ...redButton,
    '@media (max-width: 45rem)': {
      maxWidth: '15rem',
      width: '80%'
    }
  },
  icon: {
    marginLeft: '.5rem'
  },
  goLInk: {
    background: theme.palette.primary.main,
    borderRadius: '.5rem',
    color: 'white',
    marginLeft: '.35rem',
    marginTop: '.25rem'
  },
  popover: {
    background: theme.palette.primary.main,
    borderRadius: '1rem',
    bottom: '100%',
    display: 'none',
    fontFamily: 'Lato',
    margin: 0,
    minWidth: '200px',
    padding: 0,
    position: 'absolute',
    zIndex: 10,
    '& li': {
      background: theme.palette.primary.main,
      color: 'white',
      fontSize: '16px',
      padding: '0 1.75rem',
      lineHeight: '28px',
      '&:first-child': {
        borderRadius: '1rem 1rem 0 0',
        color: 'rgba(255, 255, 255, .5)',
        textTransform: 'uppercase',
        paddingTop: '1rem',
        paddingBottom: '.5rem'
      },
      '&:nth-child(2)': {
        paddingBottom: '1rem'
      },
      '&:last-child': {
        borderRadius: '0 0 1rem 1rem',
        borderTop: `.5px solid rgba(255, 255, 255, .5)`,
        paddingTop: '.5rem',
        paddingBottom: '1rem'
      },
      '& strong, & span': {
        display: 'block'
      },
      '& a': {
        alignItems: 'center',
        color: 'white',
        display: 'flex',
        fontFamily: 'Lato',
        textDecoration: 'none',
      }
    }
  }
})

const Home = props => {
  const { joinWaitListButtonText, classes, heroContent, featuredItems, profileSlug, loggedIn, enqueueSnackbar } = props
  const [logos, setLogos] = useState([])

  const showSnackIfNecessary = () => {
    if (loggedIn) {
      enqueueSnackbar('You already have an account, no need to join the waitlist again!', { variant: 'success' })
    }
  }

  const investorLink = loggedIn ? `/users/${profileSlug}` : '/forms/wait_list?type=Investor'
  const founderLink = loggedIn ? `/users/${profileSlug}` : '/forms/wait_list?type=Founder'

  const getGridItems = (featuredItems, classes) => {
    console.log(featuredItems)
    let columns = []
    let items = [...featuredItems]
    let pass = 1
    while(items.length) {
      if (pass === 1 || pass === 3 || pass === 6 || pass === 11) {
        columns.push({
          container: items.splice(0, 2),
          alignContent: pass === 1 || pass === 6 ? 'flex-start' : 'center',
          top: pass === 1,
          isDual: true
        })
        pass = pass + 1
      } else {
        columns.push({
          container: items.splice(0, 3),
          top: pass === 5 || pass === 7 || pass === 9
        })
        if (pass === 12) pass = 1
        else pass = pass + 1
      }
    }

    if (columns.length > 0) {
      columns.map((column) => {
        items.push(<li className={classes.logosColumn} style={{alignContent: column.alignContent || '', top: column.top ? '3.5rem' : ''}}>
          {column.container.map((featuredItem, index) => <article className={classes.relative}>
            <BrowserLink
              key={featuredItem.actionPath}
              to={featuredItem.actionPath}
              className={classes.actionUrl}
            >
              <figure
                className={classes.brandImage}
                style={{
                  bottom: column.isDual && column.top && index === 1 ? '-9rem' : '',
                  background: `url(${featuredItem.imageUrl}) center center no-repeat`
                }}
              />
            </BrowserLink>
            {featuredItem.deal && <ul className={`${classes.popover} collage-popover`}>
              <li>Deal Info</li>
              <li>
                <strong>${featuredItem.deal.committed}</strong>
                <span>invested on</span>
                <span>{dateFormatMMMDDYYYY(featuredItem.deal.investedOn)}</span>
              </li>
              <li><a target="_blank" href={featuredItem.deal.companyUrl}>{featuredItem.deal.companyUrl.replace('https://', '').replace('http://', '').replace('www.', '')}<FontAwesomeIcon icon={faExternalLinkSquare} className={classes.goLInk}/></a></li>
            </ul>}
          </article>)}
        </li>)
      })
    }
    return items
  }

  return <Query query={getProfile} fetchPolicy="cache-and-network">
  {({ loading, error, data }) => {
    if (error) {
      return null
    }

    let profile
    if (data) {
      profile = data.profile
    }
    const loggedIn = Boolean(profile)
    return <SkeletonTheme color="#ddd" highlightColor="#ccc">
      <div className={classes.container}>

        <div className={classes.heroContainer}>
          <SimpleHero className={classes.hero}>
            <section className={classes.heroContainerTwo}>
              <video className={classes.videoBackground} autoPlay muted loop id="background">
                <source src={Video} type="video/mp4" />
              </video>
              <CenterColumn className={classes.relative}>
                {!loggedIn && <Button className={classes.tealButton} color="inherit" href="/login">Sign in</Button>}
                {!loggedIn && <img src={thesyndicateLogoBlack} className={classes.logo} />}
                {heroContent && <div className={`${classes.centered} ${loggedIn && classes.marginTop}`}><Markdown className={classes.heroContent}>{heroContent}</Markdown></div>}
                {!heroContent && <>
                  <Skeleton count={3} />
                  <Skeleton count={1} />
                  <Skeleton count={1} />
                </>}
              </CenterColumn>

              <ul className={classes.row}>
                <li className={classes.buttonBlueContainer}>
                  <BrowserLink onClick={showSnackIfNecessary} to={investorLink} className={classes.buttonLink}>
                    <Button variant="contained" size="large" className={classes.founderButton}>
                      Founder
                      <FontAwesomeIcon icon={faLongArrowRight} className= {classes.icon} />
                    </Button>
                  </BrowserLink>
                </li>

                <li className={classes.buttonRedContainer}>
                  <BrowserLink onClick={showSnackIfNecessary} to={founderLink} className={classes.buttonLink}>
                    <Button variant="contained" size="large" className={classes.investorButton}>
                    Investor
                    <FontAwesomeIcon icon={faLongArrowRight} className= {classes.icon} />
                    </Button>
                  </BrowserLink>
                </li>
              </ul>
            </section>
          </SimpleHero>

        </div>

        <CenterColumn>
          <ul className={classes.logos}>
            {
              getGridItems(featuredItems, classes)
            }
          </ul>
        </CenterColumn>
      </div>
    </SkeletonTheme>
  }}
</Query>
}

export default withSnackbar(withStyles(styles)(Home))
