import React, {useEffect} from 'react'
import Dashboard from '../dashboard/Dashboard'
import AppLayout from '../layout/AppLayout'
import Title from '../layout/Title'

function DashboardRoute ({ match: { params: { slug } }, history }) {
  const title = <Title>
    Dashboard
  </Title>

  useEffect(() => {
    document.title = "The Syndicate - Dashboard"
  }, []);

  return <AppLayout title={title}>
    <Dashboard />
  </AppLayout>
}

export default DashboardRoute
