import Moment from 'moment'

export const dateFormatMMYY = (dateString) => {
  return Moment(dateString).format("MMM YYYY")
}

export const dateFormatMMDD = (dateString) => {
  return Moment(dateString).format("MMM DD")
}

export const dateFormatMMMDDYYYY = (dateString) => {
  return Moment(dateString).format("MMM DD, YYYY")
}
