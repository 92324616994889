import gql from 'graphql-tag';

const getInvitePageContent = gql`
  query getInvitePageContent {
    invitePageContent {
      title
      heroContent
      invitationSentContent
    }
  }
`

export default getInvitePageContent;
