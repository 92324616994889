import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import { gullGray } from '../layout/colors'
import { blackText } from '../layout/styleComponents'

const styles = theme => ({
  wrapper: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: 'calc(100% - 6.5rem)'
  },
  heading: {
    ...blackText,
    margin: '0 0 .5rem'
  },
  caption: {
    ...blackText,
    fontSize: '16px',
    fontWeight: 400,
    margin: 0,
    lineHeight: '28px',
    textAlign: 'left'
  },
})

const TileHeading = ({
  classes,
  children,
  primary,
  secondary,
  className,
  ...rest
}) => <div className={`${classes.wrapper} ${className}`}>
  <Typography variant="h2" component="h5" className={classes.heading}>{primary}</Typography>
  {secondary &&
    <>
      <Typography component="body1" variant="caption" className={classes.caption}>{secondary}</Typography>
      {children}
    </>
  }
</div>

export default withStyles(styles)(TileHeading)
