import gql from 'graphql-tag'

const deleteFundingHistory = gql`
  mutation DeleteFundingHistory($id: ID!){
    deleteFundingHistory(id: $id){
      id
    }
  }
`

export default deleteFundingHistory
