import { withStyles } from '@material-ui/core/styles'
import React, { useReducer, useState } from 'react'
import { reducer } from '../../state/QuestionReducer'
import TitledPaper from '../core/TitledPaper'
import LaunchForm from './LaunchForm'
import { subTitle, blueButton, textInput } from '../layout/styleComponents'
import { Query } from 'react-apollo'
import getProfile from '../../graphql/queries/getProfile'

const styles = theme => ({
  button: blueButton,
  question: {
    ...subTitle,
    ...textInput,
    alignItems: 'flex-start',
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '1rem',
    marginTop: '6rem',
    '& p': {
      ...subTitle,
      marginLeft: '1rem',
      width: 'calc(100% - 3rem)'
    },
    '& div': {
      background: 'none',
      marginLeft: '1.8rem',
      width: 'calc(100% - 1.8rem)',
      '@media (min-width: 45rem)': {
        marginLeft: '2rem',
        width: 'calc(100% - 4rem)'
      }
    },
    '& fieldset': {
      width: '100%'
    },
    '@media (max-height: 38rem)': {
      margin: '0 auto',
    }
  },
  paddedPaper: {
    alignContent: 'flex-start',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '90%',
    margin: 'auto',
    minHeight: '100vh',
    maxWidth: '45rem',
    '@media (min-width: 51rem)': {
      maxWidth: '50rem',
      width: '100%'
    },
    '& .form-container': {
      margin: '0 auto',
      width: '100%'
    }
  },
  paddingBottom: {
    paddingBottom: '10rem'
  }
})

const getQuestionSubmissionsArgs = (questions) => {
  return Object.keys(questions).map(e => {
    const value = questions[e]
    if (value instanceof File) {
      return { id: e, fileValue: value }
    } else {
      return { id: e, value: (value || '') }
    }
  })
}

const sendFormSubmission = (submitForm, state, formKey) => {
  let questionSubmissions = getQuestionSubmissionsArgs(state.questions)

  let questionSubmissionsCleanedUp = []

  // We clean up the question submission to remove any arrays we have
  for (let i = 0; i < questionSubmissions.length; i++) {
    let questionSubmission = questionSubmissions[i]

    if (Array.isArray(questionSubmission.value)) {
      // send the multiple as array
      questionSubmission = { id: questionSubmission.id, value: questionSubmission.value.join(',') }
    }

    questionSubmissionsCleanedUp.push(questionSubmission)
  }

  submitForm({
    variables: {
      formKey,
      questionSubmissions: questionSubmissionsCleanedUp
    }
  })
}

const sendQuestionSubmission = ({ index, questions, state, formKey, submitQuestion, dispatch }) => {
  let questionSubmissions = getQuestionSubmissionsArgs(state.questions)
  const orderedQuestions = questions.sort((a, b) => a.order - b.order)
  const submittedQuestion = orderedQuestions.find(a => a.order === index + 1)

  let questionSubmission = questionSubmissions.find(a => a.id === submittedQuestion.id)
  if (questionSubmission) {
    if (Array.isArray(questionSubmission.value)) {
      // send the multiple as array
      questionSubmission = { id: questionSubmission.id, value: questionSubmission.value.join(',') }
    }
    submitQuestion({
      variables: {
        formKey,
        questionSubmission
      }
    })
  } else {
    dispatch({ type: 'setQuestionValue', questionId: submittedQuestion.id, value: '' })
  }
}

/*
  Set question values for any query string we pass.
*/
const setQuestionValues = ({ questions, submitQuestion, formKey, hiddenFieldsKeyValues, state, dispatch }) => questions.map(question => {
  let value = hiddenFieldsKeyValues[question.key]

  if (question.type === 'single_select') {
    let labelChoice = question.choices.find(c => c.label === value)
    let idChoice = question.choices.find(c => c.id === value)

    // We are doing this to prefill the choice questions looking by key and not the value
    if (idChoice) {
      dispatch({ type: 'setQuestionValue', questionId: question.id, value: value })
    } else if (labelChoice) {
      dispatch({ type: 'setQuestionValue', questionId: question.id, value: labelChoice.id })
    }
  } else {
    const currentValue = state.questions[question.id]
    if (currentValue !== value && currentValue !== undefined) {
      dispatch({ type: 'setQuestionValue', questionId: question.id, value: value })
    }
  }
})

const Form = props => {
  const { hiddenFieldsKeyValues, formKey, title, questions, submitForm, submitQuestion, questionSubmissions, classes, continueFormSubmissionLater } = props
  const questionsInState = questions.reduce((acc, question) => { acc[question.id] = ''; return acc }, {})

  // Initiate the state with
  // "questionId": ""
  let questionsWithQuestionSubmissions = questionsInState
  // If we have question Submissions,
  // then we add these questions submissions values into the
  // state "questionId": <questionSubmissionValue>
  if (questionSubmissions !== undefined && questionSubmissions.length > 0) {
    questionsWithQuestionSubmissions = questionSubmissions.reduce((acc, question) => { acc[question.question.id] = question.value; return acc }, {})
  }

  const initialStateWithQuestionSubmissions = { finished: false, questions: { ...questionsWithQuestionSubmissions } }
  const [state, dispatch] = useReducer(reducer, { ...initialStateWithQuestionSubmissions })
  const [initialized, setInitialized] = useState(false)
  const [questionIndex, setQuestionIndex] = useState('')
  const [showReviewView, setShowReviewView] = useState(false)

  if (!initialized && !questionSubmissions.length > 0) {
    setQuestionValues({ questions, submitQuestion, formKey, hiddenFieldsKeyValues, state, dispatch })
    setInitialized(true)
  }

  let questionHeader

  if (state.questions) {
    // if we have hidden field, this is the first in the item
    const filteredQuestions = questions.filter(q => q.type !== 'hidden_field')
    if (questionIndex === filteredQuestions.length) {
      questionHeader = filteredQuestions[questionIndex - 1] && filteredQuestions[questionIndex - 1].header
    }
    else questionHeader = filteredQuestions && filteredQuestions[questionIndex + 1] ? filteredQuestions[questionIndex + 1].header : filteredQuestions[questionIndex].header
  }

  return  <Query query={getProfile} fetchPolicy="cache-and-network">
  {({ loading, error, data }) => {
    if (error) {
      return null
    }

    let profile
    if (data) {
      profile = data.profile
    }
    const loggedIn = Boolean(profile)
    return <TitledPaper
      className={`${classes.paddedPaper} ${showReviewView && classes.paddingBottom}`}
      white
      form
      smallMargin={showReviewView}
      noLogo={loggedIn}
      title={questionHeader}
    >
      <LaunchForm
        continueFormSubmissionLater={continueFormSubmissionLater}
        className={classes.form}
        questionIndex={questionIndex}
        setQuestionIndex={setQuestionIndex}
        backBtnClass={classes.button}
        formKey={formKey}
        nextBtnOnClick={(index) => sendQuestionSubmission({ index, questions, state, formKey, submitQuestion, dispatch })}
        state={state}
        dispatch={dispatch}
        onSubmit={() => sendFormSubmission(submitForm, state, formKey)}
        hiddenFieldsKeyValues={hiddenFieldsKeyValues}
        questionClass={classes.question}
        questions={questions}
        updateShowReviewView={(value) => setShowReviewView(value)}
      />
    </TitledPaper>
  }}
</Query>
}

export default withStyles(styles)(Form)
