/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable react/jsx-indent-props */
import { withStyles } from '@material-ui/core/styles'
import { withSnackbar } from 'notistack'
import React, { useState, useEffect } from 'react'
import { Mutation } from 'react-apollo'
import { Redirect } from 'react-router-dom'
import JoinUs from '../../graphql/mutations/JoinUs'
import { AppContext } from '../App'
import OnboardingForm from '../form/OnboardingForm'
import AppLayout from '../layout/AppLayout'
import CenterColumn from '../layout/CenterColumn'
import Title from '../layout/Title'

const styles = theme => ({
  paper: {
    marginTop: theme.spacing.unit * 3
  }
})

const getNotice = props => {
  if (
    props &&
    props.location &&
    props.location.state &&
    props.location.state.notice
  ) {
    return props.location.state.notice
  }
  return ''
}

const SignupOnboardingRoute = props => {
  const { enqueueSnackbar } = props
  const formKey = props.match.params.formKey
  const notice = getNotice(props)

  const title = <Title>Onboarding</Title>
  const [email, setEmail] = useState('')
  useEffect(() => {
    document.title = "The Syndicate - Onboarding"
  }, []);
  return (
    <AppLayout title={title}>
      <CenterColumn>
        <AppContext.Consumer>
          {context => {
            const token = context.token
            if (token) {
              return (
                <Redirect
                  to={{
                    pathname: `/forms/${formKey}`,
                    state: { from: props.location }
                  }}
                />
              )
            } else {
              return (
                <Mutation
                  onCompleted={data => {
                    if (data) {
                      enqueueSnackbar(
                        'Signed up! You should receive an email soon.'
                      )
                      setEmail('')
                    }
                  }}
                  mutation={JoinUs}
                >
                  {joinUs => {
                    return (
                      <OnboardingForm
                        email={email}
                        setEmail={setEmail}
                        notice={notice}
                        joinUs={joinUs}
                        formKey={formKey}
                      />
                    )
                  }}
                </Mutation>
              )
            }
          }}
        </AppContext.Consumer>
      </CenterColumn>
    </AppLayout>
  )
}

export default withStyles(styles)(withSnackbar(SignupOnboardingRoute))
