import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import { whiteSubTitle, whiteText } from '../layout/styleComponents'

const styles = theme => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: '0 1.5rem'
  },
  title: {
    ...whiteSubTitle,
    maxWidth: '60rem',
    width: '100%',
    textAlign: 'center',
    marginBottom: 0
  },
  text: {
    ...whiteText,
    width: '100%',
    textAlign: 'center'
  }
})

const MainHeading = ({ classes, primary, secondary }) => <div className={classes.wrapper}>
  <Typography variant="h2" className={classes.title}>{primary}</Typography>
  {secondary &&
    <>
      <Typography component="body1" className={classes.text}>{secondary}</Typography>
    </>
  }
</div>

export default withStyles(styles)(MainHeading)
