import gql from 'graphql-tag'

const acceptMeeting = gql`
  mutation AcceptMeeting($meetingRequestId: ID!) {
    acceptMeeting(meetingRequestId: $meetingRequestId) {
      id
    }
  }
`

export default acceptMeeting
