import gql from 'graphql-tag'

const deleteUserExperience = gql`
  mutation DeleteUserExperience($id: ID!){
    deleteUserExperience(id: $id){
      id
    }
  }
`

export default deleteUserExperience
