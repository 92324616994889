import gql from 'graphql-tag';

const followCompany = gql`
  mutation followCompany($id: ID!) {
    followCompany(id: $id) {
      id
    }
  }
`;

export default followCompany;
