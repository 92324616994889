import { FormControlLabel, FormGroup, withStyles } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import React from 'react'

const styles = theme => (
  {
    content: {
      display: 'inline-block'
    }
  }
)

function Boolean (props) {
  const { value, classes, onChange, content, required, label, onSubmit } = props
  return <div>
    <Typography variant="body1">{label} <small><i>{required && '(required)'}</i></small></Typography>
    <FormGroup row>
      <FormControlLabel
        control={
          <Checkbox
            checked={value}
            onChange={(e, checked) => {
              onChange(checked)
              onSubmit()
            }}
            value={label}
            color="primary"
          />
        }
        label={'Yes'}
      />

    </FormGroup>
  </div>
}

export default withStyles(styles)(Boolean)
