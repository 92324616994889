import { Typography } from '@material-ui/core'
import { withSnackbar } from 'notistack'
import React, { useState, useEffect } from 'react'
import { Mutation, Query } from 'react-apollo'
import Skeleton from 'react-loading-skeleton'
import UpdateUserExperience from '../../graphql/mutations/UpdateUserExperience'
import getUserExperienceById from '../../graphql/queries/getUserExperienceById'
import EditUserExperience from '../company/EditUserExperience'
import TitledPaper from '../core/TitledPaper'
import AppLayout from '../layout/AppLayout'
import CenterColumn from '../layout/CenterColumn'

function EditUserExperienceRoute (props) {
  const { match: { params: { slug, id } }, history, enqueueSnackbar } = props

  const [startYear, setStartYear] = useState('')
  const [endYear, setEndYear] = useState('')
  const [company, setCompany] = useState('')
  const [position, setPosition] = useState('')
  const [initalized, setInitialized] = useState(false)
  useEffect(() => {
    document.title = "The Syndicate - Edit User Experience"
  }, []);
  return <Query query={getUserExperienceById} variables={{ id: slug, userExperienceId: id }} fetchPolicy="network-only">
    {({ loading, error, data, refetch }) => {
      if (error) {
        return <Typography variant="display2">Error...</Typography>
      }
      let userExperience
      if (!loading && data.user && !initalized) {
        userExperience = data.user.userExperience
        setStartYear(userExperience.startYear)
        setEndYear(userExperience.endYear)
        setCompany(userExperience.company)
        setPosition(userExperience.position)
        setInitialized(true)
      }

      return <AppLayout>
        <CenterColumn>
          <Mutation
            onCompleted={data => {
              if (data) {
                history.push(`/users/${slug}`)
              }
            }}
            mutation={UpdateUserExperience}
          >
            {updateUserExperience => {
              const onSave = userExperienceInput => updateUserExperience({ variables: { id, userExperienceInput } })
              if (loading) {
                return <TitledPaper title="Edit your user experience">
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                </TitledPaper>
              } else {
                return <EditUserExperience
                  enqueueSnackbar={enqueueSnackbar}
                  startYear={startYear}
                  setStartYear={setStartYear}
                  endYear={endYear}
                  setEndYear={setEndYear}
                  company={company}
                  setCompany={setCompany}
                  position={position}
                  setPosition={setPosition}
                  onSave={onSave}
                />
              }
            }}
          </Mutation>
        </CenterColumn>
      </AppLayout>
    }}
  </Query>
}

export default withSnackbar(EditUserExperienceRoute)
