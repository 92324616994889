import { AppBar, Badge, Toolbar, Button, Grid, Dialog, DialogContent, IconButton, Typography, withMobileDialog } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { withStyles } from '@material-ui/core/styles'
import { withSnackbar } from 'notistack'
import React, { useState, useEffect } from 'react'
import 'react-image-gallery/styles/css/image-gallery.css'
import { Link as BrowserLink } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import Markdown from '../core/Markdown'
import MaxLengthTextField from '../core/MaxLengthTextField'
import Message from '../core/Message'
import PaddedPaper from '../core/PaddedPaper'
import TitledPaper from '../core/TitledPaper'
import HeaderHero from '../core/HeaderHero'
import brand from '../layout/brand'
import { metadata, metadataItem } from '../layout/styles/metadata'
import DealSnapshot from './DealSnapshot'
import { label, roundedHeroImage, transparentBackgroundContainer, subTitle, blueButton, smallTitle, centeredFlex, blackText, textInput } from '../layout/styleComponents'
import MainHeading from '../core/MainHeading'
import TabsContainer from '../layout/TabsContainer'
import { faUserChart } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVideoFile } from '@fortawesome/pro-regular-svg-icons'
import { Mutation } from 'react-apollo'
import { faBan, faEye, faLongArrowRight } from '@fortawesome/pro-light-svg-icons'
import MarkMessageAsRead from '../../graphql/mutations/MarkMessageAsRead'
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons'
import { blueRibbon, background, bigStone, white } from '../layout/colors'

const styles = theme => {
  return {
    badge: {
      width: '100%',
      '& > span': {
        background: theme.palette.error.main,
        top: '1.25rem',
        right: '1.25rem'
      }
    },
    modal: {
      '& .MuiDialog-paperFullWidth': {
        borderRadius: '.5rem',
        maxWidth: '45rem',
        width: '95%',
        height: 'unset',
        maxHeight: '80%',
      },
      '& .MuiDialogContent-root': {
        padding: '0 1.5rem 2rem',
      }
    },
    longInput: textInput,
    noConversations: {
      ...centeredFlex
    },
    hint: {
      ...label,
      margin: '.5rem 0 0'
    },
    appBarTitle: {
      ...blackText,
      margin: 0,
      fontSize: '1.1rem',
      padding: '0',
      width: '100%',
      textAlign: 'left',
    },
    transparentBackgroundContainer: transparentBackgroundContainer,
    detailsContainer: {
      background: 'white',
      borderRadius: '0 0 .5rem .5rem',
      padding: '2rem 1.5rem'
    },
    updatesContainer: {
      background: 'white',
      borderRadius: '0 0 .5rem .5rem',
      padding: '0'
    },
    dialogButton: {
      ...blueButton,
      margin: '1.5rem auto 0'
    },
    relative: {
      position: 'relative'
    },
    paper: {
      padding: '2rem 1rem',
      marginBottom: '4rem',
      boxShadow: 'none',
      '@media(min-width: 35.5rem)': {
        padding: '2rem'
      },
      '& a': {
        wordBreak: 'break-word'
      },
      '& .MuiTypography-subtitle1, & p > a, & span > a': {
        color: 'black',
        fontSize: '115%',
        fontWeight: 'bold',
        marginTop: '2rem',
        textDecoration: 'none'
      }
    },
    amount: {
      display: 'block'
    },
    button: blueButton,
    submit: {
      display: 'block'
    },
    blackTitleBig: {
      ...subTitle,
      fontSize: '1.175rem !important',
      fontWeight: 400,
      marginTop: '1.5rem'
    },
    icon: {
      marginLeft: '.5rem',
    },
    blackLabel: {
      ...blackText,
      fontWeight: 500,
      fontSize: '.85rem',
      margin: 0
    },
    logo: {
      ...roundedHeroImage,
      width: '6.5rem',
      height: '6.5rem',
      backgroundSize: '95% !important',
      backgroundColor: 'white !important'
    },
    marginBottom: {
      marginBottom: '2rem'
    },
    detailContent: {
      marginBottom: '1rem'
    },
    actions: {
      textAlign: 'right'
    },
    socialLink: {
      color: brand.iconColor,
      marginRight: theme.spacing.unit,
      '&:hover': {
        color: theme.palette.primary.main
      }
    },
    nested: {
      paddingLeft: theme.spacing.unit * 4
    },
    linkList: {
      ...smallTitle,
      fontSize: '.85rem',
      textDecoration: 'none',
      minWidth: 'unset',
      '& span': {
        fontWeight: 400
      }
    },
    link: {
      marginTop: '1.5rem',
      textAlign: 'center',
      '& a': {
        ...smallTitle,
        fontWeight: 500,
        fontSize: '1.25rem',
        textTransform: 'uppercase',
        textDecoration: 'none'
      }
    },
    appBar: {
      backgroundColor: theme.palette.info.light,
      position: 'relative',
      paddingRight: '.5rem !important',
      padding: '.5rem 1.5rem',
    },
    toolbar: {
      background: 'none',
      justifyContent: 'flex-end',
      padding: 0
    },
    '@media (min-width: 960px)': {
      rightColumn: {
        marginLeft: '0.75rem'
      }
    },
    rightColumn: {
      boxShadow: 'none',
      margin: '1rem 0 0'
    },
    paperLast: {
      marginBottom: 0
    },
    paddedPaper: {
      background: 'none',
      boxShadow: 'none',
      margin: '0 auto',
      padding: '2rem 0',
      width: '100%',
      '@media (min-width: 55.5rem)': {
        padding: '2rem 1.5rem'
      },
      '@media (min-width: 81rem)': {
        padding: '2rem 0'
      }
    },
    marginTop: {
      marginTop: '2rem'
    },
    centered: {
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      '&:first-child': {
        marginRight: '1.5rem'
      }
    },
    text: {
      ...blackText,
      fontSize: '1rem',
      margin: 0,
      fontWeight: 400,
      width: '100%',
      textAlign: 'left',
      marginLeft: '.75rem',
      '& p, & span': {
        fontSize: '1rem'
      }
    },
    dropdownOptions: {
      background: 'white',
      borderRadius: '.5rem',
      boxShadow: `0 0 .6rem -.3rem ${bigStone}`,
      padding: '.5rem',
      position: 'absolute',
      top: '100%',
      zIndex: 9,
      listStyle: 'none',
      margin: 0,
      padding: 0,
      width: '100%'
    },
    updatesButton: blueButton,
    option: {
      color: blueRibbon,
      fontSize: '.85rem',
      padding: '.5rem 1rem',
      width: '100% !important',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.info.light,
      }
    },
    actionsFilterBar: {
      borderBottom: `.015rem solid ${background}`,
      padding: '.5rem 1.25rem',
      backgroundColor: background,
      listStyle: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: 0,
      width: '100%',
      '& li:first-child': {
        '@media (min-width: 50rem)': {
          width: '60%'
        }
      },
      '& li:nth-child(2)': {
        '@media (max-width: 50rem)': {
          display: 'none'
        }
      }
    },
    noUpdates: {
      ...centeredFlex,
      paddingBottom: '2rem',
      '& p': {
        textAlign: 'center'
      }
    },
    iconBig: {
      color: theme.palette.info.light,
      fontSize: '6rem',
      fontWeight: 300,
      margin: '3rem auto 1.5rem'
    },
    metadata,
    metadataItem
  }
}

const Deal = (props) => {
  const {
    id,
    classes,
    fullScreen,
    name,
    company,
    notes,
    goal,
    committed,
    launchedOn,
    closedOn,
    number,
    investorCount,
    investorResponse,
    memo,
    webinarUrl,
    createDealDiscussion,
    onCreateMessage,
    dealDiscussions,
    risks,
    keyRisks,
    refetch,
    enqueueSnackbar,
    unreadUpdates,
    history
  } = props
  const [dealDiscussionMessage, setDealDiscussionMessage] = useState('')
  const [conversationModalOpen, setConversationModalOpen] = useState(false)
  const tabs = [
    {
      name: 'Round Details'
    },
    {
      name: 'Conversations'
    }
  ]

  const closeConversationModal = () => setConversationModalOpen(false)
  const openConversationModal = () => setConversationModalOpen(true)

  const [showOptions, setShowOptions] = useState(false)
  const [showFilters, setShowFilters] = useState(false)
  const [currentOption, setCurrentOption] = useState(0)
  const [currentFilter, setCurrentFilter] = useState(0)
  const [oldDealDiscussionsState, setOldDealDiscussionsState] = useState(dealDiscussions || [])
  const [oldUnreadDealUpdatesState, setOldUnreadDealDiscussionsState] = useState(unreadUpdates || [])
  const [unreadDealDiscussionsState, setUnreadDealDiscussionsState] = useState([])
  const [readDealDiscussionsState, setReadCompanyUpdatesState] = useState([])
  const [messageCounterState, setMessageCounterState] = useState({})
  const [dealDiscussionsState, setDealDiscussionsState] = useState(dealDiscussions || [])
  const [noDataText, setNoDataText] = useState('This company has no conversations yet.')

  const discussionExist = (id, list) => {
    const exist = list && list.length > 0 && list.filter(item => item.discussion.id === id)
    if (exist && exist.length > 0) return true
    else return false
  }

  const setUpdates = (updates, unreadUpdates) => {
    if (updates && updates.length > 0) {
      if (unreadUpdates && unreadUpdates.length > 0 && unreadDealDiscussionsState.length === 0) {
        let unreadMessagesArray = []
        let unreadMessagesCountObject = {}
        let readConversations = []
        unreadUpdates.map(unreadUpdate => {
          const discussionAdded = discussionExist(unreadUpdate.discussionId, unreadMessagesArray)
          if (discussionAdded) unreadMessagesCountObject[unreadUpdate.discussionId] = unreadMessagesCountObject[unreadUpdate.discussionId] + 1
          else {
            const discussion = updates.filter(disc => disc.discussion.id === unreadUpdate.discussionId)
            if (discussion.length > 0) unreadMessagesArray.push(discussion[0])
            unreadMessagesCountObject[unreadUpdate.discussionId] = 1
          }
        })
        updates.map(update => {
          const haveUnreadMessages = unreadMessagesArray.filter(conversationUnread => conversationUnread.id === update.id)
          const discussionAdded = discussionExist(update.discussion.id, readConversations)
          if (haveUnreadMessages.length === 0 && !discussionAdded) readConversations.push(update)
        })
        setReadCompanyUpdatesState(readConversations)
        setUnreadDealDiscussionsState(unreadMessagesArray)
        setMessageCounterState(unreadMessagesCountObject)
      } else if (unreadUpdates && unreadUpdates.length === 0 && readDealDiscussionsState.length === 0) {
        setReadCompanyUpdatesState(oldDealDiscussionsState)
      }
    }
  }

  useEffect(() => {
    if (props.dealDiscussions.length !== oldDealDiscussionsState.length) {
      setUnreadDealDiscussionsState([])
      setReadCompanyUpdatesState([])
      setMessageCounterState({})
      setOldDealDiscussionsState(props.dealDiscussions || [])
      setOldUnreadDealDiscussionsState(props.unreadUpdates || [])
    }
    setUpdates(props.dealDiscussions, props.unreadUpdates)
  }, [props.dealDiscussions])

  useEffect(() => {
    if (oldUnreadDealUpdatesState.length !== props.unreadUpdates.length) {
      setUnreadDealDiscussionsState([])
      setReadCompanyUpdatesState([])
      setMessageCounterState({})
      setOldDealDiscussionsState(props.dealDiscussions || [])
      setOldUnreadDealDiscussionsState(props.unreadUpdates || [])
    }
    setUpdates(props.dealDiscussions, props.unreadUpdates)
  }, [props.unreadUpdates])

  const options = [{name: 'All', value: 'all'}, {name: 'Read', value: 'read'}, {name: 'Unread', value: 'unread'}]
  const filters = [{name: 'Last Activity', value: 'desc'}, {name: 'Date of creation', value: 'asc'}]
  const dropdownOptions = []
  const dropdownFilters = []

  const changeFilter = (filter, index) => {
    if (currentFilter !== index) {
      setDealDiscussionsState(dealDiscussionsState.reverse())
    }
    setCurrentFilter(index)
  }

  const markAllAsRead = (MarkMessageAsRead) => {
    (oldUnreadDealUpdatesState || []).map((message, index) => {
      if (discussionExist(message.discussionId, oldDealDiscussionsStateç)) MarkMessageAsRead({ variables: { messageId: message.id } })
      if (index + 1 === oldUnreadDealUpdatesState.length) {
        setTimeout(() => {
          refetch()
        }, 2000)
      }
    })
  }

  const selectViewType = (index, option) => {
    setCurrentOption(index)
    switch (option.value) {
      case 'read':
        setNoDataText('This deal has no conversations without unread messages.')
        setDealDiscussionsState(currentFilter === 0 ? readDealDiscussionsState : readDealDiscussionsState.reverse())
        break
      case 'unread':
        setNoDataText('This deal has no unread messages.')
        setDealDiscussionsState(currentFilter === 0 ? unreadDealDiscussionsState : unreadDealDiscussionsState.reverse())
        break
      case 'all':
        setNoDataText('This deal has no conversations yet.')
        setDealDiscussionsState(currentFilter === 0 ? oldDealDiscussionsState : oldDealDiscussionsState.reverse())
        break
    }
  }

  options.map((option, index) => {
    dropdownOptions.push(<li key={option.value} className={classes.option} onClick={() => selectViewType(index, option)}>{option.name}</li>)
  })

  filters.map((filter, index) => {
    dropdownFilters.push(<li key={filter.value} className={classes.option} onClick={() => changeFilter(filter.value, index)}>{filter.name}</li>)
  })

  let logo = <figure alt="avatar" style={{background: `url(${company && company.logoUrl || '/company.png'}) center center no-repeat`}} className={classes.logo} />

  return <>
    {!company && <Skeleton />}
    <HeaderHero
        backgroundUrl={company && company.productImages && company.productImages.length > 0 && company.productImages[0]}
    >
      <Grid container alignItems="flex-start" className={classes.relative}>
        <Grid item xs={12} className={classes.centered}>
          {logo}
          <MainHeading
            primary={company && company.name || <Skeleton />}
            secondary={company && company.tagline || <Skeleton />}
          />
        </Grid>
      </Grid>
      {company && <DealSnapshot
        dealId={id}
        number={number}
        goal={goal}
        committed={committed}
        investorCount={investorCount}
        investorResponse={investorResponse}
        company={company}
        history={history}
        launchedOn={company.deal.launchedOn}
        closedOn={company.deal.closedOn}
      />}
    </HeaderHero>
    <Grid container className={classes.transparentBackgroundContainer}>
      <TabsContainer tabs={tabs} icon={faUserChart} title="Deal Memo"
        webinar={webinarUrl && <a target={'_blank'} href={webinarUrl}>Webinar link</a>}
      >
        <Grid item xs={12} className={classes.detailsContainer}>
          {webinarUrl && <Typography className={classes.link}></Typography>}
          <TitledPaper noLogo>
            {memo && <Markdown blackTitles>{memo}</Markdown>}
          </TitledPaper>
          <TitledPaper noLogo paddedPaperClassName={classes.paper}>
            <Typography variant="body1" className={classes.blackTitleBig}>Note from Lead Investor</Typography>
            {notes && <Markdown blackTitles>{notes}</Markdown>}
          </TitledPaper>
          <TitledPaper noLogo paddedPaperClassName={`${classes.paper}`}>
            <Typography variant="body1" className={classes.blackTitleBig}>Investor Risks</Typography>
            {risks && <Markdown blackTitles>{risks}</Markdown>}
          </TitledPaper>
          <TitledPaper noLogo paddedPaperClassName={`${classes.paper} ${classes.paperLast}`}>
            <Typography variant="body1" className={classes.blackTitleBig}>Founder Risks</Typography>
            {keyRisks && <Markdown blackTitles>{keyRisks}</Markdown>}
          </TitledPaper>
        </Grid>
        <Grid item xs={12}>
          <TitledPaper noLogo className={classes.updatesContainer}
            actions={<Button onClick={openConversationModal} className={`${classes.button}`}>
              Start a conversation
              <FontAwesomeIcon icon={faLongArrowRight}/>
            </Button>}
          >
            <ul className={classes.actionsFilterBar}>
              <li>
                <Mutation mutation={MarkMessageAsRead}>
                  {markNotificationAsRead => {
                    return <Button
                      className={classes.linkList}
                      component={BrowserLink}
                      size="small"
                      color="primary"
                      onClick={() => markAllAsRead(markNotificationAsRead)}
                    >
                      Mark all as read
                    </Button>
                  }}
              </Mutation>
              </li>
              <li style={{display: 'flex'}}>
                <div className={classes.centered}>
                  <label className={classes.blackLabel}>Showing:</label>
                  <Button
                    className={classes.linkList}
                    component={BrowserLink}
                    size="small"
                    color="primary"
                    onClick={() => setShowOptions(!showOptions)}
                  >
                    {options[currentOption].name}
                    <FontAwesomeIcon className={classes.icon} icon={showOptions ? faCaretUp : faCaretDown} />
                  </Button>
                  {showOptions && <ul className={classes.dropdownOptions}>
                    {dropdownOptions}
                  </ul>}
                </div>
                <div className={classes.centered}>
                  <label className={classes.blackLabel}>Sort by:</label>
                  <Button
                    className={classes.linkList}
                    component={BrowserLink}
                    size="small"
                    color="primary"
                    onClick={() => setShowFilters(!showFilters)}
                  >
                    {filters[currentFilter].name}
                    <FontAwesomeIcon className={classes.icon} icon={showFilters ? faCaretUp : faCaretDown} />
                  </Button>
                  {showFilters && <ul className={classes.dropdownOptions}>
                    {dropdownFilters}
                  </ul>}
                </div>
              </li>
            </ul>
            {dealDiscussionsState && dealDiscussionsState.length == 0 &&
            <section className={classes.noConversations}>
              <FontAwesomeIcon icon={faBan} className={classes.iconBig} />
              <Typography className={classes.text}>
                {noDataText}
              </Typography>
            </section>
            }

            {dealDiscussionsState &&
              dealDiscussionsState.length > 0 &&
              dealDiscussionsState.map((dealDiscussion, index) => {
                const { discussion } = dealDiscussion
                const itHaveUnreadMessages = messageCounterState[dealDiscussion.discussion.id] || null

                  if (itHaveUnreadMessages) return (<Badge className={classes.badge} badgeContent={itHaveUnreadMessages} color="primary">
                    <Message
                      key={discussion.id}
                      principal={index === 0}
                      message={discussion.messages !== null && discussion.messages.length > 0 ? discussion.messages[0] : {}}
                      onClick={() =>
                        history.push(
                          `/companies/${company.slug}/deals/${id}/deal_discussions/${dealDiscussion.id}`
                        )
                      }
                    />
                  </Badge>)
                  else return (
                    <Message
                      key={discussion.id}
                      principal={index === 0}
                      message={discussion.messages !== null && discussion.messages.length > 0 ? discussion.messages[0] : {}}
                      onClick={() =>
                        history.push(
                          `/companies/${company.slug}/deals/${id}/deal_discussions/${dealDiscussion.id}`
                        )
                      }
                    />
                  )
              })}
          </TitledPaper>
        </Grid>
      </TabsContainer>
    </Grid>
    <Dialog
      fullWidth
      fullScreen={fullScreen}
      maxWidth="md"
      scroll="paper"
      open={conversationModalOpen}
      onClose={closeConversationModal}
      onEscapeKeyDown={closeConversationModal}
      className={classes.modal}
    >
      <AppBar
        color="default"
        elevation={0}
        className={classes.appBar}
      >
        <Toolbar
          className={classes.toolbar}
        >
          <Typography variant="h6" color="inherit" className={classes.appBarTitle}>
            Start a conversation
          </Typography>
          <IconButton
            color="inherit"
            onClick={closeConversationModal}
            aria-label="Close"
            className={classes.appBarIcon}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent className={classes.content}>
        <MaxLengthTextField
          maxLength={250}
          label="Your message"
          value={setDealDiscussionMessage}
          onChange={evt => setDealDiscussionMessage(evt.target.value)}
          classes={classes}
        />
        <Button
          onClick={() => {
            createDealDiscussion(dealDiscussionMessage)
            closeConversationModal()
            setDealDiscussionMessage('')
            enqueueSnackbar('You have started a conversation!', { variant: 'info' })
          }}
          className={classes.dialogButton}
          variant="contained"
        >
          Start Conversation
          <FontAwesomeIcon icon={faLongArrowRight} />
        </Button>
      </DialogContent>
    </Dialog>
  </>
}

export default withSnackbar(withStyles(styles)(withMobileDialog()(Deal)))
