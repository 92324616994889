import { Typography } from '@material-ui/core'
import React from 'react'
import { Query } from 'react-apollo'
import GetPostCompanyFormText from '../../graphql/queries/getPostCompanyFormText'
import GetPostCompanyStatusChangeFormText from '../../graphql/queries/getPostCompanyStatusChangeFormText'
import GetPostInvestorFormText from '../../graphql/queries/getPostInvestorFormText'
import GetPostWaitListFormText from '../../graphql/queries/getPostWaitListFormText'
import GetPostDealFormText from '../../graphql/queries/getPostDealFormText'
import TitledPaper from '../core/TitledPaper'
import { withStyles } from '@material-ui/core/styles'
import { blackText } from '../layout/styleComponents'

const styles = theme => ({
  paper: {
    width: '100%'
  },
  text: {
    ...blackText,
    margin: '2rem 0 3rem',
    width: '100%'
  }
})

const PostCompanyFormText = props => {
  const {classes} = props
  return (
    <Query query={GetPostCompanyFormText}>
      {({ data, loading }) => {
        return (
          !loading && (
            <>
              <Typography variant="h5" className={classes.text}>
              {data.postCompanyText.postCompanyText}
              </Typography>
            </>
          )
        )
      }}
    </Query>
  )
}

const PostInvestorFormText = props => {
  const {classes} = props
  return (
    <Query query={GetPostInvestorFormText}>
      {({ data, loading }) => {
        return (
          !loading && (
            <>
              <Typography variant="h5" className={classes.text}>
                {data.postInvestorText.postInvestorText}
              </Typography>
            </>
          )
        )
      }}
    </Query>
  )
}

const PostWaitListFormText = props => {
  const {classes} = props
  return (
    <Query query={GetPostWaitListFormText}>
      {({ data, loading }) => {
        return (
          !loading && (
            <>
              <Typography variant="h5" className={classes.text}>
                {data.postWaitListText.postWaitListText}
              </Typography>
            </>
          )
        )
      }}
    </Query>
  )
}

const PostDealFormText = props => {
  const {classes} = props
  return (
    <Query query={GetPostDealFormText}>
      {({ data, loading }) => {
        return (
          !loading && (
            <>
              <Typography variant="h5" className={classes.text}>
                {data.postDealText.postDealText}
              </Typography>
            </>
          )
        )
      }}
    </Query>
  )
}

const PostCompanyStatusChangeFormText = props => {
  const {classes} = props
  return (
    <Query query={GetPostCompanyStatusChangeFormText}>
      {({ data, loading }) => {
        return (
          !loading && (
            <>
              <Typography variant="h5" className={classes.text}>
                {data.postCompanyStatusChangeText.postCompanyStatusChangeText}
              </Typography>
            </>
          )
        )
      }}
    </Query>
  )
}

const FormCompleted = ({ formKey, postConfirmation, classes }) => {
  let renderContent
  if (postConfirmation && postConfirmation !== 'user') {
    location.replace(`../forms/${postConfirmation}`)
    return (
      <>
        <Typography className={classes.text}>
          Redirecting you to the form you have applied for...
        </Typography>
      </>
    )
  } else {
    if (formKey === 'user') {
      location.replace(`../me`)
      return (
        <>
          <Typography className={classes.text}>
            Redirecting you to the form you have applied for...
          </Typography>
        </>
      )
    } else if (formKey === 'investor') {
      renderContent = <PostInvestorFormText classes={classes}/>
    } else if (formKey === 'founder') {
      renderContent = <PostCompanyFormText classes={classes}/>
    } else if (formKey === 'wait_list') {
      renderContent = <PostWaitListFormText classes={classes}/>
    } else if (formKey === 'deal') {
      renderContent = <PostDealFormText classes={classes}/>
    } else if (formKey === 'company_status_change') {
      renderContent = <PostCompanyStatusChangeFormText />
    }
  }

  return <TitledPaper white form smallTitle paddedPaperClassName={classes.paper} title="Congratulations">{renderContent}</TitledPaper>
}

export default withStyles(styles)(FormCompleted)
