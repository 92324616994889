import gql from 'graphql-tag'

const rejectMeeting = gql`
  mutation RejectMeeting($meetingRequestId: ID!) {
    rejectMeeting(meetingRequestId: $meetingRequestId) {
      id
    }
  }
`

export default rejectMeeting
