import { withStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  blackText,
  label
} from './styleComponents'
import {
  gullGray,
  background,
  bigStone
} from './colors'
import { faFileVideo } from '@fortawesome/pro-solid-svg-icons'

const styles = theme => ({
  container: {
    width: '100%',
  },
  title: {
    ...blackText,
    fontSize: '18px',
    fontWeight: 'normal',
    margin: 0,
    '& a': {
      color: theme.palette.secondary.main,
      textDecoration: 'none !important',
      textTransform: 'none'
    }
  },
  header: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    borderRadius: '.5rem .5rem 0 0',
    background: 'white',
    padding: '1rem 0 0',
    width: '100%',
    '& h2': {
      fontSize: '1rem',
      padding: '0 1.5rem',
      margin: 0
    }
  },
  icon: {
    marginRight: '.75rem'
  },
  tabs: {
    alignItems: 'center',
    display: 'flex',
    margin: '1rem 0 0',
    padding: '0 1.5rem',
    position: 'relative',
    overflowX: 'auto',
    width: '100%',
    '&::before': {
      content: '""',
      background: background,
      width: '100%',
      position: 'absolute',
      bottom: 0,
      left: 0,
      height: '.15rem',
      opacity: '.75',
      right: 0,
      zIndex: 0,
    }
  },
  tab: {
    ...label,
    color: gullGray,
    fontSize: '12px',
    fontWeight: 'normal',
    listStyle: 'none',
    marginRight: '1rem',
    paddingBottom: '.75rem',
    position: 'relative',
    transition: 'all .25s',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    zIndex: 9,
    '&:hover': {
      color: bigStone
    }
  },
  active: {
    color: bigStone,
    borderBottom: `1px solid ${bigStone}`
  }
})

const TabsContainer = ({ classes, children, tabs, icon, webinar, title }) => {
  const [currentTab, setCurrentTab] = useState(0)
  return <section className={classes.container}>
    <header className={classes.header}>
      <h2>
        <FontAwesomeIcon icon={icon} className={classes.icon}/>
        <span className={classes.title}>{title}</span>
      </h2>
      {webinar && <h2>
        <FontAwesomeIcon icon={faFileVideo} className={classes.icon}/>
        <span className={`${classes.title} ${classes.webinar}`}>{webinar}</span>
      </h2>}
      <ul className={classes.tabs}>
        {tabs && tabs.map((tab, index) => {
          if (tab.number === undefined || tab.number === null || tab.number > 0) return <li key={tab.name} className={`${classes.tab} ${currentTab === index && classes.active}`} onClick={() => setCurrentTab(index)}>{tab.name} {tab.number ? `(${tab.number})` : ''}</li>
          else return null
        })}
      </ul>
    </header>
    {children && children.length > 0 ? children[currentTab] : children}
  </section>
}

export default withStyles(styles)(TabsContainer)
