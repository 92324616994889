import { withStyles } from '@material-ui/core/styles'
import React from 'react'

const styles = theme => ({
  sectionWithBefore: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto',
    position: 'relative',
    width: '100%'
  },
  startAlign: {
    alignItems: 'flex-start'
  },
  innerSection: {
    display: 'flex',
    flexWrap: 'wrap',
    height: '100%',
    maxWidth: '100%',
    alignContent: 'flex-end',
    width: '100%',
    '@media (max-width: 70rem)': {
      marginLeft: '0',
    },
  }
})

const Title = props => {
  const { children, classes } = props
  const isHome = window.location.pathname === '/'
  const itShouldBeStartAligned = window.location.pathname.includes('conversation') || window.location.pathname.includes('notification') || props.itShouldBeStartAligned
  return  <section className={`${classes.sectionWithBefore} ${itShouldBeStartAligned && classes.startAlign}`}>
    <div className={`${classes.innerSection} ${isHome && classes.innerSectionHome}`}>
      {children}
    </div>
  </section>
}

export default withStyles(styles)(Title)