import { Avatar, Button, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import { Mutation } from 'react-apollo'
import Skeleton from 'react-loading-skeleton'
import AcceptMeeting from '../../graphql/mutations/AcceptMeeting'
import RejectMeeting from '../../graphql/mutations/RejectMeeting'
import { FullDiscussion } from '../core/Discussion'
import TitledPaper from '../core/TitledPaper'

const styles = theme => ({
  paper: {
    background: 'none',
    boxShadow: 'none',
    padding: '2rem'
  },
  paperTwo: {
    marginBottom: '4rem'
  },
  button: {
    marginRight: '1rem'
  },
  actions: {
    marginTop: '1rem'
  },
  notification: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-start'
  },
  avatar: {
    height: '5rem',
    marginRight: '.25rem',
    width: '5rem'
  },
  user: {
    color: 'black !important',
    fontSize: '100%',
    fontWeight: 'bold',
    margin: '.25rem'
  },
  title: {
    color: 'black !important',
    fontFamily: 'Poppins',
    fontSize: '120%',
    width: '100%'
  },
  status: {
    color: 'black !important',
    fontSize: '100%',
    margin: '.25rem',
    '& span': {
      fontFamily: 'Poppins',
      fontWeight: 'bold',
      marginLeft: '.25rem'
    }
  },
  body: {
    color: 'black',
    fontSize: '100%',
    marginTop: '1rem',
    width: '100%'
  }
})

const CompanyUpdateDiscussionDetail = ({ companyLogoUrl, companyName, title, body, refetch, classes }) => <section className={classes && classes.notification}>
  <Avatar alt={companyName} src={companyLogoUrl} />
  <Typography variant="h4">{companyName || <Skeleton />}</Typography>
  <Typography variant="h4">{title || <Skeleton />}</Typography>
  <Typography>
    {body || <Skeleton />}
  </Typography>
</section>

const UserUpdateDiscussionDetail = ({ userAvatarUrl, userName, title, body, refetch, classes }) => <section className={classes && classes.notification}>
  <Avatar alt={userName} src={userAvatarUrl} />
  <Typography variant="h4">{userName || <Skeleton />}</Typography>
  <Typography variant="h4">{title || <Skeleton />}</Typography>
  <Typography>
    {body || <Skeleton />}
  </Typography>
</section>

const MeetingRequestDetail = ({ meetingRequestId, avatarUrl, userName, status, title, body, refetch, classes }) => <section className={classes && classes.notification}>
  <Avatar alt={userName} src={avatarUrl} className={classes && classes.avatar} />
  <article>
    <Typography variant="h4" className={classes && classes.user}>{userName || <Skeleton />}</Typography>
    <Typography variant="h5" className={classes && classes.status}>{status && 'Status:'}<span>{status || <Skeleton />}</span></Typography>
    {status === 'PENDING' && <div className={classes && classes.actions}>
      <Mutation
        onCompleted={data => {
          if (data) {
            refetch()
          }
        }}
        mutation={AcceptMeeting}
      >
        {(acceptMeeting) => <Button
          variant="contained"
          onClick={() => acceptMeeting({ variables: { meetingRequestId } })}
          className={classes.button}
        >Accept</Button>}
      </Mutation>
      <Mutation
        onCompleted={data => {
          if (data) {
            refetch()
          }
        }}
        mutation={RejectMeeting}
      >
        {(rejectMeeting) => <Button variant="contained" onClick={() => rejectMeeting({ variables: { meetingRequestId } })}>Reject</Button>}
      </Mutation>
    </div>}
  </article>
  <Typography className={classes && classes.body}>
    {body || <Skeleton />}
  </Typography>
</section>

const Notification = props => {
  const { title, body, notifiable, classes, refetch } = props
  return (
    <>
      <TitledPaper white smallTitle paddedPaperClassName={classes.paper} title={title}>
        {notifiable && notifiable.__typename === 'MeetingRequest' &&
          <MeetingRequestDetail
            classes={classes}
            userName={notifiable.user.name}
            avatarUrl={notifiable.user.avatarUrl}
            status={notifiable.status}
            refetch={refetch}
            title={title}
            body={body}
            meetingRequestId={notifiable.id}
          />
        }
        {notifiable && notifiable.__typename === 'CompanyUpdateDiscussion' &&
          <CompanyUpdateDiscussionDetail
            classes={classes}
            companyName={notifiable.company.name}
            companyLogoUrl={notifiable.company.logoUrl}
            refetch={refetch}
            title={title}
            body={body}
          />
        }
        {notifiable && notifiable.__typename === 'UserUpdateDiscussion' &&
          <UserUpdateDiscussionDetail
            classes={classes}
            userName={notifiable.user.name}
            userAvatarUrl={notifiable.user.avatarUrl}
            refetch={refetch}
            title={title}
            body={body}
          />
        }
        {!notifiable && <>
          <MeetingRequestDetail />
        </>}
      </TitledPaper>
      {notifiable && notifiable.conversation && <TitledPaper form paddedPaperClassName={classes.paperTwo} white smallTitle title="Discussion"><FullDiscussion executeReading {...notifiable.conversation.discussion} onCreateMessage={refetch} /></TitledPaper>}
      {notifiable && notifiable.discussion && <TitledPaper form paddedPaperClassName={classes.paperTwo} white smallTitle title="Discussion"><FullDiscussion executeReading {...notifiable.discussion} onCreateMessage={refetch} /></TitledPaper>}
    </>
  )
}

export default withStyles(styles)(Notification)
