import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import { Link as BrowserLink } from 'react-router-dom'
import thesyndicateLogoBlack from '../../../../../assets/images/thesyndicate_logo_black.png'
import { mediumTitle, subTitle, blueButton } from '../layout/styleComponents'
const styles = theme => ({
  actionWrapper: {
    marginBottom: '1rem',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& a': {
      display: 'block',
      width: '100%',
      padding: '1rem'
    }
  },
  actions: {
    background: 'white',
    borderRadius: '0 0 .5rem .5rem',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: '1.5rem 0',
    '& a, & button': {
      margin: '0 .25rem'
    }
  },
  button: {
    display: 'flex',
    paddingLeft: '1.25rem',
    justifyContent: 'flex-end',
    '& > div, & span:nth-child(1)': {
      color: '#2D49B6',
      fontFamily: 'Poppins',
      fontSize: '110%',
      fontWeight: 'bold',
      position: 'relative',
      textAlign: 'center',
      textDecoration: 'none',
      textTransform: 'uppercase',
      width: '100%',
      '& a': {
        display: 'block',
        width: '100%',
        padding: '1rem'
      },
      '& button:hover': {
        backgroundColor: '#eeeeee'
      }
    }
  },
  paddingUserProfile: {
    paddingLeft: '.85rem'
  },
  textCenter: {
    textAlign: 'center'
  },
  mainTitle: mediumTitle,
  titleSmall: subTitle,
  marginTop: {
    marginTop: '4rem'
  },
  logoContainer: {
    margin: '0 auto',
    padding: '7rem 0 3rem',
    '@media (max-height: 38rem)': {
      padding: '3rem 0',
    },
    '@media (min-width: 45rem)': {
      padding: '5rem 0 7rem'
    }
  },
  logo: {
    display: 'block',
    margin: 'auto',
    maxWidth: '15.25rem',
    padding: 0,
    textAlign: 'center',
  },
  smallMargin: {
    '@media (min-width: 45rem)': {
      padding: '5rem 0 0'
    }
  }
})

const actionWrapper = props => {
  const { classes, children, actions, className, caption, updates, title, userProfile, form, smallTitle, noLogo, smallMargin } = props
  let actionsTemplate = <section className={classes.actions}>
    {actions}
  </section>
  return (
    <section className={className}>
      {!noLogo && <figure className={classes.logoContainer}>
        <BrowserLink
          to="/"
        >
          <img src={thesyndicateLogoBlack} className={`${classes.logo} ${smallMargin && classes.smallMargin}`} />
        </BrowserLink>
      </figure>}
      {title && title !== '' && <h2 className={classes.mainTitle}>{title}</h2>}
      {children}
      {actions && actionsTemplate}
    </section>
  )
}

export default withStyles(styles)(actionWrapper)
