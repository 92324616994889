import { withSnackbar } from 'notistack'
import React, {useEffect} from 'react'
import { Mutation, Query } from 'react-apollo'
import SendInvitation from '../../graphql/mutations/SendInvitation'
import InvitePageContent from '../../graphql/queries/getInvitePageContent'
import InviteFriend from '../invite/InviteFriend'
import AppLayout from '../layout/AppLayout'
import CenterColumn from '../layout/CenterColumn'
import { Redirect } from 'react-router'
import Title from '../layout/Title'
import { Typography } from '@material-ui/core'

function InviteRoute ({ match: { params: { slug } }, history, enqueueSnackbar }) {
  const titlePage = <Title>
    Invite
  </Title>

  let heroContent, title, invitationSentContent
  useEffect(() => {
    document.title = "The Syndicate - Invite"
  }, []);
  return <AppLayout title={titlePage}>
    <CenterColumn>
      <Query query={InvitePageContent}>
        {({ data, loading, error }) => {
          if (error) {
            if (error.message && error.message.includes('unauthorized')) {
              enqueueSnackbar("You don't have access to see this.", { variant: 'error' })
              return <Redirect
                to={`/login`}
              />
            } else {
              return <Typography>Error...</Typography>
            }
          }

          if (!loading) {
            heroContent = data.invitePageContent.heroContent
            title = data.invitePageContent.title
            invitationSentContent = data.invitePageContent.invitationSentContent
          }
          return <Mutation
            onCompleted={data => {
              if (data) {
                enqueueSnackbar(invitationSentContent, { variant: 'success' })
              }
            }}
            mutation={SendInvitation}
          >
            {sendInvitation => {
              return !loading && <InviteFriend title={title} heroContent={heroContent} sendInvitation={sendInvitation} />
            }}
          </Mutation>
        }}
      </Query>
    </CenterColumn>
  </AppLayout>
}

export default withSnackbar(InviteRoute)
