import gql from 'graphql-tag'

const getConversation = gql`
  query Conversation($id: ID!) {
    conversation(id: $id) {
      id
      title
      participants {
        id
        avatarUrl
        name
      }
      discussion {
        id
        messages {
          id
          body
          createdAt
          user {
            id
            avatarUrl
            name
          }
        }
      }
    }
  }
`

export default getConversation
