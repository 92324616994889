import gql from 'graphql-tag'

const getVisits = gql`
  query GetVisits {
    visits{
      id
      ip
      userAgent
      browser
      os
      startedAt
    }
  }
`

export default getVisits
