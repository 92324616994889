import React, {useEffect} from 'react'
import { Query } from 'react-apollo'
import { Switch } from 'react-router'
import { Route } from 'react-router-dom'
import { REFETCH } from '../../graphql/networkStatuses'
import getCompanyUpdates from '../../graphql/queries/getCompanyUpdates'
import CompanyUpdates from '../company/CompanyUpdates'
import AppLayout from '../layout/AppLayout'
import CenterColumn from '../layout/CenterColumn'
import Title from '../layout/Title'
import CompanyUpdateModalRoute from './CompanyUpdateModalRoute'

function CompanyUpdatesRoute ({ match, history }) {
  const { params: { slug } } = match
  const goBack = history => () => {
    if (history.location.state && history.location.state.from) {
      history.goBack()
    } else {
      history.push('../all')
    }
  }
  useEffect(() => {
    document.title = "The Syndicate - Company Updates"
  }, []);
  return <Query query={getCompanyUpdates} variables={{ slug: slug }} fetchPolicy="cache-and-network">
    {({ loading, error, data, refetch, networkStatus }) => {
      if (loading) return null
      if (error) {
        return <h2>Error...</h2>
      }

      const { company } = data

      const title = <Title>
        {company.name} Updates
      </Title>

      return <AppLayout title={title}>
        <CenterColumn>
          <CompanyUpdates refetch={refetch} history={history} updates={company.companyUpdates} companySlug={slug} can={company.can} companyName={company.name} />
          <Switch>
            <Route path={`${match.url}/:id`} render={(props) => <CompanyUpdateModalRoute {...props} companySlug={slug} onClose={goBack(history)} />} />
          </Switch>
        </CenterColumn>
      </AppLayout>
    }}
  </Query>
}

export default CompanyUpdatesRoute
