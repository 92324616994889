import { FormControl, Input, InputLabel, TextField, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React, { useReducer } from 'react'
import { initialState, reducer } from '../../state/InviteReducer'
import TitledPaper from '../core/TitledPaper'
import {blueButton, textInput, mediumTitle} from '../layout/styleComponents'
import Form from '../form/ListForm'

const styles = theme => ({
  apply: {
    padding: theme.spacing.unit * 3,
    textAlign: 'center'
  },
  textInput: textInput,
  paddedPaper: {
    boxShadow: 'none',
    width: '100%',
    position: 'relative'
  },
  mediumTitle: {
    ...mediumTitle,
    margin: '4rem auto 7rem'
  },
  button: {
    ...blueButton,
    margin: '1.5rem auto'
  }
})

const handleSendInvitation = ({ sendInvitation, state, dispatch }) => {
  sendInvitation({
    variables: { ...state }
  })
  dispatch({ type: 'clear' })
}

const InviteFriend = props => {
  const { classes, sendInvitation, heroContent, title } = props
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <TitledPaper noLogo form className={classes.paddedPaper}>
      <Form
        submitBtnText={'Send Invitation'}
        onSubmit={() => handleSendInvitation({ sendInvitation, state, dispatch })}
        title={<Typography className={classes.mediumTitle}>Invite a Friend</Typography>}
        classes={classes}
      >
        <FormControl className={classes.textInput} margin="normal" required fullWidth>
          <InputLabel htmlFor="email">Email Address</InputLabel>
          <Input
            id="email"
            value={state.email}
            onChange={event =>
              dispatch({ type: 'setEmail', value: event.target.value })
            }
            name="email"
            autoComplete="email"
            autoFocus
          />
        </FormControl>
        <FormControl className={classes.textInput} margin="normal" required fullWidth>
          <TextField
            label="Add a message"
            name="text"
            value={state.message}
            onChange={event =>
              dispatch({ type: 'setMessage', value: event.target.value })
            }
            multiline
            rows="4"
            className={classes.textField}
            margin="normal"
          />
        </FormControl>
      </Form>
    </TitledPaper>
  )
}

export default withStyles(styles, { withTheme: true })(InviteFriend)
