import { FormControl, Input, InputLabel, MenuItem, Select } from '@material-ui/core'
import React from 'react'

const MultiSelect = ({ label, fieldName, value, choices, onChange, classes }) => {
  const inputId = `select-multiple-${fieldName}`
  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  }

  const getClassName = name => {
    if (value.includes(name)) {
      return classes.selectedItem
    } else {
      return classes.unselectedItem
    }
  }

  return <FormControl style={{marginTop: '24px'}}>
    <InputLabel htmlFor={inputId}>{label}</InputLabel>
    <Select
      multiple
      value={value}
      onChange={onChange}
      input={<Input id={inputId} />}
      MenuProps={MenuProps}
    >
      {choices.map(name => (
        <MenuItem key={name} value={name} className={getClassName(name)}>
          {name}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
}

export default MultiSelect
