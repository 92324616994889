import { AppBar, Button, Badge, DialogContent, Grid, IconButton, Slide, Toolbar, Typography, withMobileDialog } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import React, { useState } from 'react'
import { Link as BrowserLink, useHistory } from 'react-router-dom'
import CreateCompanyUpdateForm from '../core/CreateCompanyUpdateForm'
import Message from '../core/Message'
import TitledPaper from '../core/TitledPaper'
import { blueButton, smallTitle, subTitle, blackText, centeredFlex, transparentBackgroundContainer } from '../layout/styleComponents'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faCaretDown, faCaretUp, faComment } from '@fortawesome/pro-solid-svg-icons'
import { blueRibbon, background, bigStone, white } from '../layout/colors'
import HeaderHero from '../core/HeaderHero'
import MainHeading from '../core/MainHeading'
import TabsContainer from '../layout/TabsContainer'
import { Mutation } from 'react-apollo'
import MarkMessageAsRead from '../../graphql/mutations/MarkMessageAsRead'

const truncateWithEllipses = (text, max) =>
  text.substr(0, max - 1) + (text.length > max ? '...' : '')

const styles = theme => ({
  transparentBackgroundContainer: transparentBackgroundContainer,
  centered: {
    ...centeredFlex,
    position: 'relative'
  },
  backgroundWhite: {
    backgroundColor: white,
    paddingBottom: '1.25rem'
  },
  badge: {
    width: '100%',
    '& > span': {
      background: theme.palette.error.main,
      top: '1.25rem',
      right: '1.25rem'
    }
  },
  heroCentered: {
    minHeight: '10rem',
  },
  icon: {
    marginLeft: '.5rem',
  },
  messagetitle: {
    ...blackText,
    fontSize: '1rem',
    margin: 0,
    fontWeight: 400,
    width: '100%'
  },
  blackLabel: {
    ...blackText,
    fontWeight: 500,
    fontSize: '.85rem',
    margin: 0
  },
  iconBig: {
    color: theme.palette.info.light,
    fontSize: '6rem',
    fontWeight: 300,
    margin: '3rem auto 1.5rem'
  },
  actionsFilterBar: {
    borderBottom: `.015rem solid ${background}`,
    padding: '.5rem 1.25rem',
    backgroundColor: background,
    listStyle: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 0,
    width: '100%',
    '& li:first-child': {
      '@media (min-width: 50rem)': {
        width: '60%'
      }
    },
    '& li:nth-child(2)': {
      '@media (max-width: 50rem)': {
        display: 'none'
      }
    }
  },
  link: {
    ...smallTitle,
    fontSize: '.85rem',
    textDecoration: 'none',
    minWidth: 'unset',
    '& span': {
      fontWeight: 400
    }
  },
  option: {
    color: blueRibbon,
    fontSize: '.85rem',
    padding: '.5rem 1rem',
    width: '100% !important',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.info.light,
    }
  },
  paper: {
    marginTop: theme.spacing.unit * 3
  },
  appBarTitle: {
    flex: 1
  },
  updatesContainer: {
    cursor: 'pointer',
    margin: 0,
  },
  updatesPaddedPaper: {
    background: 'none',
    borderBottom: '.2rem solid black',
    boxShadow: 'none',
    margin: '0 auto 4rem',
    padding: '2rem 1rem',
    width: '100%',
    '@media (min-width: 35.5rem)': {
      padding: '2rem'
    }
  },
  appBarIcon: {
    marginRight: '-1rem'
  },
  appBar: {
    backgroundColor: theme.palette.info.light,
    position: 'relative',
    paddingRight: '0 !important',
    padding: '.5rem 1.5rem'
  },
  dropdownOptions: {
    background: 'white',
    borderRadius: '.5rem',
    boxShadow: `0 0 .6rem -.3rem ${bigStone}`,
    padding: '.5rem',
    position: 'absolute',
    top: '100%',
    zIndex: 9,
    listStyle: 'none',
    margin: 0,
    padding: 0,
    width: '100%'
  },
  updatesButton: blueButton
})

function Conversations (props) {
  const {
    conversations,
    classes,
    history,
    unreadConversations,
    refetch
  } = props

  const [showOptions, setShowOptions] = useState(false)
  const [showFilters, setShowFilters] = useState(false)
  const [currentOption, setCurrentOption] = useState(0)
  const [currentFilter, setCurrentFilter] = useState(0)
  const [uneadConversationsState, setUnreadConversationsState] = useState([])
  const [ReadConversationsState, setReadConversationsState] = useState([])
  const [messageCounterState, setMessageCounterState] = useState({})
  const [conversationsState, setConversationsState] = useState(conversations)
  const [noDataText, setNoDataText] = useState('This company has no conversations yet.')

  const discussionExist = (id, list) => {
    const exist = list && list.length > 0 && list.filter(item => item.discussion.id === id)
    if (exist && exist.length > 0) return true
    else return false
  }

  if (conversationsState && conversationsState > 0) {
    if (unreadConversations && unreadConversations.unreadConversations.length > 0 && uneadConversationsState.length === 0) {
      let unreadMessagesArray = []
      let unreadMessagesCountObject = {}
      let readConversations = []
      unreadConversations.unreadConversations.map(unreadMessage => {
        const discussionAdded = discussionExist(unreadMessage.discussionId, unreadMessagesArray)
        if (discussionAdded) unreadMessagesCountObject[unreadMessage.discussionId] = unreadMessagesCountObject[unreadMessage.discussionId] + 1
        else {
          const discussion = conversations.filter(disc => disc.discussion.id === unreadMessage.discussionId)
          unreadMessagesArray.push(discussion[0])
          unreadMessagesCountObject[unreadMessage.discussionId] = 1
        }
      })
      conversations.map(conversation => {
        const haveUnreadMessages = unreadMessagesArray.filter(conversationUnread => {
          return conversationUnread.discussion.id === conversation.discussion.id
        })
        const discussionAdded = discussionExist(conversation.discussion.id, readConversations)
        if (haveUnreadMessages.length === 0 && !discussionAdded) readConversations.push(conversation)
      })
      setReadConversationsState(readConversations)
      setUnreadConversationsState(unreadMessagesArray)
      setMessageCounterState(unreadMessagesCountObject)
    } else if (unreadConversations && unreadConversations.unreadConversations.length === 0 && ReadConversationsState.length === 0) {
      setReadConversationsState(conversations)
    }
  }

  const options = [{name: 'All', value: 'all'}, {name: 'Read', value: 'read'}, {name: 'Unread', value: 'unread'}]
  const filters = [{name: 'Last Activity', value: 'desc'}, {name: 'Date of creation', value: 'asc'}]
  const dropdownOptions = []
  const dropdownFilters = []

  const changeFilter = (filter, index) => {
    if (currentFilter !== index) {
      setConversationsState(conversationsState.reverse())
    }
    setCurrentFilter(index)
  }

  const markAllAsRead = (markMessageAsRead) => {
    (unreadConversations.unreadConversations || []).map((message, index) => {
      markMessageAsRead({ variables: { messageId: message.id } })
      if (index + 1 === unreadConversations.unreadConversations.length) {
        setTimeout(() => {
          refetch()
        }, 2000)
      }
    })
  }

  const selectViewType = (index, option) => {
    setCurrentOption(index)
    switch (option.value) {
      case 'read':
        setNoDataText('This company has no conversations without unread messages.')
        setConversationsState(currentFilter === 0 ? ReadConversationsState : ReadConversationsState.reverse())
        break
      case 'unread':
        setNoDataText('This company has no unread messages.')
        setConversationsState(currentFilter === 0 ? uneadConversationsState : uneadConversationsState.reverse())
        break
      case 'all':
        setNoDataText('This company has no conversations yet.')
        setConversationsState(currentFilter === 0 ? conversations : conversations.reverse())
        break
    }
  }

  options.map((option, index) => {
    dropdownOptions.push(<li key={option.value} className={classes.option} onClick={() => selectViewType(index, option)}>{option.name}</li>)
  })

  filters.map((filter, index) => {
    dropdownFilters.push(<li key={filter.value} className={classes.option} onClick={() => changeFilter(filter.value, index)}>{filter.name}</li>)
  })

  return (<Mutation mutation={MarkMessageAsRead}>
    {markMessageAsRead => {
      return <>
        <HeaderHero className={`${classes.centered} ${classes.heroCentered}`}>
          <Grid container alignItems="flex-start" className={classes.relative}>
            <Grid item xs={12} className={classes.centered}>
              <MainHeading
                primary='Conversations'
              />
            </Grid>
          </Grid>
        </HeaderHero>
        <Grid container className={classes.transparentBackgroundContainer}>
          <TabsContainer icon={faComment} title="Conversations">
            <TitledPaper
              noLogo
              updates
            >
              <ul className={classes.actionsFilterBar}>
                <li>
                  <Button
                    className={classes.link}
                    component={BrowserLink}
                    size="small"
                    color="primary"
                    onClick={() => markAllAsRead(markMessageAsRead)}
                  >
                    Mark all as read
                  </Button>
                </li>
                <li style={{display: 'flex'}}>
                  <div className={classes.centered}>
                    <label className={classes.blackLabel}>Showing:</label>
                    <Button
                      className={classes.link}
                      component={BrowserLink}
                      size="small"
                      color="primary"
                      onClick={() => setShowOptions(!showOptions)}
                    >
                      {options[currentOption].name}
                      <FontAwesomeIcon className={classes.icon} icon={showOptions ? faCaretUp : faCaretDown} />
                    </Button>
                    {showOptions && <ul className={classes.dropdownOptions}>
                      {dropdownOptions}
                    </ul>}
                  </div>
                  <div className={classes.centered}>
                    <label className={classes.blackLabel}>Sort by:</label>
                    <Button
                      className={classes.link}
                      component={BrowserLink}
                      size="small"
                      color="primary"
                      onClick={() => setShowFilters(!showFilters)}
                    >
                      {filters[currentFilter].name}
                      <FontAwesomeIcon className={classes.icon} icon={showFilters ? faCaretUp : faCaretDown} />
                    </Button>
                    {showFilters && <ul className={classes.dropdownOptions}>
                      {dropdownFilters}
                    </ul>}
                  </div>
                </li>
              </ul>
              <Grid container className={classes.updatesContainer}>
                {conversationsState.map(conversation => {
                  let messages
                  const { id, title, discussion, participants } = conversation
                  const itHaveUnreadMessages = messageCounterState[discussion.id] ? messageCounterState[discussion.id] : null
                  const onClick = () => history.push(`/conversations/${id}`)
                  if (discussion) {
                    messages = discussion.messages
                  }
                  let lastMessage = messages && messages[messages.length - 1]
                  if (lastMessage) lastMessage.body = truncateWithEllipses(lastMessage.body, 250)
                  return <Grid item xs={12} key={id}>
                    {itHaveUnreadMessages ? <Badge className={classes.badge} badgeContent={itHaveUnreadMessages} color="primary">
                      <Message
                        title={title}
                        variant='body1'
                        message={lastMessage}
                        onClick={onClick}
                      />
                    </Badge> : <Message
                        title={title}
                        variant='body1'
                        message={lastMessage}
                        onClick={onClick}
                      />}
                  </Grid>
                })}
                {(conversationsState || []).length === 0 &&
                  <Grid item xs={12} className={`${classes.centered} ${classes.backgroundWhite}`}>
                    <FontAwesomeIcon icon={faBan} className={classes.iconBig} />
                    <Typography className={classes.messagetitle} style={{
                      justifyContent: 'flex-start',
                      alignItems: 'center' }}>
                        {noDataText}
                    </Typography>
                  </Grid>
                }
              </Grid>
            </TitledPaper>
          </TabsContainer>
        </Grid>
        </>
      }}
    </Mutation>
  )
}

export default withStyles(styles)(withMobileDialog()(Conversations))