import { Grid, withMobileDialog } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import Person from '../core/Person'
import TitledPaper from '../core/TitledPaper'

const styles = theme => ({
  paddedPaperClassName: {
    backgroundColor: 'white',
    mixBlendMode: 'multiply',
    padding: '2rem 1.5rem'
  },
  container: {
    justifyContent: 'space-between'
  },
  founder: {
    margin: '0 0 1.5rem',
    '&:last-child': {
      margin: 0
    }
  }
})

function CompanyInformationHero (props) {
  const {
    classes,
    founders
  } = props
  return (
    <>
      {(founders || []).length > 0 && (
        <TitledPaper noLogo className={classes.paddedPaperClassName}>
          <Grid container className={classes.container}>
            {founders.map((founder, i) => (
              <Grid item xs={12} key={i} className={classes.founder}>
                <Person
                  white
                  avatarUrl={founder.avatarUrl || '/user.png'}
                  name={founder.name}
                  bio={founder.bio}
                  url={`/users/${founder.slug}`}
                />
              </Grid>
            ))}
          </Grid>
        </TitledPaper>
      )}
    </>
  )
}

export default withStyles(styles)(withMobileDialog()(CompanyInformationHero))
