import gql from 'graphql-tag'

const getCompanyUpdates = gql`
  query CompanyUpdates($slug: String!) {
    company(id: $slug){
      id
      slug
      name
      can {
        manage
      }
      companyUpdates {
        id
        messages {
          id
          body
          createdAt
          user {
            id
            name
            avatarUrl
            defaultCompany {
              id
              name
            }
          }
        }
      }
    }
  }
`

export default getCompanyUpdates
