import { InMemoryCache } from 'apollo-cache-inmemory'
import ApolloClient from 'apollo-client'
import { setContext } from 'apollo-link-context'
import { createUploadLink } from 'apollo-upload-client'
import React from 'react'
import { ApolloProvider } from 'react-apollo'
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import introspectionQueryResultData from '../../../../../fragmentTypes.json';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
})

const uri = '/graphql'

const uploadLink = createUploadLink({ uri })

const getClient = token => {
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ''
      }
    }
  })

  return new ApolloClient({
    link: authLink.concat(uploadLink),
    cache: new InMemoryCache({ fragmentMatcher })
  })
}

class AppWithGraphQL extends React.PureComponent {
  render () {
    const { token } = this.props
    return (
      <ApolloProvider client={getClient(token)}>
        {this.props.children}
      </ApolloProvider>
    )
  }
}

export default AppWithGraphQL
