import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import urlize from 'urlize.js'

export default function MultiLine (props) {
  const {
    id,
    value,
    onChange,
    label,
    placeholder,
    content,
    errorMessage,
    required
  } = props

  let renderedContent = urlize(content)
  if ((renderedContent).includes('<a')) {
    renderedContent = <div dangerouslySetInnerHTML={{ __html: renderedContent }}></div>
  }

  return (
    <Grid>
      <Typography variant="body1">
        {label} <small><i>{required && '(required)'}</i></small>
      </Typography>
      {content && <Typography variant="body1"><small>{renderedContent}</small></Typography>}
      <Input fullWidth onChange={onChange} defaultValue={value} multiline placeholder={placeholder || "Type your answer here..."} />
    </Grid>

  )
}
