import gql from 'graphql-tag'

const getPostWaitListText = gql`
  query getPostWaitlistText {
    postWaitListText {
      postWaitListText
    }
  }
`

export default getPostWaitListText
