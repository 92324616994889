import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import { Query } from 'react-apollo'
import getHomePageContent from '../../graphql/queries/getHomePageContent'
import Home from '../Home'
import AppLayout from '../layout/AppLayout'
import brand from '../layout/brand'
import Title from '../layout/Title'
import getProfile from '../../graphql/queries/getProfile'

const styles = theme => ({
  layout: {
    width: '100vw',
    height: '100vh',
    backgroundColor: brand.canvasColor
  }
})

function HomeRoute ({ match: { params: { slug } }, history, classes }) {
  const title = <Title>
    TheSyndicate.com
  </Title>

  return <AppLayout title={title} className={classes.layout} >
    <div className={classes.container}>

      <Query query={getProfile} fetchPolicy="cache-and-network">
        {({ loading, error, data }) => {
          if (error) {
            return null
          }

          let profile
          if (data) {
            profile = data.profile
          }
          const loggedIn = Boolean(profile)

          return (<Query query={getHomePageContent} fetchPolicy="cache-and-network">
            {({ loading, error, data, refetch, networkStatus }) => {
              if (error) {
                return <h2>Error...</h2>
              }

              const { homePageContent, featuredItems } = data

              return !loading && <Home profileSlug={profile && profile.slug} loggedIn={loggedIn} joinWaitListButtonText={homePageContent.joinWaitListButtonText} heroContent={homePageContent.heroContent} featuredItems={homePageContent.featuredItems} />
            }}
          </Query>)
        }}
      </Query>
    </div>
  </AppLayout>
}

export default withStyles(styles)(HomeRoute)
