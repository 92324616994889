import gql from 'graphql-tag'

const requestMeeting = gql`
  mutation requestMeeting($to: RequestMeetingToInput!, $message: String!) {
    requestMeeting(to: $to, message: $message) {
      id
    }
  }
`

export default requestMeeting
