import gql from 'graphql-tag'

const getHomePageContent = gql`
  query GetHomePageContent {
    homePageContent {
      heroContent
      joinWaitListButtonText
      featuredItems {
        actionPath
        imageUrl
        deal {
          id
          investedOn
          committed
          companyUrl
        }
      }
    }
  }
`

export default getHomePageContent
