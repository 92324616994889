import { Typography, Button } from '@material-ui/core'
import { withSnackbar } from 'notistack'
import React, { useState } from 'react'
import { Mutation, Query } from 'react-apollo'
import RequestLogin from '../../graphql/mutations/RequestLogin'
import getLoginPageContent from '../../graphql/queries/getLoginPageContent'
import getHomePageContent from '../../graphql/queries/getHomePageContent'
import TitledPaper from '../core/TitledPaper'
import AppLayout from '../layout/AppLayout'
import CenterColumn from '../layout/CenterColumn'
import Login from '../login/Login'
import Skeleton from 'react-loading-skeleton'
import { blackText, blueButton, centeredFlex, redButton, label } from '../layout/styleComponents'
import { withStyles } from '@material-ui/core/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowRight} from '@fortawesome/pro-light-svg-icons'
import { Link as BrowserLink } from 'react-router-dom'

const styles = () => ({
  blueButton: blueButton,
  text: {
    ...blackText,
    textAlign: 'center',
    width: '100%'
  },
  row: {
    ...centeredFlex,
    listStyle: 'none',
    margin: 0,
    padding: '2rem 0 3rem',
    '@media (min-width: 45rem)': {
      marginLeft: '-3.5rem',
    },
    '@media (min-width: 64rem)': {
      marginLeft: '-4.5rem',
    }
  },
  buttonBlueContainer: {
    textAlign: 'center',
    '@media (max-width: 45rem)': {
      marginBottom: '.5rem',
      width: '100%',
    },
    '@media (min-width: 45rem)': {
      marginRight: '.5rem',
    },
    '&::before': {
      ...label,
      content: '"Join as"',
      marginRight: '.5rem',
      '@media (max-width: 45rem)': {
        display: 'block',
        width: '100%',
        marginBottom: '.5rem'
      }
    }
  },
  buttonRedContainer: {
    textAlign: 'center',
    '@media (max-width: 45rem)': {
      marginBottom: '.5rem',
      width: '100%',
    },
    '@media (min-width: 45rem)': {
      '&::before': {
        ...label,
        content: '"Or"',
        marginRight: '.5rem',
      }
    }
  },
  buttonLink: {
    alignItems: 'center',
    textDecoration: 'none',
    '@media (max-width: 45rem)': {
      display: 'flex',
      justifyContent: 'center',
      width: '100%'
    }
  },
  founderButton: {
    ...blueButton,
    '@media (max-width: 45rem)': {
      marginBottomn: '.5rem',
      maxWidth: '15rem',
      width: '80%'
    },
    '@media (min-width: 45rem)': {
      marginRight: '.5rem'
    }
  },
  investorButton: {
    ...redButton,
    '@media (max-width: 45rem)': {
      maxWidth: '15rem',
      width: '80%'
    }
  },
  centered: {
    ...centeredFlex,
    margin: 'auto',
    maxWidth: '55rem'
  }
})

function LoginRoute ({
  match,
  location,
  history,
  enqueueSnackbar,
  classes
}) {
  const title = 'Sign in'
  let redirectTo

  let searchString = location.search.replace('?', '')

  if (searchString.includes('redirect_to')) {
    redirectTo = searchString.split('=')[1]
  }

  const [sent, setSent] = useState(false)
  const [email, setEmail] = useState('')
  const [content, setContent] = useState('')
  const [loginStatus, setLoginStatus] = useState('')
  const [emailOk, setEmailOk] = useState(null)
  const investorLink = '/forms/wait_list?type=Investor'
  const founderLink = '/forms/wait_list?type=Founder'

  const showContent = () => {
    if (loginStatus === 'on_wait_list') {
      return <TitledPaper white form title={title}>
        <Typography variant="body1" className={classes.text}>{content || <Skeleton />}</Typography>
        <Typography variant="body1" className={classes.text}>Best, TheSyndicate.com Team</Typography>
      </TitledPaper>
    } else if (loginStatus === 'not_on_wait_list') {
      return <TitledPaper white form title='Oops...'>
        <article className={classes.centered}>
          <Typography variant="body1" className={classes.text}>{content || <Skeleton />}</Typography>
          <ul className={classes.row}>
            <li className={classes.buttonBlueContainer}>
              <BrowserLink to={investorLink} className={classes.buttonLink}>
                <Button variant="contained" size="large" className={classes.founderButton}>
                  Founder
                  <FontAwesomeIcon icon={faLongArrowRight} />
                </Button>
              </BrowserLink>
            </li>

            <li className={classes.buttonRedContainer}>
              <BrowserLink to={founderLink} className={classes.buttonLink}>
                <Button variant="contained" size="large" className={classes.investorButton}>
                Investor
                <FontAwesomeIcon icon={faLongArrowRight} />
                </Button>
              </BrowserLink>
            </li>
          </ul>
        </article>
      </TitledPaper>
    }
    return <TitledPaper white form title='Thank you'>
      <article className={classes.centered}>
        <Typography variant="body1" className={classes.text}>{content || <Skeleton />}</Typography>
        <Mutation
          onCompleted={data => {
            enqueueSnackbar(
              `Link sent to ${email}`,
              { variant: 'info' }
            )
          }}
          mutation={RequestLogin}
        >
          {requestLogin => {
            return <Button type="submit" variant="contained" className={classes.blueButton} onClick={() => requestLogin({ variables: { email: emailOk, redirectTo } })}>
              Resend Login Link
              <FontAwesomeIcon icon={faLongArrowRight} />
            </Button>
          }}
        </Mutation>
      </article>
    </TitledPaper>
  }

  const requestUserLoginLink = (requestLogin, email, redirectTo) => {
    requestLogin({ variables: { email, redirectTo } })
    setEmailOk(email)
  }

  return (<Query query={getHomePageContent} fetchPolicy="cache-and-network">
  {({ loading, error, data, refetch, networkStatus }) => {
    if (error) {
      return <h2>Error...</h2>
    }

    const { homePageContent } = data

    return (<AppLayout title={title}>
      <CenterColumn>
        <Query query={getLoginPageContent} fetchPolicy="cache-and-network">
          {({ loading, error, data, refetch, networkStatus }) => {
            if (error) {
              return <h2>Error...</h2>
            }

            let heroContent, loginSentContent, title
            if (!loading) {
              heroContent = data.loginPageContent.heroContent
              loginSentContent = data.loginPageContent.loginSentContent
              if (loginStatus !== 'on_wait_list' && loginStatus !== 'not_on_wait_list') {
                setContent(loginSentContent)
              }

              title = data.loginPageContent.title
            }

            return (
              <Mutation
                onCompleted={data => {
                  if (data.requestLogin === 'sent') {
                    setSent(true)
                  } else if (data.requestLogin === 'not_on_wait_list') {
                    setLoginStatus('not_on_wait_list')
                    setContent(`We don't have ${email} in our system, would you like to apply to be an investor or a founder in the syndicate?`)
                    setSent(true)
                  } else if (data.requestLogin === 'on_wait_list') {
                    setLoginStatus('on_wait_list')
                    setContent("You have been added to the waitlist. We will notify you when it's your turn to apply!")
                    setSent(true)
                  }
                }}
                mutation={RequestLogin}
              >
                {requestLogin => {
                  if (!sent) {
                    return (
                      <Login
                        featuredItems={homePageContent ? homePageContent.featuredItems : []}
                        heroContent={heroContent}
                        email={email}
                        setEmail={setEmail}
                        title={title}
                        history={history}
                        requestLogin={(email) => requestUserLoginLink(requestLogin, email, redirectTo)}
                      />
                    )
                  } else {
                    return showContent()
                  }
                }}
              </Mutation>
            )
          }}
        </Query>
      </CenterColumn>
    </AppLayout>)
  }}
</Query>)
}

const LoginComponent = withStyles(styles)(LoginRoute)

export default withSnackbar(LoginComponent)
