import { Avatar, ListItem, ListItemIcon, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'
import { Mutation } from 'react-apollo'
import Skeleton from 'react-loading-skeleton'
import MarkMessageAsRead from '../../graphql/mutations/MarkMessageAsRead'
import { smallTitle } from '../layout/styleComponents'
import { tealBlue, background } from '../layout/colors'
import Markdown from './Markdown'
import moment from 'moment'

const styles = theme => {
  return {
    container: {
      background: 'white',
      borderBottom: `.025rem solid ${background}`,
      padding: 0,
      '&:hover': {
        background: 'transparent'
      }
    },
    title: {
      fontWeight: 400
    },
    content: {
      marginTop: '.25rem',
      padding: 0,
      width: '100%',
      fontSize: '.95rem',
      '& span': {
        fontSize: '.95rem'
      }
    },
    defaultWrapper: {
      flexWrap: 'wrap',
      padding: 0,
      marginBottom: '2rem',
      '&:hover': {
        background: 'none'
      }
    },
    principalWrapper: {
      flexWrap: 'wrap',
      padding: 0,
      marginBottom: '2rem'
    },
    defaultBody: {},
    principalBody: {
      fontSize: '125%'
    },
    metadata: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    titleLine: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'baseline',
      justifyContent: 'space-between'
    },
    userName: {
      ...smallTitle,
      fontSize: '.85rem',
      textDecoration: 'none',
      minWidth: 'unset',
      fontWeight: 500,
      '& span': {
        fontWeight: 500
      }
    },
    userTitle: {
      marginRight: '1rem',
      display: 'inline-block',
      fontSize: '100%'
    },
    avatarSection: {
      alignItems: 'flex-start',
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '2rem 1.5rem',
      width: '100%'
    },
    listItemIcon: {
      height: '3rem',
      marginRight: '.75rem',
      width: '3rem'
    },
    createdAt: {
      fontSize: '.85rem',
      fontWeight: 300,
      color: theme.palette.info.dark
    }
  }
}

const Message = ({ classes, metadata, message, variant, onClick, title, executeReading }) => {
  let user, body, createdAt
  const today = new Date(Date.now())
  const userTimeZone = today.getTimezoneOffset()

  if (!variant) {
    variant = 'default'
  }
  if (message) {
    user = message.user
    body = message.body
    createdAt = message.createdAt
  }

  const [readMessage, markAsRead] = useState(false)
  return (
    <Mutation mutation={MarkMessageAsRead}>
      {markMessageAsRead => {
        if (!readMessage && message && executeReading) {
          markMessageAsRead({ variables: { messageId: message.id } })
          markAsRead(true)
        }
        const avatar = user ? <Avatar alt={user.name} src={user.avatarUrl} className={classes.listItemIcon} /> : <Avatar />
        return (
          <ListItem
            className={classes.container}
            alignItems="flex-start"
            button={!!onClick}
            onClick={onClick}
          >
            <div className={classes.avatarSection}>
              <ListItemIcon className={classes.listItemIcon}>{avatar}</ListItemIcon>
              {metadata}
              <div className={classes.titleLine}>
                {user && <div>
                  {title && <Typography variant="body1" className={`${classes.userName} ${classes.title}`} style={{fontSize: '1.15rem'}}>{title}</Typography>}
                  <Typography variant="caption" className={classes.createdAt}>Started by <span className={classes.userName}>{user.name}</span> {moment(createdAt).utcOffset(userTimeZone).format('hh:mm a on DD MMM YYYY')}</Typography>
                  <div className={classes.content}>
                    {body && (
                      <div className={classes[`${variant}Body`]}>
                        <Markdown>{body}</Markdown>
                      </div>
                    )}
                    {!body && <Skeleton count={3} />}
                  </div>
                </div>}
                {!user && <Skeleton />}
              </div>
            </div>
          </ListItem>
        )
      }}
    </Mutation>
  )
}

export default withStyles(styles)(Message)
