import React from 'react'
import { Mutation } from 'react-apollo'
import CreateCompanyUpdate from '../../graphql/mutations/CreateCompanyUpdate'
import NewMessageForm from './NewMessageForm'

const CreateCompanyUpdateFormContainer = ({
  companySlug,
  onSave
}) => {
  const initialState = {
    body: ''
  }

  return <Mutation
    onCompleted={data => {
      if (data) {
        onSave(data)
      }
    }}
    mutation={CreateCompanyUpdate}
  >
    {createCompanyUpdate => {
      const onSubmit = messageInput => createCompanyUpdate({ variables: { companySlug, messageInput } })
      return <NewMessageForm create onSubmit={onSubmit} initialState={initialState} />
    }}
  </Mutation>
}

export default CreateCompanyUpdateFormContainer
