import gql from 'graphql-tag'

const updateUser = gql`
  mutation updateUser($id: ID!, $userInput: UserInput!, $investorInput: InvestorInput) {
    updateUser(id: $id, userInput: $userInput, investorInput: $investorInput) {
      id
    }
  }
`

export default updateUser
