import { Button, Grid, Typography, withMobileDialog } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';
import { Link as BrowserLink } from 'react-router-dom';
import TitledPaper from '../core/TitledPaper';
import { gullGray } from '../layout/colors';
import TabsContainer from '../layout/TabsContainer'
import { faFileAlt, faTrash, faEdit } from '@fortawesome/pro-solid-svg-icons'
import { faLongArrowRight, faBan } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { blueButton, blackText, centeredFlex } from '../layout/styleComponents'
const styles = theme => ({
  roundedButton: {
    borderRadius: '50%',
    margin: 0,
    padding: '.25rem .5rem',
    minWidth: 'unset',
    '& span svg': {
      width: '1rem'
    }
  },
  delete: {
    color: `${theme.palette.error.main} !important`,
    backgroundColor: 'none !important',
    '&:hover': {
      color: `${theme.palette.error.dark} !important`
    }
  },
  edit: {
    color: `${theme.palette.secondary.main} !important`,
    backgroundColor: 'none !important',
    '&:hover': {
      color: `${theme.palette.secondary.dark} !important`
    }
  },
  padding: {
    background: 'white',
    borderRadius: '0 0 .5rem .5rem',
    padding: '2rem 1.5rem',
  },
  icon: {
    fontSize: '1.5rem',
    cursor: 'pointer'
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  paddedPaper: {
    borderBottom: '.2rem solid black',
    background: 'none',
    boxShadow: 'none',
    margin: '0 auto 4rem',
    width: '100%'
  },
  alignRightOnMobile: {
    textAlign: 'left',
    width: 'unset',
    maxWidth: 'unset',
    flexBasis: 'unset',
    flex: 'unset',
  },
  text: {
    ...blackText,
    fontSize: '1rem',
    margin: 0,
    fontWeight: 400,
    width: '100%',
    textAlign: 'left'
  },
  noExperiences: {
    ...centeredFlex,
    '& p': {
      textAlign: 'center !important'
    }
  },
  iconBig: {
    color: theme.palette.info.light,
    fontSize: '6rem',
    fontWeight: 300,
    margin: '0 auto 1.5rem'
  },
  icon: {
    color: '#2D49B6',
    margin: '0',
    minWidth: 'auto !important',
    width: 'auto'
  },
  button: blueButton,
  iconNegative: {
    color: 'red'
  }
})

function UserExperiencesHero (props) {
  const {
    classes,
    userExperiences,
    can,
    slug,
    deleteUserExperience,
    loading
  } = props

  const tabs = []
  return <TabsContainer tabs={tabs} icon={faFileAlt} title="Experience">
    <TitledPaper
      className={classes.padding}
      noLogo
      actions={
        can && can['manage'] && (
          <Button
            className={classes.button}
            component={BrowserLink}
            size="small"
            color="primary"
            to={`/users/${slug}/user_experiences/new`}
          >
            Add Experience
            <FontAwesomeIcon icon={faLongArrowRight}/>
          </Button>
        )
      }
    >
      {/* <ListItem alignItems="flex-start" key={experience.id}>
        <ListItemText
          secondary={
            <>
              <Typography color="textPrimary" component="span">
                <span style={{ color: gullGray }}>{`${experience.startYear}-${experience.endYear} `}</span> {experience.position} <span style={{ color: gullGray }}>@</span>  {experience.company}
              </Typography>
            </>
          }
        />
      </ListItem> */}
      <Grid item xs={12}>
        {userExperiences && userExperiences.length > 0 ? userExperiences.map(experience => <Grid xs={12} md={6} key={experience.id} container className={classes.container}>

          <Grid item xs={4} md={2} className={classes.alignRightOnMobile}>
            {can && can['manage'] && (
              <Button
                className={classes.roundedButton}
                component={BrowserLink}
                size="small"
                color="primary"
                to={`/users/${slug}/user_experiences/${experience.id}/edit`}
              >
                <FontAwesomeIcon icon={faEdit} className={`${classes.icon} ${classes.edit}`} />
              </Button>
            )}
            {can && can['manage'] && (
              <Button
                className={classes.roundedButton}
                onClick={() => deleteUserExperience(experience.id)}
                size="small"
                color="primary"
              >
                <FontAwesomeIcon icon={faTrash} className={`${classes.icon} ${classes.delete}`} />
              </Button>
            )}
          </Grid>
          <Grid item md={10} xs={8} style={{paddingLeft: '.5rem'}}>
            <Typography color="textPrimary" variant="body1" className={classes.text}>
              <span style={{ color: 'black' }}>{`${experience.startYear}-${experience.endYear} `}</span> {experience.position} <span>@</span>{experience.company}
            </Typography>
          </Grid>
        </Grid>) : <section className={classes.noExperiences}>
          <FontAwesomeIcon icon={faBan} className={classes.iconBig} />
          <Typography className={classes.text}>
            You don't have any experiences yet.
          </Typography>
        </section>}
      </Grid>
    </TitledPaper>
  </TabsContainer>
}

export default withStyles(styles)(withMobileDialog()(UserExperiencesHero))
