import gql from 'graphql-tag';

const updateUserExperience = gql`
  mutation UpdateUserExperience($id: ID!, $userExperienceInput: UserExperienceInput!){
    updateUserExperience(id: $id, userExperienceInput: $userExperienceInput){
      id
    }
  }
`

export default updateUserExperience
