import { createMuiTheme } from '@material-ui/core/styles'
import { bigStone, blueRibbon, formFieldBackgroundBlue, formTextColor, tealBlue, redOrange, textBlack, white, gullGray } from './colors'
// Fonts
const headingFont = `"Lato", "Roboto", "Helvetica", "Arial", sans-serif`

// Metrics
const defaultBorderWidth = 2
const buttonBorderWidth = defaultBorderWidth
const buttonBorderDistance = 2
const buttonHoverMovementDistance = 2
const buttonBorderHoverDistance = 3

const defaultTheme = createMuiTheme({
  palette: {
    primary: {
      main: bigStone
    },
    secondary: {
      main: blueRibbon
    },
    error: {
      main: redOrange
    },
    info: {
      main: tealBlue
    },
    success: {
      main: gullGray
    }
  },
  typography: {
    useNextVariants: true,
    fontSize: 18,
    body1: {
      fontSize: '1.2857142857142858em'
    },
    h1: {
      fontSize: '2em',
      fontFamily: headingFont,
      fontWeight: 700
    },
    h2: {
      fontSize: '1.802em',
      fontFamily: headingFont,
      fontWeight: 700
    },
    h3: {
      fontSize: '1.624em',
      fontFamily: headingFont
    },
    h4: {
      fontSize: '1.466em',
      fontFamily: headingFont
    },
    h5: {
      fontSize: '1.325em',
      fontFamily: headingFont,
      color: tealBlue
    },
    h6: {
      fontSize: '1.2em'
    }
  }
})

const { breakpoints, typography: { pxToRem } } = defaultTheme

const theme = {
  ...defaultTheme,
  overrides: {
    MuiFab: {
      root: {
        position: 'fixed',
        bottom: '2rem',
        right: '2rem'
      }
    },
    MuiInputLabel: {
      formControl: {
        transform: `translate(1rem, 2.5rem) scale(1)`
      }
    },
    MuiInput: {
      root: {
        backgroundColor: formFieldBackgroundBlue
      },
      input: {
        color: formTextColor,
        padding: '1rem',
        fontSize: '0.9rem'
      },
      underline: {
        '&:after': {
          borderBottom: `${defaultBorderWidth}px solid ${tealBlue}`
        },
        '&:before': {
          borderBottom: `${defaultBorderWidth}px solid ${formTextColor}`
        }
      }
    },
    MuiFormLabel: {
      root: {
        fontSize: '1rem',
        '&$focused': {
          color: textBlack
        }
      }
    },
    MuiButton: {
      root: {
        borderRadius: 0,
        fontSize: '1.1rem'
      },
      sizeLarge: {
        padding: '1rem 3rem',
        fontSize: '2rem'
      },
      contained: {
        boxShadow: `inset 0 0 0 ${buttonBorderWidth}px ${textBlack}, ${buttonBorderDistance}px ${buttonBorderDistance}px 0 0 ${textBlack}`,
        backgroundColor: white,
        borderColor: textBlack,
        color: textBlack,
        transitionProperty: 'all',
        '&:hover': {
          backgroundColor: white,
          boxShadow: `inset 0 0 0 ${buttonBorderWidth}px ${textBlack}, ${buttonBorderHoverDistance}px ${buttonBorderHoverDistance}px 0 0 ${textBlack}`,
          color: blueRibbon,
          transform: `translate(-${buttonHoverMovementDistance / 2}px, -${buttonHoverMovementDistance / 2}px)`
        }
      },
      containedPrimary: {
        boxShadow: `inset 0 0 0 ${buttonBorderWidth}px ${white}, ${buttonBorderDistance}px ${buttonBorderDistance}px 0 0 ${white}`,
        backgroundColor: blueRibbon,
        borderColor: white,
        color: white,
        '&:hover': {
          backgroundColor: white,
          boxShadow: `inset 0 0 0 ${buttonBorderWidth}px ${white}, ${buttonBorderHoverDistance}px ${buttonBorderHoverDistance}px 0 0 ${white}`
        }
      },
      label: {
        fontWeight: 700
      }
    },
    MuiTypography: {
      h1: {
        textTransform: 'uppercase'
      },
      h2: {
        textTransform: 'uppercase',
        marginBottom: '1rem'
      }
    }
  }
}

export default theme
