import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import DealSnapshotCard from './DealSnapshotCard'

const styles = theme => {
  return {
  }
}

// FIXME: At this point this interim component shouldn't exist, we'll deal with that later.
const DealSnapshot = ({
  company,
  number,
  goal,
  committed,
  investorCount,
  launchedOn,
  closedOn,
  investorResponse,
  onInterested,
  onPass,
  classes,
  hideActions,
  ...rest
}) => {
  return <DealSnapshotCard
    company={company}
    number={number}
    goal={goal}
    hideActions={hideActions}
    committed={committed}
    investorCount={investorCount}
    launchedOn={launchedOn}
    closedOn={closedOn}
    investorResponse={investorResponse}
    onInterested={onInterested}
    onPass={onPass}
    {...rest}
  />
}

export default withStyles(styles)(DealSnapshot)
