import { Typography } from '@material-ui/core'
import React, {useEffect} from 'react'
import { Mutation, Query } from 'react-apollo'
import Skeleton from 'react-loading-skeleton'
import UpdateCompany from '../../graphql/mutations/UpdateCompany'
import getCompanyBySlug from '../../graphql/queries/getCompanyBySlug'
import EditCompany from '../company/EditCompany'
import TitledPaper from '../core/TitledPaper'
import AppLayout from '../layout/AppLayout'
import CenterColumn from '../layout/CenterColumn'
import Title from '../layout/Title'

function EditCompanyRoute (props) {
  const { match: { params: { slug } }, history } = props
  useEffect(() => {
    document.title = "The Syndicate - Edit Company"
  }, []);
  return <Query query={getCompanyBySlug} variables={{ id: slug }} fetchPolicy="network-only">
    {({ loading, error, data }) => {
      if (error) {
        return <Typography variant="display2">Error...</Typography>
      }

      let company
      if (!loading) {
        company = data.company
      }

      const title = <Title>
        Edit your company information
      </Title>

      return <AppLayout title={title}>
        <CenterColumn>
          <Mutation
            onCompleted={data => {
              if (data) {
                history.push(`/companies/${slug}`)
              }
            }}
            mutation={UpdateCompany}
          >
            {(updateCompany, { error }) => {
              let errors = []
              if (error) {
                errors = error.graphQLErrors.map((e) => e.message)
              }

              const onSave = companyInput => updateCompany({ variables: { id: slug, companyInput } })
              if (loading) {
                return <TitledPaper title="Edit your company information">
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                </TitledPaper>
              } else {
                const initialState = {
                  name: company.name,
                  tagline: company.tagline,
                  description: company.description,
                  location: company.location,
                  url: company.url,
                  productDescription: company.productDescription,
                  employeeCount: company.employeeCount,
                  whatProblemAreYouSolving: company.whatProblemAreYouSolving,
                  unfairAdvantage: company.unfairAdvantage,
                  foundedOn: company.foundedOn,
                  howDoYouMakeMoney: company.howDoYouMakeMoney,
                  twitterUrl: company.twitterUrl,
                  githubUrl: company.githubUrl,
                  facebookUrl: company.facebookUrl,
                  linkedInUrl: company.linkedInUrl,
                  classifications: company.classifications
                }

                return <EditCompany errors={errors} onSave={onSave} initialState={initialState} />
              }
            }}
          </Mutation>
        </CenterColumn>
      </AppLayout>
    }}
  </Query>
}

export default EditCompanyRoute
