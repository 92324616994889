import { Button, Typography, InputLabel } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React, {useEffect} from 'react'
import { Link as BrowserLink } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import TitledPaper from '../core/TitledPaper'
import { blackText, label, blueButton, textInput } from '../layout/styleComponents'
import CenterColumn from '../layout/CenterColumn'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowRight} from '@fortawesome/pro-light-svg-icons'
import { faExternalLinkSquare } from '@fortawesome/pro-solid-svg-icons'
import {dateFormatMMMDDYYYY} from '../helpers/dateFormat'

const styles = theme => ({
  submitButton: {
    marginTop: '1.5rem'
  },
  brandImage: {
    backgroundColor: 'white !important',
    backgroundSize: 'contain !important',
    borderRadius: '1rem',
    width: '100px',
    height: '100px',
    objectFit: 'contain',
    transition: '0.3s',
    position: 'relative',
    margin: '0 0 1.25rem'
  },
  logosColumn: {
    maxWidth: '8.75rem',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    position: 'relative'
  },
  logos: {
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    listStyle: 'none',
    margin: '7rem 0 4rem',
    alignItems: 'stretch',
    width: '2000%',
    marginLeft: '-950%',
    '@media (max-width: 45rem)': {
      padding: '2rem 0'
    }
  },
  label: label,
  text: {
    ...blackText,
    textAlign: 'center',
    width: '100%'
  },
  button: {
    ...blueButton,
    boxShadow: 'none !important'
  },
  icon: {
    marginLeft: '.5rem'
  },
  textInput: textInput,
  paddedPaperClassName: {
    maxWidth: '33.5rem',
    margin: 'auto',
    padding: '0 1.5rem'
  },
  relative: {
    position: 'relative',
    '&:hover .collage-popover': {
      display: 'block'
    }
  },
  goLInk: {
    background: theme.palette.primary.main,
    borderRadius: '.5rem',
    color: 'white',
    marginLeft: '.35rem',
    marginTop: '.25rem'
  },
  popover: {
    background: theme.palette.primary.main,
    borderRadius: '1rem',
    bottom: '100%',
    display: 'none',
    fontFamily: 'Lato',
    margin: 0,
    minWidth: '200px',
    padding: 0,
    position: 'absolute',
    zIndex: 10,
    '& li': {
      background: theme.palette.primary.main,
      color: 'white',
      fontSize: '16px',
      padding: '0 1.75rem',
      lineHeight: '28px',
      '&:first-child': {
        borderRadius: '1rem 1rem 0 0',
        color: 'rgba(255, 255, 255, .5)',
        textTransform: 'uppercase',
        paddingTop: '1rem',
        paddingBottom: '.5rem'
      },
      '&:nth-child(2)': {
        paddingBottom: '1rem'
      },
      '&:last-child': {
        borderRadius: '0 0 1rem 1rem',
        borderTop: `.5px solid rgba(255, 255, 255, .5)`,
        paddingTop: '.5rem',
        paddingBottom: '1rem'
      },
      '& strong, & span': {
        display: 'block'
      },
      '& a': {
        alignItems: 'center',
        color: 'white',
        display: 'flex',
        fontFamily: 'Lato',
        textDecoration: 'none',
      }
    }
  }
})

const handleRequestLogin = ({ requestLogin, email }) => {
  requestLogin(email)
}

const Login = props => {
  const { classes, requestLogin, heroContent, title, email, setEmail, featuredItems } = props
  useEffect(() => {
    document.title = "The Syndicate - Login"
  }, []);

  const getGridItems = (featuredItems, classes) => {
    let columns = []
    let items = [...featuredItems]
    let pass = 1
    while(items.length) {
      if (pass === 1 || pass === 3 || pass === 6 || pass === 11) {
        columns.push({
          container: items.splice(0, 2),
          alignContent: pass === 1 || pass === 6 ? 'flex-start' : 'center',
          top: pass === 1,
          isDual: true
        })
        pass = pass + 1
      } else {
        columns.push({
          container: items.splice(0, 3),
          top: pass === 5 || pass === 7 || pass === 9
        })
        if (pass === 12) pass = 1
        else pass = pass + 1
      }
    }

    if (columns.length > 0) {
      columns.map((column) => {
        items.push(<li className={classes.logosColumn} style={{alignContent: column.alignContent || '', top: column.top ? '3.5rem' : ''}}>
          {column.container.map((featuredItem, index) => <article className={classes.relative}>
            <BrowserLink
              key={featuredItem.actionPath}
              to={featuredItem.actionPath}
              className={classes.actionUrl}
            >
              <figure
                className={classes.brandImage}
                style={{
                  bottom: column.isDual && column.top && index === 1 ? '-9rem' : '',
                  background: `url(${featuredItem.imageUrl}) center center no-repeat`
                }}
              />
            </BrowserLink>
            {featuredItem.deal && <ul className={`${classes.popover} collage-popover`}>
              <li>Deal Info</li>
              <li>
                <strong>${featuredItem.deal.committed}</strong>
                <span>invested on</span>
                <span>{dateFormatMMMDDYYYY(featuredItem.deal.investedOn)}</span>
              </li>
              <li><a target="_blank" href={featuredItem.deal.companyUrl}>{featuredItem.deal.companyUrl.replace('https://', '').replace('http://', '').replace('www.', '')}<FontAwesomeIcon icon={faExternalLinkSquare} className={classes.goLInk}/></a></li>
            </ul>}
          </article>)}
        </li>)
      })
    }
    return items
  }

  return (
    <TitledPaper form className={classes.paddedPaperClassName} title={title}>
      <Typography variant="body1" className={classes.text}>{heroContent || <Skeleton />}</Typography>
      <ValidatorForm
        onError={errors => console.log('errors', errors)}
        onSubmit={() => {
          handleRequestLogin({ requestLogin, email })
        }}
      >
        <InputLabel className={classes.label}>Email Address</InputLabel>
        <TextValidator
          className={classes.textInput}
          fullWidth
          onChange={event => setEmail(event.target.value)}
          autoFocus
          name="email"
          value={email}
          validators={['required', 'isEmail']}
          errorMessages={['Email is required', 'Email is not valid']}
        />
        <div className={classes.submitButton}>
          <Button type="submit" variant="contained" className={classes.button}>
            Email Login Link
            <FontAwesomeIcon icon={faLongArrowRight} className= {classes.icon} />
          </Button>
        </div>
      </ValidatorForm>
      <CenterColumn>
        <ul className={classes.logos}>
          {
            getGridItems(featuredItems, classes)
          }
        </ul>
      </CenterColumn>
    </TitledPaper>
  )
}

export default withStyles(styles, { withTheme: true })(Login)
