import gql from 'graphql-tag'

const requestAllocation = gql`
  mutation requestAllocation($dealId: ID!, $amount: Int!, $hasExperience: Boolean) {
    requestAllocation(dealId: $dealId, amount: $amount, hasExperience: $hasExperience) {
      id
    }
  }
`

export default requestAllocation
