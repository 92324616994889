import gql from 'graphql-tag';

const createMessage = gql`
  mutation createMessage($discussionId: ID!, $messageInput: MessageInput!) {
    createMessage(discussionId: $discussionId, messageInput: $messageInput) {
      id
    }
  }
`;

export default createMessage;
