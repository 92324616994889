import gql from 'graphql-tag'

const getProfile = gql`
  query Profile {
    profile {
      id
      slug
      name
      email
      firstName
      lastName
      avatarUrl
      unreadNotificationsCount
      unreadConversationsCount
      unreadDealConversationsCount
      isInvestor
    }
  }
`

export default getProfile
