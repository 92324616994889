import { Typography, withMobileDialog } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import CompanyTile from '../core/CompanyTile'
import TitledPaper from '../core/TitledPaper'
import TabsContainer from '../layout/TabsContainer'
import { faIndustry } from '@fortawesome/pro-solid-svg-icons'
import { faFacebook, faGithub, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faGlobe } from '@fortawesome/pro-solid-svg-icons'

const styles = theme => ({
  spacerTop: {
    marginTop: '1rem'
    // color: blueRibbon
  },
  container: {
    padding: '1rem 1.5rem',
    backgroundColor: 'white'
  },
  horizontalLogos: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start'
  },
  paddedPaperClassName: {
    backgroundColor: 'white',
    boxShadow: 'none',
    margin: '4rem auto',
    padding: '2rem'
  },
  title: {
    color: 'black !important',
    fontFamily: 'Poppins',
    fontSize: '110%',
    fontWeight: 'bold',
    margin: '2rem 0',
    textTransform: 'uppercase'
  }
})

function UserCompaniesHero (props) {
  const {
    classes,
    companies,
    investments,
    followedCompanies,
    defaultCompany,
    setDefaultCompany,
    can
  } = props

  let companiesSection = null
  let investmentsSection = null
  let followedCompaniesSection = null

  const getSocialLinks = (company) => {
    let socialLinks = []
    if (company.linkedInUrl) {
      socialLinks.push({ icon: faLinkedin, url: company.linkedInUrl })
    }
    if (company.twitterUrl) {
      socialLinks.push({ icon: faTwitter, url: company.twitterUrl })
    }
    if (company.facebookUrl) {
      socialLinks.push({ icon: faFacebook, url: company.facebookUrl })
    }
    if (company.githubUrl) {
      socialLinks.push({ icon: faGithub, url: company.githubUrl })
    }
    if (company.url) {
      socialLinks.push({ icon: faGlobe, url: company.url })
    }
    return socialLinks
  }

  const tabs = [
    {
      name: 'Founded',
      number: companies ? companies.length : 0
    },
    {
      name: 'Invested',
      number: investments ? investments.length : 0
    },
    {
      name: 'Following',
      number: followedCompanies ? followedCompanies.length : 0
    },
  ]

  if (companies) {
    if (companies.length > 0) {
      companiesSection = (
        <>
          {companies.map((company, i) => (
            <CompanyTile
              can={can}
              companyId={company.id}
              defaultCompany={defaultCompany}
              setDefaultCompany={setDefaultCompany}
              variant="full"
              key={`companies-${i}`}
              name={company.name}
              socialLinks={getSocialLinks(company)}
              logoUrl={company.logoUrl}
              url={`/companies/${company.slug}`}
              tagline={company.tagline}
            />
          ))}
        </>
      )
    }
  } else {
    companiesSection = <Skeleton />
  }

  if (followedCompanies) {
    if (followedCompanies.length > 0) {
      followedCompaniesSection = (
        <>
          <div className={classes.horizontalLogos}>
            {followedCompanies.map((company, i) => (
              <CompanyTile
                followed
                variant="logo"
                key={`companies-${i}`}
                name={company.name.toLowerCase()}
                logoUrl={company.logoUrl}
                socialLinks={getSocialLinks(company)}
                url={`/companies/${company.slug}`}
                tagline={company.tagline}
              />
            ))}
          </div>
        </>
      )
    }
  } else {
    followedCompaniesSection = <Skeleton />
  }

  if (investments) {
    if (investments.length > 0) {
      investmentsSection = (
        <>
          <div className={classes.horizontalLogos}>
            {investments
              .map(investment => investment.company)
              .map((company, i) => (
                <CompanyTile
                  variant="logo"
                  key={`investments-${i}`}
                  name={company.name}
                  logoUrl={company.logoUrl}
                  socialLinks={getSocialLinks(company)}
                  url={`/companies/${company.slug}`}
                  tagline={company.tagline}
                  white
                />
              ))}
          </div>
        </>
      )
    }
  } else {
    investmentsSection = <Skeleton />
  }

  return (
    <TabsContainer tabs={tabs} icon={faIndustry} title="Companies">
      <section className={classes.container}>
        {companiesSection}
      </section>
      <section className={classes.container}>
        {investmentsSection}
      </section>
      <section className={classes.container}>
        {followedCompaniesSection}
      </section>
    </TabsContainer>
  )
}

export default withStyles(styles)(withMobileDialog()(UserCompaniesHero))
