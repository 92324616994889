import Input from '@material-ui/core/Input'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import urlize from 'urlize.js'

const onEnter = onSubmit => (evt) => evt.key === 'Enter' ? onSubmit() : null

export default function OneLine (props) {
  const {
    value,
    onChange,
    label,
    required,
    content,
    placeholder,
    onSubmit
  } = props

  let renderedContent = urlize(content)
  if ((renderedContent).includes('<a')) {
    renderedContent = <div dangerouslySetInnerHTML={{ __html: renderedContent }}></div>
  }

  return <>
    <Typography variant="body1">
      {label} <small><i>{required && '(required)'}</i></small>
    </Typography>
    <div>
      {content && <Typography variant="body1"><small>{renderedContent}</small></Typography>}
    </div>

    <Input fullWidth onChange={onChange} placeholder={placeholder || 'Type your answer here...'} defaultValue={value} onKeyPress={onEnter(onSubmit)} />
  </>
}
