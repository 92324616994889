import gql from 'graphql-tag'

const getFundingHistoryById = gql`
    query UserProfile($id: String!, $userExperienceId: ID!) {
      user(id: $id){
        id
        userExperience(id: $userExperienceId) {
          id
          startYear
          endYear
          company
          position
        }
    }
  }
`

export default getFundingHistoryById
