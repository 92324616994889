import { Button, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import LongTextArea from '../core/LongTextArea';
import LongTextField from '../core/LongTextField';
import MultiSelect from '../core/MultiSelect';
import TitledPaper from '../core/TitledPaper';
import { textInput, blueButton, mediumTitle } from '../layout/styleComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons';
import Form from '../form/ListForm'

const styles = theme => ({
  longInput: {
    ...textInput,
    marginBottom: '2.5rem',
    '& label': {
      fontSize: '1.75rem'
    },
    '& input': {
      fontSize: '.9rem',
      padding: '1rem 0',
      color: theme.palette.primary.main
    }
  },
  textInput: {
    ...textInput,
    marginBottom: '2.5rem',
    '& label': {
      fontSize: '1.75rem'
    },
    '& textarea': {
      fontSize: '.9rem',
      padding: '1rem 0',
      color: theme.palette.primary.main
    },
  },
  actions: {
    marginTop: theme.spacing.unit * 3
  },
  selectedItem: {
    fontWeight: theme.typography.fontWeightMedium
  },
  unselectedItem: {
    fontWeight: theme.typography.fontWeightRegular
  },
  paper: {
    margin: '0 auto 2rem',
    maxWidth: '45rem',
    width: '100%'
  },
  button: {
    ...blueButton,
    margin: '0'
  },
  mediumTitle: {
    ...mediumTitle,
    margin: '4rem auto 7rem'
  }
})

class EditCompanyBase extends React.Component {
  setField = fieldName => {
    return (evt) => {
      this.setState({
        [fieldName]: evt.target.value
      })
    }
  }

  constructor(props) {
    super(props)
    this.state = props.initialState
  }

  render () {
    const {
      classes,
      onSave,
      name,
      tagline,
      description,
      location,
      url,
      productDescription,
      employeeCount,
      whatProblemAreYouSolving,
      unfairAdvantage,
      foundedOn,
      howDoYouMakeMoney,
      twitterUrl,
      githubUrl,
      facebookUrl,
      errors,
      linkedInUrl,
      classifications
    } = this.props

    return <TitledPaper className={classes.paper} form noLogo key="edit-container">
      {errors && errors.length > 0 &&
        <ul>{errors.map(e => <li><Typography>{e}</Typography></li>)}</ul>
      }
      <ValidatorForm
        onError={errors => console.log('errors', errors)}
      >
        <Form
          submitBtnText={'Save Changes'}
          onSubmit={() => onSave(this.state)}
          title={<Typography className={classes.mediumTitle}>Edit company information</Typography>}
          classes={classes}
        >
          <LongTextField label="Name" value={this.state.name} onChange={this.setField('name')} classes={classes} />
          <TextValidator
            className={classes.longInput}
            fullWidth
            label="Tagline" value={this.state.tagline} onChange={this.setField('tagline')} classes={classes}
            validators={['minStringLength:0', 'maxStringLength:140']}
            errorMessages={'Max length is 140'}

          />
          <LongTextField label="Description" value={this.state.description} onChange={this.setField('description')} classes={classes} />
          <LongTextField label="Location" value={this.state.location} onChange={this.setField('location')} classes={classes} />
          <LongTextField label="URL" value={this.state.url} onChange={this.setField('url')} classes={classes} />
          <LongTextField label="Product Description" value={this.state.productDescription} onChange={this.setField('productDescription')} classes={classes} />
          <TextValidator
            className={classes.longInput}
            fullWidth
            label="Employee Count" value={this.state.employeeCount} onChange={this.setField('employeeCount')} classes={classes}
            validators={['isNumber']}
            errorMessages={'It should be a number'}

          />
          <LongTextArea label="What problem are you solving?" value={this.state.whatProblemAreYouSolving} onChange={this.setField('whatProblemAreYouSolving')} classes={classes} />
          <LongTextArea label="What is your unfair advantage?" value={this.state.unfairAdvantage} onChange={this.setField('unfairAdvantage')} classes={classes} />
          <LongTextField label="When was the company founded?" value={this.state.foundedOn} onChange={this.setField('foundedOn')} classes={classes} />
          <LongTextArea label="How do you make money?" value={this.state.howDoYouMakeMoney} onChange={this.setField('howDoYouMakeMoney')} classes={classes} />
          <LongTextField label="Twitter URL" value={this.state.twitterUrl} onChange={this.setField('twitterUrl')} classes={classes} />
          <LongTextField label="GitHub URL" value={this.state.githubUrl} onChange={this.setField('githubUrl')} classes={classes} />
          <LongTextField label="Facebook URL" value={this.state.facebookUrl} onChange={this.setField('facebookUrl')} classes={classes} />
          <>
            <LongTextField label="LinkedIn URL" value={this.state.linkedInUrl} onChange={this.setField('linkedInUrl')} classes={classes} />
            <MultiSelect label="Classifications" value={this.state.classifications} choices={['B2B', 'B2C']} onChange={this.setField('classifications')} classes={classes} />
          </>
        </Form>
      </ValidatorForm>
    </TitledPaper>
  }
}

const EditCompany = withStyles(styles, { withTheme: true })(EditCompanyBase)

export default EditCompany
