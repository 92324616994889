import gql from 'graphql-tag'

const getUserUpdates = gql`
  query UserUpdates($slug: String!) {
    user(id: $slug){
      id
      slug
      name
      can {
        manage
      }
      userUpdates {
        id
        messages {
          id
          body
          createdAt
          user {
            id
            name
            title
            avatarUrl
            defaultCompany {
              id
              name
            }
          }
        }
      }
    }
  }
`

export default getUserUpdates
