import gql from 'graphql-tag'

const getLoginPageContent = gql`
  query getLoginPageContent {
    loginPageContent {
      title
      heroContent
      loginSentContent
    }
  }
`

export default getLoginPageContent
