import { faMapMarkerAlt, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Grid, Typography, withMobileDialog } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { Link as BrowserLink } from 'react-router-dom'
import MainHeading from '../core/MainHeading'
import SocialLinks from '../core/SocialLinks'
import HeaderHero from '../core/HeaderHero'
import { socialLink, socialLinks } from '../layout/styles/BaseProfile'
import { metadata, metadataItem, metadataItemIcon } from '../layout/styles/metadata'
import ImageEditable from './ImageEditable'
import { blueButton, roundedHeroImage } from '../layout/styleComponents'

import sortBy from 'lodash.sortby'
import DealSnapshot from '../../components/deal/DealSnapshot'
import { Player, BigPlayButton } from 'video-react'

const styles = theme => ({
  marginTop: {
    marginTop: '3.5rem',
    padding: 0
  },
  blueButton: {
    ...blueButton,
    boxShadow: 'none !important',
    marginBottom: '1rem',
    '@media(min-width: 45rem)': {
      marginBottom: '0',
    }
  },
  centered: {
    justifyContent: 'center'
  },
  rightSide: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start'
  },
  logo: {
    ...roundedHeroImage,
    backgroundColor: 'white !important',
    backgroundSize: '90% !important',
    width: '6.5rem',
    maxWidth: 'unset',
    maxHeight: 'unset',
    height: '6.5rem'
  },
  heroImage: {
    backgroundSize: 'contain !important',
    display: 'flex',
    margin: 'auto',
    maxHeight: '20rem',
    maxWidth: '100%'
  },
  statusBadge: {
    color: 'black',
    fontFamily: 'Poppins',
    fontSize: '100%',
    fontWeight: 'bold',
    textTransform: 'capitalize'
  },
  statusWrapper: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  relative: {
    paddingTop: '3rem',
    position: 'relative'
  },
  paddedPaper: {
    marginTop: '4rem',
    paddingTop: '2rem',
    boxShadow: 'none',
    position: 'relative'
  },
  requestChangeLink: {
    alignSelf: 'flex-end',
    backgroundColor: 'rgba(0,0,0, 0.1)',
    fontFamily: 'Poppins',
    fontSize: '75%',
    fontWeight: 'bold',
    padding: '.5rem 1rem',
    textAlign: 'center',
    textDecoration: 'none',
    textTransform: 'uppercase',
    transition: 'all .25s',
    '& p, & > span, & a': {
      color: 'rgba(0,0,0, .7) !important',
      fontFamily: 'Poppins',
      fontWeight: 'bold',
      fontSize: '100%',
      transition: 'all .25s'
    },
    '&:hover': {
      background: 'rgba(0,0,0, 0.15)',
      color: 'black !important',
      '& span, & p': {
        color: 'black !important'
      }
    },
    requestChangeLinkEdit: {
      margin: 0
    }
  },
  actions: {
    alignSelf: 'flex-end',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    padding: '1.45rem'
  },
  metadata,
  metadataItem,
  metadataItemIcon,
  socialLinks: socialLinks(theme),
  socialLink: socialLink(theme),
  marginRight: {
    marginRight: '.25rem'
  }
})

function ProductHero (props) {
  const {
    classes,
    slug,
    name,
    onFollowUnfollow,
    toggleRequestMeetingModal,
    following,
    socialLinks,
    logoUrl,
    tagline,
    description,
    location,
    status,
    token,
    can,
    ongoingDeals,
    heroImageUrl,
    refetch
  } = props

  let logo = <figure style={{background: `url(${logoUrl || '/company.png'}) center center no-repeat`}} className={classes.logo} />
  /* let heroImage = heroImageUrl.original ? <img src={heroImageUrl.original || '/no-image.png'} className={classes.heroImage} /> : <img src={heroImageUrl.poster || '/no-image.png'} className={classes.heroImage} />
  <Player
    playsInline
    poster={heroImageUrl.poster}
    src={heroImageUrl.video}
  >
    <BigPlayButton position="center" />
  </Player>
  if (can && can['manage']) {
    logo = <ImageEditable className={classes.logo} refetch={refetch} logoUrl={logoUrl} slug={slug}/>
  }

  const heroCompanyImage = <div className={classes.left}>
    {heroImage}
  </div> */

  const MetadataItem = ({ icon, children }) => (
    <Typography className={classes.metadataItem}>
      <FontAwesomeIcon
        className={classes.metadataItemIcon}
        icon={icon}
      />
      {children}
    </Typography>
  )

  return (
    <HeaderHero
      backgroundUrl={heroImageUrl.original || heroImageUrl.poster}
      title={name || <Skeleton />}
    >
      <Grid container alignItems="flex-start" className={classes.relative}>
        <Grid item xs={12} className={classes.centered}>
          {logo}
          <MainHeading
            primary={name || <Skeleton />}
            secondary={tagline || <Skeleton />}
          />
          {/* <div className={classes.metadata}>
              {location && <MetadataItem variant="subtitle1" icon={faMapMarkerAlt}>
                {location || <Skeleton />}
              </MetadataItem>}
            </div>
            <div className={classes.statusWrapper}>
              <Typography className={classes.statusBadge}>
                <FontAwesomeIcon
                  className={`${classes.metadataItemIcon} ${classes.marginRight}`}
                  icon={faInfoCircle}
                />
                Status: {status}
              </Typography>
            </div>*/
          }
          {/* socialLinks && <SocialLinks socialLinks={socialLinks} /> */}
        </Grid>
      </Grid>
      {
        (ongoingDeals || []).length > 0 && (
          <>
            {sortBy(ongoingDeals, ['launchedOn']).reverse().map((deal, i) => (
              <DealSnapshot
                companyHero
                showActions={false}
                key={deal.id}
                dealId={deal.id}
                {...deal}
              >
                {can && can['manage'] && (
                  <BrowserLink to={`/forms/company_status_change?company_id=${slug}`} className={classes.blueButton}>
                    <Typography>Change Status</Typography>
                  </BrowserLink>
                )}
                {can && !can['manage'] && (
                  <Button
                    size="small"
                    color="primary"
                    onClick={() => toggleRequestMeetingModal(token)}
                    className={classes.blueButton}
                  >
                    Request Meeting
                  </Button>
                )}
                {can && !can['manage'] && (
                  <Button
                    size="small"
                    disabled={token === null}
                    color="primary"
                    onClick={onFollowUnfollow}
                    className={classes.blueButton}
                  >
                    {following ? 'Unfollow' : 'Follow'}
                  </Button>
                )}
                {can && can['manage'] && (
                  <Button
                    component={BrowserLink}
                    size="small"
                    color="primary"
                    to={`/companies/${slug}/edit`}
                    className={classes.blueButton}
                  >
                    Edit
                  </Button>
                )}
              </DealSnapshot>
            ))}
          </>
        )
      }
    </HeaderHero>
  )
}

export default withStyles(styles)(withMobileDialog()(ProductHero))
