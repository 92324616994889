import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import React from 'react'

export default function File (props) {
  const { onChange, onSubmit, label, required, value } = props

  return <div>
    <Typography variant="body1">{label}</Typography>
    <Button
      variant="contained"
      component="label"
    >
      Choose File <small><i>{required && '(required)'}</i></small>
      <input
        type="file"
        style={{ display: 'none' }}
        onChange={({ target: { files: [file] } }) => {
          onChange(file)
          onSubmit()
        }}
      />
    </Button>
    {value && (typeof (value) === 'string') && <div><img src={value} /> </div>}
  </div>
}
