import { InputLabel, ListItem, Badge, MenuItem, Select, Typography, Grid } from '@material-ui/core'
import Link from '@material-ui/core/Link'
import { withStyles } from '@material-ui/core/styles'
import get from 'lodash.get'
import moment from 'moment'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { Link as BrowserLink } from 'react-router-dom'
import DealSnapshot from './DealSnapshot'
import TitledPaper from '../core/TitledPaper'
import HeaderHero from '../core/HeaderHero'
import MainHeading from '../core/MainHeading'
import TabsContainer from '../layout/TabsContainer'
import { roundedHeroImage, transparentBackgroundContainer, subTitle, blueButton, smallTitle, centeredFlex, blackText } from '../layout/styleComponents'
import {faIndustry} from '@fortawesome/free-solid-svg-icons'
const styles = theme => {
  return {
    transparentBackgroundContainer: transparentBackgroundContainer,
    summary: {
      marginBottom: '2rem',
      padding: '0 1rem'
    },
    badge: {
      width: '100%',
      '& > span': {
        background: theme.palette.error.main,
        top: '1.25rem',
        right: '1.25rem'
      }
    },
    heroCentered: {
      minHeight: '12rem'
    },
    centered: {
      alignItems: 'center',
      justifyContent: 'center'
    },
    relative: {
      position: 'relative'
    },
    text: {
      fontSize: '100%'
    },
    link: {
      marginBottom: '1.5rem',
      textDecoration: 'none',
      width: '100%',
      '& > div': {
        backgroundColor: 'white',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        flexDirection: 'column',
        height: '100%',
        transition: 'all .25s'
      }
    },
    titleWrapper: {
      marginTop: '1rem',
      marginBottom: '1rem',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap'
    },
    openDeals: {
      alignItems: 'stretch',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      margin: 0
    },
    statusWrapper: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    paddedPaper: {
      borderBottom: '.2rem solid black',
      background: 'none',
      boxShadow: 'none',
      margin: '0 auto 4rem',
      padding: '2rem 1rem',
      width: '100%',
      '@media (min-width: 35.5rem)': {
        padding: '2rem'
      },
    },
    marginRight: {
      marginRight: '.5rem'
    },
    title: {
      ...blackText,
      fontSize: '1rem',
      fontWeight: 500,
      margin: '0 auto 1.5rem',
      borderBottom: `.025rem solid ${theme.palette.info.light}`,
      width: '100%',
      textAlign: 'left',
      paddingBottom: '1.5rem'
    },
    card: {
      marginBottom: '2rem'
    },
    dealsClass: {
      backgroundColor: 'white',
      padding: '2rem 1.5rem'
    }
  }
}

const DealIndex = ({
  deals,
  investorStats,
  status,
  setStatus,
  classes
}) => {
  const filteredDeals = status ? deals.filter(deal => deal.company.status === status) : deals

  const tabs = [
    {
      name: 'Open Deals'
    },
    {
      name: 'Closed Deals'
    }
  ]

  const openDeals = deals ? filteredDeals.filter(deal => !deal.closedOn) : []
  const closedDeals = deals ? filteredDeals.filter(deal => Boolean(deal.closedOn)) : []
  const closedDealsByYear = closedDeals.reduce((accumulator, deal) => {
    const year = moment(deal.closedOn).year()
    accumulator[year] = [...(accumulator[year] || []), deal]
    return accumulator
  }, {})
  const pastDealYears = Object.keys(closedDealsByYear).map(Number).sort().reverse()

  const investedCompanies = get(investorStats, 'investedGroupedByYear', []).map((groupedByYear, i) => {
    const companiesLink = groupedByYear.companies.map(c => <Link key={c.id} href={`companies/${c.slug}`} className={classes.text}> {c.name} </Link>)
    return <li key={groupedByYear.total}><Typography className={classes.text}>In <strong>{groupedByYear.year}</strong> you invested <strong>${groupedByYear.total}</strong> with <strong> {companiesLink} </strong></Typography></li>
  })

  return <>
    <HeaderHero className={`${classes.centered} ${classes.heroCentered}`}>
      <Grid container alignItems="flex-start" className={classes.relative}>
        <Grid item xs={12} className={classes.centered}>
          <MainHeading
            primary='Deals'
            secondary={<p>You've invested <strong>{get(investorStats, 'totalInvested')}</strong> in <strong>{get(investorStats, 'countInvestedCompanies')}</strong> deals.</p>}
          />
        </Grid>
      </Grid>
    </HeaderHero>
    <Grid container className={classes.transparentBackgroundContainer}>
      <TabsContainer tabs={tabs} icon={faIndustry} title="Deals">
        {deals && <section className={classes.dealsClass}>
          <div className={classes.openDeals}>
            {openDeals.map(deal => {
              if (deal.unreadConversations && deal.unreadConversations.length > 0) {
                return <Badge className={classes.badge} badgeContent={deal.unreadConversations.length} color="primary">
                  <BrowserLink
                    key={`open-deals-${deal.id}`}
                    to={`/companies/${deal.company.slug}/deals/${deal.id}`}
                    className={classes.link}
                  >
                    <DealSnapshot
                      hideActions
                      key={`open-deals-deal-snapshot-${deal.id}`}
                      {...deal}
                      className={classes.card}
                    />
                  </BrowserLink>
                </Badge>
              } else {
                return <BrowserLink
                  key={`open-deals-${deal.id}`}
                  to={`/companies/${deal.company.slug}/deals/${deal.id}`}
                  className={classes.link}
                >
                  <DealSnapshot
                    hideActions
                    key={`open-deals-deal-snapshot-${deal.id}`}
                    {...deal}
                    className={classes.card}
                  />
                </BrowserLink>
              }
            })}
          </div>
          {pastDealYears.length > 0 && <div className={classes.pastDeals}>
            {pastDealYears.map(year => <div key={year} className={classes.openDeals}>
              {closedDealsByYear[year].map(deal =>{
                if (!deal.closedOn || (deal.closedOn && moment(deal.closedOn) > moment(Date.now()))) {
                  if (deal.unreadConversations && deal.unreadConversations.length > 0) {
                    return <Badge className={classes.badge} badgeContent={deal.unreadConversations.length} color="primary">
                      <BrowserLink
                        key={`past-deal-${deal.id}`}
                        to={`/companies/${deal.company.slug}/deals/${deal.id}`}
                        className={classes.link}
                      >
                        <DealSnapshot
                          hideActions
                          key={`deal-snapshot-${deal.id}`}
                          {...deal}
                          className={classes.card}
                        />
                      </BrowserLink>
                    </Badge>
                  } else {
                    return <BrowserLink
                      key={`past-deal-${deal.id}`}
                      to={`/companies/${deal.company.slug}/deals/${deal.id}`}
                      className={classes.link}
                    >
                      <DealSnapshot
                        hideActions
                        key={`deal-snapshot-${deal.id}`}
                        {...deal}
                        className={classes.card}
                      />
                    </BrowserLink>
                  }
                } else return null
              })}
            </div>)}
          </div>}
        </section>}
        {deals && <section className={classes.dealsClass}>
          {pastDealYears.length > 0 && <div className={classes.pastDeals}>
            {pastDealYears.map(year => <div key={year} className={classes.openDeals}>
              <Typography variant="h3" className={classes.title}>{year}</Typography>
              {closedDealsByYear[year].map(deal => deal.closedOn && moment(deal.closedOn) < moment(Date.now()) && <BrowserLink
                key={`past-deal-${deal.id}`}
                to={`/companies/${deal.company.slug}/deals/${deal.id}`}
                className={classes.link}
              >
                <DealSnapshot
                  hideActions
                  key={`deal-snapshot-${deal.id}`}
                  {...deal}
                  className={classes.card}
                />
              </BrowserLink>)}
            </div>)}
          </div>}
        </section>}
        {investedCompanies}
      </TabsContainer>
    </Grid>
  </>
}

export default withStyles(styles)(DealIndex)
