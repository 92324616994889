import { Typography, Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import { Link } from 'react-router-dom'
import TileHeading from '../core/TileHeading'
import { smallTitle, blueButton } from '../layout/styleComponents'
import { background } from '../layout/colors'
import SocialLinks from '../core/SocialLinks'
import { faHome } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const styles = theme => ({
  tileHeading: {
    width: '100%'
  },
  tileHeadingLeft: {
    textAlign: 'left',
    maxWidth: '90%',
    '& h5': {
      width: '100%',
      textAlign: 'left'
    }
  },
  company: {
    alignSelf: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: '1rem',
    justifyContent: 'space-between',
    position: 'relative',
    width: '100%',
    padding: '0',
    '&:hover .icon': {
      opacity: 1
    },
    '& .icon': {
      border: `1px solid ${theme.palette.success.main}`,
      borderRadius: '50%',
      fontSize: '1rem',
      top: 'calc(50% - .5rem)',
      padding: '.5rem .65rem',
      color: theme.palette.success.main,
      minWidth: 'unset',
      right: '.5rem',
      opacity: 0,
      position: 'absolute',
      '&:hover': {
        background: theme.palette.success.main,
        color: 'white',
        cursor: 'pointer'
      },
    },
  },
  companyFollowed: {
    width: 'auto',
    justifyContent: 'center',
    padding: '1rem 0'
  },
  defaultCompanyIcon: {
    flexShrink: 1
  },
  activeCompany: {
    flexShrink: 1,
    color: theme.palette.primary.main
  },
  companyTop: {
    alignItems: 'flex-start',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    width: '100%'
  },
  companyTopFollowed: {
    '& > a': {
      width: '10rem !important',
      flexWrap: 'wrap',
      marginRight: 0,
      justifyContent: 'flex-start',
      textDecoration: 'none',
      fontWeight: 400,
      '& p': {
        width: '100%',
        textAlign: 'center'
      }
    }
  },
  logo: {
    border: `1px solid ${theme.palette.info.light}`,
    borderRadius: '.5rem',
    height: '6.5rem',
    margin: 0,
    marginRight: '1rem',
    width: '6.5rem',
    backgroundSize: '95% !important'
  },
  logoFollowed: {
    margin: '0 auto'
  },
  bodyContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    alignItems: 'center',
    marginTop: '1rem',
  },
  infoContainer: {
    width: 'calc(100% - 7.5rem)',
    '& > a': {
      display: 'block',
      textDecoration: 'none',
      position: 'relative',
      '& > div': {
        width: 'calc(100% - 3rem)'
      },
      '& h5': {
        textAlign: 'left',
        display: 'block'
      }
    }
  },
  link: {
    ...smallTitle,
    fontSize: '1.15rem',
    fontWeight: 400,
    textTransform: 'capitalize',
    textDecoration: 'none',
    margin: 0,
    wordBreak: 'break-word'
  },
  linkFollowed: {
    fontSize: '1rem',
    marginTop: '.5rem'
  },
  nonDefaultCompanyText: {
    color: 'gray',
    cursor: 'default',
    backgroundColor: '#FFF',
    '&:hover': {
      // you want this to be the same as the backgroundColor above
      backgroundColor: '#FFF'
    }
  },
  makeDefault: blueButton,
  defaultCompanyText: {
    ...blueButton,
    backgroundColor: `${theme.palette.info.light} !important`,
    color: `${theme.palette.success.dark} !important`
  },
  companyDealIndex: {
    marginBottom: '.5rem'
  },
  noPoiner: {
    cursor: 'unset'
  }
})

const CompanyTile = ({
  classes,
  can,
  name,
  logoUrl,
  tagline,
  socialLinks,
  url,
  variant,
  setDefaultCompany,
  defaultCompany,
  companyId,
  white,
  followed,
  dealIndex
}) => {
  const isDefaultCompany = defaultCompany && defaultCompany.id === companyId
  const defaultCompanyText = isDefaultCompany ? 'Default Company' : 'Make default'

  if (followed) {
    return <div className={`${classes.company} ${classes.companyFollowed}`}>
      <header className={`${classes.companyTop} ${classes.companyTopFollowed}`}>
        <Link to={url}>
          <figure style={{background: `url(${logoUrl}) center center no-repeat`, backgroundSize: 'contain'}} className={`${classes.logo} ${classes.logoFollowed}`} />
          <p className={`${classes.link} ${classes.linkFollowed}`}>{name}</p>
        </Link>
      </header>
    </div>

  } else {
    return <div className={`${classes.company} ${dealIndex && classes.companyDealIndex}`}>
      <header className={classes.companyTop}>
        <figure style={{background: `url(${logoUrl}) center center no-repeat`}} className={classes.logo} />
        <section className={classes.infoContainer}>
          {dealIndex ? <TileHeading
            primary={<p className={classes.link}>{name}</p>}
            secondary={tagline}
            className={`${classes.tileHeading} ${classes.tileHeadingLeft}`}
            white
          /> : <Link to={url}>
            <TileHeading
              primary={<p className={classes.link}>{name}</p>}
              secondary={tagline}
              className={classes.tileHeading}
              white
            />
            {variant === 'full' &&
              <>
                {can && can['manage'] && <article className="icon">
                  {!isDefaultCompany && !defaultCompany ? <FontAwesomeIcon title={defaultCompanyText} onClick={() => setDefaultCompany(companyId)} icon={faHome} className={classes.homeIcon}/> : <FontAwesomeIcon title={defaultCompanyText} icon={faHome} className={classes.noPoiner}/>}
                  </article>}
              </>
            }
          </Link>}
          {socialLinks && <SocialLinks blue socialLinks={socialLinks} />}
        </section>
      </header>
    </div>
  }
}

export default withStyles(styles)(CompanyTile)
