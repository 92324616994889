import { Button } from '@material-ui/core'
import React from 'react'

const ImageUploadField = ({ label, value, onChange, classes, ...rest }) => <Button
  variant="contained"
  component="label"
  {...rest}
>
  {label}
  <input
    type="file"
    style={{ display: 'none' }}
    onChange={onChange}
  />
</Button>

export default ImageUploadField
