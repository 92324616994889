import { List, Record } from 'immutable'

export default class QuestionType
  extends Record({
    id: '',
    key: '',
    label: '',
    form_id: '',
    content: '',
    header: '',
    placeholder: '',
    type: '',
    order: 0,
    required: false,
    sectionId: '',
    validateAs: '',
    choices: List(),
    metadataFields: List(),
    deleted: false,
    persisted: false
  }) { }
