import { Button, InputLabel, List, ListItem, MenuItem, Select, TextField, FormControl, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import React from 'react';
import TitledPaper from '../core/TitledPaper';
import { blueButton, textInput, mediumTitle } from '../layout/styleComponents'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons'
import Form from '../form/ListForm'
const styles = theme => ({
  longInput: {
    ...textInput,
    background: 'none',
    marginBottom: '2.5rem',
    '& label': {
      fontSize: '1.5rem',
      padding: 0
    },
    '& input': {
      fontSize: '.85rem',
      padding: '1rem 0',
      color: theme.palette.primary.main
    }
  },
  textInput: {
    ...textInput,
    marginBottom: '2.5rem',
    '& label': {
      fontSize: '1.75rem',
      padding: 0
    },
    '& textarea': {
      fontSize: '.85rem',
      padding: '1rem 0',
      color: theme.palette.primary.main
    },
  },
  actions: {
    marginTop: theme.spacing.unit * 3
  },
  selectedItem: {
    fontWeight: theme.typography.fontWeightMedium
  },
  formControl: {
    width: '100%',
    marginTop: '1rem',
  },
  unselectedItem: {
    fontWeight: theme.typography.fontWeightRegular
  },
  mediumTitle: {
    ...mediumTitle,
    margin: '4rem auto 7rem'
  },
  paper: {
    margin: '0 auto 2rem',
    maxWidth: '45rem',
    width: '100%'
  },
  button: blueButton,
  item: {
    width: '100%'
  },
  select: {
    width: '100%'
  },
})

class EditFundingHistoriesBase extends React.Component {
  constructor(props) {
    super(props)
  }

  renderFundingHistoryLine = () => {
    const {
      roundName,
      setRoundName,
      roundNumber,
      setRoundNumber,
      amount,
      cap,
      date,
      setDate,
      setCap,
      price,
      setPrice,
      setAmount,
      classes
    } = this.props

    return (<Form
      submitBtnText={'Save Changes'}
      onSubmit={() => {
        if (moment(date).isValid()) {
          onSave({ date: moment(date), roundName, roundNumber: parseInt(roundNumber), amount: parseInt(amount), cap: parseInt(cap), price: parseInt(price) })
        } else {
          enqueueSnackbar("Something went wrong with the date. Please check the date", { variant: 'error' })
        }
      }}
      title={<Typography className={classes.mediumTitle}>Edit your funding history</Typography>}
      classes={classes}
    >
      <TextField className={classes.longInput} label="Round Number" fullWidth onChange={(e) => setRoundNumber(e.target.value)} value={roundNumber} />
      <FormControl className={classes.select}>
        <InputLabel htmlFor="age-simple">Round Name</InputLabel>
        <Select
          fullWidth
          onChange={(e) => setRoundName(e.target.value)}
          value={roundName}
          className={classes.longInput}
        >
          <MenuItem value="None">
            <em>None</em>
          </MenuItem>
          <MenuItem value={'Seed'}>Seed</MenuItem>
          <MenuItem value={'Series A'}>Series A</MenuItem>
          <MenuItem value={'Series B'}>Series B</MenuItem>
          <MenuItem value={'Series C'}>Series C</MenuItem>
          <MenuItem value={'Series D'}>Series D</MenuItem>
        </Select>
      </FormControl>
      <TextField className={classes.longInput} required label="Amount" fullWidth onChange={(e) => setAmount(e.target.value)} value={amount} />
      <TextField className={classes.longInput} required label="Date" fullWidth onChange={(e) => setDate(e.target.value)} value={date} />
      <TextField className={classes.longInput} label="Cap" fullWidth onChange={(e) => setCap(e.target.value)} value={cap} />
      <TextField className={classes.longInput} label="Price" fullWidth onChange={(e) => setPrice(e.target.value)} value={price} />
    </Form>
    )
  }

  render () {
    const {
      classes,
      onSave,
      roundName,
      roundNumber,
      enqueueSnackbar,
      cap,
      date,
      setDate,
      price,
      amount
    } = this.props

    return <TitledPaper className={classes.paper} noLogo form key="edit-container">
      {this.renderFundingHistoryLine()}
    </TitledPaper>
  }
}

const EditFundingHistories = withStyles(styles, { withTheme: true })(EditFundingHistoriesBase)

export default EditFundingHistories
