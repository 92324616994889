import gql from 'graphql-tag'

const updateCompany = gql`
  mutation SetDefaultCompany($id: String!) {
    setDefaultCompany(id: $id) {
      id
    }
  }
`

export default updateCompany
