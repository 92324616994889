import { Card, CardContent, CardMedia, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
const titleBase = {
  fontFamily: 'Poppins',
  fontSize: '135%',
  fontWeight: 'bold',
  marginTop: 0,
  textTransform: 'uppercase'
}

const captionBase = {
  fontFamily: 'Poppins',
  fontSize: '65%',
  width: '100%'
}

const styles = theme => ({
  productClass: {
    background: 'white',
    boxShadow: 'none',
    padding: '2rem 1rem',
    '@media(min-width: 35.5rem)': {
      padding: '2rem 1.5rem'
    }
  },
  actionWrapper: {
    marginBottom: '1rem',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  caption: {
    ...captionBase,
    color: 'black'
  },
  content: {
    padding: '1.5rem 0'
  },
  titleWrapper: {
    margin: '1rem auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  card: {
    background: 'none',
    overflow: 'hidden',
    boxShadow: 'none',
    borderRadius: 0
  },
  limitedMedia: {
    margin: 'auto',
    maxWidth: '50rem',
    width: '100%'
  }
})

const TitledCard = props =>{
  const { title, titleVariant, classes, children, actions, media, content, caption } = props
  return (
    <section className={classes.productClass}>
      {title &&<div className={classes.titleWrapper}>
        <small className={classes.caption}>{caption}</small>
        <h2 className={classes.title}>{title}</h2>
      </div>}
      <Card className={classes.card}>
        {children}
        {media && <CardMedia className={classes.limitedMedia}>
          {media}
        </CardMedia>}
        {content && <CardContent className={classes.content}>
          {content}
        </CardContent>}
      </Card>
      {actions && <div className={classes.actions}>
        <Grid item xs={12} sm={5} className={classes.button}>
          {actions}
        </Grid>
      </div>}
    </section>
  )
}

export default withStyles(styles)(TitledCard)
