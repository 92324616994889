import gql from 'graphql-tag'

const getCompanyBySlug = gql`
  query CompanyProfile($id: String!) {
    company(id: $id){
      id
      slug
      description
      status
      name
      logoUrl
      tagline
      location
      url
      classifications
      foundedOn
      howDoYouMakeMoney
      deals {
        id
        number
        goal
        committed
        investorCount
        investorResponse {
          response
          amount
          updatedAt
        }
        launchedOn
        closedOn
        company {
          id
          logoUrl
          name
          tagline
          slug
        }
      }
      productImages {
        id
        original
        thumbnail
        video
        poster
      }
      fundingHistories {
        id
        roundNumber
        roundName
        amount
        cap
        price
        date
      }
      productDescription
      founders {
        id
        slug
        name
        bio
        avatarUrl
      }
      investors {
        id
        user {
          id
          slug
          name
          bio
          avatarUrl
        }
      }
      employeeCount
      whatProblemAreYouSolving
      unfairAdvantage
      twitterUrl
      githubUrl
      facebookUrl
      linkedInUrl
      can {
        manage
      }
      following
      companyUpdates(limit: 15) {
        id
        messages {
          id
          body
          createdAt
          user {
            id
            name
            title
            avatarUrl
            defaultCompany {
              id
              name
            }
          }
        }
      }
    }
  }
`

export default getCompanyBySlug
