import gql from 'graphql-tag';

const submitForm = gql`
  mutation submitForm($formKey: String!, $questionSubmissions: [QuestionSubmissionInput!]!) {
    submitForm(formKey: $formKey, questionSubmissions: $questionSubmissions) {
      id
    }
  }
`;

export default submitForm;