/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable react/jsx-indent-props */
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import { withSnackbar } from 'notistack'
import queryString from 'query-string'
import React, { useState } from 'react'
import { Mutation, Query } from 'react-apollo'
import Skeleton from 'react-loading-skeleton'
import GetIncompleteFormSubmission from '../../../../graphql/queries/getIncompleteFormSubmission'
import Decoders from '../../Decoders'
import ContinueFormSubmissionLater from '../../graphql/mutations/ContinueFormSubmissionLater'
import SubmitForm from '../../graphql/mutations/SubmitForm'
import SubmitQuestion from '../../graphql/mutations/SubmitQuestion'
import GetForm from '../../graphql/queries/get_form'
import Form from '../form/Form'
import FormCompleted from '../form/FormCompleted'
import AppLayout from '../layout/AppLayout'
import CenterColumn from '../layout/CenterColumn'
import Title from '../layout/Title'

const styles = theme => ({
  paper: {
    marginTop: theme.spacing.unit * 3
  },
  button: {
    display: 'block',
    margin: '0 auto 1rem',
    width: '100%'
  },
  buttonLabel: {
    color: 'grey',
    transition: 'all .5s',
    '&:hover': {
      color: 'black'
    }
  }
})

const getHiddenFieldsValuesFromThePath = (searchString) => {
  const parsed = queryString.parse(searchString)
  return parsed
}

const FormContainer = props => {
  const { enqueueSnackbar } = props
  let form

  const { classes, postConfirmation } = props
  const formKey = props.match.params.formKey

  const title = <Title>
    TheSyndicate.com
  </Title>

  const hiddenFieldsKeyValues = getHiddenFieldsValuesFromThePath(props.location.search)

  const [finished, setFinished] = useState(false)
  return (
    <AppLayout>
      <CenterColumn>
        <Query query={GetForm} variables={{ key: formKey }}>
          {({ data, error, loading: formLoading }) => {
            if (!formLoading) {
              form = Decoders.form(data.form)
              return <Query query={GetIncompleteFormSubmission} variables={{ formKey }}>
                {({ data, error, loading: formSubmissionLoading }) => {
                  let questionSubmissions = []
                  if (!formSubmissionLoading) {
                    if (data !== undefined && data !== null && data.incompleteFormSubmission !== null) {
                      questionSubmissions = data.incompleteFormSubmission.questionSubmissions
                    }
                  }
                  return (
                    <Mutation
                      onCompleted={data => {
                        if (data) {
                          enqueueSnackbar('An email has been sent to you.', { variant: 'success' })
                        }
                      }}
                      mutation={ContinueFormSubmissionLater}
                    >
                      {continueFormSubmissionLater => {
                        return <Mutation
                          onError={error => {
                            enqueueSnackbar('There was an error', { variant: 'error' })
                          }}
                          onCompleted={data => {
                            if (data.submitForm) {
                              setFinished(true)
                            }
                          }}
                          mutation={SubmitForm}
                        >
                          {submitForm => {
                            return <Mutation
                              onError={error => {
                                enqueueSnackbar('There was an error', { variant: 'error' })
                              }}
                              onCompleted={data => {
                                console.log('submitQuestion response oncompleted', data)
                              }}
                              mutation={SubmitQuestion}
                            >
                              {submitQuestion => {
                                if (formLoading || formSubmissionLoading) {
                                  return <Skeleton count={10} />
                                } else {
                                  if (!finished) {
                                    return <>
                                      <Form
                                        questions={form ? form.questions : []}
                                        hiddenFieldsKeyValues={hiddenFieldsKeyValues}
                                        title={form.title}
                                        questionSubmissions={questionSubmissions}
                                        formKey={formKey}
                                        continueFormSubmissionLater={() => continueFormSubmissionLater({ variables: { formKey } })}
                                        submitForm={submitForm}
                                        submitQuestion={submitQuestion}
                                        {...props}
                                      />
                                    </>
                                  } else {
                                    return <FormCompleted formKey={formKey} postConfirmation={postConfirmation} />
                                  }
                                }
                              }}
                            </Mutation>
                          }}
                        </Mutation>
                      }}
                    </Mutation>
                  )
                }}
              </Query>
            } else {
              return <Skeleton count={10} />
            }
          }}
        </Query>
      </CenterColumn>
    </AppLayout>
  )
}

export default withStyles(styles)(withSnackbar(FormContainer))
