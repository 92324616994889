import gql from 'graphql-tag'

const passOnDeal = gql`
  mutation passOnDeal($dealId: ID!, $passReason: String!) {
    passOnDeal(dealId: $dealId, passReason: $passReason) {
      id
    }
  }
`

export default passOnDeal
