const veryLightGray = '#ECF1F4'
const sortaLightGray = '#aaaaaa'
const graphite = '#333333'
const mediumGray = '#777'

const brand = {
  canvasColor: veryLightGray,
  appBarColor: graphite,
  iconColor: graphite,
  lightIconColor: sortaLightGray,
  lightTextColor: mediumGray
}

export default brand
