import gql from 'graphql-tag'

const getPendingApplicationPageContent = gql`
  query GetPendingApplicationPageContent {
    pendingApplicationContent {
      pendingContent
    }
  }
`

export default getPendingApplicationPageContent
