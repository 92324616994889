import gql from 'graphql-tag'

const submitSupplementalInvestmentInformation = gql`
  mutation submitSupplementalInvestmentInformation($investorResponseId: ID!, $legalNameOfInvestmentEntity: String!, $accreditedInvestor: Boolean!,  $hasExpertiseInTheSpace: Boolean!) {
    submitSupplementalInvestmentInformation(investorResponseId: $investorResponseId, legalNameOfInvestmentEntity: $legalNameOfInvestmentEntity, accreditedInvestor: $accreditedInvestor, hasExpertiseInTheSpace: $hasExpertiseInTheSpace) {
      id
      legalNameOfInvestmentEntity
    }
  }
`

export default submitSupplementalInvestmentInformation
