import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import { socialLink, socialLinks } from '../layout/styles/BaseProfile'
import {background} from '../layout/colors'

const styles = theme => ({
  socialLinks: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    marginTop: '.5rem'
  },
  socialLink: {
    color: 'black',
    fontFamily: 'Lato',
    fontSize: '14px',
    fontWeight: 'bold',
    margin: '.25rem .75rem 0 0',
    textTransform: 'uppercase'
  },
  icon: {
    color: background,
    fontSize: '14px',
    margin: '.25rem',
    '&:hover': {
      color: 'white'
    }
  },
  iconBlue: {
    color: theme.palette.secondary.main,
    fontSize: '14px',
    margin: '0 .25rem 0 -.1rem',
    '&:hover': {
      color: theme.palette.secondary.dark,
    }
  },
  socialinkContainerBlue: {
    width: '100%'
  },
  centered: {
    margin: '0 auto',
    justifyContent: 'center'
  }
})

const SocialLinks = ({ classes, socialLinks, blue, centered }) => {
  if (socialLinks) {
    return (
      <div className={`${classes.socialLinks} ${blue && classes.socialinkContainerBlue} ${centered && classes.centered}`}>
        {socialLinks.map((socialLink, i) => <Link className={classes.socialLink} href={socialLink.url} key={`socialLinks-${i}`}>
          <i className={`fa fa-${socialLink.icon && socialLink.icon.iconName} ${blue ? classes.iconBlue : classes.icon}`}></i>
        </Link>)}</div>
    )
  } else {
    return null
  }
}

export default withStyles(styles)(SocialLinks)
