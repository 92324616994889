import { Checkbox, FormControl, FormControlLabel, FormGroup, Typography } from '@material-ui/core';
import { List } from "immutable";
import React from "react";

class MultiSelect extends React.Component {
  state = {
    options: List()
  }

  render () {
    const { id, value, label, content, choices, onChange, required } = this.props;

    const handleChange = name => event => {
      let options;
      if (!event.target.checked) {
        options = this.state.options
        options = options.filter(elem => elem !== name)
        this.setState({ options });
      } else {
        options = this.state.options
        options = options.push(event.target.value)
        this.setState({ options });
      }

      onChange(options)

    };

    const options = choices.map((choice, i) => {
      return (
        <FormControlLabel style={{fontSize: '85%'}} key={choice.get('id')} label={choice.get('label')} control={
          <Checkbox checked={(value || List()).includes(choice.get("id"))} onChange={handleChange(choice.get('id'))} value={choice.get('id')} />
        }
        />
      );
    });

    return <FormControl component="fieldset">
      <Typography variant="body1" >
        {label} <small><i>{required && '(required)'}</i></small>
      </Typography>
      <FormGroup
        value={value}
        aria-label={label}
        name={label}
      >
        {options}
      </FormGroup>
    </FormControl>
  }
}

export default MultiSelect;
