import gql from 'graphql-tag'

const getPostDealText = gql`
  query getPostDealText {
    postDealText{
      postDealText
    }
  }
`

export default getPostDealText
