import gql from 'graphql-tag'

const getNotifications = gql`
  query Notifications {
    notifications {
      id
      title
      body
      createdAt
      notifiable {
        ... on MeetingRequest {
          id
          user {
            id
            slug
            avatarUrl
            name
          }
        }
        ... on CompanyUpdateDiscussion {
          id
          company {
            id
            slug
            name
            logoUrl
          }
          discussion {
            id
          }
        }
        ... on UserUpdateDiscussion {
          id
          user {
            id
            slug
            name
            avatarUrl
          }
          discussion {
            id
          }
        }
        __typename
      }
    }
  }
`

export const getUnreadConversationsAndUpdates = gql`
query Profile {
  profile {
    id
    unreadNotifications {
      id
      createdAt
      notifiable {
        ... on MeetingRequest {
          id
          user {
            id
            slug
            avatarUrl
            name
          }
        }
        ... on CompanyUpdateDiscussion {
          id
          discussion {
            id
            messages {
              id
              body
              createdAt
              user {
                id
                name
                avatarUrl
                defaultCompany {
                  id
                  name
                }
              }
            }
          }
        }
        ... on UserUpdateDiscussion {
          id
          discussion {
            id
            messages {
              id
              body
              createdAt
              user {
                id
                name
                avatarUrl
                defaultCompany {
                  id
                  name
                }
              }
            }
          }
        }
        __typename
      }
    }
    unreadDealConversations {
      id
      body
      discussionId
      createdAt
      conversation {
        id
        companySlug
      }
      user {
        id
        avatarUrl
        name
      }
    }
    unreadConversations {
      id
      body
      conversation {
        id
        companySlug
      }
      discussionId
      createdAt
      user {
        id
        avatarUrl
        name
      }
    }
  }
}`

export default getNotifications
