import ChoiceType from './types/ChoiceType'
import FormType from './types/FormType'
import QuestionType from './types/QuestionType'

let Decoders = {
  choices: function (choicesArray) {
    return choicesArray.map(q => new ChoiceType({
      id: q.id,
      label: q.label
    }))
  },
  questions: function (questionsArray) {
    return questionsArray.map(q => new QuestionType({
      id: q.id,
      label: q.label,
      placeholder: q.placeholder,
      key: q.key,
      order: q.order,
      header: q.header,
      required: q.required,
      type: q.questionType,
      choices: Decoders.choices(q.choices),
      content: q.content
    }))
  },
  form: function (dataFromApi) {
    return new FormType({
      id: dataFromApi.id,
      title: dataFromApi.title,
      questions: Decoders.questions(dataFromApi.questions)
    })
  }
}

export default Decoders
