import { Typography } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { Query } from 'react-apollo'
import getDeals from '../../graphql/queries/getDeals'
import getInvestorStats from '../../graphql/queries/getInvestorStats'
import { withSnackbar } from 'notistack'
import { Redirect } from 'react-router'
import DealIndex from '../deal/DealIndex'
import AppLayout from '../layout/AppLayout'
import CenterColumn from '../layout/CenterColumn'
import Title from '../layout/Title'
import get from 'lodash.get'

function DealIndexRoute ({ match, history, enqueueSnackbar }) {
  const title = <Title>Deals</Title>
  const [status, setStatus] = useState('')
  useEffect(() => {
    document.title = "The Syndicate - Deals"
  }, []);
  return <Query query={getInvestorStats} fetchPolicy="network-only">
    {({ loading, data, error }) => {
      let investorStats = {}

      if (!loading) {
        investorStats = get(data, 'investorStats')
      }

      if (error) {
        if (error.message && error.message.includes('not an investor')) {
          enqueueSnackbar("You don't have access to view this.", { variant: 'error' })
          return <Redirect
            to={`/login`}
          />
        } else {
          return <Typography>Error...</Typography>
        }
      }

      return <Query query={getDeals} fetchPolicy="network-only">
        {({ loading, error, data, refetch, networkStatus }) => {
          if (error) {
            return <Typography>Error...</Typography>
          }

          let deals

          if (!loading) {
            deals = data.deals
          }

          return <AppLayout title={title}>
            <CenterColumn>
              <DealIndex status={status} setStatus={setStatus} investorStats={investorStats} deals={deals} />
            </CenterColumn>
          </AppLayout>
        }}
      </Query>
    }}
  </Query>
}

export default withSnackbar(DealIndexRoute)
