import { Checkbox, FormControl, FormControlLabel, FormGroup } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React from 'react'

const styles = theme => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  selectedClass: {
    border: '1px solid #ddd',
    background: '#eee',
    fontSize: '12px',
    display: 'inline-block',
    padding: '5px',
    margin: '0 5px',
    cursor: 'move',
    borderRadius: '2px'
  }
})

function Tags (props) {
  const {
    value,
    initialTags,
    handleChange,
    label,
    required,
    content
  } = props

  const options = initialTags.map((tag, i) => {
    return (
      <FormControlLabel key={tag.id} label={tag.title} control={
        <Checkbox checked={value ? value.includes(tag.id) : false} onChange={handleChange(tag.title)} value={tag.id} />
      }
      />
    )
  })

  return <> <FormControl component="fieldset">
    <Typography variant="body1">
      {label} <small><i>{required && '(required)'}</i></small>
    </Typography>
    <Typography variant="body1">
      <small>{content}</small>
    </Typography>
    <FormGroup
      value={value}
      aria-label={label}
      name={label}
    >
      {options}
    </FormGroup>
  </FormControl>
  </>
}

export default withStyles(styles, { withTheme: true })(Tags)
