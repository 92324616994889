import gql from 'graphql-tag'

const getUserUpdate = gql`
  query UserUpdate($userSlug: String!, $id: ID!) {
    user(id: $userSlug){
      id
      slug
      can {
        manage
      }
      userUpdate(id: $id) {
        id
        messages {
          id
          body
          createdAt
          user {
            id
            name
            title
            avatarUrl
            defaultCompany {
              id
              name
            }
          }
        }
      }
    }
  }
`

export default getUserUpdate
