import gql from 'graphql-tag'

const getPostCompanyText = gql`
  query getPostCompanyText {
    postCompanyText {
      postCompanyText
    }
  }
`

export default getPostCompanyText
