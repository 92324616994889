import gql from 'graphql-tag'

const getStartupCategories = gql`
  query getStartupCategories {
    startupCategories {
      id
      title
    }
  }
`

export default getStartupCategories
