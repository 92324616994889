import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'

const styles = theme => ({
  title: {
    display: 'flex',
    flexGrow: 1,
    marginBottom: 0,
    padding: '1.75rem',
    paddingLeft: 0,
    position: 'relative',
    width: '25%'
  },
  titleWithBefore: {
    display: 'flex',
    flexGrow: 1,
    marginBottom: 0,
    padding: '1.75rem',
    position: 'relative',
    width: '25%',
    paddingLeft: 0
  }
})

const Title = props => {
  const { children, classes } = props
  return <Typography variant="h2" color="inherit" className={`${props.home ? classes.title : classes.titleWithBefore}`}>
    {children}
  </Typography>
}

export default withStyles(styles)(Title)
