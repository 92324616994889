import { AppBar, Button, Dialog, DialogContent, FormControl, FormControlLabel, IconButton, Radio, RadioGroup, Slide, TextField, Toolbar, Typography, withMobileDialog } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import { withSnackbar } from 'notistack'
import React, { useState, useEffect } from 'react'

const styles = theme => ({
  modal: {
    '& .MuiDialog-paperFullWidth': {
      borderRadius: '.5rem',
      maxWidth: '45rem',
      height: 'unset',
      maxHeight: '80%',
      width: '95%',
    },
    '& .MuiDialogContent-root': {
      padding: '0 1.5rem 2rem',
      overFlow: 'hidden'
    }
  },
  description: {
    margin: '.5rem 0',
    fontSize: '100%'
  },
  appBarTitle: {
    fontFamily: 'Poppins',
    fontSize: '125%',
    fontWeight: 'bold',
    flex: 1,
    textTransform: 'uppercase'
  },
  appBarIcon: {
    marginRight: '-1rem'
  },
  buttonMarginTop: {
    marginTop: '1rem'
  },
  appBar: {
    backgroundColor: theme.palette.info.light,
    marginBottom: '1rem',
    position: 'relative',
    paddingRight: '0 !important',
    padding: '.5rem 1.5rem'
  },
  button: {
    backgroundColor: '#2D49B6',
    boxShadow: 'inset 0 0 0 0 #4f4f4f, 1px 1px 0 0 #4f4f4f',
    color: 'white',
    display: 'block',
    fontFamily: 'Poppins',
    fontSize: '70%',
    fontWeight: 'bold',
    margin: ' 2rem auto 0',
    textAlign: 'center',
    padding: '1rem',
    '&:hover': {
      color: 'black'
    }
  },
  form: {
    margin: '2rem 0',
    width: '100%'
  },
  toolbar: {
    background: 'none',
    justifyContent: 'flex-end',
    padding: 0
  },
  content: {
    padding: 0
  }
})

function Transition (props) {
  return <Slide direction="up" {...props} />
}

function PassAllocationModalRoute ({
  companySlug,
  fullScreen,
  match: {
    params: { id }
  },
  onClose,
  enqueueSnackbar,
  submitSupplementalInvestmentInformation,
  descriptionOfLegalName,
  passOnDeal,
  supplementalModalIsOpen,
  classes
}) {
  const handleSubmit = () => {
    submitSupplementalInvestmentInformation(legalNameOfInvestmentEntity, accreditedInvestor === 'true', hasExpertiseInTheSpace === 'true')
    enqueueSnackbar('Your interest has been noted.', { variant: 'success' })
  }

  const [legalNameOfInvestmentEntity, setLegalNameOfInvestmentEntity] = useState('')
  const [accreditedInvestor, setAccreditedInvestor] = useState('true')
  const [hasExpertiseInTheSpace, setHasExpertiseInTheSpace] = useState('true')
  useEffect(() => {
    document.title = "The Syndicate - Suplemental Investment Information Modal"
  }, []);
  return (
    <Dialog
      TransitionComponent={Transition}
      open={supplementalModalIsOpen}
      fullWidth
      maxWidth="md"
      onClose={onClose}
      scroll="paper"
      className={classes.modal}
    >
      <AppBar
        color="default"
        elevation={0}
        className={classes.appBar}
      >
        <Toolbar
          className={classes.toolbar}
        >
          <Typography variant="h6" color="inherit" className={classes.appBarTitle}>
            Supplemental Information about your Request Allocation
          </Typography>
          <IconButton
            color="inherit"
            onClick={onClose}
            aria-label="Close"
            className={classes.appBarIcon}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent className={classes.content}>
        <Typography variant="subtitle1" className={classes.description}>
          {descriptionOfLegalName}
        </Typography>
        <TextField
          required
          fullWidth
          label="Legal Name of Investment Entity"
          value={legalNameOfInvestmentEntity}
          onChange={evt => setLegalNameOfInvestmentEntity(evt.target.value)}
          classes={classes}
        />

        <FormControl className={classes.form}>
          <Typography variant="h2" className={classes.appBarTitle}>
            Are you an accredited investor?
          </Typography>
          <RadioGroup
            value={accreditedInvestor}
            onChange={(event) => {
              setAccreditedInvestor(event.target.value)
            }}
          >
            <FormControlLabel
              className={classes.option}
              label='Yes, I am an accredited investor.'
              value={'true'}
              control={<Radio />}
            />
            <FormControlLabel
              className={classes.option}
              label='No, I am not an accredited investor.'
              value={'false'}
              control={<Radio />}
            />
          </RadioGroup>
        </FormControl>
        <FormControl>
          <Typography variant="h2" className={classes.appBarTitle}>
            Do you have expertise in the space?
          </Typography>
          <RadioGroup
            value={hasExpertiseInTheSpace}
            onChange={(event) => {
              setHasExpertiseInTheSpace(event.target.value)
            }}
          >
            <FormControlLabel
              className={classes.option}
              label='Yes'
              value={'true'}
              control={<Radio />}
            />
            <FormControlLabel
              className={classes.option}
              label='No'
              value={'false'}
              control={<Radio />}
            />
          </RadioGroup>
        </FormControl>
        <div>
          <Button
            onClick={() => {
              handleSubmit()
              onClose()
            }}
            className={`${classes.button} ${classes.buttonMarginTop}`}
            variant="contained"
          >
            Send
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default withSnackbar(withStyles(styles)(withMobileDialog()(PassAllocationModalRoute)))
