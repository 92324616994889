import gql from 'graphql-tag'

const unfollowUser = gql`
  mutation unfollowUser($id: ID!) {
    unfollowUser(id: $id) {
      id
    }
  }
`

export default unfollowUser
