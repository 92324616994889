import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AppBar,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Slide,
  Snackbar,
  Toolbar,
  Typography,
  withMobileDialog,
  Grid
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import CreateUserUpdateForm from '../core/CreateUserUpdateForm';
import MaxLengthTextField from '../core/MaxLengthTextField';
import { badges, socialLink, socialLinks, tagIcon } from '../layout/styles/BaseProfile';
import { metadata, metadataItem } from '../layout/styles/metadata';
import UserCompaniesHero from './UserCompaniesHero';
import UserExperiencesHero from './UserExperiencesHero';
import UserHero from './UserHero';
import UserUpdatesHero from './UserUpdatesHero';
import Markdown from '../core/Markdown'
import { transparentBackgroundContainer, blackText, blueButton } from '../layout/styleComponents'
import { faLongArrowRight, faTimes } from '@fortawesome/pro-light-svg-icons'

const truncateWithEllipses = (text, max) =>
  text.substr(0, max - 1) + (text.length > max ? '...' : '')

function Transition (props) {
  return <Slide direction="up" {...props} />;
}

const styles = theme => ({
  modal: {
    '& .MuiDialog-paperFullWidth, & .MuiDialog-paperFullScreen': {
      borderRadius: '.5rem',
      maxWidth: '45rem',
      maxHeight: '80%',
      width: '95%',
      height: 'unset',
      margin: 'auto'
    },
    '& .MuiDialogContent-root': {
      padding: '0 1.5rem 2rem',
    }
  },
  notification: {
    position: 'absolute',
    zIndex: 10,
    top: 0,
    left: 0,
    width: '100%',
    padding: '.5rem',
    background: 'rgba(0,0,0,.75)',
    color: 'white',
    '& p': {
      fontSize: '16px',
      margin: 0
    }
  },
  closeIcon: {
    position: 'absolute',
    top: '.75rem',
    right: '.75rem',
    cursor: 'pointer',
    zIndex: 10
  },
  transparentBackgroundContainer: transparentBackgroundContainer,
  commentIcon: {
    marginRight: '0.5rem'
  },
  appBarTitle: {
    ...blackText,
    margin: 0,
    fontSize: '1.25rem',
    flex: 1,
    textAlign: 'left'
  },
  appBarIcon: {
    marginRight: '-1rem'
  },
  appBar: {
    backgroundColor: theme.palette.info.light,
    margin: 0,
    width: '100%',
    marginBottom: '1rem',
    position: 'relative',
    padding: '.5rem 1.5rem',
    paddingRight: '.5rem !important'
  },
  toolbar: {
    background: 'none',
    justifyContent: 'flex-end',
    padding: 0
  },
  requestMeetingMessageField: {
    marginBottom: "1rem"
  },
  paper: {
    marginTop: theme.spacing.unit * 3,
  },
  visitorWarning: {
    color: 'gray',
    textAlign: 'right',
  },
  avatar: {
    display: 'block',
    margin: 'auto'
  },
  actions: {
    textAlign: 'right'
  },
  centered: {
    textAlign: 'center'
  },
  horizontalLogos: {
    display: 'flex',
    flexDirection: 'row',
  },
  button: {
    ...blueButton,
    margin: '1.5rem auto'
  },
  metadata,
  metadataItem,
  tagIcon,
  badges: { ...badges },
  socialLinks: socialLinks(theme),
  socialLink: socialLink(theme)
})

class UserProfile extends React.Component {
  notification = React.createRef();

  state = {
    createUserUpdateOpen: false,
    isOpenRequestMeetingModal: false,
    message: '',
  }

  handleCreateUserUpdateOpen = () => {
    this.setState({ createUserUpdateOpen: true })
  }

  handleCreateUserUpdateClose = () => {
    this.setState({ createUserUpdateOpen: false })
  }

  openRequestMeetingModal = () => {
    this.setState({
      isOpenRequestMeetingModal: true
    })
  }

  closeRequestMeetingModal = () => {
    this.setState({
      isOpenRequestMeetingModal: false
    })
  }

  toggleRequestMeetingModal = token => () => {
    this.setState({
      isOpenRequestMeetingModal: !this.state.isOpenRequestMeetingModal,
    })
    if (!token) {
      const location = {
        pathname: '/signup/user/onboarding',
        state: {
          notice: 'To request a meeting you need to sign up or log in.',
        },
      }
      this.props.history.push(location)
    }
  }

  hideNotification () {
    this.notification.current.style.display = 'none'
  }

  render () {
    const {
      unauthorized,
      history,
      slug,
      token,
      name,
      classes,
      requestMeeting,
      avatarUrl,
      location,
      badges,
      companies,
      investments,
      linkedInUrl,
      twitterUrl,
      githubUrl,
      facebookUrl,
      memberSince,
      userUpdates,
      userExperiences,
      title,
      url,
      phone,
      bio,
      can,
      followedCompanies,
      investor,
      isInvestor,
      following,
      refetch,
      onFollowUnfollow,
      isOpenSnackbar,
      setSnackbarOpen,
      loading,
      fullScreen,
      setDefaultCompany,
      defaultCompany,
      hasPendingApplication,
      pendingApplicationContent,
      deleteUserExperience
    } = this.props

    const MetadataItem = ({ icon, children }) => (
      <Typography variant="subtitle1" className={classes.metadataItem}>
        {icon && (
          <FontAwesomeIcon icon={icon} className={classes.tagIcon} />
        )}
        {children}
      </Typography>
    )

    let createUserUpdateButton
    if (can && can.manage) {
      createUserUpdateButton = (
        <>
          <Button size="small" color="primary" onClick={this.handleCreateUserUpdateOpen}>
            Create Update
          </Button>
          <Dialog
            fullScreen={fullScreen}
            fullWidth
            maxWidth="md"
            open={this.state.createUserUpdateOpen}
            onClose={this.handleCreateUserUpdateClose}
            TransitionComponent={Transition}
            className={classes.modal}
          >
            <AppBar
              color="default"
              elevation={0}
              className={classes.appBar}
            >
              <Toolbar className={classes.toolbar}>
                <Typography variant="h6" color="inherit" className={classes.appBarTitle}>
                  Create a new update
                </Typography>
                <IconButton className={classes.appBarIcon} color="inherit" onClick={this.handleCreateUserUpdateClose} aria-label="Close">
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <DialogContent>
              <CreateUserUpdateForm
                userSlug={slug}
                className={classes.createUserUpdateForm}
                onSave={() => {
                  refetch();
                  this.handleCreateUserUpdateClose();
                }}
              />
            </DialogContent>
          </Dialog>
        </>
      )
    }
    
    return (
      <>
        { hasPendingApplication && pendingApplicationContent && <div ref={this.notification} className={`${classes.centered} ${classes.notification}`}>
          <Markdown className={classes.heroContent}>{pendingApplicationContent}</Markdown>
          <FontAwesomeIcon onClick={this.hideNotification.bind(this)} icon={faTimes} className={classes.closeIcon}></FontAwesomeIcon>
        </div> }
        <UserHero
          can={can} investor={investor} toggleRequestMeetingModal={this.toggleRequestMeetingModal}
          loading={loading}
          {...this.props}
        />
        <Grid container className={classes.transparentBackgroundContainer}>
          {((companies || []).length > 0 ||
          (followedCompanies || []).length > 0 ||
          (investments || []).length > 0) && (
            <UserCompaniesHero
              can={can}
              setDefaultCompany={setDefaultCompany}
              investments={investments}
              companies={companies}
              followedCompanies={followedCompanies}
              defaultCompany={defaultCompany}
            />
          )}
        </Grid>
        <Grid container className={classes.transparentBackgroundContainer}>
          {((!loading && can['manage']) || ((userUpdates || []).length > 0)) && <UserUpdatesHero
            history={history}
            fullScreen={fullScreen}
            refetch={refetch}
            handleCreateUserUpdateClose={this.handleCreateUserUpdateClose}
            handleCreateUserUpdateOpen={this.handleCreateUserUpdateOpen}
            createUserUpdateOpen={this.state.createUserUpdateOpen}
            userUpdates={userUpdates}
            slug={slug}
            can={can}
            loading={loading} />}
        </Grid>
        <Grid container className={classes.transparentBackgroundContainer}>
          <UserExperiencesHero deleteUserExperience={deleteUserExperience} can={can} userExperiences={userExperiences} loading={loading} slug={slug} />
        </Grid>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={isOpenSnackbar}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">Meeting requested</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={() => setSnackbarOpen(false)}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
        <Dialog
          fullScreen={fullScreen}
          fullWidth
          maxWidth="md"
          open={this.state.isOpenRequestMeetingModal}
          onClose={this.closeRequestMeetingModal}
          TransitionComponent={Transition}
        >
          <AppBar
            color="default"
            elevation={0}
            className={classes.appBar}
          >
            <Toolbar className={classes.toolbar}>
              <Typography variant="h6" color="inherit" className={classes.appBarTitle}>
                Request a meeting
              </Typography>
              <IconButton
                className={classes.appBarIcon}
                color="inherit"
                onClick={this.closeRequestMeetingModal}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <Typography variant="subtitle1">
              Send a message to this person.
            </Typography>
            <MaxLengthTextField
              maxLength={250}
              label="Your message"
              value={this.state.message}
              onChange={evt => this.setState({ message: evt.target.value })}
              classes={classes}
              className={classes.requestMeetingMessageField}
            />
            <div className={classes.actions}>
              <Button
                className={classes.button}
                variant="contained"
                onClick={() => {
                  requestMeeting(this.state.message)
                  this.toggleRequestMeetingModal(token)()
                  this.setState({ message: '' })
                }}
              >
                Request Meeting
                <FontAwesomeIcon icon={faLongArrowRight} />
            </Button>
            </div>
          </DialogContent>
        </Dialog>
      </>
    )
  }
}

export default withStyles(styles)(withMobileDialog()(UserProfile))
