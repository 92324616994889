import gql from 'graphql-tag'

const getDeal = gql`
  query Deal($companySlug: String!, $id: ID!) {
    company(id: $companySlug){
      id
      slug
      name
      tagline
      logoUrl
      productImages {
        id
        original
        thumbnail
        video
        poster
      }
      deal(id: $id){
        id
        unreadConversations {
          id
          body
          discussionId
          createdAt
          user {
            id
            avatarUrl
            name
          }
        }
        launchedOn
        closedOn
        notes
        memo
        risks
        keyRisks
        webinarUrl
        number
        goal
        committed
        investorCount
        investorResponse {
          id
          response
          amount
          updatedAt
        }
        dealDiscussions {
          id
          createdAt
          discussion {
            id
            messages {
              id
              body
              createdAt
              user {
                id
                name
                avatarUrl
              }
            }
          }
        }
      }
    }
  }
`

export default getDeal
