import { Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import { blueRibbon, bigStone } from '../layout/colors'

const styles = theme => ({
  paper: {
    background: 'transparent',
    boxShadow: 'unset',
    padding: '2rem',
    overflow: 'hidden',
    borderRadius: 0,
    '& h2, h3, h4, h5, h6': {
      color: blueRibbon,
      '& a': {
        color: bigStone
      }
    }
  }
})

const PaddedPaper = ({ classes, children, className }) => <Paper className={`${classes.paper} ${className}`}>
  {children}
</Paper>

export default withStyles(styles)(PaddedPaper)
