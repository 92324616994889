import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'
import { MoveFocusInside } from 'react-focus-lock'
import { withSnackbar } from 'notistack'
import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import { white, tealBlue } from './layout/colors'
import { uppercaseSmallDarkText, blueButton, label, formButton } from './layout/styleComponents'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons'
import { faCaretUp, faCaretDown} from '@fortawesome/pro-solid-svg-icons'
import debounce from './helpers/debounce'

const styles = theme => ({
  button: blueButton,
  finishLater: {
    ...label,
    width: '100%',
    textAlign: 'center',
    border: 'none',
    marginTop: '1.5rem',
    padding: 0
  },
  darkText: uppercaseSmallDarkText,
  label: {
    ...label,
    margin: '0 .35rem'
  },
  disabledButton: {
    ...formButton,
    color: tealBlue
  },
  actions: {
    backgroundColor: white,
    boxShadow: `0 -0rem 1.5rem -.4rem ${tealBlue}`,
    alignItems: 'center',
    bottom: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    left: 0,
    padding: '2rem .5rem',
    position: 'absolute',
    right: 0,
    '@media (max-height: 38rem)': {
      padding: '1rem .5rem',
    }
  },
  footerActions: {
    alignItems: 'center',
    display: 'flex'
  },
  footerActionsEnd: {
    justifyContent: 'flex-end'
  },
  footerActionsStart: {
    justifyContent: 'flex-start'
  },
  formButton: formButton,
  formContainer: {
    paddingBottom: '10rem'
  },
  innerFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    maxWidth: '60rem',
    width: '100%'
  }
})

/** Typeform component that renders each component of a form */
class TypeForm extends React.Component {
  /** constructor */
  constructor (props) {
    super(props)

    /** Initial State */
    this.state = {
      current: 0,
      smallHeight: false,
    }

    this.checkSize = this.checkSize.bind(this)

    /** Styles */
    this.styles = {
      tfShow: {
        display: 'block'
      },
      tfHide: {
        display: 'none'
      }
    }

    /** Binding this to methods */
    this.incState = this.incState.bind(this)
    this.handleEnter = this.handleEnter.bind(this)
    this.decState = this.decState.bind(this)
    this.isFirstComponent = this.isFirstComponent.bind(this)
    this.isLastComponent = this.isLastComponent.bind(this)
  }

  /** Set className for component to show/hide */
  setClass (element, tfStyle) {
    if (!element) { return null }
    return (
      <div key={element.key} style={tfStyle}>
        {element}
      </div>)
  }

  /** Get the current component to show on screen */
  getCurrentView (children) {
    let allChildren = []
    if (!this.props.showReviewView) {
      allChildren = React.Children.map(children, (child, index) => {
        let currentChild = this.setClass(child, this.styles.tfHide)
        if (index === this.state.current) {
          const currentChildInner = this.setClass(child, this.styles.tfShow)
          currentChild = <MoveFocusInside key={index}>{currentChildInner}</MoveFocusInside>
        }
        return currentChild
      })
    }
    /** If all elements are shown then conditionally show a review screen */
    if (this.props.showReviewView || this.isLastComponent()) {
      allChildren.pop()
      React.Children.map(children, child =>
        allChildren.push(this.setClass(child, this.styles.tfShow))
      )
      if (this.props.completionText) {
        allChildren.push(<div className="form-completion-text">{this.props.completionText}</div>)
      }
      this.props.updateShowReviewView(true)
    }
    return allChildren
  }

  /** Increment State counter */
  incState () {
    if (this.canHitNext()) {
      const { submitQuestion, questions } = this.props
      const childrenLength = questions.filter(question => question.type !== 'hidden_field').length
      if (this.state.current < childrenLength) {
        this.props.nextBtnOnClick(this.state.current)

        const current = this.state.current + 1
        this.setState({
          current
        })
        this.props.setQuestionIndex(current)
      }
    } else {
      this.props.enqueueSnackbar('This question is required.', { variant: 'error' })
    }
  }

  handleEnter (e) {
    console.log(e.shiftKey)
    if (e.shiftKey) return
    const code = e.keyCode || e.which
    if(code === 13 && !e.shiftKey) {
      if (this.isLastComponent()) this.props.onSubmit()
      else this.incState()
    }
  }

  /** Decrement State counter */
  decState () {
    if (this.state.current > 0) {
      const current = this.state.current - 1
      this.setState({
        current
      })
      this.props.setQuestionIndex(current)
    }
    this.props.backBtnOnClick()
  }

  /** Check if last component */
  isFirstComponent () {
    return this.state.current === 0
  }

  /** Check if last component */
  isLastComponent () {
    const { questions } = this.props
    const childrenLength = questions.filter(question => question.type !== 'hidden_field').length
    if (this.state.current === childrenLength) {
      this.setState({current: this.state.current - 1})
      return true
    } else {
      return this.props.showReviewView
        ? this.state.current === childrenLength
        : this.state.current === (childrenLength - 1)
    }
  }

  canHitNext () {
    const index = this.state.current
    const question = this.props.questions[index]
    const value = this.props.state.questions[question.id]

    if (question.required) {
      if (isEmpty(value) || isNil(value)) {
        return false
      } else {
        return true
      }
    }
    return true
  }

  checkSize () {
    return this.setState({smallHeight: window.innerWidth > 535})
  }

  componentDidMount() {
    console.log('asdasd')
    this.checkSize()
    window.addEventListener('resize', this.checkSize)
    document.addEventListener('keyup', this.handleEnter)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkSize)
    document.removeEventListener('keyup', this.handleEnter)
  }

  /** render the typeform */
  render () {
    const {classes, questions, continueFormSubmissionLater, formKey} = this.props
    return (
      <div className={`form-container`}>
        {this.getCurrentView(this.props.children({ next: this.incState, back: this.decState }))}
        <footer className={classes.actions}>
          <section className={classes.innerFooter}>
            <article className={`${classes.footerActions} ${classes.footerActionsStart}`}>
              {
                this.props.showReviewView || this.isLastComponent()
                  ? <Button
                    type="submit"
                    variant="contained"
                    size="medium"
                    onClick={this.props.onSubmit}
                    className={classes.button}
                  >
                    {this.props.submitBtnText}
                    <FontAwesomeIcon icon={faLongArrowRight} />
                  </Button>
                  : <Button
                    variant="contained"
                    size="medium"
                    onClick={this.incState}
                    className={classes.button}
                  >
                    {this.props.nextBtnText}
                    <FontAwesomeIcon icon={faLongArrowRight} />
                  </Button>
              }
              {this.state.smallHeight && <span className={classes.label}>Or</span>}
              {this.state.smallHeight && <span className={classes.darkText}>Press enter</span>}
            </article>
            <article className={`${classes.footerActions} ${classes.footerActionsEnd}`}>
              <span className={classes.label}>Question {this.state.current + 1}/{questions.filter(question => question.type !== 'hidden_field').length}</span>
              {this.state.smallHeight && <div>
                {
                  !this.isFirstComponent()
                    ? <Button
                    onClick={this.decState}
                    variant="contained"
                    size="medium"
                    className={classes.formButton}
                  >
                    <FontAwesomeIcon icon={faCaretUp} />
                  </Button> : <Button
                      variant="contained"
                      size="medium"
                      disabled
                      classes={{ disabled: classes.disabledButton }}
                      className={classes.formButton}
                    >
                      <FontAwesomeIcon icon={faCaretUp} />
                    </Button>
                }
                {
                  !this.props.showReviewView && !this.isLastComponent()
                    ? <Button
                    variant="contained"
                    size="medium"
                    onClick={this.incState}
                    className={classes.formButton}
                  >
                    <FontAwesomeIcon icon={faCaretDown} />
                  </Button> : <Button
                    variant="contained"
                    size="medium"
                    disabled
                    className={classes.formButton}
                    classes={{ disabled: classes.disabledButton }}
                  >
                    <FontAwesomeIcon icon={faCaretDown} />
                  </Button>
              }
              </div>}
            </article>
            {formKey !== 'wait_list' && <Button
            variant="outlined"
            className={classes.finishLater}
            onClick={() => continueFormSubmissionLater()}
            >
              Can't finish now? Email yourself a link to continue filling this out later.
            </Button>}
          </section>
        </footer>
      </div>
    )
  }
}

/** Validating propTypes */
TypeForm.propTypes = {
  backBtnClass: PropTypes.string,
  backBtnOnClick: PropTypes.func,
  backBtnText: PropTypes.string,
  children: PropTypes.func.isRequired,
  completionText: PropTypes.string,
  nextBtnClass: PropTypes.string,
  nextBtnOnClick: PropTypes.func,
  nextBtnText: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  showReviewView: PropTypes.bool,
  submitBtnClass: PropTypes.string,
  submitBtnText: PropTypes.string,
  classes: PropTypes.object
}

/** Default Props */
TypeForm.defaultProps = {
  backBtnOnClick: () => { },
  backBtnText: 'Back',
  nextBtnOnClick: () => { },
  nextBtnText: 'Next Question',
  onSubmit: () => { },
  showReviewView: true,
  submitBtnText: 'Save'
}

/** export the typeform component */
export default withStyles(styles)(withSnackbar(TypeForm))
