import gql from 'graphql-tag';

const unfollowCompany = gql`
  mutation unfollowCompany($id: ID!) {
    unfollowCompany(id: $id) {
      id
    }
  }
`;

export default unfollowCompany;
