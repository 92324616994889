import gql from 'graphql-tag'

const getPostCompanyStatusChange = gql`
  query getPostCompanyStatusChange {
    postCompanyStatusChangeText	{
      postCompanyStatusChangeText
    }
  }
`

export default getPostCompanyStatusChange
