import { Typography } from '@material-ui/core'
import { withSnackbar } from 'notistack'
import React, {useEffect} from 'react'
import { Mutation, Query } from 'react-apollo'
import Skeleton from 'react-loading-skeleton'
import UpdateUser from '../../graphql/mutations/UpdateUser'
import getUserBySlug from '../../graphql/queries/getUserBySlug'
import TitledPaper from '../core/TitledPaper'
import AppLayout from '../layout/AppLayout'
import CenterColumn from '../layout/CenterColumn'
import Title from '../layout/Title'
import EditUser from '../user/EditUser'

function EditUserRoute ({
  match: {
    params: {
      slug
    }
  },
  history,
  enqueueSnackbar
}) {
  useEffect(() => {
    document.title = "The Syndicate - Edit User"
  }, []);
  return <Query query={getUserBySlug} variables={{ id: slug }} fetchPolicy="network-only">
    {({ loading, error, data }) => {
      if (error) {
        return <Typography>Error...</Typography>
      }

      let user
      if (!loading) {
        user = data.user
      }

      const title = <Title>
        Edit your user information
      </Title>

      return <AppLayout title={title}>
        <CenterColumn>
          <Mutation
            onCompleted={data => {
              if (data) {
                enqueueSnackbar('Your information has been updated.', { variant: 'success' })
                history.push(`/users/${slug}`)
              }
            }}
            mutation={UpdateUser}
          >
            {updateUser => {
              const onSave = ({ userInput, investorInput }) => updateUser({ variables: { id: slug, userInput, investorInput } })
              if (loading) {
                return <TitledPaper title="Edit your profile information">
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                </TitledPaper>
              } else {
                const initialState = {
                  userFields: {
                    firstName: user.firstName,
                    lastName: user.lastName,
                    title: user.title,
                    bio: user.bio,
                    url: user.url,
                    phone: user.phone,
                    instagramUrl: user.instagramUrl,
                    facebookUrl: user.facebookUrl,
                    githubUrl: user.githubUrl,
                    angelListUrl: user.angelListUrl,
                    linkedInUrl: user.linkedInUrl,
                    twitterUrl: user.twitterUrl,
                    receiveNotifications: user.receiveNotifications,
                    location: user.location
                  },
                  investorFields: {
                    assistantEmail: user.investor ? user.investor.assistantEmail : ''
                  }
                }

                return <EditUser onSave={onSave} initialState={initialState} />
              }
            }}
          </Mutation>
        </CenterColumn>
      </AppLayout>
    }}
  </Query>
}

export default withSnackbar(EditUserRoute)
