import gql from 'graphql-tag'

const getDeals = gql`
  query Deals {
    deals {
      id
      launchedOn
      closedOn
      number
      goal
      committed
      investorCount
      investorResponse {
        response
        amount
        updatedAt
      }
      unreadConversations {
        id
      }
      company {
        id
        logoUrl
        name
        status
        tagline
        slug
        productImages {
          id
          original
          thumbnail
          video
          poster
        }
      }
    }
  }
`

export default getDeals
