import { List, Record } from 'immutable'

export default class FormType
  extends Record({
    id: '',
    title: '',
    applicationId: '',
    sections: List(),
    questions: List(),
    completionContent: '',
    persisted: false
  }) { }
