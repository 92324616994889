import { AppBar as MuiAppBar, Badge, Button, Hidden, IconButton, Link, Menu, MenuItem, Toolbar, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import thesyndicateLogoBlack from 'images/thesyndicate_logo_black.png'
import React, { useState } from 'react'
import { Query } from 'react-apollo'
import { Link as BrowserLink } from 'react-router-dom'
import getProfile from '../../graphql/queries/getProfile'
import Avatar from './Avatar'
import {label, blackText} from './styleComponents'
import {bigStone, white, background} from './colors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faBars, faSignOut } from '@fortawesome/pro-solid-svg-icons'
const styles = theme => ({
  notificationButton: {
    padding: '0 12px',
    height: '45px',
    borderRadius: '2rem',
    border: `1px solid ${theme.palette.info.main}`,
    marginRight: '.5rem',
    minWidth: 'unset',
    fontSize: '16px',
    '& span': {
      fontWeight: 'normal'
    },
    '& svg': {
      marginRight: '.25rem'
    }
  },
  haveNotifications: {
    color: theme.palette.error.main
  },
  doesntHaveNotification: {
    color: "#99a6b3 !important"
  },
  badge: {
    '& > span': {
      color: '#9AA7B5',
      backgroundColor: '#F5F8F9',
      borderRadius: '3px !important',
      top: 'calc(50% - 10px) !important',
      right: '0 !important',
      transform: 'unset'
    }
  },
  logoWhite: {
    margin: 0,
    maxWidth: '220px',
    zIndex: '8'
  },
  appBar: {
    backgroundColor: white,
    boxShadow: 'none',
    padding: '0 1.25rem',
    transform: 'unset',
    position: 'relative',
    zIndex: 9,
    width: '100%',
    height: '80px',
    '@media(min-width: 60rem)': {
      padding: '0 1.5rem 0 0'
    }
  },
  navButton: {
    color: 'white',
    borderColor: 'white'
  },
  iconButtonRoot: {
    color: 'white',
    padding: 0
  },
  iconButtonRootMenu: {
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    padding: '.5rem',
    position: 'fixed',
    zIndex: '9',
    fontSize: '1.5rem',
    left: '1.25rem',
    top: '20px',
    cursor: 'pointer'
  },
  navbar: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    margin: 'auto',
    height: '80px',
    maxWidth: '100rem',
    padding: 0,
    width: '100%',
    '@media(min-width: 50rem)': {
      justifyContent: 'space-between',
    }
  },
  mobileMenu: {
    display: 'flex',
    width: '100%'
  },
  desktopMenu: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 0,
    width: '100%'
  },
  links: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    '& .MuiBadge-badge': {
      right: '1.5rem'
    },
    '@media(min-width: 50rem)': {
      width: '40%'
    }
  },
  link: {
    ...label,
    color: bigStone,
    fontSize: '14px',
    fontWeight: 500,
    margin: 0,
    padding: '1.9rem 1.5rem',
    transition: 'all .25s',
    '&:hover': {
      textDecoration: 'none',
      background: background
    }
  },
  linkWhiteSmall: {
    background: 'none',
    color: 'lightgrey',
    fontSize: '65%',
    textDecoration: 'none',
    transition: 'all .15s',
    transition: 'all .15s',
    '& button': {
      background: 'none',
      transition: 'all .15s',
      textDecoration: 'none',
      '&:hover': {
        background: 'none',
        transition: 'all .15s',
        textDecoration: 'none'
      }
    },
    '&:hover': {
      color: 'white',
      transition: 'all .15s',
      textDecoration: 'none'
    }
  },
  linkWhite: {
    background: 'none',
    color: 'lightgrey',
    fontSize: '75%',
    marginRight: '0',
    textDecoration: 'none',
    transition: 'all .15s',
    '& button': {
      background: 'none',
      transition: 'all .15s',
      textDecoration: 'none',
      '&:hover': {
        background: 'none',
        transition: 'all .15s',
        textDecoration: 'none'
      }
    },
    '&:hover': {
      transition: 'all .15s',
      color: 'white'
    }
  },
  loginText: {
    fontFamily: 'Poppins',
    fontSize: '60%',
    textTransform: 'uppercase',
    textDecoration: 'none'
  },
  avatar: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '15%',
    '@media(min-width: 50rem)': {
      width: '40%'
    }
  },
  menu: {
    borderRadius: '.5rem',
    backgroundColor: 'white',
    boxShadow: `0 0 .5rem -.35rem ${theme.palette.success.dark}`,
    position: 'fixed',
    top: '1.5rem',
    right: '.5rem',
    padding: '0 0 .75rem',
    width: '14rem',
    listStyle: 'none',
    overflow: 'hidden',
    '& a, & a:hover': {
      textDecoration: 'none'
    },
    '& .header': {
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      background: 'none',
      borderBottom: `1px solid ${background}`,
      padding: '1rem 1.5rem .35rem',
      '& p': {
        marginTop: '.25rem',
        marginBottom: '.25rem',
        textAlign: 'left',
        width: '100%',
        fontWeight: 'normal'
      }
    },
    '@media(min-width: 50rem)': {
      top: '4.75rem',
      position: 'absolute',
      right: 0
    }
  },
  label: {
    ...label,
    fontSize: '12px'
  },
  name: {
    ...blackText,
    fontSize: '16px',
    fontWeight: 'normal',
    margin: 0,
    textTransform: 'none'
  },
  menuItem: {
    ...label,
    color: theme.palette.primary.main,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    height: '40px',
    fontSize: '14px',
    padding: '0 1.5rem',
    fontWeight: 400,
    textDecoration: 'none',
    '&:hover': {
      background: background
    },
    '& svg': {
      marginRight: '.25rem'
    },
    '& a': {
      width: '100%'
    },
    '& > span': {
      width: '100%'
    }
  },
  linkRight: {
    margin: 0,
    '&:hover': {
      background: 'none'
    },
    '& button': {
      background: 'none',
      '&:hover': {
        background: 'none'
      }
    }
  },
  icon: {
    marginRight: '.25rem'
  }
})



const mobileLinks = profile => {
  const defaultLinks = [
    { children: 'View Profile', url: `/users/${profile.slug}` },
    { children: 'Invite Friends', url: '/invite' }
  ]
  return defaultLinks
}

/*
    ,
    { children: 'Invite', url: '/invite' },
    { children: 'Profile', url: `/users/${profile.slug}` }
*/

const desktopLinks = profile => {
  const defaultLinks = []
  let unreadConversationsCount = 0
  let unreadDealConversationsCount = 0
  unreadConversationsCount = profile.unreadConversationsCount
  unreadDealConversationsCount = profile.unreadDealConversationsCount

  if (profile.isInvestor) {
    defaultLinks.push({ children: 'Deals', url: '/deals', badgeContent: unreadDealConversationsCount })
    defaultLinks.push({ children: 'Conversations', url: '/conversations', badgeContent: unreadConversationsCount })
    defaultLinks.push({ children: 'Invest', url: '/forms/investor' })
    defaultLinks.push({ children: 'Raise Funds', url: '/forms/founder' })
  } else {
    defaultLinks.push({ children: 'Conversations', url: '/conversations', badgeContent: unreadConversationsCount })
    defaultLinks.push({ children: 'Raise Funds', url: '/forms/founder' })
  }
  return defaultLinks
}

const AppBar = props => {
  const { classes } = props
  const [anchorEl, setAnchorEl] = useState(false)
  const [notificationsMenu, setNotificationsMenu] = useState(false)

  const handleClick = event => {
    setNotificationsMenu(false)
    setAnchorEl(!anchorEl)
  }
  const showNotificationsMenu = event => {
    setAnchorEl(false)
    setNotificationsMenu(!notificationsMenu)
  }


  return <Query query={getProfile} fetchPolicy="cache-and-network" pollInterval={3000}>
    {({ loading, error, data, networkStatus }) => {
      if (error) {
        return null
      }
      let unreadNotificationsCount = 0

      let profile
      if (data &&  data.profile) {
        profile = data.profile
        unreadNotificationsCount = profile && profile.unreadNotificationsCount
      }
      const loggedIn = Boolean(profile)

      if (loggedIn) {
        const mobileMenu = <Hidden mdUp className={classes.mobileMenu}>
          <IconButton
            onClick={handleClick}
            classes={{
              root: classes.iconButtonRootMenu
            }}
          >
            <FontAwesomeIcon icon={ faBars } />
          </IconButton>
          <section className={classes.avatar}>
            <Button component={BrowserLink} to="/notifications" className={`${classes.notificationButton} ${unreadNotificationsCount > 0 ? classes.haveNotifications : classes.doesntHaveNotification}`} title="Notifications"><FontAwesomeIcon icon={faBell} className={classes.icon}/> {unreadNotificationsCount}</Button>
            <IconButton
              onClick={handleClick}
              classes={{
                root: classes.iconButtonRoot
              }}
            >
              <Avatar profile={profile}/>
            </IconButton>
          </section>
          {anchorEl && <ul className={classes.menu}>
            <li className="header">
              <label className={classes.label}>Account</label>
              <p className={classes.name}>{profile.name}</p>
            </li>
            {mobileLinks(profile).map(link => {
              let menuEl
              if (link.badgeContent) {
                menuEl = <li onClick={handleClick} className={classes.menuItem}>
                  <Badge className={classes.badge} badgeContent={link.badgeContent} color="primary">
                    <Link key={link.url} component={BrowserLink} to={link.url}>
                      {link.children}
                    </Link>
                  </Badge>
                </li>
              } else {
                menuEl = <li onClick={handleClick} className={classes.menuItem}>{link.children}</li>
              }

              return <Link key={link.url} component={BrowserLink} to={link.url}>
                {menuEl}
              </Link>
            })}
            <a href='/logout'>
              <li onClick={handleClick} className={classes.menuItem}><FontAwesomeIcon icon={faSignOut} /> Logout</li>
            </a>
          </ul>}
        </Hidden>

        const desktopMenu = <Hidden smDown className={classes.desktopMenu}>
          <div className={classes.links}>
            {desktopLinks(profile).map(link => {
              const linkEl = <Link className={classes.link} key={link.url} component={BrowserLink} to={link.url}>
                {link.children}
              </Link>

              if (link.badgeContent) {
                return <Badge key={link.url} className={classes.badge} badgeContent={link.badgeContent} color="primary">
                  {linkEl}
                </Badge>
              } else {
                return linkEl
              }
            })}
          </div>
          <Link component={BrowserLink} to={profile.isInvestor ? '/deals' : '/'}><img src={thesyndicateLogoBlack} className={classes.logoWhite} /></Link>
          <div className={classes.avatar}>
            <Button component={BrowserLink} to="/notifications" className={`${classes.notificationButton} ${unreadNotificationsCount > 0 ? classes.haveNotifications : classes.doesntHaveNotification}`} title="Notifications"><FontAwesomeIcon icon={faBell}/> {unreadNotificationsCount}</Button>
            <Link onClick={handleClick} style={{cursor: 'pointer'}}>
              <Avatar profile={profile} hideBadge />
            </Link>
          </div>
          {anchorEl && <ul className={classes.menu}>
            <li className="header">
              <label className={classes.label}>Account</label>
              <p className={classes.name}>{profile.name}</p>
            </li>
            {mobileLinks(profile).map(link => {
              let menuEl
              if (link.badgeContent) {
                menuEl = <li onClick={handleClick} className={classes.menuItem}>
                  <Badge className={classes.margin} badgeContent={link.badgeContent} color="primary">
                    <Link key={link.url} component={BrowserLink} to={link.url}>
                      {link.children}
                    </Link>
                  </Badge>
                </li>
              } else {
                menuEl = <li onClick={handleClick} className={classes.menuItem}>{link.children}</li>
              }

              return <Link key={link.url} component={BrowserLink} to={link.url}>
                {menuEl}
              </Link>
            })}
            <a href='/logout'>
              <li onClick={handleClick} className={classes.menuItem}><FontAwesomeIcon icon={faSignOut} /> Logout</li>
            </a>
          </ul>}
        </Hidden>
        return <MuiAppBar
          position="static"
          color="default"
          className={classes.appBar}
        >
          <Toolbar className={classes.navbar}>
            {mobileMenu}
            {desktopMenu}
          </Toolbar>
        </MuiAppBar>
      } else if (networkStatus !== 4) return null
    }}
  </Query>
}

export default withStyles(styles)(AppBar)
