import { AppBar, Button, Dialog, DialogContent, IconButton, Slide, TextField, Toolbar, Typography, withMobileDialog } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import { withSnackbar } from 'notistack'
import React, { useState, useEffect } from 'react'
import { blueButton, blackText, textInput }  from '../layout/styleComponents'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons'

const styles = theme => ({
  modal: {
    '& .MuiDialog-paperFullWidth': {
      borderRadius: '.5rem',
      maxWidth: '45rem',
      height: 'unset',
      width: '95%',
      maxHeight: '80%',
      textAlign: 'center'
    },
    '& .MuiDialogContent-root': {
      padding: '0 1.5rem 2rem',
    }
  },
  textInput: textInput,
  appBarTitle: {
    ...blackText,
    margin: 0,
    fontSize: '1.25rem',
    flex: 1,
    textAlign: 'left'
  },
  appBarIcon: {
    marginRight: '-1rem'
  },
  buttonMarginTop: {
    marginTop: '1rem'
  },
  appBar: {
    backgroundColor: theme.palette.info.light,
    margin: 0,
    width: '100%',
    marginBottom: '1rem',
    position: 'relative',
    paddingRight: '0 !important',
    padding: '.5rem 1.5rem'
  },
  button: {
    ...blueButton,
    margin: '1.5rem auto 0'
  },
  toolbar: {
    background: 'none',
    justifyContent: 'flex-end',
    padding: 0
  },
  blackText: {
    ...blackText,
    margin: 0,
    fontSize: '1rem',
    textAlign: 'left',
    width: '100%'
  },
  content: {
    padding: 0
  }
})

function Transition (props) {
  return <Slide direction="up" {...props} />
}

function PassAllocationModalRoute ({
  companySlug,
  fullScreen,
  match: {
    params: { id }
  },
  onClose,
  enqueueSnackbar,
  passAllocationModalIsOpen,
  passOnDeal,
  classes,
  history
}) {
  const submitNotInterest = () => {
    passOnDeal(passReasonMessage)
    enqueueSnackbar('Your interest has been noted.', { variant: 'success' })
  }

  const [passReasonMessage, setPassReasonMessage] = useState('')
  useEffect(() => {
    document.title = "The Syndicate - Pass Allocation Modal"
  }, []);
  return (
    <Dialog
      TransitionComponent={Transition}
      open={passAllocationModalIsOpen}
      fullWidth
      fullScreen={fullScreen}
      maxWidth="md"
      onClose={onClose}
      scroll="paper"
      className={classes.modal}
    >
      <AppBar
        color="default"
        elevation={0}
        className={classes.appBar}
      >
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" color="inherit" className={classes.appBarTitle}>
             Let us know why you are passing
          </Typography>
          <IconButton
            className={classes.appBarIcon}
            color="inherit"
            onClick={() => history.push('./')}
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent className={classes.content}>
        <TextField
          fullWidth
          label="Optional"
          value={passReasonMessage}
          onChange={evt => setPassReasonMessage(evt.target.value)}
          classes={classes}
          className={classes.textInput}
        />
        <Button
          onClick={() => {
            submitNotInterest()
            onClose()
          }}
          className={classes.button}
          variant="contained"
        >
          Decline
          <FontAwesomeIcon icon={faLongArrowRight} />
        </Button>
      </DialogContent>
    </Dialog>
  )
}

export default withSnackbar(withStyles(styles)(withMobileDialog()(PassAllocationModalRoute)))
