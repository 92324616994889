import { faCheck, faDollarSign } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Grid, LinearProgress, Typography, TextField } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment'
import React, { useState, useEffect } from 'react'
import currencyFormatter from '../core/currencyFormatter'
import PaddedPaper from '../core/PaddedPaper'
import { blueRibbon, gullGray, bigStone } from '../layout/colors'
import { whiteText, blueButton, redButton } from '../layout/styleComponents'
import { withRouter } from 'react-router-dom';
import CompanyTile from '../core/CompanyTile'

const styles = theme => {
  return {
    redButton: {
      ...redButton,
      boxShadow: 'none !important',
      marginBottom: '1rem',
      '@media(min-width: 45rem)': {
        marginBottom: '0',
      }
    },
    actions: {
      alignItems: 'center',
      display: 'flex',
      flexWrap: 'wrap',
      margin: '1rem 0 0',
      justifyContent: 'space-evenly',
      width: '100%',
      '@media(min-width: 45rem)': {
        margin: '1rem 0 1.5rem',
      }
    },
    checkmark: {
      marginRight: '0.5rem',
      color: theme.palette.secondary.main,
      fontSize: '1.25rem'
    },
    paddedPaper: {
      boxShadow: 'none',
      padding: 0,
      width: '100%',
      position: 'relative'
    },
    button: {
      ...blueButton,
      boxShadow: 'none !important',
      marginBottom: '1rem',
      '@media(min-width: 45rem)': {
        marginBottom: '0',
      }
    },
    noMargin: {
      marginBottom: '0 !important'
    },
    investorResponse: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      textAlign: 'left',
      width: '100%'
    },
    barNumber: {
      position: 'absolute',
      fontSize: '.85rem',
      fontFamily: 'Lato',
      color: 'white',
      zIndex: 9,
      fontWeight: 'biold',
      left: '49.5%',
      top: '-.1rem'
    },
    progressBarContainer: {
      position: 'relative',
      width: '100%'
    },
    progressContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    progressDescription: {
      paddingLeft: '.5rem',
      marginBottom: '0.3rem',
      '@media(min-width: 40rem)': {
        marginLeft: 0,
        width: '75%'
      }
    },
    mainContent: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      justifyContent: 'space-between'
    },
    progressIndicator: {
      background: theme.palette.success.dark,
      borderRadius: '.5rem',
      height: '1rem',
      width: '100%',
      '& > div': {
        background: blueRibbon
      }
    },
    logo: {
      width: '100%'
    },
    companyInformation: {
      display: 'flex',
      flexDirection: 'row'
    },
    informationActionsContainer: {
      alignItems: 'center',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-evenly',
      padding: '0 1.5rem',
      margin: '1rem auto',
      width: '100%',
      maxWidth: '35rem',
      '@media(min-width: 45rem)': {
        maxWidth: '60rem'
      },
      '& dl': {
        textAlign: 'center',
        minWidth: '6rem'
      },
      '& dl > dt, & dl > dd': {
        ...whiteText,
        fontSize: '1.1rem !important',
        margin: 0
      },
      '& dl > dd': {
        fontSize: '.75rem !important'
      }
    },
    goalContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'baseline'
    },
    investContainer: {
      alignItems: 'center',
      background: 'white',
      borderRadius: '3rem',
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '1rem',
      maxWidth: '15rem',
      padding: '.25rem',
      position: 'relative',
      '@media(min-width: 45rem)': {
        marginBottom: 0
      }
    },
    placeholder: {
      color: theme.palette.success.main,
      fontSize: '1.25rem',
      left: '1rem',
      position: 'absolute',
      top: '1rem'
    },
    dataIndexItem: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
      width: '100%',
      '& li': {
        color: theme.palette.primary.main,
        fontWeight: 400,
        marginBottom: '.25rem',
        width: '100%'
      }
    },
    investField: {
      background: 'none',
      border: 'none',
      color: bigStone,
      fontFamily: 'Lato',
      fontSize: '1.15rem',
      fontWeight: 400,
      maxWidth: '60%',
      padding: '.5rem',
      '&:focus': {
        border: 'none',
        outline: 'none'
      }
    }
  }
}

const DealSnapshot = ({
  company,
  hideActions,
  number,
  goal,
  committed,
  investorCount,
  launchedOn,
  closedOn,
  investorResponse,
  history,
  dealId,
  classes,
  companyHero,
  children,
  ...rest
}) => {
  const [amount, setAmount] = useState('')
  const [smScreen, setSmScreen] = useState(false);
  const updateAmount = (e) => {
    const re = /^[0-9\b]+$/
    if (e.target.value === '' || re.test(e.target.value)) {
      setAmount(e.target.value)
    }
 }

  const getSocialLinks = (company) => {
    let socialLinks = []
    if (company.linkedInUrl) {
      socialLinks.push({ icon: faLinkedin, url: company.linkedInUrl })
    }
    if (company.twitterUrl) {
      socialLinks.push({ icon: faTwitter, url: company.twitterUrl })
    }
    if (company.facebookUrl) {
      socialLinks.push({ icon: faFacebook, url: company.facebookUrl })
    }
    if (company.githubUrl) {
      socialLinks.push({ icon: faGithub, url: company.githubUrl })
    }
    if (company.url) {
      socialLinks.push({ icon: faGlobe, url: company.url })
    }
    return socialLinks
  }

  const checkSize = () => {
    if (window.innerWidth < 601) setSmScreen(true)
    else setSmScreen(false)
  }

  useEffect(() => {
    checkSize()
    window.addEventListener('resize', checkSize)
    return () => {
      window.removeEventListener('resize', checkSize)
    }
  }, [])

  const progressPercentage = Math.min(100, Math.floor((committed / goal) * 100))
  const logo = <img
    alt="avatar"
    src={company.logoUrl || '/company.png'}
    className={classes.logo}
  />

  let progressSection = null
  let goalContainer = null
  let actionSection
  if (investorResponse) {
    let response
    if (investorResponse.response === 'committed') {
      response = <div className={classes.investorResponse}>
        <FontAwesomeIcon
          icon={faCheck}
          className={classes.checkmark}
        />
        <Typography variant="caption">You committed to investing <strong>{currencyFormatter.format(investorResponse.amount)}</strong> on <strong>{moment(investorResponse.updatedAt).format('LL')}</strong>.</Typography>
      </div>
    } else if (investorResponse.response === 'requested_allocation') {
      response = <div className={classes.investorResponse}>
        <FontAwesomeIcon
          icon={faCheck}
          className={classes.checkmark}
        />
        <Typography variant="caption">You requested allocation of <strong>{currencyFormatter.format(investorResponse.amount)}</strong> on <strong>{moment(investorResponse.updatedAt).format('LL')}</strong>.</Typography>
      </div>
    } else {
      response = <Typography>You passed...</Typography>
      response = <div className={classes.investorResponse}>
        <Typography variant="caption">You passed on this deal on <strong>{moment(investorResponse.updatedAt).format('LL')}</strong>.</Typography>
      </div>
    }
    actionSection = <div>
      {response}
    </div>
  }
  progressSection = <div className={classes.progressBarContainer}>
    <div className={classes.progressContainer}>
    </div>
    <Typography className={classes.barNumber}>
      {progressPercentage}%
    </Typography>
    <LinearProgress
      variant="determinate"
      value={progressPercentage}
      className={classes.progressIndicator}
    />
    {/* closedOn && closedOn < moment(Date.now()) && <Typography>
      Closed on {moment(closedOn).format('LL')}
    </Typography> */}
  </div>
  return <PaddedPaper {...rest} className={classes.paddedPaper}>
    {hideActions ? <article className={classes.dealIndexItem}>
      <CompanyTile
        variant="logo"
        name={company.name}
        logoUrl={company.logoUrl}
        socialLinks={getSocialLinks(company)}
        dealIndex
        url={`/companies/${company.slug}`}
        tagline={<ul className={classes.dataIndexItem}>
          <li>
            Raised: <strong>{currencyFormatter.format(committed)}</strong>
          </li>
          <li>
            Investor{investorCount !== 1 && 's'}: <strong>{investorCount}</strong>
          </li>
          <li>
            Target: <strong>{currencyFormatter.format(goal)}</strong>
          </li>
          {launchedOn && <li>
            Launched on: <strong>{moment(launchedOn).format('MMM DD, YYYY')}</strong>
          </li>}
          {closedOn && <li>
            {moment(closedOn) < moment(Date.now()) ? <span>
              Closed on: <strong>{moment(closedOn).format('MMM DD, YYYY')}</strong>
            </span> : <span>
              Closes in: <strong>{moment(closedOn).diff(moment(Date.now()))} Days</strong>
            </span>}
          </li>}
        </ul>}
      />
    </article> : <section className={classes.informationActionsContainer}>
      <dl>
        <dt>{currencyFormatter.format(committed)}</dt>
        <dd>Raised</dd>
      </dl>
      <dl>
        <dt>{investorCount}</dt>
        <dd>Investor{investorCount > 1 && 's'}</dd>
      </dl>
      <dl>
        <dt>{currencyFormatter.format(goal)}</dt>
        <dd>Target</dd>
      </dl>
      {launchedOn && !smScreen && <dl>
        <dt>{moment(launchedOn).format('MMM DD, YYYY')}</dt>
        <dd>Launched on</dd>
      </dl>}
      {closedOn && !smScreen && moment(closedOn) < moment(Date.now()) ? <dl>
        <dt>{moment(closedOn).format('MMM DD, YYYY')}</dt>
        <dd>Closed on</dd>
      </dl> : <dl>
        <dt>{closedOn ? moment(closedOn).diff(moment(Date.now())) : 'Open'}</dt>
        <dd>{closedOn ? 'Days left' : 'Status'}</dd>
      </dl>}
      {!investorResponse && <div className={classes.investContainer}>
        <input
          id="amount"
          value={amount}
          onChange={evt => updateAmount(evt)}
          className={classes.investField}
        />
        {amount && amount !== '' ? null : <FontAwesomeIcon icon={faDollarSign} className={classes.placeholder} />}
        <Button className={`${classes.button} ${classes.noMargin}`} variant="contained" onClick={() =>
          history.push(
            `/companies/${company.slug}/deals/${dealId}/request_allocation/${amount}`
          )}>Invest</Button>
      </div>
      }
      <section className={classes.actions}>
        {!investorResponse && <Button
          className={classes.redButton}
          onClick={() =>
            history.push(
              `/companies/${company.slug}/deals/${dealId}/pass_allocation`
            )}
          >Pass on Deal #{number}: {company.name}</Button>
        }
        {children}
      </section>
      {/* actionSection */}
    </section>}
    {progressSection}
  </PaddedPaper>
}

export default withStyles(styles)(withRouter(DealSnapshot))
