import gql from 'graphql-tag'

const getDealDiscussion = gql`
  query GetDealDiscussion($id: ID!) {
    dealDiscussion(id: $id){
      discussion {
        id
        messages {
          id
          body
          createdAt
          user {
            name
            avatarUrl
          }
        }
      }
    }
  }
`

export default getDealDiscussion
