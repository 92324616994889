import React from 'react'
import { Switch } from 'react-router'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import AfterConfirmationRoute from './routes/AfterConfirmationRoute'
import CompanyProfileRoute from './routes/CompanyProfileRoute'
import CompanyUpdatesRoute from './routes/CompanyUpdatesRoute'
import ConversationRoute from './routes/ConversationRoute'
import ConversationsRoute from './routes/ConversationsRoute'
import CreateFundingHistoryRoute from './routes/CreateFundingHistoryRoute'
import CreateUserExperienceRoute from './routes/CreateUserExperienceRoute'
import DashboardRoute from './routes/DashboardRoute'
import DealIndexRoute from './routes/DealIndexRoute'
import DealRoute from './routes/DealRoute'
import EditCompanyRoute from './routes/EditCompanyRoute'
import EditFundingHistoryRoute from './routes/EditFundingHistoryRoute'
import EditUserExperienceRoute from './routes/EditUserExperienceRoute'
import EditUserRoute from './routes/EditUserRoute'
import FormRoute from './routes/FormRoute'
import HomeRoute from './routes/HomeRoute'
import InviteRoute from './routes/InviteRoute'
import LoginRoute from './routes/LoginRoute'
import NotificationRoute from './routes/NotificationRoute'
import NotificationsRoute from './routes/NotificationsRoute'
import ScrollToTop from './routes/ScrollToTop'
import SignupOnboardingRoute from './routes/SignupOnboardingRoute'
import UserProfileRoute from './routes/UserProfileRoute'
import UserUpdatesRoute from './routes/UserUpdatesRoute'
import VisitsUserRoute from './routes/VisitsUserRoute'
import AppBar from './layout/AppBar'

function AppRouter () {
  return (
    <Router>
      <AppBar />
      <ScrollToTop>
        <Switch>
          <Route path="/deals" component={DealIndexRoute} />
          <Route path="/companies/:slug/edit" component={EditCompanyRoute} />
          <Route path="/companies/:slug/deals/:id" component={DealRoute} />
          <Route path="/companies/:slug/updates/all" component={CompanyUpdatesRoute} />
          <Route path="/companies/:slug/funding_histories/:id/edit" component={EditFundingHistoryRoute} />
          <Route path="/companies/:slug/funding_histories/new" component={CreateFundingHistoryRoute} />
          <Route path="/companies/:slug" component={CompanyProfileRoute} />
          <Route path="/users/:slug/edit" component={EditUserRoute} />
          <Route path="/users/:slug/visits" component={VisitsUserRoute} />
          <Route path="/users/:slug/updates/all" component={UserUpdatesRoute} />
          <Route path="/users/:slug/user_experiences/:id/edit" component={EditUserExperienceRoute} />
          <Route path="/users/:slug/user_experiences/new" component={CreateUserExperienceRoute} />
          <Route path="/users/:slug" component={UserProfileRoute} />
          <Route path="/invite" component={InviteRoute} />
          <Route path="/conversations/:id" component={ConversationRoute} />
          <Route path="/conversations" component={ConversationsRoute} />
          <Route path="/forms/:formKey" component={FormRoute} />
          <Route path="/signup/after_confirmation" component={AfterConfirmationRoute} />
          <Route path="/signup/:formKey/onboarding" component={SignupOnboardingRoute} />
          <Route path="/notifications/:id" component={NotificationRoute} />
          <Route path="/notifications" component={NotificationsRoute} />
          <Route path="/dashboard" component={DashboardRoute} />
          <Route path="/login" component={LoginRoute} />
          <Route path="/" component={HomeRoute} />
        </Switch>
      </ScrollToTop>
    </Router>
  )
}

export default AppRouter
