import gql from 'graphql-tag'

const createUserUpdate = gql`
  mutation createUserUpdate($userSlug: String!, $messageInput: MessageInput!) {
    createUserUpdate(userSlug: $userSlug, messageInput: $messageInput) {
      id
    }
  }
`

export default createUserUpdate
