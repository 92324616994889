import { Typography } from '@material-ui/core'
import { withSnackbar } from 'notistack'
import React, {userEffect} from 'react'
import { Query } from 'react-apollo'
import Skeleton from 'react-loading-skeleton'
import GetVisits from '../../graphql/queries/getVisits'
import TitledPaper from '../core/TitledPaper'
import AppLayout from '../layout/AppLayout'
import CenterColumn from '../layout/CenterColumn'
import Title from '../layout/Title'
import UserVisits from '../user/UserVisits'

function VisitsUserRoute ({
  match: {
    params: {
      slug
    }
  },
  history,
  enqueueSnackbar
}) {
  useEffect(() => {
    document.title = "The Syndicate - User Visits"
  }, []);
  return <Query query={GetVisits} fetchPolicy="network-only">
    {({ loading, error, data }) => {
      if (error) {
        return <Typography>Error...</Typography>
      }

      let visits
      if (!loading) {
        visits = data.visits
      }

      const title = <Title>
        Your visits
      </Title>

      const content = loading
        ? <TitledPaper title="Your visits">
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </TitledPaper>
        : <UserVisits visits={visits} />

      return <AppLayout title={title}>
        <CenterColumn>
          {content}
        </CenterColumn>
      </AppLayout>
    }}
  </Query>
}

export default withSnackbar(VisitsUserRoute)
