import { FormControl, FormLabel, Switch, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import LongTextArea from '../core/LongTextArea'
import LongTextField from '../core/LongTextField'
import TitledPaper from '../core/TitledPaper'
import { blueButton, textInput, mediumTitle } from '../layout/styleComponents'
import Form from '../form/ListForm'

const styles = theme => ({
  longInput: {
    ...textInput,
    marginBottom: '2.5rem',
    '& label': {
      fontSize: '1.5rem',
      padding: 0
    },
    '& input': {
      fontSize: '.85rem',
      padding: '1rem 0',
      color: theme.palette.primary.main
    }
  },
  textInput: {
    ...textInput,
    marginBottom: '2.5rem',
    '& label': {
      fontSize: '1.75rem',
      padding: 0
    },
    '& textarea': {
      fontSize: '.85rem',
      padding: '1rem 0',
      color: theme.palette.primary.main
    },
  },
  actions: {
    marginTop: theme.spacing.unit * 3
  },
  selectedItem: {
    fontWeight: theme.typography.fontWeightMedium
  },
  formControl: {
    width: '100%',
    marginTop: '1rem',
  },
  unselectedItem: {
    fontWeight: theme.typography.fontWeightRegular
  },
  mediumTitle: {
    ...mediumTitle,
    margin: '4rem auto 7rem'
  },
  paper: {
    margin: '0 auto 2rem',
    maxWidth: '45rem',
    width: '100%'
  },
  button: blueButton
})

class EditUserBase extends React.Component {
  setUserField = fieldName => {
    return (evt) => {
      this.setState({
        userFields: {
          ...this.state.userFields,
          [fieldName]: evt.target.value
        }
      })
    }
  }

  setInvestorField = fieldName => {
    return (evt) => {
      this.setState({
        investorFields: {
          ...this.state.investorFields,
          [fieldName]: evt.target.value
        }
      })
    }
  }

  constructor(props) {
    super(props)
    this.state = props.initialState
  }

  render () {
    const { classes, onSave } = this.props
    let {
      firstName,
      lastName,
      title,
      bio,
      url,
      phone,
      linkedInUrl,
      angelListUrl,
      instagramUrl,
      facebookUrl,
      githubUrl,
      receiveNotifications,
      twitterUrl,
      location
    } = this.state.userFields

    const { assistantEmail } = this.state.investorFields

    return <TitledPaper noLogo form className={classes.paper} key="edit-container">
      <Form
        submitBtnText={'Save Changes'}
        onSubmit={() => onSave({ userInput: this.state.userFields, investorInput: this.state.investorFields })}
        title={<Typography className={classes.mediumTitle}>Edit your information</Typography>}
        classes={classes}
      >
        <LongTextField label="First Name" value={firstName ||''} onChange={this.setUserField('firstName')} classes={classes} />
        <LongTextField label="Last Name" value={lastName ||''} onChange={this.setUserField('lastName')} classes={classes} />
        <LongTextField label="Title" value={title ||''} onChange={this.setUserField('title')} classes={classes} />
        <LongTextArea label="Bio" value={bio ||''} onChange={this.setUserField('bio')} classes={classes} />
        <LongTextField label="URL" value={url ||''} onChange={this.setUserField('url')} classes={classes} />
        <LongTextField label="Phone" value={phone ||''} onChange={this.setUserField('phone')} classes={classes} />
        <LongTextField label="LinkedIn URL" value={linkedInUrl ||''} onChange={this.setUserField('linkedInUrl')} classes={classes} />
        <LongTextField label="Twitter URL" value={twitterUrl ||''} onChange={this.setUserField('twitterUrl')} classes={classes} />
        <LongTextField label="GitHub URL" value={githubUrl ||''} onChange={this.setUserField('githubUrl')} classes={classes} />
        <LongTextField label="Angel List" value={angelListUrl ||''} onChange={this.setUserField('angelListUrl')} classes={classes} />
        <LongTextField label="Instagram" value={instagramUrl ||''} onChange={this.setUserField('instagramUrl')} classes={classes} />
        <LongTextField label="Facebook URL" value={facebookUrl ||''} onChange={this.setUserField('facebookUrl')} classes={classes} />
        <>
          <LongTextField label="Location" value={location} onChange={this.setUserField('location')} classes={classes} />
          {assistantEmail !== null && <LongTextField label="Assistant Email" value={assistantEmail || ''} onChange={this.setInvestorField('assistantEmail')} classes={classes} />}
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">Receive Notifications</FormLabel>
            <Switch
              checked={receiveNotifications}
              onChange={this.setUserField('receiveNotifications')}
            />
          </FormControl>
        </>
      </Form>
    </TitledPaper>
  }
}

const EditUser = withStyles(styles, { withTheme: true })(EditUserBase)

export default EditUser
