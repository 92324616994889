import { faFacebook, faGithub, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import { AppBar, Button, Dialog, Grid, DialogContent, IconButton, Slide, Toolbar, Typography, withMobileDialog } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import filter from 'lodash.filter'
import sortBy from 'lodash.sortby'
import { withSnackbar } from 'notistack'
import React from 'react'
import 'react-image-gallery/styles/css/image-gallery.css'
import DealSnapshot from '../../components/deal/DealSnapshot'
import MaxLengthTextField from '../core/MaxLengthTextField'
import Titled from '../core/Titled'
import { badges, socialLink, socialLinks, tagIcon } from '../layout/styles/BaseProfile'
import CompanyHero from './CompanyHero'
import CompanyInformationHero from './CompanyInformationHero'
import CompanyUpdatesHero from './CompanyUpdatesHero'
import FoundersHero from './FoundersHero'
import FundingHistoriesHero from './FundingHistoriesHero'
import InvestorsHero from './InvestorsHero'
import ProductHero from './ProductHero'
import TabsContainer from '../layout/TabsContainer'
import { transparentBackgroundContainer, blackText, blueButton } from '../layout/styleComponents'
import { faUserChart } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons'
import { Query } from 'react-apollo'
import {getUnreadConversationsAndUpdates} from '../../graphql/queries/getNotifications'

function Transition (props) {
  return <Slide direction="up" {...props} />
}

const styles = theme => ({
  transparentBackgroundContainer: transparentBackgroundContainer,
  appBarTitle: {
    ...blackText,
    margin: 0,
    fontSize: '1.25rem',
    flex: 1,
    textAlign: 'left'
  },
  appBarIcon: {
    marginRight: '-1rem'
  },
  appBar: {
    backgroundColor: theme.palette.info.light,
    margin: 0,
    width: '100%',
    marginBottom: '1rem',
    position: 'relative',
    paddingRight: '0 !important',
    padding: '.5rem 1.5rem'
  },
  button: {
    ...blueButton,
    margin: '1.5rem auto'
  },
  toolbar: {
    background: 'none',
    justifyContent: 'flex-end',
    padding: 0
  },
  paper: {
    marginTop: theme.spacing.unit * 3
  },
  dealBottom: {
    background: 'none',
    boxShadow: 'none'
  },
  modal: {
    width: '80%',
    height: '80%',
    margin: 'auto'
  },
  actions: {
    textAlign: 'right'
  },
  visitorWarning: {
    color: 'gray',
    textAlign: 'right'
  },
  detailContent: {
    marginBottom: '1rem'
  },
  icon: {
    margin: 'auto',
    fontSize: '1.5rem',
    cursor: 'pointer'
  },
  card: {
    marginBottom: '1rem'
  },
  tagIcon,
  badges,
  socialLinks: socialLinks(theme),
  socialLink: socialLink(theme)
})

function CompanyProfile (props) {
  const {
    can,
    classes,
    classifications,
    companyUpdates,
    deals,
    status,
    deleteFundingHistory,
    description,
    employeeCount,
    facebookUrl,
    following,
    foundedOn,
    founders,
    loading,
    fullScreen,
    fundingHistories,
    githubUrl,
    history,
    howDoYouMakeMoney,
    investors,
    linkedInUrl,
    location,
    logoUrl,
    name,
    onFollowUnfollow,
    productDescription,
    productImages,
    refetch,
    requestMeeting,
    slug,
    tagline,
    token,
    twitterUrl,
    unfairAdvantage,
    url,
    whatProblemAreYouSolving,
    message,
    setMessage,
    createCompanyUpdateOpen,
    setCreateCompanyUpdateOpen,
    isOpenRequestMeetingModal,
    imageGalleryItemRef,
    setIsOpenRequestMeetingModal,
    enqueueSnackbar
  } = props

  const handleCreateCompanyUpdateOpen = () => {
    setCreateCompanyUpdateOpen(true)
  }

  const handleCreateCompanyUpdateClose = () => {
    setCreateCompanyUpdateOpen(false)
  }

  const toggleRequestMeetingModal = token => {
    setIsOpenRequestMeetingModal(!isOpenRequestMeetingModal)
    if (!token) {
      const location = {
        pathname: '/signup/user/onboarding',
        state: {
          notice: 'To request a meeting you need to sign up or log in.'
        }
      }
      props.history.push(location)
    }
  }

  let socialLinks = []
  if (linkedInUrl) {
    socialLinks.push({ icon: faLinkedin, url: linkedInUrl })
  }
  if (twitterUrl) {
    socialLinks.push({ icon: faTwitter, url: twitterUrl })
  }
  if (facebookUrl) {
    socialLinks.push({ icon: faFacebook, url: facebookUrl })
  }
  if (githubUrl) {
    socialLinks.push({ icon: faGithub, url: githubUrl })
  }
  if (url) {
    socialLinks.push({ icon: faGlobe, url: url })
  }

  const ongoingDeals = filter(deals, deal => !deal.closedOn)
  const tabs = [
    {
      name: 'Deck'
    },
    {
      name: 'Round Details'
    },
    {
      name: 'Founders'
    },
    {
      name: 'Investors'
    },
    {
      name: 'Conversations'
    },
    {
      name: 'Funding History'
    }
  ]
  return (
    <>
      <CompanyHero
        heroImageUrl={productImages && productImages.length > 0 ? productImages[0] : {}}
        slug={slug}
        name={name}
        onFollowUnfollow={onFollowUnfollow}
        toggleRequestMeetingModal={toggleRequestMeetingModal}
        following={following}
        socialLinks={socialLinks}
        logoUrl={logoUrl}
        tagline={tagline}
        description={description}
        location={location}
        status={status}
        token={token}
        can={can}
        refetch={refetch}
        ongoingDeals={ongoingDeals}
      />
      <Grid container className={classes.transparentBackgroundContainer}>
        <TabsContainer tabs={tabs} icon={faUserChart} title="Company Memo">
          <ProductHero
            imageGalleryItemRef={imageGalleryItemRef}
            refetch={refetch}
            slug={slug}
            productImages={productImages}
            loading={loading}
            productDescription={productDescription}
            can={can}
          />
          <CompanyInformationHero
            foundedOn={foundedOn}
            employeeCount={employeeCount}
            howDoYouMakeMoney={howDoYouMakeMoney}
            whatProblemAreYouSolving={whatProblemAreYouSolving}
            unfairAdvantage={unfairAdvantage}
            loading={loading}
            classifications={classifications}
          />
          <FoundersHero founders={founders}
          loading={loading} />
          <InvestorsHero investors={investors}
          loading={loading} />
          <Query query={getUnreadConversationsAndUpdates} fetchPolicy="cache-and-network">
            {({ loading, error, data, refetch, networkStatus }) => {
              const refetchNotifications = refetch
              if (loading && networkStatus !== 4) return null
              return <CompanyUpdatesHero
                slug={slug}
                name={name}
                handleCreateCompanyUpdateOpen={handleCreateCompanyUpdateOpen}
                fullScreen={fullScreen}
                history={history}
                createCompanyUpdateOpen={createCompanyUpdateOpen}
                handleCreateCompanyUpdateClose={handleCreateCompanyUpdateClose}
                refetch={refetch}
                can={can}
                companyUpdates={companyUpdates}
                loading={loading}
                unreadNotifications={data.profile}
                refetch={refetch}
                refetchNotifications={refetchNotifications}
              />
           }}
          </Query>
          <FundingHistoriesHero fundingHistories={fundingHistories} deleteFundingHistory={deleteFundingHistory} slug={slug} can={can}
          loading={loading} />
          {
            (deals || []).length > 0 && (
              <>
                { sortBy(filter(deals, (deal) => Boolean(deal.closedOn)), ['launchedOn']).length > 0 && <Titled title="Closed Deals" /> }
                <>
                  {sortBy(filter(deals, (deal) => Boolean(deal.closedOn)), ['launchedOn']).reverse().map((deal, i) => {
                    return <DealSnapshot
                      showActions={false}
                      key={deal.id}
                      {...deal}
                      className={classes.dealBottom}
                    />
                  }
                  )}
                </>
              </>
            )
          }
        </TabsContainer>
      </Grid>
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        maxWidth="md"
        onClose={toggleRequestMeetingModal}
        TransitionComponent={Transition}
        open={isOpenRequestMeetingModal}
      >
        <AppBar
          color="default"
          elevation={0}
          className={classes.appBar}
        >
          <Toolbar className={classes.toolbar}>
            <Typography variant="h6" color="inherit" className={classes.appBarTitle}>
              Request a meeting
            </Typography>
            <IconButton
              className={classes.appBarIcon}
              color="inherit"
              onClick={toggleRequestMeetingModal}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Typography variant="subtitle1">
            Send a message to this company.
          </Typography>
          <MaxLengthTextField
            maxLength={250}
            label="Your message"
            value={message}
            onChange={evt => setMessage(evt.target.value)}
            classes={classes}
          />
          <div className={classes.actions}>
            <Button
              className={classes.button}
              variant="contained"
              disabled={token === null}
              onClick={() => {
                requestMeeting(message)
                toggleRequestMeetingModal(token)
                enqueueSnackbar('Meeting Requested.', { variant: 'success' })
                setMessage('')
              }}
            >
              Request Meeting
              <FontAwesomeIcon icon={faLongArrowRight} />
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default withSnackbar(withStyles(styles)(withMobileDialog()(CompanyProfile)))
