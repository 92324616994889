import { TextField, Typography } from '@material-ui/core'
import React from 'react'

const MaxLengthTextField = ({ label, className, value, onChange, maxLength, classes }) => <div className={className}>
  <TextField
    fullWidth
    className={classes.longInput}
    label={label}
    defaultValue={value}
    onChange={onChange}
    inputProps={
      { maxLength }
    }
  />
  <Typography variant="caption" className={classes.hint}>Max length: {maxLength}</Typography>
</div>

export default MaxLengthTextField
