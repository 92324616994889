import {
  faFacebook,
  faGithub,
  faLinkedin,
  faTwitter
} from '@fortawesome/free-brands-svg-icons'
import {
  faBriefcase,
  faGlobe,
  faMapMarkerAlt,
  faPhone,
  faTag,
  faCalendarCheck,
  faPencilAlt,
  faEye,
  faUserPlus,
  faUserMinus,
  faUsers
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Chip, Grid, Typography, withMobileDialog } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import { Mutation } from 'react-apollo'
import Skeleton from 'react-loading-skeleton'
import NumberFormat from 'react-number-format'
import { Link as BrowserLink } from 'react-router-dom'
import UpdateUser from '../../graphql/mutations/UpdateUser'
import EditableImage from '../core/EditableImage'
import MainHeading from '../core/MainHeading'
import SocialLinks from '../core/SocialLinks'
import TitledPaper from '../core/TitledPaper'
import { tagIcon, tagIconTwo } from '../layout/styles/BaseProfile'
import { metadata, metadataItem } from '../layout/styles/metadata'
import { dateFormatMMYY } from '../helpers/dateFormat'
import HeaderHero from '../core/HeaderHero'
import { blueButton, roundedHeroImage, centeredFlex } from '../layout/styleComponents'
import { background } from '../layout/colors'

const styles = theme => ({
  roundButton: {
    border: `1px solid ${background}`,
    borderRadius: '50%',
    marginBottom: '1.5rem',
    padding: '1rem',
    fontSize: '1rem',
    color:  background,
    minWidth: 'unset',
    '&:hover': {
      background: theme.palette.success.main
    }
  },
  userDetails: {
    ...centeredFlex,
    listStyle: 'none',
    marginTop: 0,
    padding: '0 1.5rem',
    width: '100%',
    '& li': {
      margin: '0 .75rem 1.5rem',
      width: '100%',
      '@media(min-width: 45rem)': {
        margin: '0 .75rem',
        width: 'auto',
      }
    }
  },
  memberSince: {
    fontSize: '.9rem',
    color: background,
    fontWeight: 300
  },
  marginTop: {
    marginTop: '3.5rem',
    padding: 0
  },
  marginBottom: {
    marginBottom: '1rem'
  },
  blueButton: {
    ...blueButton,
    marginBottom: '1rem',
    '@media(min-width: 45rem)': {
      marginBottom: '0',
    }
  },
  centered: {
    justifyContent: 'center'
  },
  rightSide: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start'
  },
  logo: {
    ...roundedHeroImage,
    position: 'relative'
  },
  heroImage: {
    backgroundSize: 'contain !important',
    display: 'flex',
    margin: 'auto',
    maxHeight: '20rem',
    maxWidth: '100%'
  },
  badge: {
    fontSize: '16px',
    color:  'white',
    fontWeight: 300
  },
  statusBadge: {
    color: 'black',
    fontFamily: 'Poppins',
    fontSize: '100%',
    fontWeight: 'bold',
    textTransform: 'capitalize'
  },
  statusWrapper: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  buttons: {
    display: 'flex',
    flexWrap: 'wrap',
    position: 'absolute',
    right: '1.5rem',
    top: '1.5rem',
    width: '3rem'
  },
  relative: {
    margin: 'auto',
    position: 'relative',
    maxWidth: '100rem',
    padding: 0
  },
  paddedPaper: {
    marginTop: '4rem',
    paddingTop: '2rem',
    boxShadow: 'none',
    position: 'relative'
  },
  requestChangeLink: {
    alignSelf: 'flex-end',
    backgroundColor: 'rgba(0,0,0, 0.1)',
    fontFamily: 'Poppins',
    fontSize: '75%',
    fontWeight: 'bold',
    padding: '.5rem 1rem',
    textAlign: 'center',
    textDecoration: 'none',
    textTransform: 'uppercase',
    transition: 'all .25s',
    '& p, & > span, & a': {
      color: 'rgba(0,0,0, .7) !important',
      fontFamily: 'Poppins',
      fontWeight: 'bold',
      fontSize: '100%',
      transition: 'all .25s'
    },
    '&:hover': {
      background: 'rgba(0,0,0, 0.15)',
      color: 'black !important',
      '& span, & p': {
        color: 'black !important'
      }
    },
    requestChangeLinkEdit: {
      margin: 0
    }
  },
  actions: {
    alignSelf: 'flex-end',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    padding: '1.45rem'
  },
  metadata,
  metadataItem,
  marginRight: {
    marginRight: '.25rem'
  }
})

function UserProfile (props) {
  const {
    slug,
    token,
    name,
    classes,
    avatarUrl,
    location,
    badges,
    loading,
    companies,
    toggleRequestMeetingModal,
    linkedInUrl,
    twitterUrl,
    githubUrl,
    facebookUrl,
    memberSince,
    title,
    url,
    phone,
    bio,
    can,
    investor,
    isInvestor,
    following,
    refetch,
    onFollowUnfollow
  } = props

  const MetadataItem = ({ icon, children }) => (
    <Typography className={classes.metadataItem}>
      {icon && (
        <FontAwesomeIcon icon={icon} className={classes.tagIcon} />
      )}
      {children}
    </Typography>
  )

  let socialLinks = []
  if (linkedInUrl) {
    socialLinks.push({ icon: faLinkedin, url: linkedInUrl })
  }
  if (twitterUrl) {
    socialLinks.push({ icon: faTwitter, url: twitterUrl })
  }
  if (facebookUrl) {
    socialLinks.push({ icon: faFacebook, url: facebookUrl })
  }
  if (githubUrl) {
    socialLinks.push({ icon: faGithub, url: githubUrl })
  }
  if (url) {
    socialLinks.push({ icon: faGlobe, url: url })
  }

  let avatar = <img alt="avatar" src={avatarUrl || '/user.png'} className={classes.logo} />
  if (can && can['manage']) {
    avatar = (
      <Mutation mutation={UpdateUser} onCompleted={refetch}>
        {updateUser => {
          const onChange = evt => {
            updateUser({
              variables: {
                id: slug,
                userInput: { avatar: evt.target.files[0] }
              }
            })
          }
          return (
            <EditableImage
              src={avatarUrl}
              className={classes.logo}
              onChange={onChange}
            />
          )
        }}
      </Mutation>
    )
  }

  const startupCategories = investor && investor.startupCategories
    ? investor.startupCategories.map((startupCategory, i) => <span
      className={classes.badge}
      key={`badges-${i}`} >{startupCategory.title}{investor.startupCategories.length - 1 === i ? '' : ', '}</span>)
    : null

  return (
    <HeaderHero
      profile
      title={name || <Skeleton />}
    >
      <Grid container alignItems="flex-start" className={classes.relative}>
        <Grid item xs={12} className={classes.centered}>
          {avatar}
          <MainHeading
            primary={name || <Skeleton />}
            secondary={/*title || <Skeleton />*/ ''}
          />
          <Typography className={classes.marginBottom}>
            {badges && badges.map((badgeText, i) => <span
              className={classes.badge}
              key={badgeText}
            >{`${badgeText}, `}</span>)}
            {startupCategories}
          </Typography>
          <ul className={classes.userDetails}>
            <li>{socialLinks && <SocialLinks centered socialLinks={socialLinks} />}</li>
            <li>
              {location && !loading && <MetadataItem icon={faMapMarkerAlt}>
                {location}
              </MetadataItem>}
            </li>
            <li>
              {location && !loading && <MetadataItem icon={faCalendarCheck}>
                Member since: {dateFormatMMYY(memberSince)}
              </MetadataItem>}
              {memberSince && <Typography variant="caption" className={classes.memberSince}><small></small></Typography>}
            </li>
            <li>
              {phone && !loading && (
                <MetadataItem icon={faPhone}>
                  {phone}
                </MetadataItem>
              )}
            </li>
          </ul>
          <div className={classes.verticalSpacer}>
            {/* !loading && investor &&
              investor.lowInvestmentRange &&
              investor.highInvestmentRange &&
              <Typography component="p" className={classes.badge}>
                {'Investment range: '}
                <NumberFormat value={investor.lowInvestmentRange} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                {' to '}
                <NumberFormat value={investor.highInvestmentRange} displayType={'text'} thousandSeparator={true} prefix={'$'} />
              </Typography>
            */}

            {/* !loading && investor &&
              investor.focus &&
              <Typography component="p" className={classes.badge}>
                Focus: {investor.focus}
              </Typography>
            */}
          </div>
          {!loading && can && <div className={classes.buttons}>
            {can['manage'] && (
              <Button
                component={BrowserLink}
                size="small"
                color="primary"
                to={`/users/${slug}/edit`}
                className={`${classes.roundButton}`}
                title="Edit"
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Button>
            )}
            {can['manage'] && (
              <Button
                component={BrowserLink}
                size="small"
                color="primary"
                to={`/users/${slug}/visits`}
                className={`${classes.roundButton}`}
                title="Visits"
              >
                <FontAwesomeIcon icon={faEye} />
              </Button>
            )}
          </div>}
          {!loading && !can['manage'] && (
            <Button
              size="small"
              color="primary"
              onClick={toggleRequestMeetingModal(token)}
              title='Request Meeting'
            >
              <FontAwesomeIcon icon={faUsers} />
            </Button>
          )}
          {!loading && !can['manage'] && (
            <Button
              title={following ? 'Unfollow' : 'Follow'}
              size="small"
              disabled={token === null}
              color="primary"
              onClick={onFollowUnfollow}
            >
              <FontAwesomeIcon icon={following ? faUserMinus : faUserPlus} />
            </Button>
          )}
        </Grid>
      </Grid>
    </HeaderHero>
  )
}

export default withStyles(styles)(withMobileDialog()(UserProfile))
