import Input from '@material-ui/core/Input'
import Typography from '@material-ui/core/Typography'
import React, { useState } from 'react'
import urlize from 'urlize.js'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers'

const onEnter = onSubmit => (evt) => evt.key === 'Enter' ? onSubmit() : null

export default function DateComponent (props) {
  const {
    value,
    onChange,
    label,
    content,
    required,
    placeholder,
    onSubmit
  } = props

  let renderedContent = urlize(content)
  if ((renderedContent).includes('<a')) {
    renderedContent = <div dangerouslySetInnerHTML={{ __html: renderedContent }}></div>
  }

  return <>
    <Typography variant="body1">
      {label} <small><i>{required && '(required)'}</i></small>
    </Typography>
    <div>
      {content && <Typography variant="body1"><small>{renderedContent}</small></Typography>}
    </div>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        fullWidth
        margin="normal"
        id="mui-pickers-date"
        label="Date picker"
        value={value}
        placeholder={placeholder}
        onKeyPress={onEnter(onSubmit)}
        onChange={(val) => onChange(val) }
        KeyboardButtonProps={{
          'aria-label': 'change date'
        }}
      />
    </MuiPickersUtilsProvider>
  </>
}
