import { withStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'
import { Query } from 'react-apollo'
import GetStartupCategories from '../../graphql/queries/getStartupCategories'
import Tag from './Tag'

const styles = theme => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  selectedClass: {
    border: '1px solid #ddd',
    background: '#eee',
    fontSize: '12px',
    display: 'inline-block',
    padding: '5px',
    margin: '0 5px',
    cursor: 'move',
    borderRadius: '2px'
  }
})

function Tags (props) {
  const {
    value,
    onChange,
    content,
    label
  } = props

  const [tags, setTag] = useState([])

  return <Query query={GetStartupCategories}>
    {({ data, loading }) => {
      const handleChange = name => event => {
        const tagId = event.target.value
        if (tags.map((a) => a.id).includes(tagId)) {
          const filteredTags = tags.filter((tag, index) => tag.id !== tagId)
          setTag(filteredTags)
          onChange(filteredTags.map(t => t.id).join(','))
        } else {
          tags.push({ id: tagId, title: name })
          setTag(tags)
          onChange(tags.map(t => t.id).join(','))
        }
      }

      let initialTags = []
      if (!loading) {
        initialTags = data.startupCategories
      }

      return <Tag initialTags={initialTags} label={label} content={content} value={value} handleChange={handleChange} />
    }}
  </Query>
}

export default withStyles(styles, { withTheme: true })(Tags)
