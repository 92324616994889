import gql from 'graphql-tag'

const getConversations = gql`
  query Conversations {
    conversations {
      id
      title
      participants {
        id
        avatarUrl
        name
      }
      discussion {
        id
        messages {
          id
          body
          user {
            id
            avatarUrl
            name
          }
        }
      }
    }
  }
`

export default getConversations
