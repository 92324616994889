import { List, TextField, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import TitledPaper from '../core/TitledPaper'
import { textInput, blueButton, mediumTitle } from '../layout/styleComponents'
import Form from '../form/ListForm'

const styles = theme => ({
  mediumTitle: {
    ...mediumTitle,
    margin: '4rem auto 7rem'
  },
  textInput: textInput,
  button: blueButton,
  container: {
    margin: 'auto',
    maxWidth: '50rem',
    width: 'calc(100% - 3rem)'
  }
})

class EditUserExperienceBase extends React.Component {
  render () {
    const {
      classes,
      enqueueSnackbar,
      title,
      buttonText,
      onSave,
      startYear,
      endYear,
      company,
      position,
      setStartYear,
      setEndYear,
      setCompany,
      setPosition
    } = this.props

    const renderUserExperienceLine = () => {
      return (
        <List>
        </List >
      )
    }

    return <TitledPaper className={classes.container} noLogo key="edit-container">
      <Form
        submitBtnText={buttonText || 'Update'}
        onSubmit={() => {
          if (startYear === '' || endYear === '' || company === '' || position === '') {
            enqueueSnackbar('You need to fill up all the fields', { variant: 'error' })
          } else {
            onSave({ startYear: parseInt(startYear), endYear: parseInt(endYear), company, position })
          }
        }}
        title={<Typography className={classes.mediumTitle}>Edit your work experience</Typography>}
        classes={classes}
      >
        <TextField className={classes.textInput} required label="Start Year" fullWidth onChange={(e) => setStartYear(e.target.value)} value={startYear} />
        <TextField className={classes.textInput} required label="End Year" fullWidth onChange={(e) => setEndYear(e.target.value)} value={endYear} />
        <TextField className={classes.textInput} required label="Company" fullWidth onChange={(e) => setCompany(e.target.value)} value={company} />
        <TextField className={classes.textInput} required label="Position" fullWidth onChange={(e) => setPosition(e.target.value)} value={position} />
      </Form>
    </TitledPaper>
  }
}

const EditUserExperience = withStyles(styles, { withTheme: true })(EditUserExperienceBase)

export default EditUserExperience
