import gql from 'graphql-tag'

const getDescriptionLegalNameOfInvestment = gql`
  query getDescriptionLegalNameOfInvestment {
    descriptionLegalNameOfInvesmentText {
      descriptionText
    }
  }
`

export default getDescriptionLegalNameOfInvestment
