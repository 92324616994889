import { faComment } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AppBar, Button, Dialog, DialogContent, Grid, IconButton, Slide, Toolbar, Typography, withMobileDialog } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import CloseIcon from '@material-ui/icons/Close'
import React from 'react'
import "react-image-gallery/styles/css/image-gallery.css"
import { Link as BrowserLink } from 'react-router-dom'
import CreateUserUpdateForm from '../core/CreateUserUpdateForm'
import Message from '../core/Message'
import TitledPaper from '../core/TitledPaper'
import brand from '../layout/brand'
import { blackText, transparentBackgroundContainer, blueButton, centeredFlex, smallTitle, subTitle } from '../layout/styleComponents';
import TabsContainer from '../layout/TabsContainer'
import HeaderHero from '../core/HeaderHero'
import MainHeading from '../core/MainHeading'
import { faBan, faEye, faLongArrowRight } from '@fortawesome/pro-light-svg-icons'
import { Mutation } from 'react-apollo'
import MarkNotificationAsRead from '../../graphql/mutations/MarkNotificationAsRead'
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons'
import { blueRibbon, background, bigStone, white } from '../layout/colors'

const truncateWithEllipses = (text, max) => text.substr(0, max - 1) + (text.length > max ? '...' : '')

function Transition (props) {
  return <Slide direction="up" {...props} />;
}

const styles = theme => ({
  modal: {
    '& .MuiDialog-paperFullWidth, & .MuiDialog-paperFullScreen': {
      borderRadius: '.5rem',
      maxWidth: '45rem',
      width: '95%',
      maxHeight: '80%'
    },
    '& .MuiDialogContent-root': {
      padding: '0 1.5rem 2rem',
      overFlow: 'hidden'
    }
  },
  blackLabel: {
    ...blackText,
    fontWeight: 500,
    fontSize: '.85rem',
    margin: 0
  },
  icon: {
    marginLeft: '.5rem',
  },
  appBarTitle: {
    ...blackText,
    margin: 0,
    fontSize: '1.25rem',
    flex: 1,
    textAlign: 'left'
  },
  appBarIcon: {
    marginRight: '-1rem'
  },
  toolbar: {
    background: 'none',
    justifyContent: 'flex-end',
    padding: 0
  },
  appBar: {
    backgroundColor: theme.palette.info.light,
    margin: 0,
    width: '100%',
    marginBottom: '1rem',
    position: 'relative',
    paddingRight: '0 !important',
    padding: '.5rem 1.5rem'
  },
  paper: {
    background: 'white',
  },
  button: blueButton,
  transparentBackgroundContainer: {
    ...transparentBackgroundContainer,
    background: 'white'
  },
  item: {
    background: 'white',
    boxShadow: `0 0 .5rem -.35rem ${theme.palette.success.dark}`,
    maxWidth: '15rem',
    margin: '0 .75rem 1.5rem'
  },
  flex: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    width: '100%'
  },
  title: {
    marginLeft: '1rem',
    flexGrow: 3
  },
  dataInfo: {
    color: theme.palette.primary.main,
    fontFamily: 'Lato !important',
    fontSize: '.95rem',
    '& span, & p': {
      color: `${theme.palette.primary.main} !important`,
      fontFamily: 'Lato !important',
      fontWeight: 'bold'
    }
  },
  startDate: {
    color: 'rgba(0,0,0, .5)',
    marginLeft: '.5rem',
    '& span, & p': {
      color: 'rgba(0,0,0, .5)'
    }
  },
  data: {
    width: '100%'
  },
  titleWrapper: {
    margin: '1rem 0'
  },
  heroCentered: {
    minHeight: '12rem'
  },
  badge: {
    width: '100%',
    '& > span': {
      background: theme.palette.error.main,
      top: '1.25rem',
      right: '1.25rem'
    }
  },
  dropdownOptions: {
    background: 'white',
    borderRadius: '.5rem',
    boxShadow: `0 0 .6rem -.3rem ${bigStone}`,
    padding: '.5rem',
    position: 'absolute',
    top: '100%',
    zIndex: 9,
    listStyle: 'none',
    margin: 0,
    padding: 0,
    width: '100%'
  },
  updatesButton: blueButton,
  text: {
    ...blackText,
    fontSize: '1rem',
    margin: 0,
    fontWeight: 400,
    width: '100%'
  },
  noUpdates: {
    ...centeredFlex,
    backgroundColor: 'white',
    width: '100%'
  },
  iconBig: {
    color: theme.palette.info.light,
    fontSize: '6rem',
    fontWeight: 300,
    margin: '3rem auto 1.5rem'
  },
  actionsFilterBar: {
    borderBottom: `.015rem solid ${background}`,
    padding: '.5rem 1.25rem',
    backgroundColor: background,
    listStyle: 'none',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 0,
    width: '100%',
    '& li:first-child': {
      width: '100%',
      '@media (min-width: 45rem)': {
        width: '60%'
      }
    },
    '& li:nth-child(2)': {
      '@media (max-width: 50rem)': {
        display: 'none'
      }
    }
  },
  link: {
    ...smallTitle,
    fontSize: '.85rem',
    textDecoration: 'none',
    padding: 0,
    minWidth: 'unset',
    '& span': {
      fontWeight: 400
    }
  },
  option: {
    color: blueRibbon,
    fontSize: '.85rem',
    padding: '.5rem 1rem',
    width: '100% !important',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.info.light,
    }
  },
  centered: {
    ...centeredFlex,
    position: 'relative',
    '&:first-child': {
      marginRight: '1.5rem'
    },
    '& > label': {
      marginRight: '.5rem'
    }
  },
  relative: {
    position: 'relative'
  },
})



class UserUpdatesBase extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      createUserUpdateOpen: false,
      showOptions: false,
      showFilters: false,
      currentOption: 0,
      currentFilter: 0,
      uneadUpdatesState: [],
      readUpdatesState: [],
      messageCounterState: {},
      UpdatesState: [],
      oldUpdates: props.updates,
      noDataText: 'User has no updates yet.',
    }
  }

  componentDidMount () {
    const {
      updates,
      unreadUserUpdates,
    } = this.props
    this.setState({
      oldUpdates: updates,
      UpdatesState: updates
    }, () => {
      this.setUpdates(updates, unreadUserUpdates)
    })
  }

  componentWillReceiveProps (newProps) {
    const {
      updates,
      unreadUserUpdates,
    } = newProps
    if (this.state.oldUpdates.length !== updates.length) {
      this.setState({
        oldUpdates: updates,
        UpdatesState: updates,
        uneadUpdatesState: [],
        readUpdatesState: [],
        messageCounterState: {},
      }, () => {
        this.setUpdates(updates, unreadUserUpdates)
      })
    }
  }

  setUpdates (updates, unreadUserUpdates) {
    if (unreadUserUpdates && unreadUserUpdates.unreadNotifications.length > 0 && this.state.uneadUpdatesState.length === 0) {
      let unreadMessagesArray = []
      let unreadMessagesCountObject = {}
      let readConversations = []
      const notifications = unreadUserUpdates.unreadNotifications.filter(notification => notification.notifiable.__typename !== 'MeetingRequest' && notification.notifiable.__typename !== 'CompanyUpdateDiscussion')
      notifications.map(unreadNotification => {
        const discussionAdded = this.discussionExist(unreadNotification.notifiable.discussion.id, unreadMessagesArray)
        if (discussionAdded) unreadMessagesCountObject[unreadNotification.notifiable.discussion.id] = unreadMessagesCountObject[unreadNotification.notifiable.discussion.id] + 1
        else {
          const discussion = updates.filter(updt => updt.id === unreadNotification.notifiable.discussion.id)
          unreadMessagesArray.push(discussion[0])
          unreadMessagesCountObject[unreadNotification.notifiable.discussion.id] = 1
        }
      })
      updates.map(update => {
        const haveUnreadMessages = unreadMessagesArray.filter(notificationUnread => notificationUnread.id === update.id)
        const discussionAdded = this.discussionExist(update.id, readConversations)
        if (haveUnreadMessages.length === 0 && !discussionAdded) readConversations.push(update)
      })
      this.setState({
        readUpdatesState: readConversations,
        uneadUpdatesState: unreadMessagesArray,
        messageCounterState: unreadMessagesCountObject
      })
    } else if (unreadUserUpdates && unreadUserUpdates.unreadNotifications.length === 0 && this.state.readUpdatesState.length === 0) {
      this.setState({readUpdatesState: updates})
    }
  }

  discussionExist (id, list) {
    const exist = list && list.length > 0 && list.filter(item => item.id === id)
    if (exist && exist.length > 0) return true
    else return false
  }

  changeFilter (filter, index) {
    if (this.state.currentFilter !== index) {
      this.setState({UpdatesState: this.state.UpdatesState.reverse()})
    }
    this.setState({currentFilter: index})
  }

  markAllAsRead () {
    const notifications = this.props.unreadUserUpdates && this.props.unreadUserUpdates.unreadNotifications && this.props.unreadUserUpdates && this.props.unreadUserUpdates.unreadNotifications.filter(notification => notification.notifiable.__typename !== 'MeetingRequest' && notification.notifiable.__typename !== 'CompanyUpdateDiscussion') || []
    notifications.map((notification, index) => {
      this.props.markNotificationAsRead({ variables: { notificationId: notification.id } })
      if (index + 1 === this.props.unreadUserUpdates.unreadNotifications.length) {
        setTimeout(() => {
          this.props.refetchUnreadUpdates()
        }, 2000)
      }
    })
  }

  selectViewType (index, option) {
    this.setState({currentOption: index})
    switch (option.value) {
      case 'read':
        this.setState({
          noDataText: 'User has no updates without unread messages.',
          UpdatesState: this.state.currentFilter === 0 ? this.state.readUpdatesState : this.state.readUpdatesState.reverse()
        })
        break
      case 'unread':
        this.setState({
          noDataText: 'User has no unread messages.',
          UpdatesState: this.state.currentFilter === 0 ? this.state.uneadUpdatesState : this.state.uneadUpdatesState.reverse()
        })
        break
      case 'all':
        this.setState({
          noDataText: 'User has no updates yet.',
          UpdatesState: this.state.currentFilter === 0 ? this.state.oldUpdates : this.state.oldUpdates.reverse()
        })
        break
    }
  }

  handleCreateUserUpdateOpen = () => {
    this.setState({ createUserUpdateOpen: true })
  };

  handleCreateUserUpdateClose = () => {
    this.setState({ createUserUpdateOpen: false })
  };

  render () {
    const {
      fullScreen,
      history,
      userSlug,
      can,
      classes,
      updates,
      refetchUnreadUpdates,
      unreadUserUpdates,
      refetch
    } = this.props

    let createUserUpdateButton;

    const options = [{name: 'All', value: 'all'}, {name: 'Read', value: 'read'}, {name: 'Unread', value: 'unread'}]
    const filters = [{name: 'Last Activity', value: 'desc'}, {name: 'Date of creation', value: 'asc'}]
    const dropdownOptions = []
    const dropdownFilters = []

    options.map((option, index) => {
      dropdownOptions.push(<li key={option.value} className={classes.option} onClick={() => this.selectViewType(index, option)}>{option.name}</li>)
    })

    filters.map((filter, index) => {
      dropdownFilters.push(<li key={filter.value} className={classes.option} onClick={() => this.changeFilter(filter.value, index)}>{filter.name}</li>)
    })

    if (can.manage) {
      createUserUpdateButton = <>
        <Button className={classes.button} onClick={this.handleCreateUserUpdateOpen}>Create Update <FontAwesomeIcon icon={faLongArrowRight} /> </Button>
        <Dialog
          TransitionComponent={Transition}
          fullScreen={fullScreen}
          fullWidth
          maxWidth="md"
          open={this.state.createUserUpdateOpen}
          onClose={this.handleCreateUserUpdateClose}
          scroll="paper"
          className={classes.modal}
        >
          <AppBar
            color="default"
            elevation={0}
            className={classes.appBar}
          >
            <Toolbar
              className={classes.toolbar}
            >
              <Typography variant="h6" color="inherit" className={classes.appBarTitle}>
                Create a new Update
              </Typography>
              <IconButton
                color="inherit"
                onClick={this.handleCreateUserUpdateClose}
                aria-label="Close"
                className={classes.appBarIcon}
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <CreateUserUpdateForm onSave={refetch} userSlug={userSlug} />
          </DialogContent>
        </Dialog>
      </>
    }

    return <>
    <HeaderHero className={`${classes.centered} ${classes.heroCentered}`}>
      <Grid container alignItems="flex-start" className={classes.relative}>
        <Grid item xs={12} className={classes.centered}>
          <MainHeading
            primary='Updates'
          />
        </Grid>
      </Grid>
    </HeaderHero>
    <Grid container className={classes.transparentBackgroundContainer}>
      <TabsContainer tabs={[]} icon={faEye} title="Update">
        <TitledPaper noLogo actions={createUserUpdateButton}>
          <ul className={classes.actionsFilterBar}>
            <li>
              <Mutation mutation={MarkNotificationAsRead}>
                {markNotificationAsRead => {
                  return <Button
                    className={classes.link}
                    component={BrowserLink}
                    size="small"
                    color="primary"
                    onClick={() => this.markAllAsRead(markNotificationAsRead)}
                  >
                    Mark all as read
                  </Button>
                }}
              </Mutation>
            </li>
            <li style={{display: 'flex'}}>
              <div className={classes.centered}>
                <label className={classes.blackLabel}>Showing:</label>
                <Button
                  className={classes.link}
                  component={BrowserLink}
                  size="small"
                  color="primary"
                  onClick={() => this.setState({showOptions: !this.state.showOptions})}
                >
                  {options[this.state.currentOption].name}
                  <FontAwesomeIcon className={classes.icon} icon={this.state.showOptions ? faCaretUp : faCaretDown} />
                </Button>
                {this.state.showOptions && <ul className={classes.dropdownOptions}>
                  {dropdownOptions}
                </ul>}
              </div>
              <div className={classes.centered}>
                <label className={classes.blackLabel}>Sort by:</label>
                <Button
                  className={classes.link}
                  component={BrowserLink}
                  size="small"
                  color="primary"
                  onClick={() => this.setState({showFilters: !this.state.showFilters})}
                >
                  {filters[this.state.currentFilter].name}
                  <FontAwesomeIcon className={classes.icon} icon={this.state.showFilters ? faCaretUp : faCaretDown} />
                </Button>
                {this.state.showFilters && <ul className={classes.dropdownOptions}>
                  {dropdownFilters}
                </ul>}
              </div>
            </li>
          </ul>
          {updates.length > 0 &&
            <Grid container style={{width: '100%'}}>
              {this.state.UpdatesState.map((discussion) => {
                let firstMessage = discussion.messages[0]
                if (!firstMessage) {
                  return null
                }
                const replyCount = discussion.messages.length - 1

                firstMessage.body = truncateWithEllipses(firstMessage.body, 250)

                return <Grid item xs={12} md={12} key={discussion.id} style={{padding: 0}}>
                  <Message
                    message={firstMessage}
                    onClick={() => history.push(`./all/${discussion.id}`, { state: { from: history.location.pathname } })}
                    metadata={
                      <Typography style={{ display: 'flex',
                        flexDirection: 'row',
                        fontSize: '.85rem',
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                        width: '3.5rem' }}>
                          <FontAwesomeIcon icon={faComment} />
                          {replyCount}
                        </Typography>}
                  />
                </Grid>
              })}
              {!this.state.UpdatesState || this.state.UpdatesState.length === 0 && (
                <section className={classes.noUpdates}>
                  <FontAwesomeIcon icon={faBan} className={classes.iconBig} />
                  <Typography className={classes.text}>
                    {this.state.noDataText}
                  </Typography>
                </section>
              )}
            </Grid>
          }
        </TitledPaper>
      </TabsContainer>
    </Grid>
  </>}
}

const UserUpdates = withStyles(styles)(withMobileDialog()(UserUpdatesBase))

export default UserUpdates
