import React, {useEffect} from 'react'
import { Query } from 'react-apollo'
import { Switch } from 'react-router'
import { Route } from 'react-router-dom'
import { REFETCH } from '../../graphql/networkStatuses'
import getUserUpdates from '../../graphql/queries/getUserUpdates'
import AppLayout from '../layout/AppLayout'
import CenterColumn from '../layout/CenterColumn'
import Title from '../layout/Title'
import UserUpdates from '../user/UserUpdates'
import UserUpdateModalRoute from './UserUpdateModalRoute'
import {getUnreadConversationsAndUpdates} from '../../graphql/queries/getNotifications'

function UserUpdatesRoute ({ match, history }) {
  const { params: { slug } } = match
  useEffect(() => {
    document.title = "The Syndicate - User Updates"
  }, []);
  const goBack = history => () => {
    if (history.location.state && history.location.state.from) {
      history.goBack()
    } else {
      history.push('../all')
    }
  }
  return <Query query={getUserUpdates} variables={{ slug: slug }} fetchPolicy="cache-and-network">
    {({ loading, error, data, refetch, networkStatus }) => {
      const refetchUSerUpdates = refetch
      if (loading) {
        if (networkStatus !== REFETCH) {
          return null
        }
      }
      if (error) {
        return <h2>Error...</h2>
      }

      const { user } = data

      const title = <Title>
        {user.name} Updates
      </Title>

      return <Query query={getUnreadConversationsAndUpdates} fetchPolicy="cache-and-network">
        {({ loading, error, data, refetch, networkStatus }) => {
          const refetchUnreadUpdates = refetch
          if (loading) {
            if (networkStatus !== REFETCH) {
              return null
            }
          }
          return <AppLayout title={title}>
            <CenterColumn>
              <UserUpdates unreadUserUpdates={data.profile} refetchUnreadUpdates={refetchUnreadUpdates} refetch={refetchUSerUpdates} history={history} updates={user.userUpdates} userSlug={slug} can={user.can} userName={user.name} />
              <Switch>
                <Route path={`${match.url}/:id`} render={(props) => <UserUpdateModalRoute {...props} userSlug={slug} onClose={goBack(history)} />} />
              </Switch>
            </CenterColumn>
          </AppLayout>
        }}
      </Query>
    }}
  </Query>
}

export default UserUpdatesRoute
