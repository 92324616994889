import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'
import React from 'react'

const styles = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    width: '100%'
  },
  button: {
    width: '100%'
  },
  overlay: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'space-around',
    margin: 'auto',
    width: '6rem',
    height: '6rem',
    top: '0',
    opacity: 0,
    transition: '0.3s',
    zIndex: 9,
    '&:hover': {
      opacity: 1
    }
  },
  fog: {
    borderRadius: '100%',
    position: 'absolute',
    content: '""',
    display: 'block',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 40%)',
    zIndex: 1
  },
  icon: {
    color: 'white',
    padding: '1rem',
    margin: 'auto',
    zIndex: 10,
    fontSize: '5rem',
    cursor: 'pointer'
  }
})

const EditableImage = props => {
  const { src, className, classes, onChange, heroImage } = props
  const avatarUrl = src || '/user.png'

  return <div className={`${classes.container}`}>
    <div className={classes.overlay}>
      <div className={classes.fog}></div>
      <Button
        component="label"
        className={classes.button}
      >
        <EditIcon className={classes.icon} />
        <input
          type="file"
          style={{ display: 'none' }}
          onChange={onChange}
        />
      </Button>
    </div>
    { heroImage ? (
      <figure style={{ background: `url(${avatarUrl}) center center no-repeat`, backgroundSize: 'contain', height: '15rem' }} className={className} />
    ) : (
      <img src={avatarUrl} className={className} />
    )}
  </div>
}

export default withStyles(styles)(EditableImage)
