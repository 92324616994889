import { Button, Grid, List, ListItem, ListItemText, TextField, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'

const styles = theme => ({
  selectedClass: {
    border: '1px solid #ddd',
    background: '#eee',
    fontSize: '12px',
    display: 'inline-block',
    padding: '5px',
    margin: '0 5px',
    cursor: 'move',
    borderRadius: '2px'
  },
  addExperience: {
    marginTop: '1rem'
  }
})

class ExperienceModel {
  constructor (startYear, endYear, company, position) {
    this.startYear = startYear
    this.endYear = endYear
    this.company = company
    this.position = position
  }
}

function ExperienceLine (props) {
  const {
    startYear,
    setStartYear,
    endYear,
    setEndYear,
    company,
    setCompany,
    position,
    setPosition,
    id,
    value,
    onChange,
    label,
    placeholder,
    content,
    errorMessage,
    required
  } = props
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <TextField label="Company" fullWidth onChange={(e) => setCompany(e.target.value)} value={company} />
      </Grid>
      <Grid item xs={12}>
        <TextField label="Position" fullWidth onChange={(e) => setPosition(e.target.value)} value={position} />
      </Grid>
      <Grid item xs={6}>
        <TextField label="Start year" fullWidth onChange={(e) => setStartYear(e.target.value)} value={startYear} />
      </Grid>
      <Grid item xs={6}>
        <TextField label="End year" fullWidth onChange={(e) => setEndYear(e.target.value)} value={endYear} />
      </Grid>
    </Grid>
  )
}

function Experience (props) {
  const {
    value,
    classes,
    content,
    required,
    onChange,
    label
  } = props

  const [experiences, addExperience] = useState([])
  const [startYear, setStartYear] = useState('')
  const [endYear, setEndYear] = useState('')
  const [company, setCompany] = useState('')
  const [position, setPosition] = useState('')

  const clearFields = () => {
    setStartYear('')
    setEndYear('')
    setCompany('')
    setPosition('')
  }

  return <><Typography variant="body1">
    {label} <small><i>{required && '(required)'}</i></small>
  </Typography>
    <Typography variant="body1">
      <small>{content}</small>
    </Typography>
    <div className={classes.wrapper}>
      <ExperienceLine startYear={startYear}
        setStartYear={setStartYear}
        endYear={endYear}
        setEndYear={setEndYear}
        company={company}
        setCompany={setCompany}
        position={position}
        setPosition={setPosition}
        {...props} />
      <Button
        onClick={() => {
          const newExperiencesArray = [...experiences, new ExperienceModel(startYear, endYear, company, position)]
          addExperience(newExperiencesArray)
          onChange(newExperiencesArray)
          clearFields()
        }}
        variant="contained"
        className={classes.addExperience}
      >
        Add Experience
      </Button>
    </div>
    <List>
      {experiences.map((experience, i) => {
        return <ListItem alignItems="flex-start" key={i}>
          <ListItemText
            primary={experience.company}
            secondary={
              <React.Fragment>
                <Typography color="textPrimary">
                  {experience.position}
                </Typography>
                {`${experience.startYear} - ${experience.endYear}`}
              </React.Fragment>
            }
          />
        </ListItem>
      })}
    </List>
  </>
}

export default withStyles(styles, { withTheme: true })(Experience)
