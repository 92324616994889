export function reducer (state, action) {
  switch (action.type) {
    case 'setQuestionValue':
      state.questions[action.questionId] = action.value
      console.log('global state', state)
      return { ...state }
    case 'finish':
      state = { ...state, finished: true }
      console.log('state', state)
      return state
    default:
      throw new Error()
  }
}

export const initialState = { questions: {}, finished: false }
