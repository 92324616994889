import React, { useState, useEffect } from 'react'
import { Mutation } from 'react-apollo'
import CreateFundingHistory from '../../graphql/mutations/CreateFundingHistory'
import EditFundingHistory from '../company/EditFundingHistory'
import AppLayout from '../layout/AppLayout'
import CenterColumn from '../layout/CenterColumn'
import Title from '../layout/Title'

function EditFundingHistoryRoute (props) {
  const { match: { params: { slug } }, history } = props

  const [roundName, setRoundName] = useState('')
  const [roundNumber, setRoundNumber] = useState('')
  const [amount, setAmount] = useState(0)
  const [cap, setCap] = useState(0)
  const [price, setPrice] = useState(0)
  useEffect(() => {
    document.title = "The Syndicate - Edit Funding History"
  }, []);
  const title = <Title>
    Edit your funding history
  </Title>

  return <AppLayout title={title}>
    <CenterColumn>
      <Mutation
        onCompleted={data => {
          if (data) {
            history.push(`/companies/${slug}`)
          }
        }}
        mutation={CreateFundingHistory}
      >
        {createFundingHistory => {
          const onSave = fundingHistoryInput => createFundingHistory({ variables: { companySlug: slug, fundingHistoryInput } })
          return <EditFundingHistory
            amount={amount}
            cap={cap}
            price={price}
            roundName={roundName}
            roundNumber={roundNumber}
            setAmount={setAmount}
            setCap={setCap}
            setPrice={setPrice}
            setRoundName={setRoundName}
            setRoundNumber={setRoundNumber} onSave={onSave}
          />
        }}
      </Mutation>
    </CenterColumn>
  </AppLayout>
}

export default EditFundingHistoryRoute
