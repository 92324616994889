import React, { useState, useEffect } from 'react'
import { Mutation, Query } from 'react-apollo'
import { Link as BrowserLink } from 'react-router-dom'
import MarkNotificationAsRead from '../../graphql/mutations/MarkNotificationAsRead'
import getNotification from '../../graphql/queries/getNotification'
import AppLayout from '../layout/AppLayout'
import CenterColumn from '../layout/CenterColumn'
import Title from '../layout/Title'
import NotificationShow from '../notifications/NotificationShow'

function NotificationRoute ({
  match: {
    params: { id }
  }
}) {
  const [notificationRead, markAsRead] = useState(false)
  useEffect(() => {
    document.title = "The Syndicate - Notification"
  }, []);
  return (
    <Mutation mutation={MarkNotificationAsRead}>
      {markNotificationAsRead => {
        return (
          <Query
            query={getNotification}
            fetchPolicy="cache-and-network"
            onCompleted={() => {
              if (!notificationRead) {
                markNotificationAsRead({ variables: { notificationId: id } })
                markAsRead(true)
              }
            }}
            variables={{ id }}
          >
            {({ loading, error, data, refetch }) => {
              if (error) {
                return <h2>Error...</h2>
              }
              let notification
              if (data) {
                notification = data.notification
              }

              const title = (
                <Title>
                  <BrowserLink to="/notifications">
                    &lt;- Notifications
                  </BrowserLink>
                </Title>
              )
              return (
                <AppLayout title={title}>
                  <CenterColumn>
                    <NotificationShow {...notification} refetch={refetch} />
                  </CenterColumn>
                </AppLayout>
              )
            }}
          </Query>
        )
      }}
    </Mutation>
  )
}

export default NotificationRoute
