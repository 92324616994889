import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import UpdateCompany from '../../graphql/mutations/UpdateCompany'
import { Mutation } from 'react-apollo'
import EditableImage from '../core/EditableImage'

const styles = theme => ({
})

const Title = props => {
  const { className, refetch, logoUrl, slug, heroImage } = props
  return <Mutation mutation={UpdateCompany} onCompleted={refetch}>
    {updateCompany => {
      const onChange = evt => {
        updateCompany({
          variables: {
            id: slug,
            companyInput: { logo: evt.target.files[0] }
          }
        })
      }
      return (
        <EditableImage
          src={logoUrl || '/company.png'}
          className={className}
          onChange={onChange}
          heroImage={heroImage}
        />
      )
    }}
  </Mutation>
}

export default withStyles(styles)(Title)
