import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'

const styles = theme => ({
  titleWrapper: {
    marginTop: '1rem',
    marginBottom: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  caption: {
    color: 'rgba(0,0,0, .65)',
    fontFamily: 'Poppins',
    fontSize: '65%',
    marginBottom: '1rem',
    width: '100%'

  },
  title: {
    color: 'black',
    fontFamily: 'Poppins',
    fontSize: '235%',
    fontWeight: 'bold',
    flexGrow: 3,
    marginBottom: '1.5rem',
  },
  actions: {
    textAlign: 'right',
    flexGrow: 1,
    padding: '0 1rem',
    [theme.breakpoints.down('xs')]: {
      padding: '1rem 0.5rem 0 0.5rem',
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%'
    }
  }
})

const Titled = ({ caption, title, titleVariant, classes, children, actions, className }) => <div className={className}>
  <div className={classes.titleWrapper}>
    { caption && <small className={classes.caption}>{caption}</small>}
    <Typography variant={titleVariant || 'h3'} className={classes.title}>{title}</Typography>
    {actions && <div className={classes.actions}>
      {actions}
    </div>}
  </div>
</div>

export default withStyles(styles)(Titled)
