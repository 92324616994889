import { AppBar, Badge, Button, Dialog, DialogContent, Grid, IconButton, Slide, Toolbar, Typography, withMobileDialog } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import React, { useState, useEffect } from 'react'
import { Link as BrowserLink } from 'react-router-dom'
import CreateCompanyUpdateForm from '../core/CreateCompanyUpdateForm'
import Message from '../core/Message'
import TitledPaper from '../core/TitledPaper'
import { blueButton, smallTitle, blackText, centeredFlex } from '../layout/styleComponents'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowRight, faCaretUp, faCaretDown } from '@fortawesome/pro-solid-svg-icons'
import { faBan } from '@fortawesome/pro-light-svg-icons'
import { blueRibbon, gullGray, tealBlue, background, bigStone } from '../layout/colors'
import { Mutation } from 'react-apollo'
import MarkNotificationAsRead from '../../graphql/mutations/MarkNotificationAsRead'

const truncateWithEllipses = (text, max) =>
  text.substr(0, max - 1) + (text.length > max ? '...' : '')

function Transition (props) {
  return <Slide direction="up" {...props} />
}

const styles = theme => ({
  modal: {
    '& .MuiDialog-paperFullWidth, & .MuiDialog-paperFullScreen': {
      borderRadius: '.5rem',
      maxWidth: '45rem',
      maxHeight: '80%',
      width: '95%',
      height: 'unset',
      margin: 'auto'
    },
    '& .MuiDialogContent-root': {
      padding: '0 1.5rem 2rem',
    }
  },
  centered: {
    ...centeredFlex,
    position: 'relative',
    '&:first-child': {
      marginRight: '1.5rem'
    },
    '& > label': {
      marginRight: '.5rem'
    }
  },
  icon: {
    marginLeft: '.5rem',
  },
  blackLabel: {
    ...blackText,
    fontWeight: 500,
    fontSize: '.85rem',
    margin: 0
  },
  actionsFilterBar: {
    borderBottom: `.015rem solid ${background}`,
    padding: '.5rem 1.25rem',
    backgroundColor: background,
    listStyle: 'none',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 0,
    width: '100%',
    '& li:first-child': {
      width: '100%',
      '@media (min-width: 45rem)': {
        width: '60%'
      }
    },
    '& li:nth-child(2)': {
      '@media (max-width: 50rem)': {
        display: 'none'
      }
    }
  },
  link: {
    ...smallTitle,
    fontSize: '.85rem',
    textDecoration: 'none',
    padding: 0,
    minWidth: 'unset',
    '& span': {
      fontWeight: 400
    }
  },
  option: {
    color: blueRibbon,
    fontSize: '.85rem',
    padding: '.5rem 1rem',
    width: '100% !important',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.info.light,
    }
  },
  paper: {
    marginTop: theme.spacing.unit * 3
  },
  appBarTitle: {
    flex: 1
  },
  updatesContainer: {
    cursor: 'pointer',
    margin: 0,
    maxHeight: '40rem',
    overflow: 'auto',
  },
  badge: {
    width: '100%',
    '& > span': {
      background: theme.palette.error.main,
      top: '1.25rem',
      right: '1.25rem'
    }
  },
  updatesPaddedPaper: {
    background: 'none',
    borderBottom: '.2rem solid black',
    boxShadow: 'none',
    margin: '0 auto 4rem',
    padding: '2rem 1rem',
    width: '100%',
    '@media (min-width: 35.5rem)': {
      padding: '2rem'
    }
  },
  appBarIcon: {
    marginRight: '-1rem'
  },
  appBar: {
    backgroundColor: theme.palette.info.light,
    position: 'relative',
    paddingRight: '0 !important',
    padding: '.5rem 1.5rem'
  },
  dropdownOptions: {
    background: 'white',
    borderRadius: '.5rem',
    boxShadow: `0 0 .6rem -.3rem ${bigStone}`,
    padding: '.5rem',
    position: 'absolute',
    top: '100%',
    zIndex: 9,
    listStyle: 'none',
    margin: 0,
    padding: 0,
    width: '100%'
  },
  updatesButton: blueButton,
  text: {
    ...blackText,
    fontSize: '1rem',
    margin: 0,
    fontWeight: 400,
    width: '100%'
  },
  noUpdates: {
    ...centeredFlex,
    backgroundColor: 'white'
  },
  iconBig: {
    color: theme.palette.info.light,
    fontSize: '6rem',
    fontWeight: 300,
    margin: '3rem auto 1.5rem'
  },
})

function ProductHero (props) {
  const {
    classes,
    slug,
    handleCreateCompanyUpdateOpen,
    fullScreen,
    history,
    loading,
    createCompanyUpdateOpen,
    handleCreateCompanyUpdateClose,
    refetch,
    can,
    companyUpdates,
    name,
    unreadNotifications,
    refetchNotifications
  } = props

  let createCompanyUpdateButton

  const [showOptions, setShowOptions] = useState(false)
  const [showFilters, setShowFilters] = useState(false)
  const [currentOption, setCurrentOption] = useState(0)
  const [currentFilter, setCurrentFilter] = useState(0)
  const [oldCompanyUpdatesState, setOldCompanyUpdatesState] = useState(companyUpdates)
  const [oldUnreadCompanyUpdatesState, setOldUnreadCompanyUpdatesState] = useState(unreadNotifications)
  const [unreadCompanyUpdatesState, setUnreadCompanyUpdatesState] = useState([])
  const [readCompanyUpdatesState, setReadCompanyUpdatesState] = useState([])
  const [messageCounterState, setMessageCounterState] = useState({})
  const [companyUpdatesState, setCompanyUpdatesState] = useState(companyUpdates)
  const [noDataText, setNoDataText] = useState('This company has no conversations yet.')

  const discussionExist = (id, list) => {
    const exist = list && list.length > 0 && list.filter(item => item.id === id)
    if (exist && exist.length > 0) return true
    else return false
  }

  const setUpdates = (updates, unreadNotifications) => {
    if (updates && updates.length > 0) {
      if (unreadNotifications && unreadNotifications.unreadNotifications.length > 0 && unreadCompanyUpdatesState.length === 0) {
        let unreadMessagesArray = []
        let unreadMessagesCountObject = {}
        let readConversations = []
        const notifications = unreadNotifications.unreadNotifications.filter(unreadNotification => unreadNotification.notifiable.__typename !== 'MeetingRequest' && unreadNotification.notifiable.__typename !== 'UserUpdateDiscussion')
        notifications.map(unreadNotification => {
          const discussionAdded = discussionExist(unreadNotification.notifiable.discussion.id, unreadMessagesArray)
          if (discussionAdded) unreadMessagesCountObject[unreadNotification.notifiable.discussion.id] = unreadMessagesCountObject[unreadNotification.notifiable.discussion.id] + 1
          else {
            const discussion = updates.filter(disc => disc.id === unreadNotification.notifiable.discussion.id)
            unreadMessagesArray.push(discussion[0])
            unreadMessagesCountObject[unreadNotification.notifiable.discussion.id] = 1
          }
        })
        updates.map(conversation => {
          const haveUnreadMessages = unreadMessagesArray.filter(conversationUnread => {
            return conversationUnread.id === conversation.id
          })
          const discussionAdded = discussionExist(conversation.id, readConversations)
          if (haveUnreadMessages.length === 0 && !discussionAdded) readConversations.push(conversation)
        })
        setReadCompanyUpdatesState(readConversations)
        setUnreadCompanyUpdatesState(unreadMessagesArray)
        setMessageCounterState(unreadMessagesCountObject)
      } else if (unreadNotifications && unreadNotifications.unreadNotifications.length === 0 && readCompanyUpdatesState.length === 0) {
        setReadCompanyUpdatesState(oldCompanyUpdatesState)
      }
    }
  }

  useEffect(() => {
    if (props.companyUpdates[0].id !== oldCompanyUpdatesState[0].id) {
      setUnreadCompanyUpdatesState([])
      setReadCompanyUpdatesState([])
      setMessageCounterState({})
      setOldCompanyUpdatesState(companyUpdates)
      setOldUnreadCompanyUpdatesState(props.unreadNotifications)
      setUpdates(props.companyUpdates, props.unreadNotifications)
    }
  }, [props.companyUpdates])

  useEffect(() => {
    if (oldUnreadCompanyUpdatesState.length !== props.unreadNotifications.length) {
      setUnreadCompanyUpdatesState([])
      setReadCompanyUpdatesState([])
      setMessageCounterState({})
      setOldCompanyUpdatesState(companyUpdates)
      setOldUnreadCompanyUpdatesState(props.unreadNotifications)
      setUpdates(props.companyUpdates, props.unreadNotifications)
    }
  }, [props.unreadNotifications])

  const options = [{name: 'All', value: 'all'}, {name: 'Read', value: 'read'}, {name: 'Unread', value: 'unread'}]
  const filters = [{name: 'Last Activity', value: 'desc'}, {name: 'Date of creation', value: 'asc'}]
  const dropdownOptions = []
  const dropdownFilters = []

  const changeFilter = (filter, index) => {
    if (currentFilter !== index) {
      setCompanyUpdatesState(companyUpdatesState.reverse())
    }
    setCurrentFilter(index)
  }

  const markAllAsRead = (markNotificationAsRead) => {
    (oldUnreadCompanyUpdatesState.unreadNotifications || []).map((notification, index) => {
      if (notification.notifiable.__typename !== 'MeetingRequest' && notification.notifiable.__typename !== 'UserUpdateDiscussion' && notification.notifiable.discussion) {
        markNotificationAsRead({ variables: { notificationId: notification.id } })
      }
      if (index + 1 === oldUnreadCompanyUpdatesState.unreadNotifications.length) {
        setTimeout(() => {
          refetchNotifications()
        }, 2000)
      }
    })
  }

  const selectViewType = (index, option) => {
    setCurrentOption(index)
    switch (option.value) {
      case 'read':
        setNoDataText('This company has no conversations without unread messages.')
        setCompanyUpdatesState(currentFilter === 0 ? readCompanyUpdatesState : readCompanyUpdatesState.reverse())
        break
      case 'unread':
        setNoDataText('This company has no unread messages.')
        setCompanyUpdatesState(currentFilter === 0 ? unreadCompanyUpdatesState : unreadCompanyUpdatesState.reverse())
        break
      case 'all':
        setNoDataText('This company has no conversations yet.')
        setCompanyUpdatesState(currentFilter === 0 ? oldCompanyUpdatesState : oldCompanyUpdatesState.reverse())
        break
    }
  }

  options.map((option, index) => {
    dropdownOptions.push(<li key={option.value} className={classes.option} onClick={() => selectViewType(index, option)}>{option.name}</li>)
  })

  filters.map((filter, index) => {
    dropdownFilters.push(<li key={filter.value} className={classes.option} onClick={() => changeFilter(filter.value, index)}>{filter.name}</li>)
  })


  if (can && can.manage) {
    createCompanyUpdateButton = (
      <>
        <Button
          className={classes.updatesButton}
          onClick={handleCreateCompanyUpdateOpen}
          size="small"
          color="primary"
        >
          Create Update
          <FontAwesomeIcon className={classes.icon} icon={faLongArrowRight} />
        </Button>
        <Dialog
          fullScreen={fullScreen}
          open={createCompanyUpdateOpen}
          onClose={handleCreateCompanyUpdateClose}
          TransitionComponent={Transition}
          className={classes.modal}
        >
          <AppBar
            color="default"
            elevation={0}
            className={classes.appBar}
          >
            <Toolbar>
              <Typography variant="h6" color="inherit" className={classes.appBarTitle}>
                Create a new update
              </Typography>
              <IconButton
                className={classes.appBarIcon}
                color="inherit"
                onClick={handleCreateCompanyUpdateClose}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <CreateCompanyUpdateForm
              onSave={(data) => {
                refetch()
                handleCreateCompanyUpdateClose()
              }}
              companySlug={slug}
            />
          </DialogContent>
        </Dialog>
      </>
    )
  }

  return <TitledPaper
    noLogo
    updates
    actions={
      <>
        {/* slug && (
          (companyUpdates || []).length > 0 && <Button
            className={classes.updatesButton}
            component={BrowserLink}
            size="small"
            color="primary"
            to={`/companies/${slug}/updates/all`}
          >
            Show all
            <FontAwesomeIcon icon={faLongArrowRight} className={classes.icon} />
          </Button>
        ) */}
        {createCompanyUpdateButton}
      </>
    }
  >
    <ul className={classes.actionsFilterBar}>
      <li>
        <Mutation mutation={MarkNotificationAsRead}>
          {markNotificationAsRead => {
            return <Button
              className={classes.link}
              component={BrowserLink}
              size="small"
              color="primary"
              onClick={() => markAllAsRead(markNotificationAsRead)}
            >
              Mark all as read
            </Button>
          }}
      </Mutation>
      </li>
      <li style={{display: 'flex'}}>
        <div className={classes.centered}>
          <label className={classes.blackLabel}>Showing:</label>
          <Button
            className={classes.link}
            component={BrowserLink}
            size="small"
            color="primary"
            onClick={() => setShowOptions(!showOptions)}
          >
            {options[currentOption].name}
            <FontAwesomeIcon className={classes.icon} icon={showOptions ? faCaretUp : faCaretDown} />
          </Button>
          {showOptions && <ul className={classes.dropdownOptions}>
            {dropdownOptions}
          </ul>}
        </div>
        <div className={classes.centered}>
          <label className={classes.blackLabel}>Sort by:</label>
          <Button
            className={classes.link}
            component={BrowserLink}
            size="small"
            color="primary"
            onClick={() => setShowFilters(!showFilters)}
          >
            {filters[currentFilter].name}
            <FontAwesomeIcon className={classes.icon} icon={showFilters ? faCaretUp : faCaretDown} />
          </Button>
          {showFilters && <ul className={classes.dropdownOptions}>
            {dropdownFilters}
          </ul>}
        </div>
      </li>
    </ul>
    {(companyUpdatesState || []).length > 0 && (
      <Grid container className={classes.updatesContainer}>
        {companyUpdatesState.map(discussion => {
          let firstMessage = discussion.messages[0]
          const itHaveUnreadMessages = messageCounterState[discussion.id] ? messageCounterState[discussion.id] : null
          if (!firstMessage) {
            return null
          }

          firstMessage.body = truncateWithEllipses(firstMessage.body, 250)

          return (
            <Grid item xs={12} md={12} key={discussion.id}>
              {itHaveUnreadMessages ? <Badge className={classes.badge} badgeContent={itHaveUnreadMessages} color="primary">
                <Message
                  variant='body1'
                  message={firstMessage}
                  onClick={() =>
                    history.push(
                      `/companies/${slug}/updates/${discussion.id}`
                    )
                  }
                />
              </Badge> : <Message
                variant='body1'
                message={firstMessage}
                onClick={() =>
                  history.push(
                    `/companies/${slug}/updates/${discussion.id}`
                  )
                }
              />}
            </Grid>
          )
        })}
      </Grid>
    )}
    {(companyUpdatesState || []).length === 0 &&
      <section className={classes.noUpdates}>
        <FontAwesomeIcon icon={faBan} className={classes.iconBig} />
        <Typography className={classes.text}>
          {noDataText}
        </Typography>
      </section>
    }
  </TitledPaper>
}

export default withStyles(styles)(withMobileDialog()(ProductHero))
