import gql from 'graphql-tag'

const updateProductImage = gql`
  mutation updateProductImage($id: ID!, $input: ProductImageInput!) {
    updateProductImage(id: $id, input: $input) {
      id
    }
  }
`

export default updateProductImage
