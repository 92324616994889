import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import PaddedPaper from '../core/PaddedPaper'
import { bigStone } from '../layout/colors'
import { label, smallTitle } from '../layout/styleComponents'

const styles = theme => ({
  footerPaddedPaperDashboard: {
    alignItems: 'center',
    alignSelf: 'flex-end',
    background: 'transparent',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: '3rem 2rem',
    width: '100%'
  },
  innerFooter: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    maxWidth: '45rem',
    margin: '0 auto',
    width: '100%',
    '@media (min-width: 45rem)': {
      justifyContent: 'space-between'
    },
    '@media (min-width: 51rem)': {
      maxWidth: '50rem',
    },
    '@media (min-width: 66rem)': {
      maxWidth: '940px',
    }
  },
  linkDashboard: {
    ...smallTitle,
    fontSize: '75%',
    fontWeight: '400',
    marginRight: '.75rem',
    textDecoration: 'none',
    width: '100%'
  },
  copyRight: {
    ...label,
    fontSize: '75%',
    fontWeight: 400,
    marginTop: '.5rem',
    textAlign: 'left',
    textTransform: 'none',
    '@media (max-width: 45rem)': {
      width: '100% !important'
    },
    '@media (min-width: 45rem)': {
      margin: 0,
      width: 'auto',
      textAlign: 'right'
    }
  },
  footerPaddedPaper: {
    alignSelf: 'flex-end',
    borderTop: `1px solid ${theme.palette.divider}`,
    flexShrink: '0',
    width: '100%',
    textAlign: 'right',
    padding: '.5rem',
    marginTop: '3rem',
    boxShadow: 'none'
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
    listStyleType: 'none',
    padding: 0,
    margin: 0
  },
  listDashboard: {
    '@media(max-width: 45rem)': {
      width: '100%',
    },
    '& li': {
      margin: 0
    }
  },
  fullLink: {
    fontSize: '80%',
    width: '100%'
  },
  link: {
    fontSize: '100%',
    '& > a': {
      textDecoration: 'none',
      color: bigStone
    }
  },
  spacer: {
    margin: 0,
    textAlign: 'center',
    '@media(min-width: 35.5rem)': {
      marginLeft: '1rem',
      textAlign: 'right'
    }
  }
})

const desktopLinks = profile => {
  const defaultLinks = [
    { children: 'Conversations', url: '/conversations'},
    { children: 'Notifications', url: '/notifications' },
    { children: 'Invite', url: '/invite' }
  ]
  return defaultLinks
}

const Footer = ({ classes, className }) => {
  if (window.location.href.toLowerCase().includes('form') || window.location.href.toLowerCase().includes('invite') || window.location.href.toLowerCase().includes('edit') || window.location.href.toLowerCase().includes('new')) return null
  else {
    return (
      <PaddedPaper className={`${classes.footerPaddedPaperDashboard} ${className}`}>
        <section className={classes.innerFooter}>
          <ul className={`${classes.list} ${classes.listDashboard}`}>
            <li className={`${classes.spacer}`}>
              <a className={classes.linkDashboard} href="https://launch.co/privacy">Privacy Policy</a>
            </li>
            <li>
              <a className={classes.linkDashboard} href="https://launch.co/harassment-policy">Harassment Policy</a>
            </li>
          </ul>
          <article className={classes.copyRight}>
            Copyright &copy; {new Date().getFullYear()} LAUNCH
          </article>
        </section>
      </PaddedPaper>
    )
  }
}

export default withStyles(styles)(Footer)
