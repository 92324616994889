import { MenuItem, Select, Typography } from '@material-ui/core'
import React from 'react'

export default function SelectBox (props) {
  const { value, label, content, choices, required, onChange } = props
  const options = choices.map((choice, i) => {
    return (
      <MenuItem key={i} value={choice.get('label')}>{choice.get('label')}</MenuItem>
    )
  })
  return (
    <div>
      <Typography variant="body1">
        {label} <small><i>{required && '(required)'}</i></small>
      </Typography>
      <Typography variant="body1">
        <small>{content}</small>
      </Typography>
      <Select
        fullWidth
        onChange={onChange}
        value={value}
      >
        {options}
      </Select>
    </div>
  )
}
