import { TextField } from '@material-ui/core'
import React from 'react'

const LongTextField = ({ label, value, onChange, maxLength, classes }) => <TextField
  fullWidth
  className={classes.longInput}
  label={label}
  value={value}
  onChange={onChange}
/>

export default LongTextField
