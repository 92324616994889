import React, { useRef } from 'react'
import ReactImageGallery from 'react-image-gallery'
import './ImageGallery.css'
import { withStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import { Mutation } from 'react-apollo'
import UpdateProductImage from '../../graphql/mutations/UpdateProductImage'
import CreateProductImage from '../../graphql/mutations/CreateProductImage'
import DeleteProductImage from '../../graphql/mutations/DeleteProductImage'
import { Player, BigPlayButton } from 'video-react'

const styles = theme => ({
  customControls: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 1000
  },
  addAnImageSlide: {
    height: '300px',
    width: '100%',
    maxWidth: '50rem',
    backgroundColor: '#eee'
  },
  addAnImageButton: {
    width: '100%',
    height: '100%'
  }
})

const isImageSet = (item) => Boolean(item.imageSet)
const isImage = (item) => !isImageSet(item) && !isVideo(item)
const isVideo = (item) => Boolean(item.video)

export const maybeRenderImageSet = (item, { onImageLoad, onImageError }) => isImageSet(item) && <picture
  onLoad={onImageLoad}
  onError={onImageError}
>
  {
    item.imageSet.map((source, index) => (
      <source
        key={index}
        media={source.media}
        srcSet={source.srcSet}
        type={source.type}
      />
    ))
  }
  <img
    alt={item.originalAlt}
    src={item.original}
  />
</picture>

export const maybeRenderImage = (item, { onImageLoad, onImageError }) => isImage(item) && <img
  src={item.original}
  alt={item.originalAlt}
  srcSet={item.srcSet}
  sizes={item.sizes}
  title={item.originalTitle}
  onLoad={onImageLoad}
  onError={onImageError}
/>

export const maybeRenderVideo = (item, { ref }) => isVideo(item) && <Player
  playsInline
  poster={item.poster}
  src={item.video}
  ref={ref}
>
  <BigPlayButton position="center" />
</Player>

const renderItem = ({ props, classes, onUpdate }) => (item) => {
  const onImageError = props.onImageError || (() => {})
  const { onImageLoad, ref } = props

  return <>
    <div className='image-gallery-image'>
      {maybeRenderImageSet(item, { onImageLoad, onImageError })}
      {maybeRenderImage(item, { onImageLoad, onImageError })}
      {maybeRenderVideo(item, { ref })}

      {
        item.description &&
          <span className='image-gallery-description'>
            {item.description}
          </span>
      }
    </div>
  </>
}

export const onSlide = (items) => (i) => {
  const item = items[i - 1]
  if (isVideo(item)) {
    if (item.ref.current) {
      item.ref.current.pause()
    }
  }
}

const ImageGallery = props => {
  let { classes, items, onUpdate, companySlug, ...rest } = props
  items = items.map(item => {
    const ref = useRef(null)
    return {
      ...item,
      ref,
      renderItem: renderItem({ props: { ...props, ref }, classes, onUpdate })
    }
  })

  return <ReactImageGallery
    items={items}
    lazyLoad={false}
    onSlide={onSlide(items)}
    useBrowserFullscreen={false}
    {...rest}
  />
}

export default withStyles(styles)(ImageGallery)
