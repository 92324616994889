import { Typography, Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import TabsContainer from '../layout/TabsContainer'
import HeaderHero from '../core/HeaderHero'
import MainHeading from '../core/MainHeading'
import { faEye } from '@fortawesome/pro-light-svg-icons'
import { transparentBackgroundContainer } from '../layout/styleComponents'
const styles = theme => ({
  paper: {
    background: 'white',
  },
  transparentBackgroundContainer: {
    ...transparentBackgroundContainer,
    background: 'white'
  },
  item: {
    borderRadius: '.5rem',
    background: 'white',
    boxShadow: `0 0 .3rem -.15rem ${theme.palette.success.dark}`,
    maxWidth: '19rem',
    margin: '0 1.5rem 1.5rem 0 !important',
    padding: '.5rem 1rem !important'
  },
  flex: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    width: '100%',
    margin: 0,
    padding: '0 1.5rem'
  },
  title: {
    marginLeft: '1rem',
    flexGrow: 3
  },
  dataInfo: {
    color: theme.palette.primary.main,
    fontFamily: 'Lato !important',
    fontSize: '.95rem',
    padding: 0,
    margin: 0,
    '& span, & p': {
      color: `${theme.palette.primary.main} !important`,
      fontFamily: 'Lato !important',
      fontWeight: 'bold'
    },
    width: '100%'
  },
  date: {
    fontSize: '.9rem !important'
  },
  startDate: {
    color: 'rgba(0,0,0, .5)',
    marginLeft: '0',
    '& span, & p': {
      color: 'rgba(0,0,0, .5)'
    }
  },
  data: {
    width: '100%'
  },
  titleWrapper: {
    margin: '1rem 0'
  },
  heroCentered: {
    minHeight: '12rem'
  },
  centered: {
    alignItems: 'center',
    justifyContent: 'center'
  },
  relative: {
    position: 'relative'
  },
})

class UserVisits extends React.Component {
  render () {
    const {
      visits,
      classes
    } = this.props

    return (<>
      <HeaderHero className={`${classes.centered} ${classes.heroCentered}`}>
        <Grid container alignItems="flex-start" className={classes.relative}>
          <Grid item xs={12} className={classes.centered}>
            <MainHeading
              primary='Visits'
            />
          </Grid>
        </Grid>
      </HeaderHero>
      <Grid container className={classes.transparentBackgroundContainer}>
        <TabsContainer tabs={[]} icon={faEye} title="Visits">
          <ul className={classes.flex}>
            {(visits || []).length > 0 && visits.map(visit => {
              return <li className={`${classes.item} ${classes.flex}`} key={visit.id}>
                <Typography className={`${classes.dataInfo} ${classes.startDate}`}>Date: <span className={classes.date}>{visit.startedAt}</span></Typography>
                <Typography className={classes.dataInfo}>
                  Ip: <span>{visit.ip}</span>
                </Typography>
                <article className={`${classes.data}`}>
                  <Typography className={classes.dataInfo}>
                    OS: <span>{visit.os}</span>
                  </Typography>
                  <Typography className={classes.dataInfo}>
                    Browser: <span>{visit.browser}</span>
                  </Typography>
                </article>
              </li>
            })}
          </ul>
        </TabsContainer>
      </Grid>
    </>)
  }
}

export default withStyles(styles)(UserVisits)
