import {
  blueRibbon,
  redOrange,
  tealBlue,
  bigStone,
  gullGray,
  background,
  white
} from './colors'

export const transparentBackgroundContainer = {
  alignItems: 'center',
  background: 'transparent',
  borderRadius: '.75rem',
  boxShadow: `0 0 1.5rem -1.25rem ${bigStone}`,
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  margin: '1.5rem auto 0',
  maxWidth: '65rem',
  width: '95%',
  '@media (min-width: 45rem)': {
    width: '100%'
  }
}

export const roundedHeroImage = {
  borderRadius: '50%',
  display: 'block',
  margin: '0 auto 1.5rem',
  maxHeight: '6rem',
  maxWidth: '100%',
  width: 'auto'
}

export const generalButton = {
  borderRadius: '2rem',
  boxShadow: '0px 0px 19.2px 0.8px rgba(188, 203, 219, 1) !important',
  color: 'white',
  fontSize: '1rem',
  fontFamily: 'Lato',
  padding: '.55rem 1.5rem',
  fontWeight: 400,
  textTransform: 'none',
  transform: 'unset !important',
  transition: 'all .25s',
  '& span, & p': {
    fontSize: '1rem',
    fontWeight: 400
  },
  '& svg, & i': {
    marginLeft: '.5rem'
  }
}

export const blueButton = {
  ...generalButton,
  background: blueRibbon,
  textDecoration: 'none',
  '&:hover': {
    background: "#013ce9",
    color: 'white'
  }
}

export const redButton = {
  ...generalButton,
  background: redOrange,
  '&:hover': {
    background: "#d30206",
    color: 'white'
  }
}

export const tealBorderedButton = {
  ...generalButton,
  background: 'transparent',
  border: `1px solid ${tealBlue}`,
  color: blueRibbon,
  fontWeight: '500'
}

export const mobileLoginButton = {
  background: tealBlue,
  borderRadius: 0,
  color: blueRibbon,
  left: 0,
  top: '0',
  fontWeight: '500',
  width: '100%',
  '& span': {
    textAlign: 'left',
    justifyContent: 'flex-start'
  }
}

export const generalTitle = {
  fontFamily: 'Lato',
  fontWeight: 100,
  textTransform: 'none',
  '& strong': {
    fontWeight: 300
  }
}

export const title = {
  ...generalTitle,
  color: bigStone,
  fontSize: '40px',
  lineHeight: '125%',
  margin: '1.5rem auto 2rem',
  '@media (min-width: 45rem)': {
    fontSize: '50px'
  }
}

export const mediumTitle = {
  ...title,
  fontSize: '2rem',
  textAlign: 'center',
  '@media (min-width: 45rem)': {
    fontSize: '3.35rem'
  }
}

export const subTitle = {
  color: bigStone,
  fontSize: '25px',
  lineHeight: '200%',
  fontWeight: 300,
  '@media (min-width: 45rem)': {
    '& strong': {
      fontWeight: 400
    }
  }
}

export const whiteSubTitle = {
  ...subTitle,
  color: white,
  textTransform: 'none',
  fontSize: '25px'
}

export const whiteText = {
  ...whiteSubTitle,
  fontSize: '12px',
  '@media (min-width: 45rem)': {
    fontSize: '.9rem'
  }
}

export const smallTitle = {
  ...generalTitle,
  fontSize: '.9rem',
  color: blueRibbon
}

export const label = {
  fontFamily: 'Lato',
  fontSize: '12px',
  color: gullGray,
  fontWeight: 400,
  textTransform: 'uppercase'
}

export const homeButton = {
  ...generalButton,
  boxShadow: 'none !important',
  position: 'absolute',
  '@media (max-width: 45rem)': {
    ...mobileLoginButton,
    boxShadow: 'none !important'
  },
  '@media (min-width: 45rem)': {
    ...tealBorderedButton,
    boxShadow: 'none !important',
    right: '0',
    top: '4.25rem'
  },
  '& span': {
    fontWeight: 500
  }
}

export const blackText = {
  color: bigStone,
  display: 'inline-block',
  fontSize: '16px',
  margin: '.25rem 0 3rem',
  textAlign: 'center'
}

export const uppercaseSmallDarkText = {
  ...blackText,
  fontSize: '12px',
  margin: 0,
  textTransform: 'uppercase',
  fontWeight: 400
}

const input = {
  color: bigStone,
  fontFamily: 'Lato',
  fontSize: '16px',
  height: 'unset',
  marginTop: '1rem',
  padding: '.5rem 0',
  '&::placeholder': {
    color: bigStone
  }
}

export const formButton = {
  backgroundColor: `${background} !important`,
  border: 'none',
  borderRadius: '.5rem',
  boxShadow: '0px 0px 19.2px 0.8px rgba(188, 203, 219, 1)',
  color: bigStone,
  fontSize: '1.5rem',
  minWidth: 'unset',
  padding: '.4rem .8rem',
  '&:last-child': {
    borderRadius: '0 .5rem .5rem 0',
  },
  '&:first-child': {
    borderRadius: '.5rem 0 0 .5rem',
  },
  '&:disabled': {
    color: tealBlue
  },
  '&:hover': {
    boxShadow: '0px 0px 19.2px 0.8px rgba(188, 203, 219, 1)',
    backgroundColor: `${tealBlue} !important`,
    color: blueRibbon,
    transform: 'none'
  }
}

export const textInput = {
  background: 'none',
  '& div': {
    background: 'none',
    '&::before': {
      borderBottom: `1px solid ${tealBlue} !important`
    },
    '&:hover::before': {
      borderBottom: `1px solid ${tealBlue} !important`
    }
  },
  '& input, & textarea': {
    ...input
  }
}

export const centeredFlex = {
  alignItems: 'center',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center'
}
