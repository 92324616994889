import { faComment, faBullhorn } from '@fortawesome/free-solid-svg-icons'
import { faBan } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AppBar, Button, Dialog, DialogContent, IconButton, Slide, Toolbar, Typography, withMobileDialog } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { Link as BrowserLink } from 'react-router-dom'
import CreateUserUpdateForm from '../core/CreateUserUpdateForm'
import Message from '../core/Message'
import TitledPaper from '../core/TitledPaper'
import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons'
import { blueButton, blackText, centeredFlex } from '../layout/styleComponents'
import { background, white } from '../layout/colors'
import TabsContainer from '../layout/TabsContainer'

const truncateWithEllipses = (text, max) =>
  text.substr(0, max - 1) + (text.length > max ? '...' : '')

function Transition (props) {
  return <Slide direction="up" {...props} />
}

const styles = theme => ({
  modal: {
    '& .MuiDialog-paperFullWidth, & .MuiDialog-paperFullScreen': {
      borderRadius: '.5rem',
      maxWidth: '45rem',
      maxHeight: '80%',
      width: '95%',
      height: 'unset',
      margin: 'auto'
    },
    '& .MuiDialogContent-root': {
      padding: '0 1.5rem 2rem',
    }
  },
  appBarTitle: {
    flex: 1
  },
  text: {
    ...blackText,
    fontSize: '1rem',
    margin: 0,
    fontWeight: 400,
    width: '100%'
  },
  appBarIcon: {
    marginRight: '-1rem'
  },
  button: blueButton,
  updatesPaddedPaper: {
    background: 'none',
    borderBottom: '.2rem solid black',
    boxShadow: 'none',
    padding: '0.5rem 2rem',
    margin: '0 auto 4rem',
    width: '100%',
    '@media(min-width: 60rem)': {
      padding: '2rem 0 2rem 2rem'
    }
  },
  noUpdates: {
    ...centeredFlex,
    backgroundColor: white
  },
  iconBig: {
    color: theme.palette.info.light,
    fontSize: '6rem',
    fontWeight: 300,
    margin: '3rem auto 1.5rem'
  },
  appBar: {
    backgroundColor: theme.palette.info.light,
    position: 'relative',
    paddingRight: '0 !important',
    padding: '.5rem 1.5rem'
  }
})

function UserUpdatesHero (props) {
  const {
    classes,
    refetch,
    userUpdates,
    slug,
    history,
    fullScreen,
    can,
    loading,
    handleCreateUserUpdateClose,
    handleCreateUserUpdateOpen,
    createUserUpdateOpen
  } = props

  let createUserUpdateButton
  const tabs = []

  if (can && can.manage) {
    createUserUpdateButton = (
      <>
        <Button className={classes.button} size="small" color="primary" onClick={handleCreateUserUpdateOpen}>
          Create Update
          <FontAwesomeIcon icon={faLongArrowRight} />
        </Button>
        <Dialog
          fullScreen={fullScreen}
          open={createUserUpdateOpen}
          onClose={handleCreateUserUpdateClose}
          TransitionComponent={Transition}
          className={classes.modal}
        >
          <AppBar
            color="default"
            elevation={0}
            className={classes.appBar}
          >
            <Toolbar className={classes.toolbar}>
              <Typography variant="h6" color="inherit" className={classes.appBarTitle}>
                Create a new update
              </Typography>
              <IconButton className={classes.appBarIcon} color="inherit" onClick={handleCreateUserUpdateClose} aria-label="Close">
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <CreateUserUpdateForm
              userSlug={slug}
              className={classes.createUserUpdateForm}
              onSave={() => {
                refetch()
                handleCreateUserUpdateClose()
              }}
            />
          </DialogContent>
        </Dialog>
      </>
    )
  }

  return <TabsContainer tabs={tabs} icon={faBullhorn} title="Updates">
    <TitledPaper
      noLogo
      actions={
        loading ? (
          <Skeleton />
        ) : (
            <>
              {(userUpdates || []).length > 0 && <Button className={classes.button}
                component={BrowserLink}
                size="small"
                color="primary"
                to={`/users/${slug}/updates/all`}
              >
                Show all
              </Button>}
              {createUserUpdateButton}
            </>
        )
      }
    >
      {(userUpdates || []).length > 0 && userUpdates.map(discussion => {
        let firstMessage = discussion.messages[0]
        if (!firstMessage) {
          return null
        }
        const replyCount = discussion.messages.length - 1

        firstMessage.body = truncateWithEllipses(firstMessage.body, 250)

        return <Message
          key={discussion.id}
          message={firstMessage}
          metadata={
            <Typography style={{ display: 'flex',
              flexDirection: 'row',
              fontSize: '.85rem',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              width: '3.5rem',
              marginTop: '.15rem' }}>
              <FontAwesomeIcon icon={faComment} />
              {replyCount}
            </Typography>}
          onClick={() =>
            history.push(`/users/${slug}/updates/${discussion.id}`)
          }
        />
      })}

      {(userUpdates || []).length === 0 &&
        <section className={classes.noUpdates}>
          <FontAwesomeIcon icon={faBan} className={classes.iconBig} />
          <Typography className={classes.text}>
            User doesn't have any updates yet.
          </Typography>
        </section>
      }
    </TitledPaper>
  </TabsContainer>
}

export default withStyles(styles)(withMobileDialog()(UserUpdatesHero))
