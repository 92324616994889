import CssBaseline from '@material-ui/core/CssBaseline'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import { AppContext } from '../App'
import Footer from '../core/Footer'
import AppBar from './AppBar'
import brand from './brand'
import theme from './theme'
import Title from './Title'
import {background} from './colors'
import SectionWidthBefore from '../core/SectionWithBefore'

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: background
    }
  },
  autoHeight: {
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    minHeight: '100%',
    width: '100%',
    padding: 0
  },
  generalC: {
    minHeight: '100vh',
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    overflow: 'hidden',
  },
  main: {
    display: 'flex',
    flexGrow: '0',
    flexWrap: 'wrap',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
    '& > div': {
      width: '100%',
    }
  }
})

const AppLayout = props => {
  const { classes, children, className } = props
  let { title } = props

  if (!title) {
    title = <Title>
      <a href='/'>TheSyndicate.com</a>
    </Title>
  }
  return <AppContext.Consumer style={{backgroundColor: '#eeeeee'}} className={classes.autoHeight}>
    {context => {
      return <div className={classes.generalC}>
        <CssBaseline />
        <SectionWidthBefore itShouldBeStartAligned>
          <div className={classes.container}>
            <div className={classes.main}>
              {children}
            </div>
          </div>
        </SectionWidthBefore>
        <Footer />
      </div>
    }}
  </AppContext.Consumer>
}

export default withStyles(styles)(AppLayout)
