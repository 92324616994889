import gql from 'graphql-tag'

const createDealDiscussion = gql`
  mutation createDealDiscussion($dealId: ID!, $messageInput: MessageInput!) {
    createDealDiscussion(dealId: $dealId, messageInput: $messageInput) {
      discussion {
        id
      }
    }
  }
`

export default createDealDiscussion
