import brand from '../brand'
import {background} from '../colors'

export const metadata = {
  flexDirection: 'row',
  flexWrap: 'wrap',
  display: 'flex',
  alignItems: 'center',
  width: '100%'
}

export const metadataItem = {
  color: background,
  fontFamily: 'Lato',
  fontSize: '14px',
  fontWeight: 300,
  margin: 0,
  width: '100%'
}

export const metadataItemIcon = {
  color: 'black',
  fontSize: '110%',
  marginRight: '.5rem',
  width: '100%'
}
