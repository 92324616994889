import React, {useEffect} from 'react'
import { Mutation, Query } from 'react-apollo'
import { Typography } from '@material-ui/core'
import { Redirect } from 'react-router'
import MarkNotificationAsRead from '../../graphql/mutations/MarkNotificationAsRead'
import getNotifications from '../../graphql/queries/getNotifications'
import AppLayout from '../layout/AppLayout'
import CenterColumn from '../layout/CenterColumn'
import Title from '../layout/Title'
import Notifications from '../notifications/Notifications'
import {getUnreadConversationsAndUpdates} from '../../graphql/queries/getNotifications'
import { withSnackbar } from 'notistack'

function NotificationsRoute ({ history, enqueueSnackbar }) {
  useEffect(() => {
    document.title = "The Syndicate - Notifications"
  }, []);
  return <Mutation mutation={MarkNotificationAsRead}>
    {markNotificationAsRead => {
      return (<Query query={getNotifications} fetchPolicy="cache-and-network">
        {({ loading, error, data }) => {
          if (error) {
            if (error.message && error.message.includes('unauthorized')) {
              enqueueSnackbar("You don't have access to see this.", { variant: 'error' })
              return <Redirect
                to={`/login`}
              />
            } else {
              return <Typography>Error...</Typography>
            }
          }

          let notifications
          if (!loading) {
            notifications = data.notifications
          } else {
            return null
          }

          const title = <Title>Notifications</Title>
          return <Query query={getUnreadConversationsAndUpdates} fetchPolicy="cache-and-network">
          {({ loading, error, data, refetch, networkStatus }) => {
            if (loading) {
              return null
            }
            return <AppLayout title={title}>
              <CenterColumn>
                <Notifications markNotificationAsRead={markNotificationAsRead} notifications={notifications} history={history} loading={loading} unreadNotifications={data.profile} refetch={refetch}/>
              </CenterColumn>
            </AppLayout>
          }}
        </Query>
        }}
      </Query>)
    }}</Mutation>
}

export default withSnackbar(NotificationsRoute)
