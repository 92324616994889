import { Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import React from 'react'

const Dashboard = props =>
  (
    <Grid
      item xs={12}
      container
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Typography variant="display3">Dashboard</Typography>
    </Grid>
  )

export default Dashboard
