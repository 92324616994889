import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'

const styles = theme => ({
  question: {
    margin: '0 auto 1rem',
    fontSize: '100%'
  },
  option: {
    fontSize: '100%',
    display: 'flex',
    margin: '0 0 .5rem',
    alignItems: 'flex-start',
    '& .MuiIconButton-root': {
      padding: 0,
      margin: 0
    },
    '& .MuiTypography-root': {
      fontSize: '100%'
    },
    '& span:nth-child(1)': {
      marginRight: '.5rem'
    }
  },
  radio: {
    '& svg': {
      width: '1.5rem'
    }
  }
})

const SingleSelect = (props) => {
  const {
    classes,
    value,
    label,
    choices,
    required,
    onChange,
    onSubmit
  } = props

  const options = choices.map((choice, i) => {
    return (
      <FormControlLabel
        className={classes.option}
        key={choice.get('id')}
        label={choice.get('label')}
        value={choice.get('id')}
        control={<Radio className={classes.radio} />}
      />
    )
  })

  return (<div>
    <FormControl component="fieldset">
      <Typography variant="body1" className={classes.question}>
        {label} <small><i>{required && '(required)'}</i></small>
      </Typography>
      <RadioGroup
        onChange={(event) => {
          onChange(event.target.value)
          onSubmit()
        }}
        value={value}
        aria-label={label}
        name={label}
      >
        {options}
      </RadioGroup>
    </FormControl>
  </div>)
}

export default withStyles(styles)(SingleSelect)
