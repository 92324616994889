import gql from 'graphql-tag'

const getIncompleteFormSubmission = gql`
  query IncompleteFormSubmission($formKey: String!) {
    incompleteFormSubmission(formKey: $formKey){
      id
      questionSubmissions {
        id
        question {
          id
        }
        value
      }
    }
  }
`

export default getIncompleteFormSubmission
