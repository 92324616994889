import gql from 'graphql-tag'

const updateCompany = gql`
  mutation updateCompany($id: ID!, $companyInput: CompanyInput!) {
    updateCompany(id: $id, companyInput: $companyInput) {
      id
    }
  }
`

export default updateCompany
