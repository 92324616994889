import { AppBar, Button, Dialog, DialogContent, Grid, IconButton, Slide, Toolbar, Typography, withMobileDialog } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import "react-image-gallery/styles/css/image-gallery.css";
import { Link as BrowserLink } from 'react-router-dom';
import CreateCompanyUpdateForm from '../core/CreateCompanyUpdateForm';
import Message from '../core/Message';
import TitledPaper from '../core/TitledPaper';
import { smallTitle, transparentBackgroundContainer } from '../layout/styleComponents';
import TabsContainer from '../layout/TabsContainer'
import HeaderHero from '../core/HeaderHero'
import MainHeading from '../core/MainHeading'
import { faEye } from '@fortawesome/pro-light-svg-icons'

const truncateWithEllipses = (text, max) => text.substr(0, max - 1) + (text.length > max ? '...' : '')

function Transition (props) {
  return <Slide direction="up" {...props} />;
}

const styles = theme => ({
  modal: {
    '& .MuiDialog-paperFullWidth, & .MuiDialog-paperFullScreen': {
      borderRadius: '.5rem',
      maxWidth: '45rem',
      maxHeight: '80%',
      width: '95%',
      height: 'unset',
      margin: 'auto'
    },
    '& .MuiDialogContent-root': {
      padding: '0 1.5rem 2rem',
    }
  },
  paper: {
    background: 'white',
  },
  transparentBackgroundContainer: {
    ...transparentBackgroundContainer,
    background: 'white'
  },
  item: {
    background: 'white',
    boxShadow: `0 0 .5rem -.35rem ${theme.palette.success.dark}`,
    maxWidth: '15rem',
    margin: '0 .75rem 1.5rem'
  },
  appBar: {
    backgroundColor: theme.palette.info.light,
    paddingRight: '0 !important',
    padding: '.5rem 1.5rem'
  },
  flex: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    width: '100%'
  },
  title: {
    marginLeft: '1rem',
    flexGrow: 3
  },
  dataInfo: {
    color: theme.palette.primary.main,
    fontFamily: 'Lato !important',
    fontSize: '.95rem',
    '& span, & p': {
      color: `${theme.palette.primary.main} !important`,
      fontFamily: 'Lato !important',
      fontWeight: 'bold'
    }
  },
  startDate: {
    color: 'rgba(0,0,0, .5)',
    marginLeft: '.5rem',
    '& span, & p': {
      color: 'rgba(0,0,0, .5)'
    }
  },
  data: {
    width: '100%'
  },
  titleWrapper: {
    margin: '1rem 0'
  },
  heroCentered: {
    minHeight: '12rem'
  },
  centered: {
    alignItems: 'center',
    justifyContent: 'center'
  },
  relative: {
    position: 'relative'
  },
})


class CompanyUpdatesBase extends React.Component {
  state = {
    createCompanyUpdateOpen: false
  }

  handleCreateCompanyUpdateOpen = () => {
    this.setState({ createCompanyUpdateOpen: true })
  };

  handleCreateCompanyUpdateClose = () => {
    this.setState({ createCompanyUpdateOpen: false })
  };

  render () {
    const {
      fullScreen,
      history,
      companySlug,
      can,
      classes,
      updates,
      refetch
    } = this.props

    let createCompanyUpdateButton;
    if (can.manage) {
      createCompanyUpdateButton = <>
        <Button onClick={this.handleCreateCompanyUpdateOpen}>Create Update</Button>
        <Dialog
          classes={{
            scrollPaper: classes.dialog
          }}
          TransitionComponent={Transition}
          fullScreen={fullScreen}
          fullWidth
          maxWidth="md"
          open={this.state.createCompanyUpdateOpen}
          onClose={this.handleCreateCompanyUpdateClose}
          scroll="paper"
          className={classes.modal}
        >
          <AppBar
            color="white"
            elevation={0}
            className={classes.appBar}
          >
            <Toolbar
              className={classes.toolbar}
            >
              <Typography variant="h6" color="inherit" className={classes.appBarTitle}>
                Create a new update
              </Typography>
              <IconButton
                color="inherit"
                onClick={this.handleCreateCompanyUpdateClose}
                aria-label="Close"
                className={classes.appBarIcon}
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <CreateCompanyUpdateForm onSave={refetch} companySlug={companySlug} />
          </DialogContent>
        </Dialog>
      </>
    }

    return <>
    <HeaderHero className={`${classes.centered} ${classes.heroCentered}`}>
      <Grid container alignItems="flex-start" className={classes.relative}>
        <Grid item xs={12} className={classes.centered}>
          <MainHeading
            primary='Updates'
          />
        </Grid>
      </Grid>
    </HeaderHero>
    <Grid container className={classes.transparentBackgroundContainer}>
      <TabsContainer tabs={[]} icon={faEye} title="Updates">
        <TitledPaper noLogo actions={createCompanyUpdateButton}>
          {updates.length > 0 &&
            <Grid container spacing={4}>
              {updates.map((discussion) => {
                let firstMessage = discussion.messages[0]
                if (!firstMessage) {
                  return null
                }

                firstMessage.body = truncateWithEllipses(firstMessage.body, 250)

                return <Grid item xs={12} md={12} key={discussion.id}>
                  <Message message={firstMessage} onClick={() => history.push(`./all/${discussion.id}`, { state: { from: history.location.pathname } })} />
                </Grid>
              })}
            </Grid>
          }
        </TitledPaper>
      </TabsContainer>
    </Grid>
  </>}
}

const CompanyUpdates = withStyles(styles)(withMobileDialog()(CompanyUpdatesBase))

export default CompanyUpdates
