import React, {Component} from 'react'
import * as Sentry from '@sentry/browser'

class WithSentry extends Component {
    constructor(props) {
      super(props)
      this.state = { error: null }
      Sentry.init({ dsn: `https://93cf278a2bab485f9eb27623cce8f50b@sentry.io/${process.env.SENTRY_API_KEY}` })
    }

    componentDidCatch (error, errorInfo) {
      this.setState({ error });
      Sentry.withScope(scope => {
        Object.keys(errorInfo).forEach(key => {
          scope.setExtra(key, errorInfo[key])
        });
        Sentry.captureException(error)
      });
    }

    render () {
      if (this.state.error) return <a onClick={() => Sentry.showReportDialog()}>Report feedback</a>
      else return <div>{this.props.children}</div>
    }
}

export default WithSentry