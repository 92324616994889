import gql from 'graphql-tag'

const getForm = gql`
  query form($key: String!) {
    form(key: $key) {
      id
      title
      questions {
        id
        key
        label
        order
        required
        content
        header
        placeholder
        questionType
        choices {
          id
          label
        }
      }
    }
  }
`

export default getForm
