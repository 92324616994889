import gql from 'graphql-tag'

const createFundingHistory = gql`
  mutation CreateFundingHistory($companySlug: String!, $fundingHistoryInput: FundingHistoryInput!){
    createFundingHistory(companySlug: $companySlug, fundingHistoryInput: $fundingHistoryInput){
      id
      roundName
      roundNumber
    }
  }
`

export default createFundingHistory
