import { AppBar, Dialog, DialogContent, IconButton, Slide, Toolbar, Typography, withMobileDialog } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import React, {useEffect} from 'react'
import { Query } from 'react-apollo'
import getCompanyUpdate from '../../graphql/queries/getCompanyUpdate'
import { FullDiscussion } from '../core/Discussion'

const styles = theme => ({
  appBar: {
    backgroundColor: theme.palette.info.light,
    position: 'relative',
    paddingRight: '0 !important',
    padding: '.5rem 1.5rem'
  },
  appBarIcon: {
    marginRight: '-1rem'
  },
  toolbar: {
    justifyContent: 'flex-end'
  }
})

function Transition (props) {
  return <Slide direction="up" {...props} />
}

function CompanyUpdateModalRoute ({
  companySlug,
  fullScreen,
  match: {
    params: { id }
  },
  onClose,
  classes
}) {
  useEffect(() => {
    document.title = "The Syndicate - Company Update Modal"
  }, []);
  return (
    <Dialog
      TransitionComponent={Transition}
      fullScreen={fullScreen}
      fullWidth
      maxWidth="md"
      open
      onClose={onClose}
      scroll="paper"
    >
      <AppBar
        color="default"
        elevation={0}
        className={classes.appBar}
      >
        <Toolbar
          className={classes.toolbar}
        >
          <IconButton
            color="inherit"
            onClick={onClose}
            aria-label="Close"
            className={classes.appBarIcon}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Query
          query={getCompanyUpdate}
          variables={{ companySlug, id }}
          fetchPolicy="cache-and-network"
        >
          {({ loading, error, data, refetch, networkStatus }) => {
            if (error) {
              return <Typography>Error...</Typography>
            }

            let companyUpdate
            if (!loading) {
              companyUpdate = data.company.companyUpdate
            }

            return (
              <FullDiscussion executeReading {...companyUpdate} onCreateMessage={refetch} />
            )
          }}
        </Query>
      </DialogContent>
    </Dialog>
  )
}

export default withStyles(styles)(withMobileDialog()(CompanyUpdateModalRoute))
